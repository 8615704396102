import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import DataGridSkeleton from '@/components/dataDisplay/DataGridSkeleton'
import { SITE_REVIEW_TABLE_CELL_STYLES } from '@/features/site/constants'
import type { MarketProgram } from '@/features/site/types/marketProgram'
import type { CreateSiteForm } from '@/features/site/types/site'
import { formatValue } from '@/features/site/utils/review'

import ReviewSiteSection from './ReviewSiteSection'

type MarketProgramsReviewSectionProps = {
  marketProgramContracts: CreateSiteForm['marketProgramContracts']
  marketPrograms: MarketProgram[]
  isLoading: boolean
}

function MarketProgramsReviewSection({
  marketProgramContracts,
  marketPrograms,
  isLoading,
}: Readonly<MarketProgramsReviewSectionProps>) {
  const { t } = useTranslation()
  const marketProgramContractsLength =
    marketProgramContracts.filter((marketProgramContracts) => marketProgramContracts.isSelected).length ?? 0

  if (isLoading) {
    return <DataGridSkeleton includeWrapper={false} numberOfColumns={4} numberOfRows={2} />
  }

  return (
    <ReviewSiteSection stepId="market_programs" title={t('sites.add_new.steps.market_programs')}>
      {marketProgramContractsLength > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>
                {t('sites.add_new.form.market_programs.table.columns.name')}
              </TableCell>
              <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>
                {t('sites.add_new.form.market_programs.table.columns.revenue_share_fraction')}
              </TableCell>
              <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>
                {t('sites.add_new.form.market_programs.table.columns.rounding_error_in_watts')}
              </TableCell>
              <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>
                {t('sites.add_new.form.market_programs.table.columns.sold_capacity_algorithm')}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {marketProgramContracts
              .filter((marketProgramContract) => marketProgramContract.isSelected)
              .map((marketProgramContract) => (
                <TableRow key={marketProgramContract.marketProgramId}>
                  <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>
                    {formatValue(
                      marketPrograms?.find(
                        (marketProgram) => marketProgram.id === marketProgramContract.marketProgramId,
                      )?.name,
                    )}
                  </TableCell>
                  <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>
                    {formatValue(
                      marketProgramContract.revenueSharingFraction
                        ? String(marketProgramContract.revenueSharingFraction)
                        : '',
                    )}
                  </TableCell>
                  <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>
                    {formatValue(
                      marketProgramContract.roundingErrorInKilowatts
                        ? String(marketProgramContract.roundingErrorInKilowatts)
                        : '',
                    )}
                  </TableCell>
                  <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>
                    {formatValue(
                      marketProgramContract.soldCapacityAlgorithmType
                        ? t(
                            `sites.add_new.form.market_programs.table.sold_capacity_algorithm_options.${marketProgramContract.soldCapacityAlgorithmType}`,
                          )
                        : '-',
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      ) : (
        <Stack sx={{ flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
          <CustomTypography variant="body2">
            {t('sites.add_new.form.review.empty.market_program_contracts')}
          </CustomTypography>
        </Stack>
      )}
    </ReviewSiteSection>
  )
}

export default MarketProgramsReviewSection
