import { Box } from '@mui/material'
import { grey } from '@mui/material/colors'
import type { TFunction } from 'i18next'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type { WeekdayStr } from 'rrule'
import { ALL_WEEKDAYS } from 'rrule/dist/esm/weekday'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import { formatLimitValue, formatLocalizedDateMed } from '@/features/site/components/availability/utils'
import type { MarketProgramContract } from '@/features/site/types/marketProgramContract'
import type { ServiceRuleForm } from '@/features/site/types/serviceRuleForm'

interface ServiceRuleConfigurationInfoSectionProps {
  customerTimeZone: string
  marketProgramContract: MarketProgramContract
}

export const ServiceRuleConfigurationInfoSection = (props: ServiceRuleConfigurationInfoSectionProps) => {
  const { t, i18n } = useTranslation()
  const { getValues } = useFormContext<ServiceRuleForm>()
  const { id, recurringDays, exceptionDates, startDate, endDate, limitValue } = getValues()

  const limitValueSummary = id ? (limitValue ?? 0) : props.marketProgramContract.contractPrice

  return (
    <Box
      sx={{
        bgcolor: grey[200],
        borderRadius: 3,
        p: 2,
      }}
      width={'100%'}
    >
      <CustomTypography paragraph variant={'h3'}>
        {`${t('customer_details.tabs.availability.common.configuration_section.intro')}:`}
      </CustomTypography>
      <CustomTypography component="div" variant={'h4'}>
        {`${t('customer_details.tabs.availability.common.configuration_section.availability.first_sentence', {
          recurrence: getRecurrencePart(recurringDays ?? [], t),
          start_date: formatLocalizedDateMed(props.customerTimeZone, i18n.language, startDate),
          end_date: formatLocalizedDateMed(props.customerTimeZone, i18n.language, endDate),
          limit_value: formatLimitValue(t, props.marketProgramContract.service.type, limitValueSummary),
        })}\n        ${
          exceptionDates.length > 1
            ? t('customer_details.tabs.availability.common.configuration_section.availability.second_sentence', {
                exception_count: exceptionDates.length - 1,
              })
            : ''
        }`}
      </CustomTypography>
    </Box>
  )
}

const getRecurrencePart = (recurringDays: string[], t: TFunction) => {
  if (recurringDays.length === 0) {
    return ''
  }
  return t('customer_details.tabs.availability.common.configuration_section.availability.recurrence_part', {
    recurrence:
      ALL_WEEKDAYS.length === recurringDays.length
        ? t('customer_details.tabs.availability.common.configuration_section.availability.day')
        : sortWeekdays(recurringDays as WeekdayStr[])
            .map((day) => t(`common.days.${day as WeekdayStr}.long`))
            .join(', '),
  })
}

function sortWeekdays(days: WeekdayStr[]): WeekdayStr[] {
  const sortedDays: WeekdayStr[] = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']
  return days.sort((a, b) => sortedDays.indexOf(a) - sortedDays.indexOf(b))
}
