import { FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomSelectField from '@/components/inputs/CustomSelectField'
import TextFieldController from '@/components/inputs/TextFieldController'
import { DEFAULT_PARTNER } from '@/constants/partners'
import environment from '@/environment'
import { usePartnersQuery } from '@/features/partner/hooks/usePartnersQuery'
import { SiteAddressInput } from '@/features/site/components/companyInfo/SiteAddressInput'
import { PartnerFieldController } from '@/features/site/components/PartnerFieldController'
import { SITE_STEPS_FIELD_WIDTH } from '@/features/site/constants'
import type { CreateSiteForm } from '@/features/site/types/site'
import determineTimezone from '@/features/site/utils/determineTimezone'
import type { GpsLocation } from '@/types/gpsLocation'
import { getCountryCodeByLocation } from '@/utils/locations'

const locationInfos = environment.getLocationsList()

function BasicInformationStep() {
  const { t } = useTranslation()
  const { control, getValues, setValue, resetField } = useFormContext<CreateSiteForm>()
  const { partners } = usePartnersQuery()
  const [timezone, setTimezone] = useState<string | undefined>(getValues().timezone)

  const location = getValues('location')

  function handleLocationChange(event) {
    const selectedLocation = event.target.value

    setValue('location', selectedLocation)

    const selectedLocationCountryCode = getCountryCodeByLocation(event.target.value)

    if (selectedLocationCountryCode) {
      setValue('countryCode', selectedLocationCountryCode)
    }

    const timezone = determineTimezone(selectedLocationCountryCode)

    setTimezone(timezone)
    setValue('timezone', timezone)

    // After changing location, market programs must be reset as they are dependent on the location.
    // Each country has a set of market programs available, so it makes sense that when you select a new location
    // the market programs are reset as users should configure them again.
    resetField('marketProgramContracts')

    const selectedPartner = partners!.find((partner) => partner.partnerCode === getValues().partnerCode)
    if (
      (selectedPartner && selectedPartner.partnerCode !== DEFAULT_PARTNER.partnerCode) ||
      areDifferentCountries(selectedPartner?.countryCode, selectedLocationCountryCode)
    ) {
      setValue('partnerCode', DEFAULT_PARTNER.partnerCode)
    }
  }

  function handlePartnerChange(_, newValue) {
    setValue('partnerCode', newValue)

    if (newValue !== DEFAULT_PARTNER.partnerCode) {
      resetField('financialInformation')
    }
  }

  function areDifferentCountries(partnerCountryCode?: string, locationCountryCode?: string) {
    return partnerCountryCode && locationCountryCode && partnerCountryCode !== locationCountryCode
  }

  function onChangeAddress(address: string, gpsLocation: GpsLocation) {
    setValue('address', address, { shouldTouch: true })

    if (gpsLocation) {
      setValue('coordinates', { latitude: gpsLocation.lat!, longitude: gpsLocation.lon! }, { shouldTouch: true })
    }
  }

  return (
    <Grid container gap={2}>
      <Grid width={SITE_STEPS_FIELD_WIDTH}>
        <TextFieldController required label={t('sites.add_new.form.name.label')} name="name" />
      </Grid>
      <Grid size={9} />
      <Grid width={SITE_STEPS_FIELD_WIDTH}>
        <TextFieldController required label={t('sites.add_new.form.symbolic_name.label')} name="symbolicName" />

        <FormHelperText
          component="ul"
          sx={{
            paddingLeft: '30px',
            '& li': {
              display: 'list-item',
              listStyleType: 'disc',
            },
          }}
        >
          <li>{t('sites.add_new.form.symbolic_name.helper1')}</li>
          <li>{t('sites.add_new.form.symbolic_name.helper2')}</li>
          <li>{t('sites.add_new.form.symbolic_name.helper3')}</li>
          <li>{t('sites.add_new.form.symbolic_name.helper4')}</li>
        </FormHelperText>
      </Grid>
      <Grid size={9} />
      <Grid width={SITE_STEPS_FIELD_WIDTH}>
        <Controller
          control={control}
          name="location"
          render={({ field: { value, ref: fieldRef, ...field }, fieldState: { error, invalid } }) => (
            <CustomSelectField
              {...field}
              ref={fieldRef}
              fullWidth
              required
              error={invalid}
              helperText={error?.message}
              label={t('common.location')}
              name="location"
              options={locationInfos.map((info) => ({
                id: info.location,
                value: info.location,
                label: info.location,
              }))}
              value={value ?? ''}
              onChange={handleLocationChange}
            />
          )}
        />
      </Grid>
      <Grid alignContent={'center'} size={3}>
        <Typography> {timezone} </Typography>
      </Grid>
      <Grid size={9} />
      <Grid width={'350px'}>
        <PartnerFieldController
          label={`${t('common.partner')} *`}
          location={location}
          name="partnerCode"
          partners={partners ?? []}
          onChange={handlePartnerChange}
        />
      </Grid>
      <Grid size={9} />
      <Grid width={SITE_STEPS_FIELD_WIDTH}>
        <SiteAddressInput
          hiddenMap
          address={getValues('address')}
          gpsLocation={undefined}
          onChangeAddress={onChangeAddress}
        />
      </Grid>
      <Grid size={9} />
      <Grid alignContent={'center'} width={SITE_STEPS_FIELD_WIDTH}>
        <Typography> {t('common.type')} </Typography>
        <Controller
          control={control}
          name="siteType"
          render={({ field }) => (
            <RadioGroup aria-label="siteType" sx={{ ml: 2, mt: 1 }} value={field.value ?? ''} onChange={field.onChange}>
              <FormControlLabel control={<Radio />} label={t('sites.types.sawmill')} value="sawmill" />
              <FormControlLabel control={<Radio />} label={t('sites.types.greenhouse')} value="greenhouse" />
              <FormControlLabel control={<Radio />} label={t('sites.types.other')} value="other" />
            </RadioGroup>
          )}
        />
      </Grid>
    </Grid>
  )
}

export default BasicInformationStep
