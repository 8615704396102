import { USER_ROLES } from '@/constants/userRoles'

export function hasFlexPortalAccess(role: string) {
  const enabledUserRoles = [
    USER_ROLES.ADMINISTRATORS,
    USER_ROLES.PARTNER_ADMINISTRATORS,
    USER_ROLES.RESOURCE_OWNERS,
    USER_ROLES.CUSTOMER_MANAGERS,
  ]
  return enabledUserRoles.some((userRole) => userRole.value === role)
}
