import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import MainContentContainer from '@/components/layouts/MainContentContainer'
import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { ResourceLevelRestrictionsDataGrid } from '@/features/site/components/availability/restriction/resourceLevelUnavailaibility/ResourceLevelRestrictionsDataGrid'
import { SiteSelector } from '@/features/site/components/SiteSelector'
import { SiteDetailsRouteInformation } from '@/features/site/pages/constants'
import { useSiteDetails } from '@/features/site/pages/SiteDetails'

export const ResourceLevelUnavailability = () => {
  const { site, siteDetailsCommonBreadcrumbs, setPageConfig } = useSiteDetails()
  const { t } = useTranslation()
  const { loggedInUser } = useAuth()

  const viewOnly =
    loggedInUser?.role !== USER_ROLES.ADMINISTRATORS.value && loggedInUser?.role !== USER_ROLES.CUSTOMER_MANAGERS.value

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.res_level_unavailability.title'),
      pageTag: 'customer-details-resource-level-unavailability',
      breadcrumbs: siteDetailsCommonBreadcrumbs,
      appBarContent: <SiteSelector siteUuid={site.uuid} />,
      activeTab: SiteDetailsRouteInformation.RES_LEVEL_UNAVAILABILITY.navigationPath,
    })
  }, [])

  return (
    <MainContentContainer>
      <ResourceLevelRestrictionsDataGrid
        siteLocation={site.location ?? ''}
        siteTimezone={site.timezone ?? ''}
        siteUuid={site.uuid!}
        viewOnly={viewOnly}
      />
    </MainContentContainer>
  )
}
