import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { convertFromWToRoundedMW } from '@/features/site/utils/formatPowerMeasurements'

interface PhaseMeasurementProps {
  phaseNumber: number
  measurementInW: number
}

export const PhasePowerMeasurement = (props: PhaseMeasurementProps) => {
  const { t } = useTranslation()
  const { phaseNumber, measurementInW } = props

  return (
    <div>
      <Typography sx={{ fontFamily: 'Quicksand, sans-serif', fontSize: '20px', fontWeight: 600 }}>
        {t('customer_measurements.power_measurements.phase_with_number', { number: phaseNumber })}:{' '}
        {convertFromWToRoundedMW(measurementInW)} MW
      </Typography>
    </div>
  )
}
