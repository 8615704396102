import { ApartmentOutlined, MonetizationOnOutlined } from '@mui/icons-material'
import BoltIcon from '@mui/icons-material/Bolt'
import CampaignOutlinedIcon from '@mui/icons-material/Campaign'
import DateRangeIcon from '@mui/icons-material/DateRange'
import SettingsIcon from '@mui/icons-material/Settings'
import { useTranslation } from 'react-i18next'

import ListItemLink from '@/components/navigation/ListItemLink'
import { usePermissions } from '@/features/authorization/contexts/PermissionsContext'

function CustomerNavBarItems() {
  const { t } = useTranslation()
  const { permissions } = usePermissions()

  return (
    <>
      {permissions.has('powerMeasurements') && (
        <ListItemLink
          activePath={'/sites/power-measurements'}
          icon={<BoltIcon />}
          primary={t('component.navbar.power_measurements')}
          to="/sites/power-measurements"
        />
      )}
      <ListItemLink
        activePath={'/sites/unavailability'}
        icon={<DateRangeIcon />}
        primary={t('customer_details.tabs.unavailability.title')}
        to="/sites/unavailability"
      />
      <ListItemLink
        activePath={'/activations/*'}
        icon={<CampaignOutlinedIcon />}
        primary={t('customer_details.tabs.activations')}
        to="/activations"
      />
      {permissions.has('revenues') && (
        <ListItemLink
          activePath={'/sites/revenue'}
          icon={<MonetizationOnOutlined />}
          primary={t('component.navbar.revenue')}
          to="/sites/revenue"
        />
      )}
      <ListItemLink
        activePath={'/sites/company-info'}
        icon={<ApartmentOutlined />}
        primary={t('component.navbar.company_info')}
        to="/sites/company-info"
      />
      <ListItemLink
        activePath={'/sites/settings'}
        icon={<SettingsIcon />}
        primary={t('customer_details.tabs.settings.title')}
        to="/sites/settings"
      />
    </>
  )
}

export default CustomerNavBarItems
