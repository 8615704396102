import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { Restriction } from '@/features/unavailability/types/restriction'

export const RestrictionTypeSection = () => {
  const { t } = useTranslation()
  const { control, getValues } = useFormContext<Restriction>()
  const { id } = getValues()

  return (
    <FormControl component="fieldset">
      <Controller
        control={control}
        name={'type'}
        render={({ field }) => (
          <RadioGroup row {...field}>
            <FormControlLabel
              control={<Radio disabled={!!id} />}
              label={t('customer_details.tabs.availability.unavailability_form.type.no_repeat')}
              value="non-repeating"
            />
            <FormControlLabel
              control={<Radio disabled={!!id} />}
              label={t('customer_details.tabs.availability.unavailability_form.type.daily_repeat')}
              value="repeating-daily"
            />
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}
