import { Box } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAlertContext } from '@/contexts/AlertContext'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { SiteSettingsForm } from '@/features/site/components/settings/SiteSettingsForm'
import { SiteSelector } from '@/features/site/components/SiteSelector'
import { updateCustomerSettings } from '@/features/site/endpoints/customerSettings'
import {
  useCustomerSettingsQuery,
  useInvalidateCustomerSettingsQuery,
} from '@/features/site/hooks/useCustomerSettingsQuery'
import { SiteDetailsRouteInformation } from '@/features/site/pages/constants'
import { useSiteDetails } from '@/features/site/pages/SiteDetails'
import type { CustomerSettingsPayload } from '@/features/site/types/customer'

export const SiteDetailsSettings = () => {
  const { loggedInUser } = useAuth()
  const { site, siteDetailsCommonBreadcrumbs, setPageConfig } = useSiteDetails()
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()

  const userRole = loggedInUser!.role!

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.settings.title'),
      pageTag: 'customer-details-settings',
      breadcrumbs: siteDetailsCommonBreadcrumbs,
      appBarContent: <SiteSelector siteUuid={site.uuid} />,
      activeTab: SiteDetailsRouteInformation.SETTINGS.navigationPath,
    })
  }, [])

  const { customerSettings } = useCustomerSettingsQuery({ uuid: site.uuid! })

  const invalidateCustomerSettingsQuery = useInvalidateCustomerSettingsQuery()

  const updateSettings = useMutation({
    mutationFn: (variables: { data: CustomerSettingsPayload }) => {
      if (!site?.uuid) {
        return Promise.reject(new Error(t('customer_settings.form.error.customer_without_uuid')))
      }

      return updateCustomerSettings(site.uuid, {
        ...variables.data,
      })
    },
    onSuccess: async () => {
      await invalidateCustomerSettingsQuery({ uuid: site.uuid!, location: site.location })

      pushAlert({
        message: t('customer_settings.form.save_success'),
        severity: 'success',
      })
    },
    onError: (error: AxiosError<{ message: string; name: string }>) => {
      if (error.response?.data.name === 'validation-failed' && error.response?.data.message.includes('phone')) {
        pushAlert({
          message: t('customer_settings.form.notification_preferences.phone_invalid_error'),
          severity: 'error',
        })
      } else {
        pushAlert({
          message: t('customer_settings.form.save_error'),
          severity: 'error',
        })
      }
    },
  })

  return (
    <Box mt={3} sx={{ width: '100%' }}>
      {customerSettings && (
        <SiteSettingsForm
          defaultValues={customerSettings}
          userRole={userRole}
          onSubmit={(data) => {
            updateSettings.mutate({ data })
          }}
        />
      )}
    </Box>
  )
}
