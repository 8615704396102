import { USER_ROLES } from '@/constants/userRoles'
import environment from '@/environment'
import type { PermissionHandlerParams } from '@/features/authorization/contexts/PermissionsContext'

const NW_UUID =
  environment.environmentName === 'production'
    ? '4c1f6be9-f795-4529-8f20-a81ca5c75cbf'
    : 'c7cc280c-8ff9-45dc-88f7-f4509f63998d'

export const hasRevenuePermissions = ({ loggedInUser, site }: PermissionHandlerParams) => {
  const hasAllowedRole =
    loggedInUser?.role === USER_ROLES.ADMINISTRATORS.value ||
    loggedInUser?.role === USER_ROLES.CUSTOMER_MANAGERS.value ||
    loggedInUser?.role === USER_ROLES.RESOURCE_OWNERS.value

  const hasFinancialPermission = site?.financialInformation?.isFinancialVisible ?? false
  const hasLocationPermission = site?.location === 'fi'
  const isDirectSite = site?.partner?.partnerCode === 'SYMPOWER'

  return hasAllowedRole && hasFinancialPermission && hasLocationPermission && isDirectSite
}

export const hasRevenueNWPermissions = ({ loggedInUser, site }: PermissionHandlerParams) => {
  const hasAllowedRole =
    loggedInUser?.role === USER_ROLES.ADMINISTRATORS.value ||
    loggedInUser?.role === USER_ROLES.CUSTOMER_MANAGERS.value ||
    loggedInUser?.role === USER_ROLES.RESOURCE_OWNERS.value

  const isNWCustomer = site?.uuid === NW_UUID
  const hasFinancialPermission = site?.financialInformation?.isFinancialVisible ?? false

  return hasAllowedRole && isNWCustomer && hasFinancialPermission
}
