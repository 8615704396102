import { useMutation } from '@tanstack/react-query'

import { createCustomerContact } from '@/features/site/endpoints/customers'
import type { CustomerContact } from '@/features/site/types/customer'

export type UseCreateCustomerContactMutationResult = ReturnType<typeof useCreateCustomerContactMutation>

export function useCreateCustomerContactMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (variables: { customerUuid: string; contact: CustomerContact }) =>
      createCustomerContact(variables.customerUuid, variables.contact),
  })

  return {
    createCustomerContact: mutateAsync,
    ...mutationResult,
  }
}
