import type { GridColDef, GridRowParams } from '@mui/x-data-grid'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_FILTER_MODEL, DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import ActivationsDataGridToolbar from '@/features/activation/components/ActivationsDataGridToolbar'
import type { Activation } from '@/features/activation/types/activation'
import type { MarketProgramType } from '@/types/marketProgramType'

interface ActivationsDataGridProps {
  rowNavigateTo?: (row: GridRowParams<any>) => string
  columns: GridColDef<any>[]
  rows: Activation[]
  isLoading: boolean
  marketProgramOptions: Set<MarketProgramType>
  canSeePrequalifications: boolean
}

export const ActivationsDataGrid = ({
  rowNavigateTo,
  columns,
  rows,
  isLoading,
  marketProgramOptions,
  canSeePrequalifications,
}: ActivationsDataGridProps) => {
  return (
    <CustomDataGrid
      clickableRows={{
        navigateTo: rowNavigateTo,
      }}
      columnVisibilityModel={{
        type: canSeePrequalifications,
      }}
      columns={columns}
      initialState={{
        filter: {
          filterModel: DEFAULT_DATA_GRID_FILTER_MODEL,
        },
        pagination: {
          paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
        },
        sorting: {
          sortModel: [],
        },
      }}
      isLoading={isLoading}
      rows={rows ?? []}
      slotProps={{
        toolbar: {
          marketProgramOptions,
        },
      }}
      slots={{
        toolbar: ActivationsDataGridToolbar,
      }}
    />
  )
}
