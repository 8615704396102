import { useQueries } from '@tanstack/react-query'

import type { BidType, MarketProgram } from '@/features/bidding/constants'
import { findBid, GET_BID_API_ID } from '@/features/bidding/endpoints/bids'
import { getCapacityInsights } from '@/features/bidding/endpoints/insights'
import { useErrorHandler } from '@/features/bidding/hooks/useErrorHandler'
import { GET_CAPACITY_INSIGHTS_API_ID } from '@/features/bidding/hooks/useInsightsDataQuery'
import type { ExtendedBid, PastBid } from '@/features/bidding/types/bid'
import type { HistoricalCapacity } from '@/features/bidding/types/historicalCapacity'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'

type BidQueryParams = {
  date: MarketDate
  portfolioCode: string
  marketProgram: MarketProgram
  activationGroupUuid: string
  bidType: BidType
}

export type BidWithActualControllableResult = {
  bid?: PastBid
  isFetching: boolean
}

export function useBidWithActualControllableQuery(params: BidQueryParams): BidWithActualControllableResult {
  const activationGroupUuid = params.activationGroupUuid
  const fromDeliveryDay = params.date
  const toDeliveryDay = params.date.plus({ days: 1 })
  const queryResults = useQueries({
    queries: [
      {
        queryKey: [GET_BID_API_ID, params],
        queryFn: () =>
          findBid(params.date, params.portfolioCode, params.marketProgram, params.activationGroupUuid, params.bidType),
      },
      {
        queryKey: [GET_CAPACITY_INSIGHTS_API_ID, params],
        queryFn: () => getCapacityInsights(activationGroupUuid, fromDeliveryDay, toDeliveryDay),
      },
    ],
  })
  useErrorHandler(queryResults.some((result) => result.isError))

  if (!queryResults[0].data) {
    return {
      bid: undefined,
      isFetching: queryResults.some((result) => result.isFetching),
    }
  }

  return {
    bid: combineBidWithActualControllable(queryResults[0].data, queryResults[1].data),
    isFetching: queryResults.some((result) => result.isFetching),
  }
}

function combineBidWithActualControllable(
  bid: ExtendedBid,
  actualControllable: HistoricalCapacity[] | null | undefined,
) {
  const pastBid: PastBid = {
    actualControllable: [],
    ...bid,
  }
  for (const ptu of bid.ptus ?? []) {
    const historicalCapacity = actualControllable?.find((bidPtu) => bidPtu.startDate.equals(ptu.ptu.start))
    pastBid.actualControllable.push({
      ptu: {
        start: ptu.ptu.start,
        end: ptu.ptu.end,
      },
      volume: {
        quantity: historicalCapacity?.scheduledMaintainedCapacityWatts ?? 0,
        unit: 'WATTS',
      },
    })
  }
  return pastBid
}
