import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { CircularProgress, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTimeRangePicker, SingleInputDateTimeRangeField } from '@mui/x-date-pickers-pro'
import type EChartsReact from 'echarts-for-react'
import ReactECharts from 'echarts-for-react'
import { saveAs } from 'file-saver'
import { DateTime } from 'luxon'
import type { Dispatch, SetStateAction } from 'react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomSelectField from '@/components/inputs/CustomSelectField'
import MainContentContainer from '@/components/layouts/MainContentContainer'
import { FIELD_WIDTH } from '@/constants/layout'
import { Period } from '@/constants/period'
import { displayValueInTheRightUnit } from '@/features/measurement/utils/units'
import type { BaselineSerie } from '@/features/report/types/baseline'
import {
  calculatePeriodEndTime,
  calculatePeriodStartTime,
  formatDateTimeForPeriodSelection,
  formatDateTimeForTimeline,
} from '@/features/report/utils/utils'
import { hexToRgba } from '@/utils/hexToRgba'

const GRID_COLOR = '#A3A3A3'
const BACKGROUND_COLOR = '#FFFFFF'

type BaselineChartProps = {
  title: string
  infoText: string
  lineColor: string
  lineType?: string
  timezone: string
  initialPeriodSelection: Period
  startTime: DateTime
  setStartTime: Dispatch<SetStateAction<DateTime>>
  endTime: DateTime
  setEndTime: Dispatch<SetStateAction<DateTime>>
  allowedEndTimeInFuture?: boolean
  isLoading: boolean
  timeData: DateTime[]
  baselineSeries: BaselineSerie[]
}

function BaselineChart({
  title,
  infoText,
  lineColor,
  lineType,
  timezone,
  initialPeriodSelection,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  allowedEndTimeInFuture,
  isLoading,
  timeData,
  baselineSeries,
}: Readonly<BaselineChartProps>) {
  const { t } = useTranslation()

  const zonedTimeData = timeData.map((dateTime) => dateTime.setZone(timezone))
  const seriesData = baselineSeries.length === 1 ? baselineSeries[0].data : []

  const chartRef = useRef<EChartsReact>(null)

  const [periodSelection, setPeriodSelection] = useState<Period>(initialPeriodSelection)

  const handlePeriodChange = (event) => {
    const period = event.target.value
    setPeriodSelection(period)
    setStartTime(calculatePeriodStartTime(period))
    setEndTime(calculatePeriodEndTime(period))
  }

  const [clickPosition, setClickPosition] = useState<{ mouseX: number; mouseY: number } | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setClickPosition({
      mouseX: event.clientX - 10,
      mouseY: event.clientY - 10,
    })
  }

  const handleClose = () => {
    setClickPosition(null)
  }

  const handleDownloadPNG = () => {
    if (chartRef.current) {
      const chartInstance = chartRef.current.getEchartsInstance()
      const chartDom = chartInstance.getDom()
      const canvas = chartDom.querySelector('canvas')

      if (canvas) {
        const dataURL = canvas.toDataURL('image/png')

        const link = document.createElement('a')
        link.href = dataURL
        link.download = 'chart.png'
        link.click()
      }
    }
    handleClose()
  }

  const handleDownloadCSV = () => {
    downloadCSV(zonedTimeData, seriesData, 'chart-data.csv')
    handleClose()
  }

  const commonProps = {
    type: 'line',
    lineStyle: { width: 2, type: lineType ?? 'solid' },
    step: 'end',
  }

  const series = {
    ...commonProps,
    data: seriesData,
    color: lineColor,
    areaStyle: {
      color: lineColor,
      opacity: 0.4,
    },
    name: title,
    showSymbol: false, // Remove points for each value
  }

  const option = {
    backgroundColor: BACKGROUND_COLOR,
    grid: {
      bottom: 100,
    },
    legend: {
      left: 0,
      top: 0,
      show: true,
      icon: 'roundRect',
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
      borderRadius: 8,
      itemWidth: 20,
      itemHeight: 10,
      padding: 15,
      itemStyle: {
        color: hexToRgba(lineColor, 0.4),
        borderColor: lineColor,
        borderWidth: 1,
        borderType: lineType ?? 'solid',
      },
    },
    dataZoom: [
      {
        start: 0,
        end: 100,
        labelFormatter: (value) => {
          const dateTime = zonedTimeData[value]
          if (!dateTime) {
            return ''
          }
          const { dayPart, timePart } = formatDateTimeForTimeline(dateTime)
          return dayPart + '\n' + timePart
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      valueFormatter: displayValueInTheRightUnit,
    },
    toolbox: {
      feature: {
        restore: {
          title: t('common.refresh'),
        },
        myOpenDownloadMenu: {
          show: true,
          title: t('common.download.label'),
          icon: 'path://M12 16.5l4-4h-3V3h-2v9.5H8l4 4zM5 18v2h14v-2H5z',
          onclick: function (...args) {
            handleClick(args[3].event)
          },
        },
      },
    },
    animation: false,
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: zonedTimeData.map((dateTime) => {
        return dateTime.toFormat('HH:mm \n yyyy-MM-dd')
      }),
      axisLine: {
        lineStyle: {
          color: GRID_COLOR,
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: displayValueInTheRightUnit,
      },
      axisPointer: {
        snap: true,
      },
      axisLine: {
        lineStyle: {
          color: GRID_COLOR,
        },
      },
      min: 'dataMin',
    },
    series,
  }

  return (
    <MainContentContainer>
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{title}</Typography>
          <Typography color="textSecondary" variant="body2">
            {t('common.times_displayed_in_timezone', { timezone: timezone })}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <InfoOutlinedIcon color={'info'} width={'20px'} />
          <Typography fontSize={16} maxWidth={1000} variant="body1">
            {infoText}
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={1}>
          <CustomSelectField
            label={t('common.period.label')}
            options={[
              { id: 'past-month', value: Period.PAST_MONTH, label: t('common.period.past_month') },
              { id: 'past-24-hours', value: Period.PAST_24_HOURS, label: t('common.period.past_24_hours') },
              { id: 'past-12-hours', value: Period.PAST_12_HOURS, label: t('common.period.past_12_hours') },
              { id: 'past-6-hours', value: Period.PAST_6_HOURS, label: t('common.period.past_6_hours') },
              { id: 'past-3-hours', value: Period.PAST_3_HOURS, label: t('common.period.past_3_hours') },
              { id: 'past-hour', value: Period.PAST_HOUR, label: t('common.period.past_hour') },
              ...(allowedEndTimeInFuture
                ? [
                    { id: 'next-24-hours', value: Period.NEXT_24_HOURS, label: t('common.period.next_24_hours') },
                    { id: 'next-48-hours', value: Period.NEXT_48_HOURS, label: t('common.period.next_48_hours') },
                    { id: 'next-72-hours', value: Period.NEXT_72_HOURS, label: t('common.period.next_72_hours') },
                  ]
                : []),
              { id: 'custom', value: Period.CUSTOM, label: t('common.period.custom') },
            ]}
            size="medium"
            sx={{ maxWidth: FIELD_WIDTH }}
            value={periodSelection}
            onChange={handlePeriodChange}
          />
          {periodSelection === Period.CUSTOM ? (
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DateTimeRangePicker
                ampm={false}
                format={'dd-MM-yyyy, HH:mm'}
                label={t('common.period.time_range')}
                maxDateTime={allowedEndTimeInFuture ? undefined : DateTime.now()}
                slots={{ field: SingleInputDateTimeRangeField }}
                sx={{ width: '350px' }}
                timezone={timezone}
                value={[startTime, endTime]}
                onChange={(newValue) => {
                  if (newValue[0] !== null) {
                    setStartTime(newValue[0])
                  }
                  if (newValue[1] !== null) {
                    setEndTime(newValue[1])
                  }
                }}
              />
            </LocalizationProvider>
          ) : (
            <Typography fontSize={16}>
              {`${formatDateTimeForPeriodSelection(startTime.setZone(timezone))} `}
              <i>{t('common.period.to_lowercase')}</i>
              {` ${formatDateTimeForPeriodSelection(endTime.setZone(timezone))}`}
            </Typography>
          )}
        </Stack>
        {isLoading ? (
          <Grid container alignItems="center" direction="row" justifyContent="center" sx={{ height: '600px' }}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Menu
              anchorPosition={clickPosition ? { top: clickPosition.mouseY, left: clickPosition.mouseX } : undefined}
              anchorReference="anchorPosition"
              open={clickPosition !== null}
              onClose={handleClose}
            >
              <MenuItem onClick={handleDownloadPNG}>{t('common.download.png')}</MenuItem>
              <MenuItem onClick={handleDownloadCSV}>{t('common.download.csv')}</MenuItem>
            </Menu>
            <ReactECharts ref={chartRef} notMerge={true} option={option} style={{ height: '600px', padding: '0' }} />
          </>
        )}{' '}
      </Stack>
    </MainContentContainer>
  )
}

function convertToCSV(timeData: DateTime[], seriesData: number[]): string {
  let csvContent = 'Time,Value\n'
  for (let i = 0; i < timeData.length; i++) {
    csvContent += `${timeData[i].toFormat('D HH:mm z')},${seriesData[i]}\n`
  }
  return csvContent
}

function downloadCSV(timeData: DateTime[], seriesData: number[], filename: string) {
  const csvContent = convertToCSV(timeData, seriesData)
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  saveAs(blob, filename)
}

export default BaselineChart
