import type { ExtendedBidPtu } from '@/features/bidding/types/bid'
import sumArray from '@/features/bidding/utils/calculations/sumArray'
import { PriceLadderType } from '@/features/bidding/utils/getBidConfig'

export function getBidTotalOffered(bidPtus: ExtendedBidPtu[], priceLadder: PriceLadderType): number {
  if (bidPtus.length === 0) return 0

  if (bidPtus[0].ptuChunks && bidPtus[0].ptuChunks.length > 0) {
    // calculate total based on PTU chunks and price ladder type
    if (priceLadder === PriceLadderType.CUMULATIVE) {
      return getTotalOfferedForCumulativePriceLadder(bidPtus)
    }
    return getTotalOfferedForIncrementalPriceLadder(bidPtus)
  }

  // calculate total based on PTUs
  return bidPtus.reduce((total, ptu) => total + ptu.offeredVolume.quantity, 0)
}

export function getBidTotalAccepted(bidPtus: ExtendedBidPtu[], priceLadder: PriceLadderType): number {
  if (bidPtus.length === 0) return 0

  if (bidPtus[0].ptuChunks && bidPtus[0].ptuChunks.length > 0) {
    // calculate total based on PTU chunks and price ladder type
    if (priceLadder === PriceLadderType.CUMULATIVE) {
      return getTotalAcceptedForCumulativePriceLadder(bidPtus)
    }
    return getTotalAcceptedForIncrementalPriceLadder(bidPtus)
  }

  // calculate total based on PTUs
  return bidPtus.reduce((total, ptu) => total + (ptu.acceptedVolume?.quantity ?? 0), 0)
}

const getTotalOfferedForCumulativePriceLadder = (bidPtus: ExtendedBidPtu[]) => {
  const totalQuantitiesPerPtu = bidPtus.map(
    (ptu) => ptu.ptuChunks?.map((chunk) => chunk.offeredVolume.quantity).toSorted((a, b) => b - a)[0] ?? 0,
  )

  return sumArray(totalQuantitiesPerPtu)
}

const getTotalOfferedForIncrementalPriceLadder = (bidPtus: ExtendedBidPtu[]) =>
  bidPtus.reduce(
    (total, ptu) => total + (ptu.ptuChunks?.reduce((total, chunk) => total + chunk.offeredVolume.quantity, 0) ?? 0),
    0,
  )

const getTotalAcceptedForCumulativePriceLadder = (bidPtus: ExtendedBidPtu[]) => {
  const totalQuantitiesPerPtu = bidPtus.map(
    (ptu) => ptu.ptuChunks?.map((chunk) => chunk.acceptedVolume?.quantity ?? 0).toSorted((a, b) => b - a)[0] ?? 0,
  )
  return sumArray(totalQuantitiesPerPtu)
}

const getTotalAcceptedForIncrementalPriceLadder = (bidPtus: ExtendedBidPtu[]) =>
  bidPtus.reduce(
    (total, ptu) =>
      total + (ptu.ptuChunks?.reduce((total, chunk) => total + (chunk.acceptedVolume?.quantity ?? 0), 0) ?? 0),
    0,
  )
