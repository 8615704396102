import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { hasRevenueNWPermissions } from '@/features/authorization/permissionHandlers/revenuePermissions'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import type { Site } from '@/features/site/types/site'

type UseHasRevenueNWPermissionsParams = {
  site: Site | null
}

export function useHasRevenueNWPermissions(params: UseHasRevenueNWPermissionsParams) {
  const { loggedInUser } = useAuth()
  const { isEnabled } = useFeatureToggle()

  return (
    isEnabled('FLEX_PORTAL_NEW_REVENUE_PAGE') &&
    hasRevenueNWPermissions({
      site: params.site,
      loggedInUser: loggedInUser!,
    })
  )
}
