import { FormControl, Stack, Switch } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AvailabilityStatusChip } from '@/features/site/components/availability/datagrid/AvailabilityStatusChip'
import type { ServiceRuleForm } from '@/features/site/types/serviceRuleForm'

export const ServiceRuleStatusSection = () => {
  const { t } = useTranslation()
  const { getValues, setValue, control, trigger } = useFormContext<ServiceRuleForm>()
  const { status } = getValues()

  useEffect(() => {
    trigger(['status'])
  }, [status])

  if (!status) return null

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h6">{t('common.status')}</Typography>
      <Stack alignItems="center" direction="row" spacing={1}>
        <AvailabilityStatusChip status={status} />
        {(status === 'active' || status === 'deactivated') && (
          <FormControl>
            <Controller
              control={control}
              name="startDate"
              render={() => (
                <Switch
                  checked={status === 'active'}
                  onChange={(event, checked) => {
                    setValue('status', checked ? 'active' : 'deactivated')
                  }}
                />
              )}
            />
          </FormControl>
        )}
      </Stack>
    </Stack>
  )
}
