import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { FormHelperText, Tooltip } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'

import CustomFormControl from '@/components/inputs/CustomFormControl'

import type { CustomSelectFieldProps, SelectFieldOption } from './CustomSelectField'

export type CustomMultipleSelectFieldProps<V extends string[] | number[]> = Omit<
  CustomSelectFieldProps<V>,
  'options' | 'multiple' | 'showAllOption'
> & {
  options: SelectFieldOption<V[number]>[]
}

/**
 * It has the same customizations as CustomSelectField, but it allows multiple selections types.
 *
 * The reason why this component looks very similar to the CustomSelectField it's because supporting
 * multiple selections requires complex Typescript logic in order to make the generic V type work
 * properly for single and multiple values.
 */
function CustomMultipleSelectField<V extends string[] | number[]>(
  {
    fullWidth,
    helperText,
    variant = 'outlined',
    disabled,
    color,
    value,
    required,
    ...props
  }: CustomMultipleSelectFieldProps<V>,
  ref: ForwardedRef<HTMLSelectElement>,
) {
  const { error, sx, id, label, options, size } = props

  const labelId = `${id}-label`
  const commonItemStyles = {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  }

  return (
    <CustomFormControl
      color={color}
      disabled={disabled}
      error={Boolean(error)}
      fullWidth={fullWidth}
      required={required}
      size={size}
      sx={sx}
      variant={variant}
    >
      <InputLabel id={labelId}>{label}</InputLabel>

      <Select
        {...props}
        ref={ref}
        multiple
        color={color}
        disabled={disabled}
        fullWidth={fullWidth}
        labelId={labelId}
        required={required}
        sx={{
          ...sx,
          '& .MuiSelect-select': commonItemStyles,
        }}
        value={value}
        variant={variant === 'outlinedWhite' ? 'outlined' : variant}
      >
        {options.map(({ id, value, label, icon, tooltip, disabled = false, ...props }) => (
          <MenuItem {...props} key={id} disabled={disabled} sx={commonItemStyles} value={value}>
            <>
              {icon}
              {label}
              {tooltip && (
                <Tooltip title={tooltip}>
                  <HelpOutlineIcon fontSize="small" sx={{ color: '#000' }} />
                </Tooltip>
              )}
            </>
          </MenuItem>
        ))}
      </Select>

      {error && <FormHelperText>{helperText}</FormHelperText>}
    </CustomFormControl>
  )
}
export default forwardRef(CustomMultipleSelectField) as typeof CustomMultipleSelectField
