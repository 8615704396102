import { IconClose } from '@aws-amplify/ui-react/internal'
import { Button, DialogActions, DialogContent } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomDialog from '@/components/feedback/CustomDialog'

type SiteDisableConformationDialogProps = {
  customerName?: string
  open: boolean
  onConfirm: () => void
  onCancel?: () => void
}

export const SiteDisableConformationDialog = ({
  customerName,
  open,
  onConfirm,
  onCancel,
}: SiteDisableConformationDialogProps) => {
  const { t } = useTranslation()

  return (
    <CustomDialog
      open={open}
      position="top"
      size="small"
      title={t('customers.customer_disable.confirm_dialog_title', { customerName })}
      onClose={onCancel}
    >
      <DialogContent>{t('customers.customer_disable.confirm_dialog_text')}</DialogContent>
      <DialogActions>
        <Button color="primary" startIcon={<IconClose />} onClick={onCancel}>
          {t('common.button.cancel')}
        </Button>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          {t('customers.customer_disable.confirm_button')}
        </Button>
      </DialogActions>
    </CustomDialog>
  )
}
