import type { DateTime } from 'luxon'

import type { BidPtu, ExtendedBidPtu } from '@/features/bidding/types/bid'

export const findVolumeForTime = (ptuStart: DateTime, bidPtus: BidPtu[]) =>
  bidPtus.find((bidPtu) => bidPtu.ptu.start.equals(ptuStart))?.volume

export const findOfferedVolumeForTime = (ptuStart: DateTime, bidPtus: ExtendedBidPtu[]) =>
  bidPtus.find((bidPtu) => bidPtu.ptu.start.equals(ptuStart))?.offeredVolume

export const findAcceptedVolumeForTime = (ptuStart: DateTime, bidPtus: ExtendedBidPtu[]) =>
  bidPtus.find((bidPtu) => bidPtu.ptu.start.equals(ptuStart))?.acceptedVolume
