import { zodResolver } from '@hookform/resolvers/zod'
import { DialogContent, InputAdornment } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import FormDialogActions from '@/components/feedback/FormDialogActions'
import CheckboxController from '@/components/inputs/CheckboxController'
import TextFieldController from '@/components/inputs/TextFieldController'
import { getAssignResourceToActivationGroupSchema } from '@/features/activationGroup/schemas'
import type { ActivationGroup, AssignResourceToActivationGroup } from '@/features/activationGroup/types'
import { ActivationGroupState } from '@/features/activationGroup/types'

type AssignResourceDialogFormProps = {
  activationGroup: ActivationGroup
  isLoading: boolean
  onCancel: () => void
  onSubmit: (data: AssignResourceToActivationGroup) => void
}

/**
 * Contains all the logic related to the form to assign a new resource to an existing activation group.
 *
 * It is used within the AssignResourceDialog component.
 */
const AssignResourceDialogForm = ({
  activationGroup,
  isLoading,
  onCancel,
  onSubmit,
}: AssignResourceDialogFormProps) => {
  const { t } = useTranslation()
  const form = useForm<AssignResourceToActivationGroup>({
    defaultValues: {
      resourceUuid: '',
      qualifiedCapacity: null,
      activationPriority: null,
      isUsedForMinCapacityPrequalification: false,
    },
    resolver: zodResolver(getAssignResourceToActivationGroupSchema(t, activationGroup)),
  })

  function handleSubmit(data: AssignResourceToActivationGroup) {
    onSubmit(data)
  }

  return (
    <FormProvider {...form}>
      <form
        noValidate
        aria-label={t('activation_groups.assign_resource_form.title')}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <TextFieldController
            required
            label={t('activation_groups.assign_resource_form.resource_label')}
            name="resourceUuid"
            type="text"
          />

          <TextFieldController
            label={t('activation_groups.assign_resource_form.qualified_capacity_label')}
            name="qualifiedCapacity"
            required={activationGroup.state === ActivationGroupState.LIVE}
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">Watts</InputAdornment>,
              },
            }}
            type="number"
          />

          <TextFieldController
            label={t('activation_groups.assign_resource_form.priority_label')}
            name="activationPriority"
            type="number"
          />

          <CheckboxController
            label={t('activation_groups.assign_resource_form.is_used_for_min_capacity_prequalification_label')}
            name="isUsedForMinCapacityPrequalification"
          />
        </DialogContent>

        <FormDialogActions isLoading={isLoading} onCancel={onCancel} />
      </form>
    </FormProvider>
  )
}

export default AssignResourceDialogForm
