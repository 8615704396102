import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { SiteSelector } from '@/features/site/components/SiteSelector'
import { SiteDetailsRouteInformation } from '@/features/site/pages/constants'
import { useSiteDetails } from '@/features/site/pages/SiteDetails'
import { SiteUnavailabilityView } from '@/features/unavailability/components/SiteUnavailabilityView'

export const SiteDetailsUnavailability = () => {
  const { site, isFetchingSite, siteDetailsCommonBreadcrumbs, setPageConfig } = useSiteDetails()

  const { t } = useTranslation()

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.unavailability.title'),
      breadcrumbs: siteDetailsCommonBreadcrumbs,
      pageTag: 'customer-details-unavailability',
      appBarContent: <SiteSelector siteUuid={site.uuid} />,
      activeTab: SiteDetailsRouteInformation.UNAVAILABILITY.navigationPath,
    })
  }, [])

  if (!site || isFetchingSite) {
    return null
  }

  return <SiteUnavailabilityView site={site} />
}
