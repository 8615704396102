import { Route, Routes } from 'react-router-dom'

import { SiteActivationDetails } from '@/features/activation/pages/SiteActivationDetails'
import { SiteActivations } from '@/features/activation/pages/SiteActivations'
import NavigateToError from '@/features/error/pages/NavigateToError'

export default function ActivationRoutes() {
  return (
    <Routes>
      <Route element={<SiteActivations />} path="/" />
      <Route element={<SiteActivationDetails />} path="/:activationId" />
      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}
