import type { Dispatch } from 'react'
import { useReducer } from 'react'

import { NW_MARKET_PROGRAMS } from '@/features/site/constants'
import type { InsightRevenueDateTimeRange } from '@/features/site/types/revenue'

type UseRevenueFiltersParams = {
  limitDateTimeRange: InsightRevenueDateTimeRange
}

export type UseRevenueFiltersResult = [State, Dispatch<Actions>]

type State = {
  marketPrograms: string[]
  timeRange: InsightRevenueDateTimeRange
}

type Actions = SetMarketProgramsAction | SetTimeRangeAction

type SetMarketProgramsAction = {
  type: 'setMarketPrograms'
  payload: string[]
}

type SetTimeRangeAction = {
  type: 'setTimeRange'
  payload: InsightRevenueDateTimeRange
}

function getInitialState(limitDateTimeRange: InsightRevenueDateTimeRange): State {
  return {
    marketPrograms: NW_MARKET_PROGRAMS,
    timeRange: limitDateTimeRange,
  }
}

function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'setMarketPrograms': {
      return {
        ...state,
        marketPrograms: action.payload,
      }
    }
    case 'setTimeRange': {
      return {
        ...state,
        timeRange: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export function useRevenueFilters({ limitDateTimeRange }: UseRevenueFiltersParams) {
  return useReducer(reducer, null, () => getInitialState(limitDateTimeRange))
}
