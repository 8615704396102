import type { TFunction } from 'i18next'
import type { DateTime } from 'luxon'
import { z } from 'zod'

import { dateTimeSchema } from '@/utils/dateTimeSchema'

export type ServiceRuleForm = z.infer<ReturnType<typeof getServiceRuleFormSchema>>

export type ExceptionDate = {
  range: boolean
  startDate: DateTime
  endDate?: DateTime
}

export const getServiceRuleFormSchema = (t: TFunction) =>
  z
    .object({
      id: z.number().optional(),
      startDate: dateTimeSchema,
      endDate: dateTimeSchema,
      startTime: dateTimeSchema,
      endTime: dateTimeSchema,
      limitValue: z.number(),
      exceptionDates: z.array(
        z
          .object({
            range: z.boolean(),
            startDate: dateTimeSchema,
            endDate: dateTimeSchema.optional(),
          })
          .refine(
            (schema) => {
              return !schema.range || schema.endDate
            },
            {
              message: t('customer_details.tabs.availability.common.form.error.end_date_required'),
              path: ['endDate'],
            },
          )
          .refine(
            (schema) => {
              if (schema.range) {
                return schema.endDate && schema.startDate <= schema.endDate
              }
              return true
            },
            {
              message: t('customer_details.tabs.availability.common.form.error.end_date_before_start_date'),
              path: ['endDate'],
            },
          ),
      ),
      selectedResources: z.array(z.number()),
      recurringDays: z.array(z.string()),
      location: z.string(),
      status: z.enum(['waiting', 'active', 'deactivated', 'expired']).optional(),
    })
    .refine(
      (schema) => {
        return schema.startDate <= schema.endDate
      },
      {
        message: t('customer_details.tabs.availability.common.form.error.end_date_before_start_date'),
        path: ['endDate'],
      },
    )
    .refine(
      (schema) => {
        return schema.endTime > schema.startTime
      },
      {
        message: t('customer_details.tabs.availability.common.form.error.end_time_before_start_time'),
        path: ['endTime'],
      },
    )
    .refine(
      (schema) => {
        return schema.recurringDays.length > 0
      },
      {
        message: t('customer_details.tabs.availability.availability_details.dialog.error.recurring_days_required'),
        path: ['recurringDays'],
      },
    )
