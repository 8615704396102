import { zodResolver } from '@hookform/resolvers/zod'
import { DialogContent, FormControl, FormControlLabel, FormHelperText, Stack, Switch } from '@mui/material'
import { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import FormDialogActions from '@/components/feedback/FormDialogActions'
import SelectFieldController from '@/components/inputs/SelectFieldController'
import TextFieldController from '@/components/inputs/TextFieldController'
import { getUpdateActivationGroupSchema } from '@/features/activationGroup/schemas'
import type {
  ActivationGroup,
  ApiActivationGroupResource,
  UpdateActivationGroup,
} from '@/features/activationGroup/types'
import { ActivationGroupState, ApiVolumeRangeUnit } from '@/features/activationGroup/types'

type UpdateActivationGroupDialogFormProps = {
  activationGroup: ActivationGroup
  resources: ApiActivationGroupResource[]
  isLoading: boolean
  onCancel: () => void
  onSubmit: (data: UpdateActivationGroup) => void
}

/**
 * Contains all the logic related to the form to modify an existing activation group.
 *
 * It is used within the EditActivationGroupDialog component.
 */
const UpdateActivationGroupDialogForm = ({
  isLoading,
  onCancel,
  onSubmit,
  activationGroup,
  resources,
}: UpdateActivationGroupDialogFormProps) => {
  const { t } = useTranslation()
  const form = useForm<UpdateActivationGroup>({
    defaultValues: {
      name: activationGroup.code,
      biddableVolumeRange: {
        min: activationGroup.biddableVolumeRange?.min ?? 0,
        max: activationGroup.biddableVolumeRange?.max ?? 0,
        unit: activationGroup.biddableVolumeRange?.unit.toUpperCase() as ApiVolumeRangeUnit,
      },
      state: activationGroup.state,
    },
    resolver: zodResolver(getUpdateActivationGroupSchema(t)),
  })
  const resourcesAreQualified = useMemo(
    () => resources.every((resource) => resource.qualifiedCapacity != null),
    [resources],
  )
  // Status switch is disabled if activation groups is not LIVE and there aren't any qualified resources
  const statusSwitchDisabled = form.watch('state') !== ActivationGroupState.LIVE && !resourcesAreQualified

  function handleSubmit(updateActivationGroup: UpdateActivationGroup) {
    onSubmit(updateActivationGroup)
  }

  return (
    <FormProvider {...form}>
      <form noValidate aria-label={t('activation_groups.update_form.title')} onSubmit={form.handleSubmit(handleSubmit)}>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <TextFieldController required id="name" label={t('common.name')} name="name" />

          <Stack flexDirection="column" gap={3}>
            <CustomTypography variant="h3">{t('activation_groups.create_form.bidding_limits_label')}</CustomTypography>
            <Stack flexDirection="column" gap={3}>
              <TextFieldController
                id="biddable-volume-range-min"
                label={t('activation_groups.create_form.biddable_volume_range_min_label')}
                name="biddableVolumeRange.min"
                type="number"
              />
              <TextFieldController
                id="biddable-volume-range-max"
                label={t('activation_groups.create_form.biddable_volume_range_max_label')}
                name="biddableVolumeRange.max"
                type="number"
              />
              <SelectFieldController
                fullWidth
                id="biddable-volume-range-unit"
                label={t('activation_groups.create_form.biddable_volume_range_unit_label')}
                name="biddableVolumeRange.unit"
                options={Object.values(ApiVolumeRangeUnit).map((unit) => ({
                  value: unit,
                  label: t(
                    `activation_groups.volume_range_units.${unit.toLowerCase() as Lowercase<ApiVolumeRangeUnit>}`,
                  ),
                  id: unit,
                }))}
              />
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      checked={form.watch('state') === ActivationGroupState.LIVE}
                      data-testid="activation-group-state-switch"
                      disabled={statusSwitchDisabled}
                      name="state"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        form.setValue(
                          'state',
                          event.target.checked ? ActivationGroupState.LIVE : ActivationGroupState.NOT_LIVE,
                        )
                      }
                    />
                  }
                  label={t('activation_groups.create_form.is_live_label')}
                  sx={{ m: 0 }}
                />
                {statusSwitchDisabled && (
                  <FormHelperText sx={{ color: 'warning.main' }}>
                    {t('activation_groups.create_form.is_live_disabled_helper')}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>
          </Stack>
        </DialogContent>

        <FormDialogActions isLoading={isLoading} onCancel={onCancel} />
      </form>
    </FormProvider>
  )
}

export default UpdateActivationGroupDialogForm
