import type { TooltipProps } from '@mui/material'
import { Fab, Tooltip } from '@mui/material'
import type { IconButtonProps } from '@mui/material/IconButton'
import IconButton from '@mui/material/IconButton'
import type { OverridableComponent } from '@mui/material/OverridableComponent'
import type { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon'
import type { ElementType } from 'react'

export type CustomIconButtonProps<C extends ElementType> = Omit<
  IconButtonProps<C, { component?: C }>,
  'children' | 'color' | 'size'
> & {
  variant?: 'solid' | 'plain'
  Icon: OverridableComponent<SvgIconTypeMap>
  iconProps?: SvgIconTypeMap['props']
  color?: IconButtonProps['color']
  size?: IconButtonProps['size']
  tooltipProps?: Omit<TooltipProps, 'children'>
}

function getSize(size: IconButtonProps['size']) {
  switch (size) {
    case 'small': {
      return '36px'
    }
    case 'large': {
      return '48px'
    }
    default: {
      return '42px'
    }
  }
}

/**
 * It customizes the IconButton component from MUI. The Sympower customizations are:
 *
 * - It adds two variants: solid and plain with customize set of colors.
 * - It changes the width and height assign to each size (small, medium and large).
 */
function CustomIconButton<C extends ElementType>({
  variant = 'plain',
  color = 'default',
  size = 'medium',
  Icon,
  iconProps,
  tooltipProps,
  ...props
}: CustomIconButtonProps<C>) {
  const buttonSize = getSize(size)

  switch (variant) {
    case 'solid':
      return (
        <Tooltip title="" {...tooltipProps}>
          <Fab
            {...props}
            color={color}
            size={size}
            sx={{
              ...props.sx,
              boxShadow: 'none',
              '&': {
                width: buttonSize,
                height: buttonSize,
              },
            }}
          >
            <Icon fontSize={size} {...iconProps} />
          </Fab>
        </Tooltip>
      )
    case 'plain':
      return (
        <Tooltip title="" {...tooltipProps}>
          <IconButton
            {...props}
            color={color}
            sx={{
              ...props.sx,
              '&': {
                width: buttonSize,
                height: buttonSize,
              },
            }}
          >
            <Icon fontSize={size} {...iconProps} />
          </IconButton>
        </Tooltip>
      )
    default:
      return null
  }
}

export default CustomIconButton
