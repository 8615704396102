import type { TFunction } from 'i18next'

import type { MarketProgram } from '@/features/site/types/marketProgram'
import type { MarketProgramContract } from '@/features/site/types/marketProgramContract'
import type { MarketProgramType } from '@/types/marketProgramType'

export function compareMarketPrograms(t: TFunction, a: MarketProgram, b: MarketProgram) {
  const prevMarketProgramTranslation: string = t(`common.market_program.${a.type as MarketProgramType}`)
  const nextMarketProgramTranslation: string = t(`common.market_program.${b.type as MarketProgramType}`)

  return prevMarketProgramTranslation.localeCompare(nextMarketProgramTranslation)
}

export function sortMarketPrograms(t: TFunction, marketPrograms: MarketProgram[]) {
  return marketPrograms.sort((a, b) => {
    return compareMarketPrograms(t, a, b)
  })
}

export function sortMarketProgramContracts(t: TFunction, marketProgramContracts: MarketProgramContract[]) {
  return marketProgramContracts.sort((a, b) => {
    return compareMarketPrograms(t, a.service, b.service)
  })
}
