import { IconChevronLeft, IconChevronRight, IconClose } from '@aws-amplify/ui-react/internal'
import { CheckOutlined } from '@mui/icons-material'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CustomButton from '@/components/inputs/CustomButton'
import { useSiteStepsContext } from '@/features/site/contexts/SiteStepsContext'

type StepperNavigationFooterProps = {
  isSavePending: boolean
  onMoveToStep: (index: number) => void
  onSubmit: () => void
}

function StepperNavigationFooter({ onMoveToStep, isSavePending, onSubmit }: Readonly<StepperNavigationFooterProps>) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [{ activeStep, stepsToShow, completed }] = useSiteStepsContext()

  const numberOfSteps = stepsToShow.length
  const showSkipToReviewButton = completed[0] && completed[1]

  function handleNext() {
    onMoveToStep(activeStep + 1)
  }

  function handleBack() {
    onMoveToStep(activeStep - 1)
  }

  function handleSkipToReview() {
    onMoveToStep(numberOfSteps - 1)
  }

  const isLastStep = activeStep === numberOfSteps - 1
  const isStepBeforeLast = activeStep === numberOfSteps - 2

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', py: 2 }}>
      <CustomButton startIcon={<IconClose />} sx={{ mr: 1 }} onClick={() => navigate(-1)}>
        {t('common.button.cancel')}
      </CustomButton>
      {activeStep > 0 && (
        <CustomButton startIcon={<IconChevronLeft />} sx={{ mr: 1 }} variant={'outlined'} onClick={handleBack}>
          {t('common.button.back')}
        </CustomButton>
      )}
      <Box sx={{ flex: '1 1 auto' }} />
      {showSkipToReviewButton && !isStepBeforeLast && !isLastStep && (
        <CustomButton endIcon={<IconChevronRight />} sx={{ mr: 1 }} onClick={handleSkipToReview}>
          {t('component.stepper.skip_to_review')}
        </CustomButton>
      )}
      {isLastStep ? (
        <CustomButton disabled={isSavePending} startIcon={<CheckOutlined />} variant="contained" onClick={onSubmit}>
          {t('sites.add_new.save_button_label')}
        </CustomButton>
      ) : (
        <CustomButton endIcon={<IconChevronRight />} variant={'contained'} onClick={handleNext}>
          {isStepBeforeLast ? t('component.stepper.review') : t('common.button.next')}
        </CustomButton>
      )}
    </Box>
  )
}

export default StepperNavigationFooter
