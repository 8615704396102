import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { SiteActivationDetailsView } from '@/features/activation/components/SiteActivationDetailsView'
import NavigateToError from '@/features/error/pages/NavigateToError'
import { SiteDetailsRouteInformation } from '@/features/site/pages/constants'
import { useSiteDetails } from '@/features/site/pages/SiteDetails'

export const SiteDetailsActivationDetails = () => {
  const { site, siteDetailsCommonBreadcrumbs, setPageConfig } = useSiteDetails()
  const { t } = useTranslation()
  const { activationId } = useParams()

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.activation_details'),
      pageTag: 'customer-details-activation-details',
      breadcrumbs: [
        ...siteDetailsCommonBreadcrumbs,
        {
          text: t('customer_details.tabs.activations'),
          to: `/sites/${site.uuid}/activations`,
        },
      ],
      activeTab: SiteDetailsRouteInformation.ACTIVATIONS.navigationPath,
    })
  }, [])

  if (!activationId) {
    return <NavigateToError statusCode={404} />
  }

  return <SiteActivationDetailsView activationId={activationId} site={site} />
}
