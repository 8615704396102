import { Autocomplete, TextField } from '@mui/material'
import type { GridFilterItem } from '@mui/x-data-grid'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AUTOCOMPLETE_FIELD_WIDTH } from '@/constants/layout'
import { OPERATOR_VALUE_HAS_ANY_OF } from '@/utils/datagrid/filters'

type Props = {
  // DataGrid columns have a property called field, which acts as a unique identifier for the column.
  field: string
  value: string[]
  id?: string
  customerOptions: string[]
  onChange: (items: GridFilterItem[]) => void
}

const SitesFilter = ({ field, value, id, customerOptions, onChange }: Props) => {
  const { t } = useTranslation()
  const sortedCustomerOptions = useMemo(() => customerOptions.toSorted((a, b) => a.localeCompare(b)), [customerOptions])

  return (
    <Autocomplete
      filterSelectedOptions
      multiple
      data-testid={id}
      id={id}
      limitTags={2}
      options={sortedCustomerOptions}
      renderInput={(params) => <TextField {...params} label={t('users.customer.label')} />}
      sx={{ maxWidth: AUTOCOMPLETE_FIELD_WIDTH, minWidth: AUTOCOMPLETE_FIELD_WIDTH }}
      value={value}
      onChange={(event, value) => {
        onChange([
          {
            id: field,
            field,
            operator: OPERATOR_VALUE_HAS_ANY_OF,
            value,
          },
        ])
      }}
    />
  )
}

export default SitesFilter
