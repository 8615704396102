import { Box, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import type { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import LabeledTextField from '@/components/dataDisplay/LabeledTextField'
import BidStatusChip from '@/features/bidding/components/BidStatusChip'
import type { BidMetaData } from '@/features/bidding/types/bid'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends BidVersionPtusToolbarProps {}
}

type BidVersionPtusToolbarProps = {
  bid: BidMetaData
  activationGroupSelectorComponent?: ReactElement
  bidSelectorComponent?: ReactElement
  expandAllRowsComponent?: ReactElement
}

const BidVersionPtusToolbar = ({
  bid,
  activationGroupSelectorComponent,
  bidSelectorComponent,
  expandAllRowsComponent,
}: BidVersionPtusToolbarProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Stack direction="row" marginY={2} spacing={1}>
        {activationGroupSelectorComponent ?? null}
        {bidSelectorComponent ?? null}
        {expandAllRowsComponent ?? null}
      </Stack>
      <Stack direction="row" marginY={2} spacing={1}>
        <TableTextSummary label={t('bidding.create_bid.select_portfolio')} text={bid.portfolio.name} />
        <TableTextSummary
          label={t('bidding.create_bid.select_market_program')}
          text={t(`bidding.market_program.${bid.marketProgram}`)}
        />

        <TableTextSummary label={t('bidding.results.bid_date')} text={bid.deliveryDay.getStartOfDay().toFormat('DD')} />
        <Box sx={{ bgcolor: grey[50], borderRadius: 1, p: 2 }} width={'100%'}>
          <CustomTypography color={'#9e9e9e'} variant="caption">
            {t('bidding.results.status')}
          </CustomTypography>
          <BidStatusChip status={bid.status} />
        </Box>
      </Stack>
    </>
  )
}

type TableTextSummaryProps = {
  label: string
  text: string
}

const TableTextSummary = ({ label, text }: TableTextSummaryProps) => (
  <LabeledTextField label={label} text={text} variant="filled" />
)

export default BidVersionPtusToolbar
