import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { FormGroup, Grid, Stack, Tooltip } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import type { SelectFieldOption } from '@/components/inputs/CustomSelectField'
import SelectFieldController from '@/components/inputs/SelectFieldController'
import type { UserRole } from '@/constants/userRoles'
import { USER_ROLES } from '@/constants/userRoles'
import type { User } from '@/features/user/types/user'
import type { Partner } from '@/openapi/v1/msa-customer-partner-manager/partner'

interface PartnerAccessSectionProps {
  partners: Partner[]
}

const SYMPOWER = 'SYMPOWER'

export default function PartnerAccessSection({ partners }: PartnerAccessSectionProps) {
  const { t } = useTranslation()
  const { getValues, setValue, trigger } = useFormContext<User>()

  const { role } = getValues()

  const isHidden = !(hasRole(USER_ROLES.PARTNER_ADMINISTRATORS.value) || hasRole(USER_ROLES.CUSTOMER_MANAGERS.value))
  const isCustomerManager = hasRole(USER_ROLES.CUSTOMER_MANAGERS.value)

  function getPartnerAccessTooltip() {
    if (!isUserPartnerAvailable()) return t('user_form.form.partner_access_partner_not_available')
    if (getPartnerSelectionOptions().length === 0) return t('user_form.form.partner_access_no_partners')
    return null
  }

  function isUserPartnerAvailable(): boolean {
    const partnerCode = getValues('partnerCode')
    return !partnerCode || !!partners.find((partner) => partner.partnerCode === partnerCode)
  }

  function hasRole(role: UserRole): boolean {
    return getValues('role')?.includes(role) ?? false
  }

  function triggerPartnerValidation() {
    trigger('partnerCode')
  }

  function getPartnerSelectionOptions() {
    let partnerOptions = partners.map((partner) => ({ value: partner.partnerCode, text: partner.name }))
    if (role !== USER_ROLES.RESOURCE_OWNERS.value) {
      partnerOptions = partnerOptions.filter((partner) => partner.value !== SYMPOWER)
    }
    return partnerOptions
  }

  function getSelectionOptions(): SelectFieldOption<string>[] {
    return [
      { value: '', label: '-', id: '-' },
      ...getPartnerSelectionOptions().map((partner) => ({
        id: partner.value,
        value: partner.value,
        label: partner.text,
      })),
    ]
  }

  return isHidden ? null : (
    <FormGroup>
      <Stack direction="column" gap={2}>
        <CustomTypography gutterBottom variant="h6">
          {isCustomerManager
            ? t('user_form.form.title_partner_admin_access')
            : t('user_form.form.title_partner_access')}
        </CustomTypography>
        {isCustomerManager && (
          <Stack direction="row" gap={1}>
            <InfoOutlinedIcon color="primary" fontSize="small" />
            <CustomTypography gutterBottom variant="body2">
              {t('user_form.form.partner_admin_access_hint')}
            </CustomTypography>
          </Stack>
        )}
        <Grid container>
          <Grid size={{ md: 5, sm: 12, xs: 12 }}>
            <Tooltip placement={'right'} title={getPartnerAccessTooltip()}>
              <SelectFieldController
                fullWidth
                disabled={getPartnerSelectionOptions().length === 0 || !isUserPartnerAvailable()}
                id="partner-selection"
                label={t('user_form.form.partner_access_label')}
                name="partnerCode"
                options={
                  isUserPartnerAvailable()
                    ? getSelectionOptions()
                    : [
                        {
                          id: getValues('partnerCode'),
                          value: getValues('partnerCode'),
                          label: getValues('partnerCode'),
                        } as SelectFieldOption<string>,
                      ]
                }
                onChange={(event) => {
                  setValue('partnerCode', event.target.value)
                  if (isCustomerManager) {
                    setValue('allowedRoIds', [])
                  }
                  triggerPartnerValidation()
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Stack>
    </FormGroup>
  )
}
