import axios from 'axios'

import environment from '@/environment'

export const NORDPOOL_TRADE_ADAPTER_API_URL = environment.services.nordpoolTradeAdapterApiUrl

export const importTrade = async (fileContent: string): Promise<void> => {
  // As we send a text file, we need to create the payload using the FormData API.
  const formData = new FormData()
  formData.append('body', fileContent)

  const url = `${NORDPOOL_TRADE_ADAPTER_API_URL}/trades/entelios/import`

  await axios.post<string>(url, formData)
}
