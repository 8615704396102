import CheckIcon from '@mui/icons-material/CheckOutlined'
import CloseIcon from '@mui/icons-material/Close'
import type { DrawerProps } from '@mui/material'
import { Box, Button, CircularProgress, Divider, Drawer, IconButton, Typography } from '@mui/material'
import React from 'react'

export type MultiSelectDrawerProps = DrawerProps & {
  onSave: () => void
  description?: string
  selectedItemsCount: number
  isSaving?: boolean
  saveButtonText: string
  cancelButtonText: string
}

const MultiSelectDrawer = ({
  open,
  onClose,
  onSave,
  title = 'Select Items',
  description = 'Select items you want to add',
  selectedItemsCount,
  isSaving = false,
  children,
  saveButtonText,
  cancelButtonText = 'Cancel',
}: MultiSelectDrawerProps) => {
  const formattedSaveButtonText = isSaving ? null : `${saveButtonText} (${selectedItemsCount})`

  return (
    <Drawer
      anchor="right"
      open={open}
      slotProps={{
        backdrop: {
          onClick: (event) => {
            event.stopPropagation()
          },
        },
      }}
      sx={{
        '& .MuiDrawer-paper': {
          width: { xs: '100%', sm: 580 },
        },
      }}
      onClose={onClose}
    >
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5">{title}</Typography>
        <IconButton onClick={(event) => onClose && onClose(event, 'escapeKeyDown')}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider />

      {description && (
        <Box sx={{ p: 2 }}>
          <Typography gutterBottom color="text.primary" variant="body1">
            {description}
          </Typography>
        </Box>
      )}

      {/* Content area */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>

      {/* Footer actions */}
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          startIcon={<CloseIcon />}
          sx={{ mr: 1 }}
          onClick={(event) => onClose && onClose(event, 'escapeKeyDown')}
        >
          {cancelButtonText}
        </Button>
        <Button
          color="primary"
          disabled={isSaving || selectedItemsCount === 0}
          startIcon={<CheckIcon />}
          variant="contained"
          onClick={onSave}
        >
          {isSaving ? <CircularProgress size={24} /> : formattedSaveButtonText}
        </Button>
      </Box>
    </Drawer>
  )
}

export default MultiSelectDrawer
