import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { Box, Divider, Pagination, Stack } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { usePermissions } from '@/features/authorization/contexts/PermissionsContext'
import SearchInput from '@/features/search/components/Search'
import { ContactCard } from '@/features/site/components/companyInfo/ContactCard'
import { useInvalidateSiteQuery } from '@/features/site/hooks/useSiteQuery'
import type { Site, SiteContact } from '@/features/site/types/site'

interface SiteContactsProps {
  site?: Site
}

const CONTACT_PAGE_SIZE = 5

function sortByDateCreatedDescendingComparator() {
  return (a: SiteContact, b: SiteContact) => {
    if (a.createdAt && b.createdAt) {
      return a.createdAt > b.createdAt ? -1 : 1
    }
    return 0
  }
}

interface SectionTitleProps {
  onAddClick: any
  viewOnly?: boolean
}

export const SectionTitle = ({ onAddClick, viewOnly }: SectionTitleProps) => (
  <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
    <CustomTypography fontSize={20} variant="h4">
      {t('customer_details.tabs.company_info.contacts.title')}
    </CustomTypography>

    {!viewOnly && (
      <CustomIconButton
        Icon={AddOutlinedIcon}
        aria-label={'Create contact'}
        color="primary"
        size="small"
        variant={'solid'}
        onClick={onAddClick}
      />
    )}
  </Stack>
)

interface ShowContactCardProps {
  siteUuid?: string
  location?: string
  isCreateMode: boolean
  onChange: () => void
  onCancel: () => void
}

const ShowContactCard = ({ siteUuid, location, isCreateMode, onCancel, onChange }: ShowContactCardProps) => {
  if (!location || !isCreateMode) return null

  return (
    <Box>
      <ContactCard isFormMode={true} location={location} siteUuid={siteUuid} onCancel={onCancel} onChange={onChange} />

      <Divider sx={{ marginTop: 2 }} />
    </Box>
  )
}

export const SiteContacts = (props: SiteContactsProps) => {
  const { permissions } = usePermissions()
  const invalidateSiteQuery = useInvalidateSiteQuery()
  const [filteredContacts, setFilteredContacts] = useState<SiteContact[]>([])
  const [contactsToShow, setContactsToShow] = useState<SiteContact[]>([])
  const [currentPageIndex, setCurrentPageIndex] = useState(1)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [isCreateMode, setIsCreateMode] = useState(false)
  const [searchText, setSearchText] = useState('')

  const isViewOnly = !permissions.has('siteContactsMutation')

  // Set all contacts on site change
  useEffect(() => {
    filterContacts(searchText)
  }, [props.site])

  // Update number of pages when filtered contacts change
  useEffect(() => {
    setNumberOfPages(Math.ceil(filteredContacts?.length / CONTACT_PAGE_SIZE))
  }, [filteredContacts])

  useEffect(() => {
    const contacts = filteredContacts?.slice(
      (currentPageIndex - 1) * CONTACT_PAGE_SIZE,
      currentPageIndex * CONTACT_PAGE_SIZE,
    )
    setContactsToShow(contacts)
  }, [currentPageIndex, filteredContacts])

  function searchContacts(searchText: string) {
    setSearchText(searchText)
    filterContacts(searchText)
  }

  function filterContacts(searchText: string) {
    if (!searchText) {
      setFilteredContacts(props.site?.contacts?.sort(sortByDateCreatedDescendingComparator()) || [])
      setCurrentPageIndex(1)
      return
    }
    const filteredContacts = props.site?.contacts?.filter((contact) => {
      return (
        contact.name?.toLowerCase().includes(searchText.toLowerCase()) ||
        contact.email?.toLowerCase().includes(searchText.toLowerCase()) ||
        contact.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
        contact.comment?.toLowerCase().includes(searchText.toLowerCase())
      )
    })
    setFilteredContacts(filteredContacts?.sort(sortByDateCreatedDescendingComparator()) || [])
    setCurrentPageIndex(1)
  }

  function handleContactFormChange() {
    setIsCreateMode(false)
    setSearchText('')
    invalidateSiteQuery({ uuid: props.site?.uuid })
  }

  function handleContactFormCancel() {
    setIsCreateMode(false)
  }

  if (!props.site?.contacts?.length) {
    return (
      <>
        <SectionTitle viewOnly={isViewOnly} onAddClick={() => setIsCreateMode(!isCreateMode)} />

        <CustomTypography variant="body1">
          {t('customer_details.tabs.company_info.contacts.no_contacts')}
        </CustomTypography>

        <ShowContactCard
          isCreateMode={isCreateMode}
          location={props.site?.location}
          siteUuid={props.site?.uuid}
          onCancel={handleContactFormCancel}
          onChange={handleContactFormChange}
        />
      </>
    )
  }

  return (
    <Stack alignItems={'stretch'} direction={'column'} justifyContent={'space-between'} minHeight={'100%'} spacing={2}>
      <Stack gap={2}>
        <SectionTitle viewOnly={isViewOnly} onAddClick={() => setIsCreateMode(!isCreateMode)} />

        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <SearchInput search={searchContacts} searchText={searchText} />
        </Box>

        <Divider />

        <ShowContactCard
          isCreateMode={isCreateMode}
          location={props.site?.location}
          siteUuid={props.site?.uuid}
          onCancel={handleContactFormCancel}
          onChange={handleContactFormChange}
        />

        {!contactsToShow.length ? (
          <CustomTypography variant="body1">
            {t('customer_details.tabs.company_info.contacts.no_contacts_found')}
          </CustomTypography>
        ) : (
          contactsToShow?.map((contact) => (
            <Box key={contact.legacyId}>
              <ContactCard
                contact={contact}
                location={props.site?.location}
                siteUuid={props.site?.uuid}
                viewOnly={isViewOnly}
                onChange={() => invalidateSiteQuery({ uuid: props.site!.uuid! })}
              />

              <Divider sx={{ marginTop: 2 }} />
            </Box>
          ))
        )}
      </Stack>

      {/* If number of pages is less than 1, hide*/}
      {numberOfPages > 1 && (
        <Stack alignItems={'center'} mt={2} spacing={2}>
          <Pagination
            count={numberOfPages}
            page={currentPageIndex}
            onChange={(event, page) => {
              setCurrentPageIndex(page)
              setContactsToShow(
                filteredContacts?.slice(
                  (page - 1) * CONTACT_PAGE_SIZE,
                  (page - 1) * CONTACT_PAGE_SIZE + CONTACT_PAGE_SIZE,
                ),
              )
            }}
          />
        </Stack>
      )}
    </Stack>
  )
}
