import { IconChevronLeft } from '@aws-amplify/ui-react/internal'
import { Box } from '@mui/material'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import ButtonBar from '@/components/inputs/ButtonBar'
import CustomButton from '@/components/inputs/CustomButton'
import PageHeader from '@/components/layouts/PageHeader'
import BidConfirmSendButton from '@/features/bidding/components/capacity/confirm/BidConfirmSendButton'
import CountrySelector from '@/features/bidding/components/CountrySelector'
import BidConfirmPriceDataGrid from '@/features/bidding/components/price/confirm/BidConfirmPriceDataGrid'
import { ALL_RESULTS } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { useGroupedBidsPageQuery } from '@/features/bidding/hooks/useGroupedBidsPageQuery'
import { useMarketClosingWarning } from '@/features/bidding/hooks/useMarketClosingWarning'
import { buildBidCapacityLink, buildBidPriceLink } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import getGroupedBidParamsFromUrl from '@/features/bidding/utils/groupedBidParams/getGroupedBidParamsFromUrl'

const BidOfferPriceConfirm = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { selectedCountry } = useBiddingContext()

  const { showMarketClosingWarning } = useMarketClosingWarning()
  const groupParams = getGroupedBidParamsFromUrl(location)

  const { groupedBidsPage } = useGroupedBidsPageQuery(
    {
      pagination: { pageSize: ALL_RESULTS, page: 0 },
      filter: {
        items: groupParams
          ? [
              { field: 'portfolio', operator: 'equals', value: groupParams.portfolioCode },
              { field: 'marketProgram', operator: 'equals', value: groupParams.marketProgram },
              { field: 'deliveryDay', operator: 'equals', value: groupParams.deliveryDay },
              { field: 'bidType', operator: 'equals', value: groupParams.bidType },
            ]
          : [],
      },
      country: selectedCountry,
    },
    { enabled: !!groupParams },
  )
  const activationGroupBids = groupedBidsPage?.groupedBids.flatMap((group) => Object.values(group.bids))

  useEffect(() => {
    if (groupParams?.deliveryDay) showMarketClosingWarning(groupParams.deliveryDay)
  }, [groupParams?.deliveryDay])

  if (!groupParams || !groupedBidsPage || !activationGroupBids) return <FullPageSpinner />

  return (
    <>
      <PageHeader
        appBarContent={
          <Box alignItems="center" display="flex" flexDirection="row" sx={{ justifyContent: 'left' }}>
            <CountrySelector disabled />
          </Box>
        }
        breadcrumbItems={[
          { text: t('component.page_header.bidding'), to: '/bidding' },
          { text: t('component.page_header.bid.todo_list'), to: '/bidding/todo-list' },
          {
            text: t('component.page_header.bid.create.label', {
              portfolio: groupParams.portfolioCode,
              marketProgram: t(`bidding.market_program.${groupParams.marketProgram}`),
              bidType: groupParams.bidType.toLowerCase(),
            }),
            to: buildBidCapacityLink(groupParams),
          },
          { text: t('bidding.create_bid.price_bid'), to: buildBidPriceLink(groupParams) },
        ]}
        pageTitle={t('component.page_header.bid.confirmation.label')}
      />

      <BidConfirmPriceDataGrid activationGroupBids={activationGroupBids} />

      <ButtonBar>
        <CustomButton
          key="backButton"
          startIcon={<IconChevronLeft />}
          variant={'outlined'}
          onClick={() => navigate(-1)}
        >
          {t('common.navigation.back')}
        </CustomButton>

        <BidConfirmSendButton bids={activationGroupBids} />
      </ButtonBar>
    </>
  )
}

export default BidOfferPriceConfirm
