import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import type { SelectChangeEvent } from '@mui/material'
import { Stack } from '@mui/material'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import type { PickerOwnerState } from '@mui/x-date-pickers-pro'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import type { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomDateTimeRangePicker from '@/components/inputs/CustomDateTimeRangePicker'
import CustomFormControl from '@/components/inputs/CustomFormControl'
import type { SelectFieldOption } from '@/components/inputs/CustomSelectField'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import type { InsightRevenueDateTimeRange } from '@/features/site/types/revenue'

export type Period = 'allTime' | 'year' | 'custom'

type TimeRangeFilterPropsByPeriodProps = {
  period: Period
  timeRange: InsightRevenueDateTimeRange
  timezone: string
  limitDateTimeRange: InsightRevenueDateTimeRange
  onChange: (timeRange: InsightRevenueDateTimeRange) => void
}

type SiteRevenuePeriodFilterProps = {
  defaultPeriod: Period
  timeRange: InsightRevenueDateTimeRange
  timezone: string
  limitDateTimeRange: InsightRevenueDateTimeRange
  onChange: (timeRange: InsightRevenueDateTimeRange) => void
}

type YearPickerIconProps = {
  ownerState: PickerOwnerState<DateTime>
}

const YearPickerIcon = ({ ownerState }: YearPickerIconProps) => {
  return ownerState.open ? <ArrowDropUp /> : <ArrowDropDown />
}

const TimeRangeFilterByPeriod = ({
  period,
  timeRange,
  onChange,
  timezone,
  limitDateTimeRange,
}: Readonly<TimeRangeFilterPropsByPeriodProps>) => {
  const { t, i18n } = useTranslation()

  switch (period) {
    case 'year':
      return (
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
          <CustomFormControl id="revenue-period-year" variant="outlinedWhite">
            <DesktopDatePicker
              disableFuture
              label={t('common.period.year')}
              maxDate={limitDateTimeRange.endTime}
              shouldDisableYear={(year) => year < limitDateTimeRange.startTime.startOf('year')}
              slotProps={{
                desktopPaper: {
                  sx: { paddingY: 5, paddingX: 4.5 },
                },
                layout: {
                  sx: {
                    '& .MuiDateCalendar-root': {
                      height: 'auto',
                    },
                  },
                },
              }}
              slots={{
                openPickerIcon: YearPickerIcon,
              }}
              sx={{ width: '100px' }}
              value={timeRange.endTime}
              views={['year']}
              yearsOrder="desc"
              onChange={(value) =>
                onChange({
                  startTime: value?.startOf('year') ?? timeRange.endTime.startOf('year'),
                  endTime: value?.endOf('year') ?? timeRange.endTime,
                })
              }
            />
          </CustomFormControl>
        </LocalizationProvider>
      )
    case 'custom':
      return (
        <CustomFormControl id="revenue-period-custom" variant="outlinedWhite">
          <CustomDateTimeRangePicker
            label={t('common.period.custom')}
            maxDate={limitDateTimeRange.endTime}
            shouldDisableDate={(date) => date < limitDateTimeRange.startTime.startOf('day')}
            timezone={timezone}
            value={[timeRange.startTime, timeRange.endTime]}
            onAccept={(range) => {
              onChange({
                startTime: range[0] ?? timeRange.startTime,
                endTime: range[1] ?? timeRange.endTime,
              })
            }}
          />
        </CustomFormControl>
      )
    case 'allTime':
      return (
        <CustomTypography color="white" sx={{ paddingX: 1.5 }} variant="body1">
          {`${timeRange.startTime.toFormat('dd-MM-yyyy, HH:mm')} ${t('common.period.to').toLowerCase()} ${timeRange.endTime.toFormat('dd-MM-yyyy, HH:mm')}`}
        </CustomTypography>
      )
  }
}

export const SiteRevenuePeriodFilter = ({
  onChange,
  timezone,
  defaultPeriod,
  timeRange,
  limitDateTimeRange,
}: Readonly<SiteRevenuePeriodFilterProps>) => {
  const { t } = useTranslation()
  const [period, setPeriod] = useState<Period>(defaultPeriod)

  const periodOptions: SelectFieldOption<Period>[] = [
    { id: 'allTime', value: 'allTime', label: t('common.period.all_time') },
    { id: 'year', value: 'year', label: t('common.period.year') },
    { id: 'custom', value: 'custom', label: t('common.period.custom') },
  ]

  function handlePeriodChange(evt: SelectChangeEvent) {
    const newPeriod = evt.target.value as Period

    setPeriod(newPeriod)

    // Apply default values when period changes, which is always the period of time from
    // the start of the year to the current date.
    // This will change as soon as we have the ability to request the new /periods endpoint
    onChange(limitDateTimeRange)
  }

  return (
    <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
      <CustomSelectField
        autoWidth
        id="site-revenue-period-filter"
        label={t('common.period.label')}
        options={periodOptions}
        size="medium"
        value={period}
        variant="outlinedWhite"
        onChange={handlePeriodChange}
      />

      <TimeRangeFilterByPeriod
        limitDateTimeRange={limitDateTimeRange}
        period={period}
        timeRange={timeRange}
        timezone={timezone}
        onChange={onChange}
      />
    </Stack>
  )
}
