import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import {
  GET_CUSTOM_ATTRIBUTE_SCHEMAS_API_ID,
  getAllCustomAttributeSchemas,
} from '@/features/resource/endpoints/customAttributeSchemas'
import type { CustomAttributeSchema } from '@/features/resource/types'
import type { UseQueryOptions } from '@/types/queries'

export type UseCustomAttributeSchemasQueryResult = Omit<UseQueryResult, 'data'> & {
  customAttributeSchemas?: CustomAttributeSchema[]
}

export function useCustomAttributeSchemasQuery(
  options?: UseQueryOptions<CustomAttributeSchema[]>,
): UseCustomAttributeSchemasQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_CUSTOM_ATTRIBUTE_SCHEMAS_API_ID],
    queryFn: () => getAllCustomAttributeSchemas(),
  })

  return { customAttributeSchemas: data, ...queryResult }
}
