import { zodResolver } from '@hookform/resolvers/zod'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import { useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { DEFAULT_PARTNER } from '@/constants/partners'
import { useAlertContext } from '@/contexts/AlertContext'
import StepperNavigationFooter from '@/features/site/components/create/StepperNavigationFooter'
import { ALL_STEPS, INDIRECT_SITE_STEPS, useSiteStepsContext } from '@/features/site/contexts/SiteStepsContext'
import { useCreateSiteMutation } from '@/features/site/hooks/useCreateSiteMutation'
import { getCreateSiteFormSchema } from '@/features/site/schemas'
import type { CreateSiteForm } from '@/features/site/types/site'
import type { SiteCreateStepId } from '@/features/site/types/siteCreateStep'
import { convertCreateSiteFormToSite, convertSiteToCreateSiteForm } from '@/features/site/utils/createSiteForm'
import { errorHandler } from '@/utils/errorHandler'

export default function SiteCreate() {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()
  const navigate = useNavigate()
  const { createSite, isPending } = useCreateSiteMutation()
  const form = useForm<CreateSiteForm>({
    mode: 'onBlur',
    defaultValues: convertSiteToCreateSiteForm(),
    resolver: zodResolver(getCreateSiteFormSchema(t)),
  })
  const partnerCode = useWatch({
    control: form.control,
    name: 'partnerCode',
  })
  const [{ activeStep, stepsToShow, completed, failed }, siteStepsDispatch] = useSiteStepsContext()

  useEffect(() => {
    if (partnerCode === DEFAULT_PARTNER.partnerCode) {
      siteStepsDispatch({
        type: 'setStepsToShowAction',
        payload: ALL_STEPS,
      })
    } else {
      siteStepsDispatch({
        type: 'setStepsToShowAction',
        payload: INDIRECT_SITE_STEPS,
      })

      form.resetField('financialInformation')
    }
  }, [partnerCode])

  async function handleSubmitSuccess(formData: CreateSiteForm) {
    try {
      await createSite(convertCreateSiteFormToSite(formData))
      navigate('/sites')
    } catch (err) {
      const error = errorHandler(err, t('sites.add_new.errors.request'))

      pushAlert({
        severity: 'error',
        message: error.message,
      })
    }
  }

  function handleSubmitError() {
    pushAlert({
      severity: 'error',
      message: t('sites.add_new.errors.form_submit'),
    })
  }

  async function handleSubmit() {
    const statuses = await Promise.all(ALL_STEPS.map((step) => step.determineStatus(form)))
    let hasErrors = false

    statuses.forEach((status, stepIndex) => {
      if (!hasErrors && status === 'failed') {
        hasErrors = true
      }

      markStepCompleted(stepIndex, status === 'completed')
      markStepFailed(stepIndex, status === 'failed')
    })

    if (hasErrors) {
      handleSubmitError()
    } else {
      form.handleSubmit(handleSubmitSuccess)()
    }
  }

  async function handleMoveToStep(newStep: number) {
    const status = await ALL_STEPS[activeStep].determineStatus(form)

    markStepCompleted(activeStep, status === 'completed')
    markStepFailed(activeStep, status === 'failed')

    if (activeStep === 0 && status === 'failed') return

    siteStepsDispatch({
      type: 'moveToStep',
      payload: newStep,
    })
  }

  function markStepFailed(index: number, failed: boolean) {
    siteStepsDispatch({
      type: 'setFailed',
      payload: { [index]: failed },
    })
  }

  function markStepCompleted(index: number, completed: boolean) {
    siteStepsDispatch({
      type: 'setCompleted',
      payload: { [index]: completed },
    })
  }

  return (
    <FormProvider {...form}>
      <Box component="form" sx={{ width: '100%', mt: 4 }}>
        <Stepper nonLinear activeStep={activeStep}>
          {stepsToShow.map((step, index) => {
            const stepProps: { completed?: boolean } = {}
            const labelProps: {
              optional?: React.ReactNode
              error?: boolean
            } = {}

            if (stepsToShow[index].optional) {
              labelProps.optional = <Typography variant="caption">{t('component.stepper.optional')}</Typography>
            }

            if (failed[index]) {
              labelProps.optional = (
                <Typography color="error" variant="caption">
                  {stepsToShow[index].optional
                    ? t('component.stepper.invalid_values')
                    : t('component.stepper.mandatory_fields')}
                </Typography>
              )
              labelProps.error = true
            }

            return (
              <Step
                key={step.id}
                completed={completed[index]}
                sx={{ cursor: 'pointer' }}
                {...stepProps}
                onClick={() => {
                  handleMoveToStep(index)
                }}
              >
                {index === stepsToShow.length - 1 ? (
                  <StepLabel
                    {...labelProps}
                    slots={{
                      stepIcon: () => (
                        <VisibilityOutlinedIcon color={activeStep === stepsToShow.length - 1 ? 'primary' : 'action'} />
                      ),
                    }}
                  >
                    {t(`sites.add_new.steps.${step.id as SiteCreateStepId}`)}
                  </StepLabel>
                ) : (
                  <StepLabel {...labelProps}>{t(`sites.add_new.steps.${step.id as SiteCreateStepId}`)}</StepLabel>
                )}
              </Step>
            )
          })}
        </Stepper>

        <Paper sx={{ p: 3, mt: 3 }}>
          <Typography sx={{ mb: 3 }} variant={'h5'}>
            {t(`sites.add_new.steps.${stepsToShow[activeStep].id as SiteCreateStepId}`)}
          </Typography>

          {stepsToShow[activeStep].content}
        </Paper>

        <StepperNavigationFooter isSavePending={isPending} onMoveToStep={handleMoveToStep} onSubmit={handleSubmit} />
      </Box>
    </FormProvider>
  )
}
