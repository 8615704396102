import { DateTime, IANAZone } from 'luxon'

// TODO: this bidding market program should be removed in favor of using the MarketProgramType enum from src/types/marketProgramType.
export enum MarketProgram {
  FCRD_UP = 'FCRD_UP',
  FCRD_DOWN = 'FCRD_DOWN',
  FCRN = 'FCRN',
  FFR_UP = 'FFR_UP',
  MFRR_UP = 'MFRR_UP',
  MFRR_DOWN = 'MFRR_DOWN',
  NORDICS_FCRD_DOWN_STATIC = 'NORDICS_FCRD_DOWN_STATIC',
  NORDICS_FCRD_UP_STATIC = 'NORDICS_FCRD_UP_STATIC',
  NORDICS_FCRD_DOWN_DYNAMIC = 'NORDICS_FCRD_DOWN_DYNAMIC',
  NORDICS_FCRD_UP_DYNAMIC = 'NORDICS_FCRD_UP_DYNAMIC',
  NORDICS_FCRN = 'NORDICS_FCRN',
  WHOLESALE_BUY = 'WHOLESALE_BUY',
  WHOLESALE_SELL = 'WHOLESALE_SELL',
}

export enum ManualAcceptFlow {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum Status {
  TODO = 'TODO',
  DRAFT = 'DRAFT',
  OFFERED = 'OFFERED',
  PARTIALLY_ACCEPTED = 'PARTIALLY_ACCEPTED',
  BUYBACK = 'BUYBACK',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  OVERBID_ACCEPTED = 'OVERBID_ACCEPTED',
  MANUALLY_ACCEPTED = 'MANUALLY_ACCEPTED',
  MANUALLY_EDITED = 'MANUALLY_EDITED',
  NO_BID = 'NO_BID',
}

export enum BidType {
  CAPACITY = 'CAPACITY',
  ENERGY = 'ENERGY',
}

export const MARKET_TIMEZONE = new IANAZone('Europe/Paris')

export const MAX_PTU_ROWS_PER_DATAGRID = 100 // on daylight savings time we can have up to 96+4 15-minute PTUs per day

export const MARKET_CLOSING_TIME = { hour: 17, minute: 0, second: 0 }

export const MARKET_CLOSING_TIME_AS_STRING = DateTime.fromObject(MARKET_CLOSING_TIME).toFormat('HH:mm')

export const MARKET_CLOSING_WARNING_MINUTES_BEFORE = 60

export const MAXIMUM_MW_PER_PTU = 1000

export const OLD_MARKET_PROGRAMS = [MarketProgram.FCRD_DOWN, MarketProgram.FCRD_UP]

export const ALL_RESULTS = 10_000

export const DEFAULT_CURRENCY = 'EUR'
