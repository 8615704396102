import { useTranslation } from 'react-i18next'

import CustomDialog from '@/components/feedback/CustomDialog'
import { useAlertContext } from '@/contexts/AlertContext'
import EditResourceDialogForm from '@/features/activationGroup/components/EditResourceDialogForm'
import { useInvalidateActivationGroupResourcesQuery } from '@/features/activationGroup/hooks/useActivationGroupResourcesQuery'
import { useUpdateActivationGroupMutation } from '@/features/activationGroup/hooks/useUpdateActivationGroupMutation'
import type {
  ActivationGroup,
  ApiActivationGroupResource,
  AssignResourceToActivationGroup,
} from '@/features/activationGroup/types'
import { serializeAssignResourceToActivationGroup } from '@/features/activationGroup/utils/activationGroups'
import { errorHandler } from '@/utils/errorHandler'

type EditResourceDialogProps = {
  activationGroupResource: ApiActivationGroupResource
  activationGroup: ActivationGroup
  resources: ApiActivationGroupResource[]
  open: boolean
  onClose: () => void
}

const EditResourceDialog = ({
  activationGroupResource,
  open,
  activationGroup,
  resources,
  onClose,
}: EditResourceDialogProps) => {
  const { t } = useTranslation()
  const { updateActivationGroup, isPending } = useUpdateActivationGroupMutation({
    activationGroupUuid: activationGroup.uuid,
  })
  const invalidateActivationGroupResourcesQuery = useInvalidateActivationGroupResourcesQuery({
    activationGroupUuid: activationGroup.uuid,
  })
  const { pushAlert } = useAlertContext()

  function handleClose() {
    onClose()
  }

  async function handleSubmit(data: AssignResourceToActivationGroup) {
    try {
      await updateActivationGroup(serializeAssignResourceToActivationGroup(data, activationGroup, resources))
      await invalidateActivationGroupResourcesQuery()

      pushAlert({
        message: t('activation_groups.edit_resource_success_message'),
        severity: 'success',
      })
      onClose()
    } catch (err) {
      const error = errorHandler(err, t('activation_groups.assign_resource_generic_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <CustomDialog open={open} title={t('activation_groups.edit_resource_dialog.title')} onClose={handleClose}>
      <EditResourceDialogForm
        activationGroup={activationGroup}
        activationGroupResource={activationGroupResource}
        isLoading={isPending}
        onCancel={handleClose}
        onSubmit={handleSubmit}
      />
    </CustomDialog>
  )
}

export default EditResourceDialog
