import { zodResolver } from '@hookform/resolvers/zod'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Divider, Stack } from '@mui/material'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import { LabeledTextFieldWithTooltip } from '@/components/dataDisplay/LabeledTextField'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { USER_ROLES } from '@/constants/userRoles'
import { useAlertContext } from '@/contexts/AlertContext'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { usePartnersQuery } from '@/features/partner/hooks/usePartnersQuery'
import CancelConfirmIconButtons from '@/features/site/components/CancelConfirmIconButtons'
import { SiteAddressInput } from '@/features/site/components/companyInfo/SiteAddressInput'
import { SiteAddressView } from '@/features/site/components/companyInfo/SiteAddressView'
import EditableBox from '@/features/site/components/EditableBox'
import { EditablePartnerFieldController } from '@/features/site/components/EditablePartnerFieldController'
import { EditableTextFieldController } from '@/features/site/components/EditableTextFieldController'
import { EditableTimezoneFieldController } from '@/features/site/components/EditableTimezoneFieldController'
import { useInvalidateCustomerSettingsQuery } from '@/features/site/hooks/useCustomerSettingsQuery'
import { useInvalidateSiteQuery } from '@/features/site/hooks/useSiteQuery'
import { useUpdateSiteInformationMutation } from '@/features/site/hooks/useUpdateSiteInformationMutation'
import { getUpdateSiteInformationFormSchema } from '@/features/site/schemas'
import type { Site, UpdateSiteInformationForm } from '@/features/site/types/site'
import type { GpsLocation } from '@/types/gpsLocation'
import { errorHandler } from '@/utils/errorHandler'

interface SiteContactInfoProps {
  site: Site
}

export const SiteInfoForm = ({ site }: SiteContactInfoProps) => {
  const { t } = useTranslation()
  const { loggedInUser } = useAuth()
  const [editMode, setEditMode] = useState(false)
  const { updateSiteInformation, isPending } = useUpdateSiteInformationMutation()
  const invalidateCustomerQuery = useInvalidateSiteQuery()
  const invalidateCustomerSettingsQuery = useInvalidateCustomerSettingsQuery()
  const defaultFormValues = {
    address: site.address,
    coordinates: site.coordinates,
    name: site.name,
    partnerCode: site.partner?.partnerCode,
    symbolicName: site.symbolicName,
    timezone: site.timezone,
  }

  const hasEditPartnerPermissions = loggedInUser?.role === USER_ROLES.ADMINISTRATORS.value
  const { partners } = usePartnersQuery({
    initialData: [site.partner!],
    enabled: hasEditPartnerPermissions,
  })

  const { pushAlert } = useAlertContext()

  const form = useForm<UpdateSiteInformationForm>({
    mode: 'onBlur',
    defaultValues: defaultFormValues,
    resolver: zodResolver(getUpdateSiteInformationFormSchema(t)),
  })

  async function handleSubmit(formData: UpdateSiteInformationForm) {
    try {
      await updateSiteInformation({
        siteInformation: {
          ...formData,
          location: site.location!,
        },
        siteUuid: site.uuid!,
      })

      await Promise.all([
        invalidateCustomerQuery({ uuid: site.uuid }),
        invalidateCustomerSettingsQuery({ uuid: site.uuid!, location: site.location }),
      ])

      exitEditMode(form.getValues())
    } catch (err) {
      const error = errorHandler(err, t('customer_details.tabs.company_info.errors.update_site_information_error'))

      pushAlert({ message: error.message, severity: 'error' })
    }
  }

  function handleAddressChange(address: string, gpsLocation: GpsLocation) {
    form.setValue('address', address)
    form.setValue('coordinates', gpsLocation ? { latitude: gpsLocation.lat!, longitude: gpsLocation.lon! } : undefined)
  }

  function handlePartnerChange(_, value) {
    form.setValue('partnerCode', value)
  }

  function handleTimeZoneChange(_, value) {
    form.setValue('timezone', value)
  }

  function enterEditMode() {
    setEditMode(true)
  }

  function exitEditMode(formValues?: UpdateSiteInformationForm) {
    form.reset(formValues)
    setEditMode(false)
  }

  function handleReset() {
    exitEditMode()
  }

  return (
    <FormProvider {...form}>
      <Stack
        noValidate
        component="form"
        sx={{ gap: 2 }}
        onReset={handleReset}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <CustomTypography fontSize={20} variant="h4">
            {t('customer_details.tabs.company_info.general_information_form_title')}
          </CustomTypography>

          <Box>
            {editMode ? (
              <CancelConfirmIconButtons disabled={isPending} />
            ) : (
              <CustomIconButton
                Icon={EditOutlinedIcon}
                color="primary"
                iconProps={{
                  titleAccess: t('customer_details.tabs.company_info.edit_icon_title', { siteName: site.name }),
                }}
                size="small"
                variant="solid"
                onClick={enterEditMode}
              />
            )}
          </Box>
        </Stack>

        <EditableBox isEditable={editMode}>
          <Stack sx={{ flexDirection: 'column', gap: 2 }}>
            <Stack
              direction={{
                md: 'column',
                lg: 'row',
              }}
              sx={{ justifyContent: 'space-between', flexDirection: { md: 'column', lg: 'row' }, gap: 1 }}
            >
              <EditableTextFieldController
                required
                isEditable={editMode}
                label={t('customer_details.tabs.company_info.name')}
                name="name"
              />

              <EditablePartnerFieldController
                isEditable={editMode && hasEditPartnerPermissions}
                label={t('common.partner')}
                location={site.location!}
                name="partnerCode"
                partners={partners ?? []}
                textFieldProps={{
                  required: true,
                }}
                onChange={handlePartnerChange}
              />
            </Stack>

            <Stack
              direction={{
                md: 'column',
                lg: 'row',
              }}
              sx={{ justifyContent: 'space-between', flexDirection: { md: 'column', lg: 'row' }, gap: 1 }}
            >
              {loggedInUser?.role === USER_ROLES.ADMINISTRATORS.value && (
                <LabeledTextFieldWithTooltip
                  label={t('customer_details.tabs.company_info.symbolic_name')}
                  text={site.symbolicName}
                  tooltip={t('customer_details.tabs.company_info.symbolic_name_hint')}
                />
              )}

              <EditableTimezoneFieldController
                isEditable={editMode}
                label={t('customer_details.tabs.company_info.timezone')}
                name="timezone"
                textFieldProps={{
                  required: true,
                }}
                onChange={handleTimeZoneChange}
              />
            </Stack>
          </Stack>
          <Divider flexItem orientation="horizontal" style={{ marginBottom: '16px', marginTop: '16px' }} />

          {editMode ? (
            <SiteAddressInput
              address={site.address}
              gpsLocation={
                site.coordinates
                  ? {
                      lat: site.coordinates.latitude,
                      lon: site.coordinates.longitude,
                    }
                  : undefined
              }
              onChangeAddress={handleAddressChange}
            />
          ) : (
            <SiteAddressView
              address={site.address}
              gpsLocation={
                site.coordinates
                  ? {
                      lat: site.coordinates.latitude,
                      lon: site.coordinates.longitude,
                    }
                  : undefined
              }
            />
          )}
        </EditableBox>
      </Stack>
    </FormProvider>
  )
}
