import axios from 'axios'
import { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import AuthenticatedApp from '@/AuthenticatedApp'
import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import { DEFAULT_INTERFACE_LANGUAGE } from '@/constants/interfaceLanguages'
import { useAlertContext } from '@/contexts/AlertContext'
import environment from '@/environment'
import UnauthenticatedApp from '@/UnauthenticatedApp'

import { useAuth } from './features/authentication/contexts/AuthContext'
import { PermissionsProvider } from './features/authorization/contexts/PermissionsContext'
import ErrorShell from './features/error/components/ErrorShell'
import { ErrorsRouteInformation } from './features/error/constants'
import Error500 from './features/error/pages/Error500'
import { initGoogleAnalytics, resetGoogleAnalytics } from './features/googleAnalytics/init'
import { initSentry } from './features/sentry/init'
import { set3xxValidStatus, setDeployedVersionHeader } from './utils/axios/config'
import {
  withAuthenticationErrorInterceptor,
  withAuthorizationTokenInterceptor,
  withForbidenErrorInterceptor,
} from './utils/axios/interceptors'

function App() {
  const { isLogged, isStarting, loggedInUser, logout } = useAuth()
  const location = useLocation()
  const { i18n } = useTranslation()
  const { resetAlerts } = useAlertContext()
  const navigate = useNavigate()

  const environmentName = environment.environmentName

  useEffect(() => {
    // Sentry
    if (['staging', 'production'].includes(environmentName)) {
      initSentry(environmentName)
    }
  }, [environmentName])

  useEffect(() => {
    if (loggedInUser) {
      // Google Analytics
      // We will run Google Analytics only in all environments in order to test it in development mode.
      initGoogleAnalytics(loggedInUser)
    } else {
      resetGoogleAnalytics()
    }
  }, [loggedInUser])

  // Axios
  useEffect(() => {
    withAuthenticationErrorInterceptor(axios, handleAPIUnauthenticatedError)
    withForbidenErrorInterceptor(axios, handleAPIForbiddenError)
    withAuthorizationTokenInterceptor(axios)
    set3xxValidStatus(axios)

    if (loggedInUser?.username) {
      const deployedVersion = environment.isDevelopmentMode
        ? `development-${loggedInUser.username}`
        : environment.deployedVersion

      setDeployedVersionHeader(axios, deployedVersion)
    }
  }, [loggedInUser?.username])

  useEffect(() => {
    async function changeLanguage(userUiLanguage: string) {
      await i18n.changeLanguage(userUiLanguage)
    }

    // Set UI language to follow user preferences.
    const userUiLanguage = loggedInUser?.uiLanguage ?? DEFAULT_INTERFACE_LANGUAGE.value
    if (i18n.language !== userUiLanguage) {
      changeLanguage(userUiLanguage)
    }
  }, [loggedInUser?.uiLanguage])

  useEffect(() => {
    // Reset alerts when the user navigates to a new page.
    resetAlerts()
  }, [location.pathname])

  function handleAPIForbiddenError() {
    navigate(ErrorsRouteInformation[403].navigationPath)
  }

  function handleAPIUnauthenticatedError() {
    logout()
  }

  if (isStarting) {
    return <FullPageSpinner />
  }

  return (
    <ErrorBoundary
      fallback={
        <ErrorShell>
          <Error500 />
        </ErrorShell>
      }
    >
      {isLogged ? (
        <PermissionsProvider>
          <AuthenticatedApp />
        </PermissionsProvider>
      ) : (
        <UnauthenticatedApp />
      )}
    </ErrorBoundary>
  )
}

export default App
