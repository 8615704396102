import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { AmActivatedResource } from '@/features/activation/types/activatedResource'
import { GET_AM_ACTIVATED_RESOURCES_API_ID, getActivatedResources } from '@/features/site/endpoints/amActivations'
import type { UseQueryOptions } from '@/types/queries'

type ActivatedResourcesFromAmQueryParams = {
  siteId?: string
  activationId?: string
}
export type UseActivatedResourcesFromAmQueryResult = Omit<UseQueryResult, 'data'> & {
  amActivatedResources: AmActivatedResource[] | null
}

export function useActivatedResourcesFromAmQuery(
  params: ActivatedResourcesFromAmQueryParams,
  options?: UseQueryOptions<AmActivatedResource[]>,
): UseActivatedResourcesFromAmQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_AM_ACTIVATED_RESOURCES_API_ID, params],
    queryFn: () => getActivatedResources(params.siteId, params.activationId),
  })

  return { amActivatedResources: data, ...queryResult }
}
