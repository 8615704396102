import { t } from 'i18next'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { SiteRevenueViewForNW } from '@/features/site/components/revenue/SiteRevenueViewForNW'
import { useHasRevenueNWPermissions } from '@/features/site/hooks/useHasRevenueNWPermissions'
import { useInsightRevenuePeriodsQuery } from '@/features/site/hooks/useInsightRevenuePeriodsQuery'
import { SiteRevenueViewOld } from '@/features/site/pages/components/SiteRevenueViewOld'
import { SiteDetailsRouteInformation } from '@/features/site/pages/constants'
import { useSiteDetails } from '@/features/site/pages/SiteDetails'

export const SiteDetailsRevenue = () => {
  const navigate = useNavigate()
  const { site, isFetchingSite, setPageConfig, siteDetailsCommonBreadcrumbs } = useSiteDetails()
  const hasRevenueNWPermissions = useHasRevenueNWPermissions({ site })
  const { revenuePeriods, isFetching: isFetchingRevenuePeriods } = useInsightRevenuePeriodsQuery(
    {
      siteUuid: site.uuid,
      timezone: site.timezone,
    },
    {
      enabled: hasRevenueNWPermissions,
    },
  )

  useEffect(() => {
    if (site.location !== 'fi') {
      navigate(`/sites/${site.uuid}/contact-info`)
    }
  }, [site.location])

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.revenue.title'),
      breadcrumbs: siteDetailsCommonBreadcrumbs,
      pageTag: 'customer-details-revenue',
      activeTab: SiteDetailsRouteInformation.REVENUE.navigationPath,
    })
  }, [])

  if (!site || isFetchingSite || isFetchingRevenuePeriods) {
    return null
  }

  return hasRevenueNWPermissions ? (
    <SiteRevenueViewForNW revenuePeriods={revenuePeriods} site={site} />
  ) : (
    <SiteRevenueViewOld site={site} />
  )
}
