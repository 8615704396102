import { useMutation } from '@tanstack/react-query'

import { linkCustomAttributeSchemaToResource } from '@/features/resource/endpoints/customAttributeSchemas'

export function useLinkCustomAttributeSchemaToResourceMutation(resourceID: string) {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (customAttributeSchemaNamespace: string) => {
      return linkCustomAttributeSchemaToResource(resourceID, customAttributeSchemaNamespace)
    },
  })
  return { linkSchemaToResource: mutateAsync, ...mutationResult }
}
