import type { ChipProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomChip from '@/components/dataDisplay/CustomChip'
import { ActivationType } from '@/features/activation/types/activation'

type ActivationTypeChipProps = {
  activationType: ActivationType
}

const commonProperties: ChipProps = {
  sx: { textTransform: 'uppercase' },
  variant: 'outlined',
}

function ActivationTypeChip({ activationType }: Readonly<ActivationTypeChipProps>) {
  const { t } = useTranslation()

  switch (activationType) {
    case ActivationType.DISTURBANCE:
      return (
        <CustomChip
          {...commonProperties}
          color="warning"
          label={t('customer_details.activations.type_chip.disturbance')}
        />
      )
    case ActivationType.PREQUALIFICATION:
      return (
        <CustomChip
          {...commonProperties}
          color="primary"
          label={t('customer_details.activations.type_chip.prequalification')}
        />
      )
    default:
      return (
        <CustomChip {...commonProperties} color="default" label={t('customer_details.activations.type_chip.unknown')} />
      )
  }
}

export default ActivationTypeChip
