import type { DateTime } from 'luxon'

import type { ExtendedBid } from '@/features/bidding/types/bid'
import { convertToRoundedMw } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import { getPtuPriceQuantitiesFromExtendedBids } from '@/features/bidding/utils/price/getPtuPriceQuantitiesFromBids'

export const calculateIncrementalVolumes = (bids: ExtendedBid[], ptuStart: DateTime) => {
  // how much volume we have available across all activation groups
  const available = convertToRoundedMw(
    bids.reduce(
      (total, bid) => total + bid.ptus.find((bidPtu) => bidPtu.ptu.start.equals(ptuStart))!.offeredVolume.quantity,
      0,
    ),
  )
  // how much volume we have allocated according to chunks
  const priceQuantities = getPtuPriceQuantitiesFromExtendedBids(ptuStart, bids)
  const chunkedQuantity = priceQuantities.reduce((total, priceCapacity) => total + priceCapacity.quantity, 0)
  return {
    available: available,
    used: chunkedQuantity,
  }
}
