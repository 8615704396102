import { Box, capitalize, Card, CardHeader, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomCardContent from '@/components/layouts/CustomCardContent'
import { useActivationGroupDetails } from '@/features/activationGroup/pages/ActivationGroupDetails'
import type { ActivationGroup } from '@/features/activationGroup/types'
import { ApiVolumeRangeUnit } from '@/features/activationGroup/types'
import { convertEnergyPower } from '@/utils/powerEnergyTransformations'

const StyledResourceDetailRow = styled(TableRow)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

const StyledResourceDetailHeaderColumn = styled(TableCell)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

const ActivationGroupDetailTable = ({ activationGroup }: { activationGroup: ActivationGroup }) => {
  const { t } = useTranslation()
  const unit = activationGroup.biddableVolumeRange?.unit === ApiVolumeRangeUnit.WATTS ? 'kilowatts' : 'kilowatt_hours'
  const rows = useMemo(() => {
    return [
      {
        label: t('activation_groups.detail_panel.uuid'),
        value: activationGroup.uuid,
        column: 1,
        position: 1,
      },
      {
        label: t('activation_groups.detail_panel.name'),
        value: activationGroup.code,
        column: 1,
        position: 2,
      },
      {
        label: t('activation_groups.detail_panel.min_capacity'),
        value: activationGroup.biddableVolumeRange?.min
          ? convertEnergyPower(activationGroup.biddableVolumeRange.min, unit)
          : t('common.not_set'),
        column: 1,
        position: 4,
      },
      {
        label: t('activation_groups.detail_panel.max_capacity'),
        value: activationGroup.biddableVolumeRange?.max
          ? convertEnergyPower(activationGroup.biddableVolumeRange.max, unit)
          : t('common.not_set'),
        column: 1,
        position: 5,
      },
      {
        label: t('activation_groups.detail_panel.portfolio_code'),
        value: activationGroup.portfolio.code,
        column: 2,
        position: 1,
      },
      {
        label: t('activation_groups.detail_panel.market_program'),
        value: t(`bidding.market_program.${activationGroup.marketProgram}`),
        column: 2,
        position: 2,
      },
      {
        label: t('activation_groups.detail_panel.state'),
        value: activationGroup.state ? capitalize(activationGroup.state.toLowerCase()) : t('common.not_set'),
        column: 2,
        position: 3,
      },
    ]
  }, [activationGroup, t])

  // Determine the number of columns by finding the maximum column number in the rows
  const columns = Math.max(...rows.map((row) => row.column))

  // Group rows by their column number and sort them by their position within the column
  const columnsData = Array.from({ length: columns }, (_, i) =>
    rows.filter((row) => row.column === i + 1).sort((a, b) => a.position - b.position),
  )

  return (
    <>
      <CardHeader sx={{ paddingX: 0 }} title={t('activation_groups.detail_panel.general_information')}></CardHeader>
      <Box display="flex">
        {columnsData.map((columnRows) => (
          <Table
            key={columnRows[0].column}
            aria-label={t('activation_groups.detail_panel.general_information')}
            role="table"
            size="small"
          >
            <TableBody>
              {columnRows.map((row) => (
                <StyledResourceDetailRow key={row.label} role="row">
                  <StyledResourceDetailHeaderColumn>
                    <CustomTypography color="grey.500" variant="caption">
                      {row.label}
                    </CustomTypography>
                  </StyledResourceDetailHeaderColumn>
                  <TableCell>
                    <CustomTypography sx={{ lineHeight: 'inherit' }} variant="inherit">
                      {row.value}
                    </CustomTypography>
                  </TableCell>
                </StyledResourceDetailRow>
              ))}
            </TableBody>
          </Table>
        ))}
      </Box>
    </>
  )
}

const ActivationGroupCard = () => {
  const { activationGroup } = useActivationGroupDetails()

  if (activationGroup === undefined || activationGroup === null) {
    return null
  }

  return (
    <Card sx={{ marginY: 3 }}>
      <CustomCardContent title={activationGroup.uuid}>
        <ActivationGroupDetailTable activationGroup={activationGroup} />
      </CustomCardContent>
    </Card>
  )
}

export default ActivationGroupCard
