import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { abortGenericActivationSchedule } from '@/features/activationGroup/endpoints/activationGroups'
import type { UseMutationOptions } from '@/types/queries'

export type UseAbortActivationScheduleMutationResult = Omit<UseMutationResult<void, Error, string>, 'mutateAsync'> & {
  abortActivationSchedule: UseMutationResult<void, Error, string>['mutateAsync']
}

export function useAbortActivationScheduleMutation(
  options?: UseMutationOptions<void, string>,
): UseAbortActivationScheduleMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (activationScheduleUuid: string) => abortGenericActivationSchedule(activationScheduleUuid),
  })

  return { abortActivationSchedule: mutateAsync, ...mutationResult }
}
