import { Box, Button, Checkbox, FormControlLabel, FormGroup, Link, Tooltip } from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'

import { useAlertContext } from '@/contexts/AlertContext'
import environment from '@/environment'
import {
  decommissionResource,
  republishResource,
  resyncResource,
  syncResourceComponentStatusesFromMonolith,
} from '@/features/resource/endpoints/resources'
import { errorHandler } from '@/utils/errorHandler'

const ResourceSupportToolbox = ({ resourceId }) => {
  const { pushAlert } = useAlertContext()

  // URLs based on resourceId
  const loggingUrl = environment.dashboards.loggingSupportToolboxUrl.replace('~resource-placeholder~', resourceId)
  const grafanaUrl = environment.dashboards.grafanaSupportToolboxUrl.replace('~resource-placeholder~', resourceId)

  // State to manage the toggle switches for republish options, with all flags set to true by default
  const [flags, setFlags] = useState({
    enableConfigRepublish: true,
    enableSteeringRepublish: true,
    enableSteerabilityAndBatteryRepublish: true,
  })

  // Handler functions for button actions
  const handleDecommission = async () => {
    try {
      await decommissionResource(resourceId)
    } catch (err) {
      const error = errorHandler(err, t('resources.support_toolbox.errors.decommission_error'))
      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  const handleResync = async () => {
    try {
      await resyncResource(resourceId)
    } catch (err) {
      const error = errorHandler(err, t('resources.support_toolbox.errors.resync_error'))
      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  const handleRepublish = async () => {
    try {
      const requestPayload = {
        config: flags.enableConfigRepublish,
        steering: flags.enableSteeringRepublish,
        steerabilityAndBattery: flags.enableSteerabilityAndBatteryRepublish,
      }
      await republishResource(resourceId, requestPayload)
    } catch (err) {
      const error = errorHandler(err, t('resources.support_toolbox.errors.republish_error'))
      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  const handleSyncResourceComponentStatuses = async () => {
    try {
      await syncResourceComponentStatusesFromMonolith(resourceId)
    } catch (err) {
      const error = errorHandler(err, t('resources.support_toolbox.errors.sync_component_statuses_error'))
      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <>
      <Box component="header" sx={{ marginBottom: 2 }}>
        {t('resources.support_toolbox.title')}
      </Box>
      <Box component="section">
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          {/* Left Column */}
          <Box sx={{ flex: 1 }}>
            <Box sx={{ marginBottom: 2 }}>
              <Link href={grafanaUrl} rel="noopener noreferrer" target="_blank">
                {t('resources.support_toolbox.grafana_link_label')}
              </Link>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
              <Link href={loggingUrl} rel="noopener noreferrer" target="_blank">
                {t('resources.support_toolbox.logging_link_label')}
              </Link>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
              <Button fullWidth color="secondary" variant="contained" onClick={handleDecommission}>
                {t('resources.support_toolbox.decommission_button_label')}
              </Button>
            </Box>

            <Box sx={{ marginBottom: 2 }}>
              <Tooltip arrow title={t('resources.support_toolbox.resync_config.tooltip')}>
                <Button fullWidth color="secondary" variant="contained" onClick={handleResync}>
                  {t('resources.support_toolbox.resync_config.button_label')}
                </Button>
              </Tooltip>
            </Box>
            <Box sx={{ marginBottom: 2 }}>
              <Tooltip arrow title={t('resources.support_toolbox.sync_ressource_component_statuses.tooltip')}>
                <Button fullWidth color="secondary" variant="contained" onClick={handleSyncResourceComponentStatuses}>
                  {t('resources.support_toolbox.sync_ressource_component_statuses.button_label')}
                </Button>
              </Tooltip>
            </Box>
          </Box>

          {/* Right Column */}
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                border: '1px solid #ccc',
                borderRadius: 2,
                padding: 2,
                marginBottom: 2,
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={flags.enableConfigRepublish}
                      color="primary"
                      onChange={() =>
                        setFlags((prevFlags) => ({
                          ...prevFlags,
                          enableConfigRepublish: !prevFlags.enableConfigRepublish,
                        }))
                      }
                    />
                  }
                  label={t('resources.support_toolbox.republish.config_label')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={flags.enableSteeringRepublish}
                      color="primary"
                      onChange={() =>
                        setFlags((prevFlags) => ({
                          ...prevFlags,
                          enableSteeringRepublish: !prevFlags.enableSteeringRepublish,
                        }))
                      }
                    />
                  }
                  label={t('resources.support_toolbox.republish.steering_label')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={flags.enableSteerabilityAndBatteryRepublish}
                      color="primary"
                      onChange={() =>
                        setFlags((prevFlags) => ({
                          ...prevFlags,
                          enableSteerabilityAndBatteryRepublish: !prevFlags.enableSteerabilityAndBatteryRepublish,
                        }))
                      }
                    />
                  }
                  label={t('resources.support_toolbox.republish.steerability_and_battery_label')}
                />
              </FormGroup>
              <Tooltip arrow title={t('resources.support_toolbox.republish.tooltip')}>
                <Button fullWidth color="secondary" variant="contained" onClick={handleRepublish}>
                  {t('resources.support_toolbox.republish.button_label')}
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ResourceSupportToolbox
