import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { importTrade } from '@/features/wholesale/endpoints/trades'

export type UseImportTradeMutationResult = Omit<UseMutationResult<void, Error, string>, 'mutateAsync'> & {
  importTrade: UseMutationResult<void, Error, string>['mutateAsync']
}

export function useImportTradeMutation(): UseImportTradeMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (fileContent: string) => importTrade(fileContent),
  })

  return {
    importTrade: mutateAsync,
    ...mutationResult,
  }
}
