import type { TableCellProps } from '@mui/material'
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import DataGridSkeleton from '@/components/dataDisplay/DataGridSkeleton'
import CheckboxController from '@/components/inputs/CheckboxController'
import SelectFieldController from '@/components/inputs/SelectFieldController'
import TextFieldController from '@/components/inputs/TextFieldController'
import { useAlertContext } from '@/contexts/AlertContext'
import { SITE_MARKET_PROGRAMS_QUERY_STALE_TIME } from '@/features/site/constants'
import { useMarketProgramsQuery } from '@/features/site/hooks/useMarketProgramsQuery'
import type { MarketProgram } from '@/features/site/types/marketProgram'
import type { CreateSiteForm, UpdateSiteMarketProgramContractsForm } from '@/features/site/types/site'
import { convertMarketProgramsToFormMarketPrograms } from '@/features/site/utils/createSiteForm'
import { getMarketProgramsByCountryCode } from '@/features/site/utils/getMarketProgramsByCountryCode'
import { compareMarketPrograms } from '@/features/site/utils/sortMarketPrograms'
import type { MarketProgramType } from '@/types/marketProgramType'

type MarketProgramsEditTableProps = {
  countryCode?: CountryIdentifier
}

type MarketProgramsEditTableBodyProps = {
  marketPrograms: MarketProgram[]
}

const SITE_MARKET_PROGRAM_FIELD_WIDTH = '200px'
const COMMON_TABLE_CELL_PROPS: Partial<TableCellProps> = { size: 'small' }

function MarketProgramsEditTableHeader() {
  const { t } = useTranslation()
  const { watch, setValue } = useFormContext<CreateSiteForm | UpdateSiteMarketProgramContractsForm>()

  const siteMarketPrograms = watch('marketProgramContracts')

  function handleSelectAllChange(evt: ChangeEvent<HTMLInputElement>) {
    const { checked } = evt.target

    setValue(
      'marketProgramContracts',
      siteMarketPrograms.map((siteMarketProgram) => ({
        ...siteMarketProgram,
        isSelected: checked,
      })),
    )
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={
              (siteMarketPrograms.length ?? 0) > 0 &&
              siteMarketPrograms.every((siteMarketProgram) => siteMarketProgram.isSelected)
            }
            inputProps={{ 'aria-label': t('sites.add_new.form.market_programs.table.select_all_label') }}
            onChange={handleSelectAllChange}
          />
        </TableCell>

        <TableCell {...COMMON_TABLE_CELL_PROPS}>{t('sites.add_new.form.market_programs.table.columns.name')}</TableCell>

        <TableCell {...COMMON_TABLE_CELL_PROPS}>
          {t('sites.add_new.form.market_programs.table.columns.revenue_share_fraction')}
        </TableCell>

        <TableCell {...COMMON_TABLE_CELL_PROPS}>
          {t('sites.add_new.form.market_programs.table.columns.rounding_error_in_watts')}
        </TableCell>

        <TableCell {...COMMON_TABLE_CELL_PROPS}>
          {t('sites.add_new.form.market_programs.table.columns.sold_capacity_algorithm')}
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

function MarketProgramsEditTableBody({ marketPrograms }: Readonly<MarketProgramsEditTableBodyProps>) {
  const { t } = useTranslation()

  return (
    <TableBody>
      {marketPrograms.map((marketProgram, index) => (
        <TableRow key={marketProgram.id}>
          <TableCell padding="checkbox">
            <CheckboxController
              inputProps={{
                'aria-label': t('sites.add_new.form.market_programs.table.select_label', {
                  marketProgramName: t(`common.market_program.${marketProgram.type as MarketProgramType}`),
                }),
              }}
              name={`marketProgramContracts.${index}.isSelected`}
            />
          </TableCell>

          <TableCell {...COMMON_TABLE_CELL_PROPS}>
            {t(`common.market_program.${marketProgram.type as MarketProgramType}`)}
          </TableCell>

          <TableCell {...COMMON_TABLE_CELL_PROPS}>
            <TextFieldController
              name={`marketProgramContracts.${index}.revenueSharingFraction`}
              showErrorMessage={false}
              size="small"
              slotProps={{
                htmlInput: {
                  'aria-label': t('sites.add_new.form.market_programs.table.columns.revenue_share_fraction'),
                },
              }}
              sx={{ width: SITE_MARKET_PROGRAM_FIELD_WIDTH }}
              type="number"
            />
          </TableCell>

          <TableCell {...COMMON_TABLE_CELL_PROPS}>
            <TextFieldController
              name={`marketProgramContracts.${index}.roundingErrorInKilowatts`}
              showErrorMessage={false}
              size="small"
              slotProps={{
                htmlInput: {
                  'aria-label': t('sites.add_new.form.market_programs.table.columns.rounding_error_in_watts'),
                },
              }}
              sx={{ width: SITE_MARKET_PROGRAM_FIELD_WIDTH }}
              type="number"
            />
          </TableCell>

          <TableCell {...COMMON_TABLE_CELL_PROPS}>
            <SelectFieldController
              name={`marketProgramContracts.${index}.soldCapacityAlgorithmType`}
              options={[
                {
                  id: 'none',
                  label: t('common.none'),
                  value: '',
                },
                {
                  id: 'min',
                  label: t('sites.add_new.form.market_programs.table.sold_capacity_algorithm_options.min'),
                  value: 'min',
                },
                {
                  id: 'average',
                  label: t('sites.add_new.form.market_programs.table.sold_capacity_algorithm_options.average'),
                  value: 'average',
                },
              ]}
              showErrorMessage={false}
              size="small"
              slotProps={{
                input: {
                  'aria-label': t('sites.add_new.form.market_programs.table.columns.sold_capacity_algorithm'),
                },
              }}
              sx={{ width: SITE_MARKET_PROGRAM_FIELD_WIDTH }}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}

function MarketProgramsEditTable({ countryCode }: Readonly<MarketProgramsEditTableProps>) {
  const { setValue, getValues } = useFormContext<CreateSiteForm | UpdateSiteMarketProgramContractsForm>()
  const { marketPrograms, error, isFetching, isFetched } = useMarketProgramsQuery({
    gcTime: SITE_MARKET_PROGRAMS_QUERY_STALE_TIME,
    staleTime: SITE_MARKET_PROGRAMS_QUERY_STALE_TIME,
  })
  const { pushAlert } = useAlertContext()
  const { t } = useTranslation()

  const formData = getValues()
  const countryMarketPrograms = getMarketProgramsByCountryCode(
    countryCode ?? ((formData as CreateSiteForm).countryCode as CountryIdentifier),
  )

  const filteredMarketPrograms = useMemo(() => {
    return (
      marketPrograms
        ?.filter((marketPrograms) => countryMarketPrograms.includes(marketPrograms.type!))
        .toSorted((a, b) => compareMarketPrograms(t, a, b)) ?? []
    )
  }, [marketPrograms, t])

  /**
   * When the page is rendered and marketPrograms are fetched, we need to convert marketPrograms to form marketPrograms. There
   * are several reasons why we need it:
   *
   * 1. On the form, we need the property isSelected and the API does not have it. This property is responsible to show if
   * a market program is selected or not. When user submits the form, we need to send only the selected market programs.
   *
   * 2. To improve the UX, we are saving all interactions done on this step, even on unselected fields. This way, when user
   * navigates to another step and comes back, all data will be there.
   */
  useEffect(() => {
    if (filteredMarketPrograms.length > 0 && marketPrograms) {
      setValue(
        'marketProgramContracts',
        convertMarketProgramsToFormMarketPrograms(
          filteredMarketPrograms,
          formData.marketProgramContracts,
          countryCode ?? ((formData as CreateSiteForm).countryCode as CountryIdentifier),
        ),
      )
    }
  }, [isFetched])

  useEffect(() => {
    if (error) {
      pushAlert({
        message: t('sites.add_new.form.market_programs.errors.market_programs_request_error'),
        severity: 'error',
      })
    }
  }, [error])

  if (isFetching) {
    return <DataGridSkeleton includeWrapper={false} numberOfColumns={4} numberOfRows={5} />
  }

  if (!marketPrograms || error) {
    return null
  }

  return (
    <Table>
      <MarketProgramsEditTableHeader />

      <MarketProgramsEditTableBody marketPrograms={filteredMarketPrograms} />
    </Table>
  )
}

export default MarketProgramsEditTable
