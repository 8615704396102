import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Stack, Tooltip } from '@mui/material'
import type { FieldPath, FieldValues } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { CustomAutocompleteProps } from '@/components/inputs/CustomAutocomplete'
import CustomAutocomplete from '@/components/inputs/CustomAutocomplete'

export type TimezoneFieldControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = Omit<
  CustomAutocompleteProps<string>,
  'options' | 'value' | 'ref' | 'disableClearable' | 'fullWidth' | 'error' | 'helperText' | 'label'
> & {
  name: TName
  label: string
  showTooltip?: boolean
}

export function TimezoneFieldController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  label,
  onChange,
  showTooltip = false,
  ...autocompleteProps
}: TimezoneFieldControllerProps<TFieldValues, TName>) {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error, invalid } }) => (
        <Stack alignItems="center" direction="row" justifyContent="flex-start" spacing={2} sx={{ width: '100%' }}>
          <CustomAutocomplete
            {...autocompleteProps}
            autoHighlight
            disableClearable
            fullWidth
            error={invalid}
            helperText={error?.message}
            label={label}
            options={Intl.supportedValuesOf('timeZone').map((timeZone) => ({
              id: timeZone,
              label: timeZone,
              value: timeZone,
            }))}
            value={field.value ?? ''}
            onChange={onChange}
          />

          {showTooltip && (
            <Tooltip title={t('customer_settings.form.timezone_hint')}>
              <HelpOutlineIcon color={'action'} />
            </Tooltip>
          )}
        </Stack>
      )}
    />
  )
}
