import type { DateTime } from 'luxon'

import { WATTS_IN_MW } from '@/constants/units'
import type { ExtendedBid, ExtendedBidPtu } from '@/features/bidding/types/bid'
import { roundWattsToNearest10KW } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import { decimalAdjust } from '@/features/bidding/utils/calculations/decimalAdjust'
import { PriceLadderType } from '@/features/bidding/utils/getBidConfig'
import type { AggregatedBid, AggregatedBidPtu } from '@/features/bidding/utils/model/buildAggregatedBid'
import splitTotalPriceChunkOfferByActivationGroup from '@/features/bidding/utils/price/splitTotalPriceChunkOfferByActivationGroup'

export const splitAggregatedBid = (aggregatedBid: AggregatedBid, activationGroupBids: ExtendedBid[]): ExtendedBid[] => {
  const bidsWithNewPrices = createActivationGroupBidsWithNewPrices(aggregatedBid.ptus, activationGroupBids)

  aggregatedBid.ptus.forEach((ptu) => {
    ptu.priceQuantities.forEach((priceCapacity) => {
      splitAggregatedPtuVolumeByActivationGroup(
        bidsWithNewPrices,
        ptu.ptuStart,
        priceCapacity.price,
        priceCapacity.quantity,
        aggregatedBid.ladderType,
      )
    })
  })

  return bidsWithNewPrices
}

/**
 * Creates a copy of the old activation group bids with updated price chunks
 * based on the prices available in the aggregated bid.
 * Price chunks are re-created using the new prices with the volume set to 0.
 *
 * This allows us to split the user-entered aggregated PTU volumes later.
 */
const createActivationGroupBidsWithNewPrices = (
  aggregatedBid: AggregatedBidPtu[],
  activationGroupBids: ExtendedBid[],
): ExtendedBid[] => {
  const prices = aggregatedBid[0].priceQuantities.map((priceCapacity) => priceCapacity.price)

  return activationGroupBids.map((oldBid) => ({
    ...oldBid,
    ptus: oldBid.ptus.map((oldPtu) => ({
      ...oldPtu,
      ptuChunks: prices.map((price) => ({
        offeredPrice: price,
        offeredVolume: { quantity: 0, unit: oldPtu.offeredVolume.unit },
      })),
    })),
  }))
}

/**
 * @param activationGroupBids - List of bids with price chunks.
 * @param ptuStart - The PTU for which the volume is split (equivalent to one row in the data grid).
 * @param price - The price for which the volume is split.
 * @param newVolume - The new total volume (MW or MWh) to be split.
 * @param priceLadder - CUMULATIVE or INCREMENTAL
 * @returns Updated bids with adjusted price chunk volumes.
 *
 * Splits the user-entered aggregated PTU volumes by activation group.
 * The split is done proportionally considering the activation group sizes.
 * Based on the price ladder type, ensures that assigned volumes do not exceed the total PTU volume.
 */
function splitAggregatedPtuVolumeByActivationGroup(
  activationGroupBids: ExtendedBid[],
  ptuStart: DateTime,
  price: number,
  newVolume: number,
  priceLadder: PriceLadderType,
): ExtendedBid[] {
  // Split volume (given per price and PTU) proportionately between activation groups
  const splitCapacityByActivationGroup = splitPtuVolumeByActivationGroup(activationGroupBids, newVolume, ptuStart)

  // Loop through all bids to find the correct PTU, and the PTU price chunk.
  // Allocate that price chunk the correct split volume.
  return activationGroupBids.map((bid) => {
    const offeredPtu = bid.ptus.find((bidPtu) => bidPtu.ptu.start.equals(ptuStart))!
    const priceChunk = offeredPtu.ptuChunks!.find((priceChunk) => priceChunk.offeredPrice === price)!

    priceChunk.offeredVolume.quantity = splitCapacityByActivationGroup[bid.activationGroupUuid]

    // Ensure that the sum of all price chunk volumes (in case of an INCREMENTAL price ladder) or
    // the volume from the chunk with the highest volume (in case of a CUMULATIVE price ladder) is not more than PTU volume
    // If it is more, subtract the difference from the last price chunk to ensure the bid passes validation
    const totalOfferedPtuVolume =
      priceLadder === PriceLadderType.INCREMENTAL
        ? sumPriceChunksVolume(offeredPtu)
        : volumeFromChunkWithHighestVolume(offeredPtu)

    if (totalOfferedPtuVolume > offeredPtu.offeredVolume.quantity) {
      const overAssignedVolume = totalOfferedPtuVolume - offeredPtu.offeredVolume.quantity
      priceChunk.offeredVolume.quantity -= overAssignedVolume
    }

    return bid
  })
}

const splitPtuVolumeByActivationGroup = (activationGroupBids: ExtendedBid[], newVolume: number, ptuStart: DateTime) => {
  const newCapacityInWatts = decimalAdjust('round', newVolume * WATTS_IN_MW, -2)
  const roundedNewCapacityInWatts = roundWattsToNearest10KW(newCapacityInWatts)
  const offeredCapacityByActivationGroup = activationGroupBids.map((bid) => ({
    activationGroupUuid: bid.activationGroupUuid,
    offered: bid.ptus.find((p) => p.ptu.start.equals(ptuStart))!.offeredVolume.quantity,
  }))
  return splitTotalPriceChunkOfferByActivationGroup(roundedNewCapacityInWatts, offeredCapacityByActivationGroup)
}

const sumPriceChunksVolume = (offeredPtu: ExtendedBidPtu) =>
  offeredPtu.ptuChunks!.reduce((total, chunk) => total + chunk.offeredVolume.quantity, 0)

const volumeFromChunkWithHighestVolume = (offeredPtu: ExtendedBidPtu) =>
  offeredPtu.ptuChunks?.map((chunk) => chunk.offeredVolume.quantity).toSorted((a, b) => b - a)[0] ?? 0
