import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { GET_PARTNERS_API_ID, getPartners } from '@/features/partner/endpoints/partners'
import type { Partner } from '@/openapi/v1/msa-customer-partner-manager/partner'
import type { UseQueryOptions } from '@/types/queries'

export type UsePartnersQueryResult = Omit<UseQueryResult, 'data'> & {
  partners?: Partner[]
}

export function usePartnersQuery(options?: UseQueryOptions<Partner[]>): UsePartnersQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_PARTNERS_API_ID],
    queryFn: () => getPartners(),
  })

  return { partners: data, ...queryResult }
}

export function useInvalidatePartnersQuery() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: [GET_PARTNERS_API_ID] })
}
