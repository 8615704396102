import './priceDataGrid.css'

import type { FC } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import BiddingPricesDialog from '@/features/bidding/components/price/offer/BiddingPricesDialog'
import PriceDataGridToolbar from '@/features/bidding/components/price/offer/PriceDataGridToolbar'
import { MAX_PTU_ROWS_PER_DATAGRID } from '@/features/bidding/constants'
import { decimalAdjust } from '@/features/bidding/utils/calculations/decimalAdjust'
import sumArray from '@/features/bidding/utils/calculations/sumArray'
import { PriceLadderType } from '@/features/bidding/utils/getBidConfig'
import type { GroupedBidParams } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import type { AggregatedBid, AggregatedBidPtu } from '@/features/bidding/utils/model/buildAggregatedBid'
import { getPriceBidDataGridColumns } from '@/features/bidding/utils/price/getPriceBiddingDataGridColumns'
import type { PriceQuantity } from '@/features/bidding/utils/price/getPtuPriceQuantitiesFromBids'

type Props = {
  groupParams: GroupedBidParams
  aggregatedBid: AggregatedBid
  onAggregatedBidChange: (bid: AggregatedBid) => void
  isLoading: boolean
}

const PriceDataGrid: FC<Props> = ({ groupParams, aggregatedBid, onAggregatedBidChange, isLoading }) => {
  const { t, i18n } = useTranslation()
  const [priceColumnsModalOpen, setPriceColumnsModalOpen] = useState(false)

  const processRowUpdate = async (updatedPtu: AggregatedBidPtu): Promise<AggregatedBidPtu> => {
    const newAggregatedBidPtus = aggregatedBid.ptus.map((ptu) => {
      if (ptu.ptuStart === updatedPtu.ptuStart) {
        // if the user has changed the quantity for one price, the total remaining quantity must be recalculated
        const offered = calculateTotalOffered(updatedPtu, aggregatedBid.ladderType)
        updatedPtu.remaining = decimalAdjust('round', ptu.available - offered, -2)
        return updatedPtu
      } else {
        return ptu
      }
    })
    onAggregatedBidChange({ ...aggregatedBid, ptus: newAggregatedBidPtus })
    return Promise.resolve(updatedPtu)
  }

  const updatePriceColumns = async (updatedPrices: Record<number, number | null>): Promise<void> => {
    const newAggregatedBidPtus = aggregatedBid.ptus.map((ptu) => {
      const newPriceQuantities: PriceQuantity[] = []
      ptu.priceQuantities.forEach((priceQuantity) => {
        const newPrice = updatedPrices[priceQuantity.price]
        if (!newPrice) return

        newPriceQuantities.push({
          price: newPrice,
          quantity: priceQuantity.quantity,
        })
      })

      Object.values(updatedPrices).forEach((newPrice) => {
        const existingPriceQuantity = newPriceQuantities.find((priceQuantity) => priceQuantity.price === newPrice)
        if (!existingPriceQuantity && newPrice !== null) {
          newPriceQuantities.push({
            price: newPrice,
            quantity: 0,
          })
        }
      })

      return {
        ...ptu,
        priceQuantities: newPriceQuantities,
      }
    })
    onAggregatedBidChange({ ...aggregatedBid, ptus: newAggregatedBidPtus })
  }

  const onBulkFillHandler = (value: number | undefined, priceColumn: number | undefined) => {
    if (value === undefined || !priceColumn) return

    const newAggregatedBid = { ...aggregatedBid }
    newAggregatedBid.ptus.forEach((ptu) => {
      const priceQuantity = ptu.priceQuantities.find((priceQuantity) => priceQuantity.price == priceColumn)!
      priceQuantity.quantity = value
      const offered = calculateTotalOffered(ptu, aggregatedBid.ladderType)
      ptu.remaining = decimalAdjust('round', ptu.available - offered, -2)
    })
    onAggregatedBidChange(newAggregatedBid)
  }

  const calculateTotalOffered = (ptu: AggregatedBidPtu, priceLadderType: PriceLadderType) => {
    if (priceLadderType === PriceLadderType.INCREMENTAL) {
      return sumArray(ptu.priceQuantities.map((priceQuantity) => priceQuantity.quantity))
    } else {
      return ptu.priceQuantities.toSorted((a, b) => b.quantity - a.quantity)[0].quantity
    }
  }

  return (
    <>
      <CustomDataGrid
        disableDefaultHeaderBackground
        hideFooter
        columns={getPriceBidDataGridColumns(aggregatedBid, true, t, i18n.language)}
        getRowId={(row: AggregatedBidPtu) => row.ptuStart.toString()}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: MAX_PTU_ROWS_PER_DATAGRID,
              page: 0,
            },
          },
        }}
        isLoading={isLoading}
        processRowUpdate={processRowUpdate}
        rows={aggregatedBid.ptus}
        slotProps={{
          toolbar: {
            deliveryDay: groupParams.deliveryDay,
            onFill: onBulkFillHandler,
            onSetPriceColumnsClick: () => setPriceColumnsModalOpen(true),
            priceColumns: getUniquePrices(aggregatedBid.ptus),
            unit: aggregatedBid.unit,
          },
        }}
        slots={{ toolbar: PriceDataGridToolbar }}
      />

      {priceColumnsModalOpen && (
        <BiddingPricesDialog
          initialPrices={aggregatedBid.ptus[0].priceQuantities.map((pc) => pc.price)}
          locales={i18n.language}
          open={priceColumnsModalOpen}
          onClose={() => setPriceColumnsModalOpen(false)}
          onSubmit={updatePriceColumns}
        />
      )}
    </>
  )
}

const getUniquePrices = (bidPtus: AggregatedBidPtu[]) => {
  const prices = bidPtus.flatMap((rowItem) => rowItem.priceQuantities.map((priceQuantity) => priceQuantity.price))
  return Array.from(new Set(prices)).sort((a, b) => a - b)
}

export default PriceDataGrid
