import type { GridColDef, GridFilterModel, GridRowSelectionModel } from '@mui/x-data-grid'
import { GridOverlay } from '@mui/x-data-grid'
import type { TFunction } from 'i18next'
import type { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import PastBidDateSelectorToolbar from '@/features/bidding/components/capacity/offer/PastBidDateSelectorToolbar'
import PastBidPtusSelectionToolbar from '@/features/bidding/components/capacity/offer/PastBidPtusSelectionToolbar'
import { MAX_PTU_ROWS_PER_DATAGRID, Status } from '@/features/bidding/constants'
import { useBidWithActualControllableQuery } from '@/features/bidding/hooks/useBidWithActualControllableQuery'
import type { BidMetaData, BidPtu, ExtendedBidPtu, PastBid } from '@/features/bidding/types/bid'
import { convertToRoundedMw } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import { getBidDisplayUnit } from '@/features/bidding/utils/getBidDisplayUnit'
import { getFilterItemValue } from '@/utils/datagrid/filters'

type PastBidPtusDataGridProps = {
  bidMetaData: BidMetaData
  copyPtusToBid: (ptus: ExtendedBidPtu[]) => void
}

type PastBidPtu = {
  offered: BidPtu
  actualControllable: BidPtu
}

const PastBidPtusDataGrid = ({ bidMetaData, copyPtusToBid }: Readonly<PastBidPtusDataGridProps>) => {
  const { t } = useTranslation()

  const [selectedPtus, setSelectedPtus] = useState<GridRowSelectionModel>([])
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: 'pastDeliveryDay',
        operator: 'is',
        value: bidMetaData.deliveryDay.minus({ days: 1 }),
      },
    ],
  })

  const pastBidDeliveryDay = getFilterItemValue<MarketDate>(filterModel, 'pastDeliveryDay')

  const { bid: pastBid, isFetching: isFetchingPastBid } = useBidWithActualControllableQuery({
    date: pastBidDeliveryDay!,
    portfolioCode: bidMetaData.portfolio.code,
    marketProgram: bidMetaData.marketProgram,
    activationGroupUuid: bidMetaData.activationGroupUuid,
    bidType: bidMetaData.bidType,
  })

  const maxDate = bidMetaData.deliveryDay.minus({ days: 1 }).getStartOfDay()

  const onCopyClick = () => {
    copyPtusToBid(getSelectedPtus(selectedPtus as string[], pastBid))
    setSelectedPtus([])
  }

  const rows = pastBid?.ptus?.map((ptu) => ({
    offered: {
      ptu: ptu.ptu,
      volume: ptu.offeredVolume,
    } as BidPtu,
    actualControllable: pastBid?.actualControllable?.find((actualPtu) => actualPtu.ptu.start.equals(ptu.ptu.start)),
  }))

  const unit = getVolumeUnit(pastBid)

  return (
    <CustomDataGrid
      checkboxSelection
      hideFooter
      keepNonExistentRowsSelected
      clickableRows={{ onRowSelectionModelChange: setSelectedPtus }}
      columns={getColumns(t, unit)}
      filterMode="server"
      filterModel={filterModel}
      getRowHeight={() => 52}
      getRowId={(row: PastBidPtu) => row.offered.ptu.start.toString()}
      initialState={{
        pagination: { paginationModel: { pageSize: MAX_PTU_ROWS_PER_DATAGRID, page: 0 } },
      }}
      isLoading={isFetchingPastBid}
      rowSelection={{ multiple: true, model: selectedPtus }}
      rows={(rows && pastBid?.status !== Status.DRAFT ? rows : []) ?? []}
      slotProps={{
        toolbar:
          selectedPtus.length > 0
            ? {
                ptusSelected: selectedPtus.length,
                onCopyClick: onCopyClick,
                onSelectionClear: () => setSelectedPtus([]),
              }
            : { maxDate },
      }}
      slots={{
        toolbar: selectedPtus.length > 0 ? PastBidPtusSelectionToolbar : PastBidDateSelectorToolbar,
        noRowsOverlay: () => <GridOverlay>{t('bidding.create_bid.bid_not_available')}</GridOverlay>,
      }}
      title={t('bidding.create_bid.previous_bid')}
      onFilterModelChange={setFilterModel}
    />
  )
}

const getColumns = (t: TFunction<'translation'>, unit: string): GridColDef<PastBidPtu>[] => [
  {
    field: 'pastDeliveryDay',
    headerName: t('bidding.create_bid.header.ptu'),
    flex: 1,
    valueFormatter: (value: DateTime) => value.toFormat('HH:mm'),
    valueGetter: (_, bidPtu: PastBidPtu) => bidPtu.offered.ptu.start,
  },
  {
    field: 'offeredWatts',
    headerName: t('bidding.create_bid.header.offered'),
    flex: 1,
    valueFormatter: (value: number) => (value ? `${convertToRoundedMw(value)} ${unit}` : '-'),
    valueGetter: (_, bidPtu: PastBidPtu) => bidPtu.offered.volume.quantity,
  },
  {
    field: 'actualControllableWatts',
    headerName: t('bidding.create_bid.header.actual_controllable'),
    flex: 1,
    valueFormatter: (value: number) => (value ? `${convertToRoundedMw(value)} MW` : '-'),
    valueGetter: (_, bidPtu: PastBidPtu) => bidPtu.actualControllable?.volume.quantity ?? 0,
  },
]

const getSelectedPtus = (selectedPtus: string[], pastBid: PastBid | undefined): ExtendedBidPtu[] =>
  pastBid!.ptus?.filter((ptu) => selectedPtus.includes(ptu.ptu.start.toString()))

const getVolumeUnit = (bid: PastBid | undefined): string => {
  if (!bid || !bid.ptus || bid.ptus.length === 0) {
    return ''
  }

  return getBidDisplayUnit(bid.ptus[0].offeredVolume.unit)
}

export default PastBidPtusDataGrid
