import { Status } from '@/features/bidding/constants'
import type { ExtendedBid } from '@/features/bidding/types/bid'

const findAcceptedBidBeforeBuyback = (
  selectedVersion: ExtendedBid,
  bidVersions: ExtendedBid[],
): ExtendedBid | undefined => {
  if (selectedVersion.status != Status.BUYBACK) return

  return [...bidVersions]
    .sort((bid1, bid2) => bid2.createdAt.valueOf() - bid1.createdAt.valueOf())
    .find(
      (version) =>
        (version.status == Status.ACCEPTED || version.status == Status.PARTIALLY_ACCEPTED) &&
        version.createdAt < selectedVersion.createdAt,
    )
}

export default findAcceptedBidBeforeBuyback
