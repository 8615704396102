import axios from 'axios'

import environment from '@/environment'
import type { CustomerSettingsPayload } from '@/features/site/types/customer'

export const CUSTOMERS_API_URL = `${environment.services.monolithGatewayManagerApiUrl}/customers`

export const GET_CUSTOMER_SETTINGS_API_ID = 'GET_CUSTOMER_SETTINGS'

export async function updateCustomerSettings(customerUuid: string, settings: CustomerSettingsPayload) {
  const response = await axios.put<CustomerSettingsPayload>(`${CUSTOMERS_API_URL}/${customerUuid}/settings`, settings)
  return response.data
}

export async function getCustomerSettings(customerUuid: string, location?: string) {
  const response = await axios.get<CustomerSettingsPayload>(`${CUSTOMERS_API_URL}/${customerUuid}/settings`, {
    params: {
      location,
    },
  })

  return response.data
}
