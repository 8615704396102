import { Box, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'

import GaugeBackground from '@/features/site/components/power_measurements/GaugeBackground'
import { convertFromWToRoundedMW } from '@/features/site/utils/formatPowerMeasurements'

interface TotalPowerMeasurementWithGaugeProps {
  totalMeasurementInW: number
}

export const TotalPowerMeasurementWithGauge = (props: TotalPowerMeasurementWithGaugeProps) => {
  const { totalMeasurementInW } = props

  return (
    <Box sx={{ mt: 5, position: 'relative', height: '360px', width: '552px' }}>
      <GaugeBackground
        style={{
          position: 'absolute',
          height: '360px',
          width: '552px',
        }}
      />
      <Stack
        alignItems="baseline"
        direction="row"
        justifyContent="center"
        sx={{
          position: 'absolute',
          top: '190px',
          width: '552px',
          zIndex: 1,
        }}
      >
        <Typography fontFamily={'Roboto, sans-serif'} sx={{ fontSize: '92px', fontWeight: 700, mr: 2 }}>
          {convertFromWToRoundedMW(totalMeasurementInW)}
        </Typography>
        <Typography fontFamily={'Roboto, sans-serif'} sx={{ fontSize: '56px', fontWeight: 700 }}>
          MW
        </Typography>
      </Stack>
    </Box>
  )
}
