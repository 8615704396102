import { Route, Routes } from 'react-router-dom'

import PublicShell from '@/components/layouts/PublicShell'
import Login from '@/features/authentication/pages/Login'
import LoginWithRedirect from '@/features/authentication/pages/LoginWithRedirect'
import ErrorRoutes from '@/features/error/ErrorsRoutes'
import { PrivacyPolicy } from '@/features/user/pages/PrivacyPolicy'

export default function UnauthenticatedAppRoutes() {
  return (
    <Routes>
      <Route element={<Login />} path="/" />
      <Route
        element={
          <PublicShell>
            <PrivacyPolicy />
          </PublicShell>
        }
        path="/privacy-policy"
      />

      <Route element={<ErrorRoutes />} path="/errors/*" />

      <Route element={<LoginWithRedirect />} path="*" />
    </Routes>
  )
}
