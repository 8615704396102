import { DateTime } from 'luxon'

import type { InsightRevenueDateTimeRange, InsightRevenuePeriod } from '@/features/site/types/revenue'

function getMinAndMaxDate(revenuePeriods: InsightRevenuePeriod[]): {
  minDate: DateTime | null
  maxDate: DateTime | null
} {
  const minDate = revenuePeriods.reduce(
    (acc, period) => (acc === null || period.earliestCalculated < acc ? period.earliestCalculated : acc),
    null as DateTime | null,
  )
  const maxDate = revenuePeriods.reduce(
    (acc, period) => (acc === null || period.latestCalculated > acc ? period.latestCalculated : acc),
    null as DateTime | null,
  )

  return { minDate, maxDate }
}
/**
 * Get the limit of the revenue date time range based on the values coming from the periods endpoint.
 *
 * If there is an error getting the revenue perios, the limits are set from the beginning of the year to the current date.
 *
 * This limit is used to set the initial time range for the revenue request, and also to limit the choice of the revenue
 * period filters in the UI.
 */
export function getLimitRevenueDateTimeRange(
  timezone: string,
  revenuePeriods?: InsightRevenuePeriod[],
): InsightRevenueDateTimeRange {
  const { minDate, maxDate } = getMinAndMaxDate(revenuePeriods ?? [])

  return {
    endTime: maxDate ?? DateTime.now().setZone(timezone),
    startTime: minDate ?? DateTime.now().setZone(timezone).startOf('year'),
  }
}
