/* tslint:disable */
/* eslint-disable */
/**
 * User API
 * API for user management
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserRole } from '@/constants/userRoles'
import { getUserFormSchema } from '@/features/user/schemas'
import { z } from 'zod'

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   * Unique identifier for this user.
   * @type {string}
   * @memberof User
   */
  id?: string
  /**
   * Username for this user.
   * @type {string}
   * @memberof User
   */
  username?: string
  /**
   * Phone number for this user.
   * @type {string}
   * @memberof User
   */
  phone?: string
  /**
   * User role.
   * @type {string}
   * @memberof User
   */
  role?: UserRole
  /**
   * User email address.
   * @type {string}
   * @memberof User
   */
  email?: string
  /**
   * User given name.
   * @type {string}
   * @memberof User
   */
  givenName?: string
  /**
   * User family name.
   * @type {string}
   * @memberof User
   */
  familyName?: string
  /**
   * UI language, ISO 639-1 language code (e.g. 'en' for English, 'fi' for Finnish).
   * @type {string}
   * @memberof User
   */
  uiLanguage?: string
  /**
   * ISO 639-1 language code (e.g. 'en' for English, 'fi' for Finnish).
   * @type {string}
   * @memberof User
   */
  numeralLanguage?: string
  /**
   * Is user active.
   * @type {boolean}
   * @memberof User
   */
  isActive?: boolean
  /**
   * Is user restricted.
   * @type {boolean}
   * @memberof User
   */
  isRestricted?: boolean
  /**
   * Ids of resource owners that the user is allowed to modify and update.
   * @type {Array<string>}
   * @memberof User
   */
  allowedRoIds?: Array<string>
  /**
   * Countries the user is allowed to use.
   * @type {Array<string>}
   * @memberof User
   */
  allowedCountries?: Array<string>
  /**
   * Partner's symbolic name, in which the user belongs to.
   * @type {string}
   * @memberof User
   */
  partnerCode?: string
  /**
   * The current authentication status of the user. Read-only! This can be one of the following: * UNCONFIRMED - User has been created but not confirmed. * CONFIRMED - User has been confirmed. * ARCHIVED - User is no longer active. * UNKNOWN - User status isn't known. * RESET_REQUIRED - User is confirmed, but the user must request a code and reset their password before they can sign in. * FORCE_CHANGE_PASSWORD - The user is confirmed and the user can sign in using a temporary password, but on first sign-in, the user must change their password to a new value before doing anything else.
   * @type {string}
   * @memberof User
   */
  authenticationStatus?: UserAuthenticationStatusEnum

  mfaSettings?: MfaSettings | null
}

export type UserForm = z.infer<ReturnType<typeof getUserFormSchema>>

/**
 * @export
 * @enum {string}
 */
export enum UserAuthenticationStatusEnum {
  UNCONFIRMED = 'UNCONFIRMED',
  CONFIRMED = 'CONFIRMED',
  ARCHIVED = 'ARCHIVED',
  COMPROMISED = 'COMPROMISED',
  UNKNOWN = 'UNKNOWN',
  RESETREQUIRED = 'RESET_REQUIRED',
  FORCECHANGEPASSWORD = 'FORCE_CHANGE_PASSWORD',
}

export type MfaSettings = {
  isSmsEnabled: boolean
  isTotpEnabled: boolean
  preferredOption: MfaPreferredOption
}

export type MfaPreferredOption = 'sms' | 'totp' | null
