import { IconChevronLeft } from '@aws-amplify/ui-react/internal'
import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import ButtonBar from '@/components/inputs/ButtonBar'
import CustomButton from '@/components/inputs/CustomButton'
import PageHeader from '@/components/layouts/PageHeader'
import { useActivationGroupsQuery } from '@/features/activationGroup/hooks/useActivationGroupsQuery'
import BidConfirmSendButton from '@/features/bidding/components/capacity/confirm/BidConfirmSendButton'
import BidConfirmTabs from '@/features/bidding/components/capacity/confirm/BidConfirmTabs'
import CountrySelector from '@/features/bidding/components/CountrySelector'
import { ALL_RESULTS } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { useBidHistoriesPageQuery } from '@/features/bidding/hooks/useBidHistoriesPageQuery'
import { useMarketClosingWarning } from '@/features/bidding/hooks/useMarketClosingWarning'
import { getLatestExtendedBid } from '@/features/bidding/utils/getLatestBid'
import { buildBidCapacityLink } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import getGroupedBidParamsFromUrl from '@/features/bidding/utils/groupedBidParams/getGroupedBidParamsFromUrl'

function BidOfferConfirm() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { selectedCountry } = useBiddingContext()
  const location = useLocation()

  const { showMarketClosingWarning, clearMarketClosingWarning } = useMarketClosingWarning()
  const groupParams = getGroupedBidParamsFromUrl(location)
  const { activationGroups } = useActivationGroupsQuery({ countryCode: selectedCountry })

  const { bidHistoriesPage } = useBidHistoriesPageQuery(
    {
      pagination: { pageSize: ALL_RESULTS, page: 0 },
      sorting: [],
      filter: {
        items: [
          { field: 'portfolio', operator: 'equals', value: groupParams?.portfolioCode },
          { field: 'marketProgram', operator: 'equals', value: groupParams?.marketProgram },
          { field: 'deliveryDay', operator: 'equals', value: groupParams?.deliveryDay },
          { field: 'bidType', operator: 'equals', value: groupParams?.bidType },
        ],
      },
      countryFilter: selectedCountry,
    },
    {
      enabled: !!groupParams,
    },
  )
  const latestBids = bidHistoriesPage?.bidHistories.map((bidHistory) => getLatestExtendedBid(bidHistory.bids))
  const hasGroupParams = Boolean(groupParams)

  useEffect(() => {
    if (!hasGroupParams) navigate('/bidding', { replace: true })
  }, [hasGroupParams, navigate])

  useEffect(() => {
    if (groupParams?.deliveryDay) showMarketClosingWarning(groupParams.deliveryDay)

    return () => {
      clearMarketClosingWarning()
    }
  }, [groupParams?.deliveryDay.valueOf()])

  if (!bidHistoriesPage || !latestBids || !activationGroups || !groupParams) return <FullPageSpinner />

  return (
    <>
      <PageHeader
        appBarContent={
          <Box alignItems="center" display="flex" flexDirection="row" sx={{ justifyContent: 'left' }}>
            <CountrySelector disabled />
          </Box>
        }
        breadcrumbItems={[
          { text: t('component.page_header.bidding'), to: '/bidding' },
          { text: t('component.page_header.bid.todo_list'), to: '/bidding/todo-list' },
          {
            text: t('component.page_header.bid.create.label', {
              portfolio: groupParams.portfolioCode,
              marketProgram: t(`bidding.market_program.${groupParams.marketProgram}`),
              bidType: groupParams.bidType.toLowerCase(),
            }),
            to: buildBidCapacityLink(groupParams),
          },
        ]}
        pageTitle={t('component.page_header.bid.confirmation.label')}
      />
      <BidConfirmTabs activationGroups={activationGroups} bidHistories={bidHistoriesPage.bidHistories} />

      <ButtonBar>
        <CustomButton
          key="backButton"
          startIcon={<IconChevronLeft />}
          variant={'outlined'}
          onClick={() => navigate(-1)}
        >
          {t('common.navigation.back')}
        </CustomButton>

        <BidConfirmSendButton bids={latestBids} />
      </ButtonBar>
    </>
  )
}

export default BidOfferConfirm
