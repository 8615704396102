import axios from 'axios'
import { DateTime } from 'luxon'

import environment from '@/environment'
import { CUSTOMERS_API_URL } from '@/features/site/endpoints/customers'
import type {
  InsightRevenue,
  InsightRevenueParams,
  InsightRevenuePeriod,
  InsightRevenuePeriodsParams,
  InsightRevenuesByMarketProgramsParams,
  RevenueDataRow,
  RevenuePeriod,
  RevenueTimeSeries,
} from '@/features/site/types/revenue'
import { getLimitRevenueDateTimeRange } from '@/features/site/utils/revenueUtils/getLimitRevenueDateTimeRange'

export const GET_REVENUE_TIME_SERIES_API_ID = 'GET_REVENUE_TIME_SERIES'
export const GET_REVENUE_PERIODS_API_ID = 'GET_REVENUE_PERIODS'
export const GET_INSIGHT_REVENUES_API_ID = 'GET_INSIGHT_REVENUES'

export async function getCustomerRevenuePeriod(
  customerUuid: string,
  location: string | undefined,
): Promise<RevenuePeriod[]> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  const response = await axios.get(`${CUSTOMER_API_URL}/revenues/period`, {
    params: {
      location,
    },
  })

  return response.data
}

export async function getCustomerRevenueTimeSeries(
  customerUuid: string,
  serviceId: number,
  startTime: DateTime,
  endTime: DateTime,
  location: string | undefined,
): Promise<RevenueTimeSeries> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  const response = await axios.get(`${CUSTOMER_API_URL}/revenues`, {
    params: {
      serviceId,
      startTime,
      endTime,
      location,
    },
  })

  return response.data
}

/**
 * Get and transform revenues from API.
 *
 * The API returns as several frames with two properties: fields and data.
 *
 * fields: Includes metadata information about the frame data. Here an example of how it looks like:
 * 
  "fields": [
        {
          "name": "time",
          "type": "Time",
          "labels": null
        },
        {
          "name": "availableCapacity",
          "type": "Number",
          "labels": [
            {
              "name": "marketProgram",
              "value": "FCR-N"
            },
            {
              "name": "siteUuid",
              "value": "c7cc280c-8ff9-45dc-88f7-f4509f63998d"
            }
          ]
        },
    ]        
 *  data: contains the frames values. it looks like this:

    "data": [
        [1735768800000, 1735772400000],
        [null, 43.69],
    ]
 * 
 * We need to know the index of every field in order to map the data. Based on the previous example, we
 * know that the first index of the array represents time (because is the first index on the fields property), and
 * the second index represents availableCapacity (because it's the second index of the fields property).
 * 
 * In order to make the code on the client side simpler, we transform this information info an array of
 * RevenueDataRow objects. 
 */
export async function getInsightRevenues(params: InsightRevenueParams): Promise<RevenueDataRow[] | null> {
  const defaultDateTimeRange = getLimitRevenueDateTimeRange(params.timezone)
  const endTime = params.endTime?.setZone('UTC') ?? defaultDateTimeRange.endTime
  const startTime = params.startTime?.setZone('UTC') ?? defaultDateTimeRange.startTime

  const response = await axios.get<InsightRevenue>(`${environment.services.insightsManagerApiUrl}/revenues`, {
    params: {
      'market-program': params.marketProgram,
      'site-id': params.siteId,
      'start-time': startTime.toMillis(),
      'end-time': endTime.toMillis(),
    },
  })

  const frames = response.data?.frames ?? null

  if (!frames) {
    return null
  }
  // revenueFieldsIdx it's a map that represents the different fields and their index position.
  const revenueFieldsIdx = new Map<string, number>()
  const revenueData: RevenueDataRow[] = []
  // We can assume that for this request we will always have one frame. Multiple frames could be useful when receiving multiple data from
  // different market prorgrams, but it's not the case for this specific request
  const { data, fields } = response.data?.frames[0] ?? { fields: [], data: [] }
  const dataLength = data?.[0].length ?? 0

  if (!data || !dataLength) {
    return null
  }

  fields.forEach((field, index) => {
    revenueFieldsIdx.set(field.name, index)
  })

  // availableCapacity and soldCapacity are using MW
  const capacityIndex = revenueFieldsIdx.get('availableCapacity')
  const soldCapacityIndex = revenueFieldsIdx.get('soldCapacity')
  const revenueIndex = revenueFieldsIdx.get('soldRevenue')
  const timestampIndex = revenueFieldsIdx.get('time')

  for (let i = 0; i < dataLength; i++) {
    const timestamp = timestampIndex !== undefined ? data[timestampIndex][i] : null

    // timestamp should never be null, but just in case we skip that particular data frame
    if (!timestamp) {
      continue
    }
    revenueData.push({
      // TODO: We use the timestamp as internal id. This is being used to identify the data in the UI. We should consider changing this for a uuid property, but
      // that will require changes on the old revenue page as well. We could do it as a follow up task when we remove the old revenue page.
      id: timestamp,
      capacity: capacityIndex ? (data[capacityIndex][i] ?? 0) : 0,
      timestamp: DateTime.fromMillis(timestamp).setZone(params.timezone),
      revenue: revenueIndex ? (data[revenueIndex][i] ?? 0) : 0,
      soldCapacity: soldCapacityIndex ? (data[soldCapacityIndex][i] ?? 0) : 0,
    })
  }

  return revenueData
}

/**
 * It gets revenes grouped by market program. This function returns a map, where the keys are
 * the market program types, and the values are array of revenues. Be aware that when a market
 * program does not have revenues, the value of the map will be null.
 */
export async function getInsightRevenuesByMarketPrograms({
  marketPrograms,
  ...params
}: InsightRevenuesByMarketProgramsParams): Promise<Map<string, RevenueDataRow[] | null>> {
  const revenuesByMarketProgram = new Map()

  const responses = await Promise.all(
    marketPrograms.map((marketProgram) => getInsightRevenues({ marketProgram, ...params })),
  )

  marketPrograms.forEach((marketProgram, i) => {
    revenuesByMarketProgram.set(marketProgram, responses[i])
  })

  return revenuesByMarketProgram
}

export async function getInsightRevenuesPeriods(params: InsightRevenuePeriodsParams): Promise<InsightRevenuePeriod[]> {
  // earliestCalculated and latestCalculated are milliseconds in UTC
  const response = await axios.get<{ earliestCalculated: number; latestCalculated: number; marketProgram: string }[]>(
    `${environment.services.insightsManagerApiUrl}/revenues/periods/${params.siteUuid}`,
  )

  return response.data.map((period) => ({
    earliestCalculated: DateTime.fromMillis(period.earliestCalculated).setZone(params.timezone),
    latestCalculated: DateTime.fromMillis(period.latestCalculated).setZone(params.timezone),
    marketProgram: period.marketProgram,
  }))
}
