import { Box, Paper, Stack } from '@mui/material'

import { DEFAULT_PARTNER } from '@/constants/partners'
import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import { SiteFinancialInfoForm } from '@/features/site/components/companyInfo/SiteFinancialInfoForm'
import type { Site } from '@/features/site/types/site'

import { SiteCompanyInfoForm } from './companyInfo/SiteCompanyInfoForm'
import { SiteContacts } from './companyInfo/SiteContacts'
import { SiteInfoForm } from './companyInfo/SiteInfoForm'

interface SiteCompanyInfoViewProps {
  site: Site
}

export default function SiteCompanyInfoView({ site }: Readonly<SiteCompanyInfoViewProps>) {
  const { isEnabled } = useFeatureToggle()

  const { loggedInUser } = useAuth()

  const isAdministrator = loggedInUser?.role === USER_ROLES.ADMINISTRATORS.value
  const isSiteDirectCustomer = site.partner?.partnerCode === DEFAULT_PARTNER.partnerCode

  return (
    <Box my={3} sx={{ width: '100%' }}>
      <Stack sx={{ flexDirection: 'row', width: '100%', gap: 2 }}>
        <Stack gap={2} sx={{ width: '100%' }}>
          <Paper sx={{ p: 3, height: 'fit-content', width: '100%' }}>
            {isEnabled('FLEX_PORTAL_NEW_SITE_DETAILS') ? (
              <SiteInfoForm site={site} />
            ) : (
              <SiteCompanyInfoForm site={site} />
            )}
          </Paper>
          {isAdministrator && isSiteDirectCustomer && isEnabled('FLEX_PORTAL_SITE_FINANCIAL_INFO') && (
            <Paper sx={{ p: 3, height: 'fit-content', width: '100%' }}>
              <SiteFinancialInfoForm site={site} />
            </Paper>
          )}
        </Stack>

        <Paper sx={{ p: 3, height: 'fit-content', width: '100%' }}>
          <SiteContacts site={site} />
        </Paper>
      </Stack>
    </Box>
  )
}
