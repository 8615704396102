import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { GET_SITE_API_ID, getSite } from '@/features/site/endpoints/sites'
import type { Site } from '@/features/site/types/site'
import type { UseQueryOptions } from '@/types/queries'

type SiteQueryParams = {
  uuid?: string
}
export type UseSiteQueryResult = Omit<UseQueryResult, 'data'> & {
  site: Site | null
}

export function useSiteQuery(params: SiteQueryParams, options?: UseQueryOptions<Site>): UseSiteQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_SITE_API_ID, params],
    queryFn: () => getSite(params.uuid as string),
    enabled: Boolean(params.uuid),
  })

  return { site: data, ...queryResult }
}

export function useInvalidateSiteQuery() {
  const queryClient = useQueryClient()

  return (params: SiteQueryParams) => queryClient.invalidateQueries({ queryKey: [GET_SITE_API_ID, params] })
}
