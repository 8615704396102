import { useTranslation } from 'react-i18next'

import { MarketProgramFilterTabs } from '@/features/site/components/availability/MarketProgramFilterTabs'
import type { MarketProgram } from '@/features/site/types/marketProgram'
import { sortMarketPrograms } from '@/features/site/utils/sortMarketPrograms'
import type { MarketProgramType } from '@/types/marketProgramType'

type RevenuesMarketProgramProps = {
  marketProgramsOptions: MarketProgram[]
  marketProgramSelection?: MarketProgram
  handleMarketProgramChange: (newValue: MarketProgramType) => void
}
export const RevenueMarketProgramTabs = ({
  marketProgramsOptions,
  marketProgramSelection,
  handleMarketProgramChange,
}: RevenuesMarketProgramProps) => {
  const { t } = useTranslation()

  if (!marketProgramsOptions || !marketProgramSelection) return null

  const marketProgramTypes = sortMarketPrograms(t, marketProgramsOptions).map(
    (program) => program?.type as MarketProgramType,
  )
  const selectedMarketProgramType = marketProgramSelection?.type

  return (
    <MarketProgramFilterTabs
      activeMarketPrograms={marketProgramTypes}
      value={selectedMarketProgramType}
      onChange={handleMarketProgramChange}
    />
  )
}
