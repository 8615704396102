import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import type { MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import CustomIconButton from '@/components/inputs/CustomIconButton'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { isBidEditable } from '@/features/bidding/utils/bidStatus'
import type { GroupedBidParams } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import { buildBidCapacityLink } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'

interface EditBidOptionProps {
  groupedBidParams: GroupedBidParams
}

const EditBid = ({ groupedBidParams }: EditBidOptionProps) => {
  const { selectedCountry } = useBiddingContext()
  const navigate = useNavigate()

  return (
    isBidEditable(groupedBidParams.deliveryDay, selectedCountry) && (
      <CustomIconButton
        Icon={EditOutlinedIcon}
        aria-label="edit"
        data-testid="edit-bid"
        onClick={(evt: MouseEvent<HTMLButtonElement>) => {
          evt.preventDefault()

          navigate(
            buildBidCapacityLink({
              deliveryDay: groupedBidParams.deliveryDay,
              portfolioCode: groupedBidParams.portfolioCode,
              marketProgram: groupedBidParams.marketProgram,
              bidType: groupedBidParams.bidType,
            }),
          )
        }}
      />
    )
  )
}

export default EditBid
