import { Parser } from '@json2csv/plainjs'
import fileDownload from 'js-file-download'
import type { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import type { SiteRevenuePerMarketProgram } from '@/features/site/types/revenue'
import type { MarketProgramType } from '@/types/marketProgramType'

type RevenueDateRange = {
  startDate: DateTime
  endDate: DateTime
}

export function useExportRevenue() {
  const { t } = useTranslation()

  function exportCsv(revenues: SiteRevenuePerMarketProgram | null, siteName: string, dateRange: RevenueDateRange) {
    const translatedTimestamp = t('site_revenue.export.timestamp')
    const translatedCapacity = t('site_revenue.export.offered_capacity')
    const translatedSoldCapacity = t('site_revenue.export.sold_capacity')
    const translatedRevenue = t('site_revenue.export.total_revenue')
    const translatedMarketProgram = t('site_revenue.export.market_program')

    const columnLabels = [
      translatedTimestamp,
      translatedCapacity,
      translatedSoldCapacity,
      translatedRevenue,
      translatedMarketProgram,
    ]

    const csvData: Record<string, any>[] = []

    if (revenues) {
      Array.from(revenues.keys()).forEach((marketProgramName) => {
        revenues.get(marketProgramName)?.forEach((row) => {
          csvData.push({
            [translatedTimestamp]: row.timestamp,
            [translatedCapacity]: row.capacity.toFixed(2),
            [translatedSoldCapacity]: row.soldCapacity.toFixed(2),
            [translatedRevenue]: row.revenue.toFixed(2),
            [translatedMarketProgram]: t(
              `common.market_program.${convertNwMarketProgramToMarketProgramType(marketProgramName)}`,
            ), // TODO: translate market program name based on the market program ID
          })
        })
      })
    }

    const parser = new Parser({
      fields: columnLabels,
    })
    const csv = parser.parse(csvData)
    const translatedRevenues = t('site_revenue.export.revenues')
    const fileName = `${translatedRevenues} - ${dateRange.startDate.toISODate()} - ${dateRange.endDate.toISODate()} - ${siteName}.csv`
    fileDownload(csv, fileName)
  }

  return exportCsv
}

function convertNwMarketProgramToMarketProgramType(nwMarketProgram: string): MarketProgramType {
  return nwMarketProgram.toLocaleLowerCase().replaceAll('_', '-') as MarketProgramType
}
