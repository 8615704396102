import type { SelectChangeEvent } from '@mui/material'
import type { GridFilterModel } from '@mui/x-data-grid'
import { gridFilterModelSelector, useGridApiContext } from '@mui/x-data-grid'
import type { FC } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import GridToolbar from '@/components/dataDisplay/GridToolbar'
import type { SelectFieldOption } from '@/components/inputs/CustomSelectField'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import { FIELD_WIDTH } from '@/constants/layout'
import DeliveryWindowSelector from '@/features/bidding/components/DeliveryWindowSelector'
import MarketProgramSelector from '@/features/bidding/components/MarketProgramSelector'
import type { MarketProgramBiddingName, Portfolio } from '@/features/bidding/types/bid'
import { PortfolioState } from '@/features/bidding/types/bid'
import { getFilterItemValue, updateDataGridFilter } from '@/utils/datagrid/filters'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends Props {}
}

type Props = {
  portfolios: Portfolio[] | null
}

export const DEFAULT_DATA_GRID_BIDS_FILTER_MODEL: GridFilterModel = {
  items: [],
}

const BiddingTodoListFilterToolbar: FC<Readonly<Props>> = ({ portfolios }) => {
  const apiRef = useGridApiContext()
  const { t } = useTranslation()

  const sortedPortfolios = useMemo(() => portfolios?.toSorted((a, b) => a.name.localeCompare(b.name)), [portfolios])

  const filterModel = gridFilterModelSelector(apiRef)
  const marketProgram = getFilterItemValue<MarketProgramBiddingName>(filterModel, 'marketProgram') ?? ''
  const portfolio = getFilterItemValue<string>(filterModel, 'portfolio') ?? ''
  const deliveryDay = getFilterItemValue<string>(filterModel, 'deliveryDay') ?? ''

  const handleFilterChange = (field: string, value: any) => {
    updateDataGridFilter(apiRef, field, { id: field, field, operator: 'equals', value })
  }

  return (
    <GridToolbar
      filters={
        <>
          <DeliveryWindowSelector
            value={deliveryDay}
            onChange={(event: SelectChangeEvent) => handleFilterChange('deliveryDay', event.target.value)}
          />

          <CustomSelectField
            showAllOption
            data-testid="portfolio-selector"
            id="portfolio"
            label={t('bidding.overview.header.portfolio')}
            options={getPortfolioOptions(sortedPortfolios)}
            sx={{ minWidth: FIELD_WIDTH }}
            value={portfolio}
            onChange={(event: SelectChangeEvent) => handleFilterChange('portfolio', event.target.value)}
          />

          <MarketProgramSelector
            data-testid="market-program-selector"
            value={marketProgram}
            onChange={(event: SelectChangeEvent) => handleFilterChange('marketProgram', event.target.value)}
          />
        </>
      }
    />
  )
}

const getPortfolioOptions = (portfolios: Portfolio[] | undefined): SelectFieldOption<string>[] =>
  (portfolios ?? [])
    .filter((portfolio) => portfolio.state === PortfolioState.LIVE)
    .map((portfolio) => ({
      id: portfolio.code,
      value: portfolio.code,
      label: portfolio.name,
    }))
    .slice()

export default BiddingTodoListFilterToolbar
