import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Stack } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { AggregatedPowerMeasurement } from '@/features/site/components/power_measurements/AggregatedPowerMeasurement'
import GreyAndWhiteCloud from '@/features/site/components/power_measurements/GreyAndWhiteCloud'
import WhiteCloud from '@/features/site/components/power_measurements/WhiteCloud'
import { useCustomerQuery } from '@/features/site/hooks/useCustomerQuery'

export const PowerMeasurements = () => {
  const { t } = useTranslation()
  const { loggedInUser } = useAuth()
  const customerUuid = loggedInUser?.allowedRoIds?.[0] ?? ''

  const { customer, isFetching: isFetchingCustomer } = useCustomerQuery(
    { uuid: customerUuid },
    {
      enabled: Boolean(customerUuid),
    },
  )

  if (!customer || isFetchingCustomer) return null

  return (
    <Stack
      alignItems="center"
      direction="column"
      justifyContent="center"
      sx={{ width: '100%', height: '100%' }} // Add this line
    >
      <WhiteCloud style={{ position: 'absolute', top: 150, left: 500, zIndex: -99 }} />
      <GreyAndWhiteCloud style={{ position: 'absolute', top: 400, right: 200, zIndex: -99 }} />
      <CustomTypography fontSize={36} variant="h1">
        {t('customer_measurements.power_measurements.title')}
      </CustomTypography>
      <AggregatedPowerMeasurement customerUuid={customer.uuid!} location={customer.location!} />
      <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
        <InfoOutlinedIcon color={'info'} width={'20px'} />
        <CustomTypography variant="body1">{t('customer_measurements.power_measurements.information')}</CustomTypography>
      </Stack>
    </Stack>
  )
}
