import type { ActivationGroup } from '@/features/activationGroup/types'
import { ActivationGroupState } from '@/features/activationGroup/types'
import type { MarketProgram } from '@/features/bidding/constants'
import { BidType, Status } from '@/features/bidding/constants'
import type { Portfolio } from '@/features/bidding/types/bid'
import type { BidHistory } from '@/features/bidding/types/bidHistory'
import { getFutureDeliveryDays } from '@/features/bidding/utils/date/getFutureDeliveryDays'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import { MARKET_PROGRAMS_WITH_ENERGY_BIDDING_ENABLED } from '@/features/bidding/utils/getBidConfig'

export type BiddingTodoItem = {
  deliveryDay: MarketDate
  portfolio: Portfolio
  marketProgram: MarketProgram
  activationGroups: ActivationGroup[]
  capacityBidStatus: Status
  energyBidStatus: Status
}

type ActivationGroupItem = {
  deliveryDay: MarketDate
  activationGroup: ActivationGroup
  capacityBidStatus: Status
  energyBidStatus: Status
}

export const composeBiddingTodoList = (
  bidHistories: BidHistory[],
  activationGroups: ActivationGroup[],
  biddableMarketPrograms: MarketProgram[],
) => {
  const activationGroupItems: ActivationGroupItem[] = []
  const deliveryDays = getFutureDeliveryDays()

  activationGroups.forEach((activationGroup) => {
    if (activationGroup.state !== ActivationGroupState.LIVE) return
    if (isNotBiddableBalancingMarketProgram(activationGroup.marketProgram, biddableMarketPrograms)) return

    deliveryDays.forEach((deliveryDay) => {
      const existingBids: BidHistory[] = bidHistories.filter(
        (history) => history.deliveryDay.equals(deliveryDay) && history.activationGroupUuid === activationGroup.uuid,
      )

      const capacityBid = existingBids.find((bid) => bid.bidType === BidType.CAPACITY)
      const energyBid = existingBids.find((bid) => bid.bidType === BidType.ENERGY)

      const capacityBidStatus = getBidStatus(capacityBid, BidType.CAPACITY, activationGroup)
      const energyBidStatus = getBidStatus(energyBid, BidType.ENERGY, activationGroup)

      if (atLeastOneBidHasStatusTodoOrDraft(capacityBidStatus, energyBidStatus)) {
        activationGroupItems.push({
          deliveryDay,
          activationGroup,
          capacityBidStatus: capacityBidStatus,
          energyBidStatus: energyBidStatus,
        })
      }
    })
  })

  return groupActivationGroups(activationGroupItems)
}

function isNotBiddableBalancingMarketProgram(marketProgram: MarketProgram, biddableMarketPrograms: MarketProgram[]) {
  return biddableMarketPrograms.find((value) => value === marketProgram) === undefined
}

const groupActivationGroups = (activationGroupItems: ActivationGroupItem[]): BiddingTodoItem[] =>
  activationGroupItems.reduce<BiddingTodoItem[]>(
    (todoItems, { deliveryDay, activationGroup, capacityBidStatus, energyBidStatus }) => {
      const existingItem = todoItems.find(
        (todoItem) =>
          todoItem.deliveryDay.equals(deliveryDay) &&
          todoItem.portfolio.code === activationGroup.portfolio.code &&
          todoItem.marketProgram === activationGroup.marketProgram &&
          !todoItem.activationGroups.some((group) => group.uuid === activationGroup.uuid),
      )

      if (existingItem) {
        existingItem.activationGroups.push(activationGroup)
      } else {
        todoItems.push({
          deliveryDay,
          portfolio: activationGroup.portfolio,
          marketProgram: activationGroup.marketProgram,
          activationGroups: [activationGroup],
          capacityBidStatus,
          energyBidStatus,
        })
      }

      return todoItems
    },
    [],
  )

const getBidStatus = (bid: BidHistory | undefined, bidType: BidType, activationGroup: ActivationGroup) => {
  if (bid) {
    return bid.summary.status
  }

  if (
    bidType === BidType.CAPACITY ||
    (bidType === BidType.ENERGY && MARKET_PROGRAMS_WITH_ENERGY_BIDDING_ENABLED.includes(activationGroup.marketProgram))
  ) {
    return Status.TODO
  }

  return Status.NO_BID
}

const atLeastOneBidHasStatusTodoOrDraft = (capacityBidStatus: Status, energyBidStatus: Status) => {
  const statuses = [Status.TODO, Status.DRAFT]
  return statuses.includes(capacityBidStatus) || statuses.includes(energyBidStatus)
}
