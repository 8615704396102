import { useTranslation } from 'react-i18next'

import MainContentContainer from '@/components/layouts/MainContentContainer'
import Page from '@/components/layouts/Page'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import { ResourceLevelRestrictionsDataGrid } from '@/features/site/components/availability/restriction/resourceLevelUnavailaibility/ResourceLevelRestrictionsDataGrid'
import {
  RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_COUNTRIES_TOGGLE_NAME,
  RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_CUSTOMERS_TOGGLE_NAME,
} from '@/features/site/constants'
import { useSiteQuery } from '@/features/site/hooks/useSiteQuery'
import { SiteUnavailabilityView } from '@/features/unavailability/components/SiteUnavailabilityView'

export const SiteUnavailability = () => {
  const { t } = useTranslation()
  const { useFeatureWithContext } = useFeatureToggle()

  const { loggedInUser } = useAuth()
  const siteUuid = loggedInUser?.allowedRoIds?.[0] ?? ''

  const { site, isFetching: isFetchingSite } = useSiteQuery({ uuid: siteUuid }, { enabled: Boolean(siteUuid) })

  const { isFeatureEnabled: isNewRestrictionsEnabledForCountry } = useFeatureWithContext(
    RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_COUNTRIES_TOGGLE_NAME,
    { countryCode: site?.countryCode?.toLowerCase() ?? '' },
  )

  const { isFeatureEnabled: isNewRestrictionsEnabledForCustomer } = useFeatureWithContext(
    RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_CUSTOMERS_TOGGLE_NAME,
    { userId: site?.uuid ?? '' },
  )

  const isCustomerOnNewPlatform = isNewRestrictionsEnabledForCountry || isNewRestrictionsEnabledForCustomer

  if (!site || isFetchingSite) {
    return null
  }

  return (
    <Page
      pageHeaderProps={{
        pageTitle: t('component.page_header.unavailability'),
        pageTag: isCustomerOnNewPlatform ? 'res-level-unavailability' : 'unavailability',
      }}
    >
      {isCustomerOnNewPlatform ? (
        <MainContentContainer>
          <ResourceLevelRestrictionsDataGrid
            siteLocation={site.location ?? ''}
            siteTimezone={site.timezone ?? ''}
            siteUuid={site.uuid!}
            viewOnly={false}
          />
        </MainContentContainer>
      ) : (
        <SiteUnavailabilityView site={site} />
      )}
    </Page>
  )
}
