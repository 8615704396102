import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAlertContext } from '@/contexts/AlertContext'
import { parseTextToPtuValues } from '@/features/activationGroup/utils/clipboard'
import OfferedBidPtusDataGrid from '@/features/bidding/components/capacity/offer/OfferedBidPtusDataGrid'
import { useInvalidateBidHistoriesQuery } from '@/features/bidding/hooks/useBidHistoriesPageQuery'
import { useInvalidateBidOverviewPageQuery } from '@/features/bidding/hooks/useBidOverviewPageQuery'
import { useInvalidateGroupedBidsPageQuery } from '@/features/bidding/hooks/useGroupedBidsPageQuery'
import { useMarketClosingWarning } from '@/features/bidding/hooks/useMarketClosingWarning'
import { useSaveBidsMutation } from '@/features/bidding/hooks/useSaveBidsMutation'
import type {
  BidMetaData,
  BulkFillMode,
  ExtendedBidPtu,
  OfferedAndForecastAndSuggestedPtu,
} from '@/features/bidding/types/bid'
import { calculateBulkFillCapacity } from '@/features/bidding/utils/calculations/calculateBulkFillCapacity'
import { bidFromOfferedAndForecastPtus } from '@/features/bidding/utils/model/createBid'

type OfferedBidFormProps = {
  ptus: OfferedAndForecastAndSuggestedPtu[]
  bidMetaData: BidMetaData
  pastPtusToCopy: ExtendedBidPtu[]
}

const OfferedBidForm = ({ ptus, bidMetaData, pastPtusToCopy }: Readonly<OfferedBidFormProps>) => {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()
  const [bidPtus, setBidPtus] = useState<OfferedAndForecastAndSuggestedPtu[]>(ptus)
  const invalidateBidHistories = useInvalidateBidHistoriesQuery()
  const invalidateGroupedBids = useInvalidateGroupedBidsPageQuery()
  const invalidateBidOverview = useInvalidateBidOverviewPageQuery()
  const { saveBids } = useSaveBidsMutation()
  const { showMarketClosingWarning, clearMarketClosingWarning } = useMarketClosingWarning()
  const deliveryISODate = bidMetaData.deliveryDay.toISODate()

  useEffect(() => {
    copyPastPtus()
  }, [pastPtusToCopy])

  useEffect(() => {
    showMarketClosingWarning(bidMetaData.deliveryDay)

    return () => {
      clearMarketClosingWarning()
    }
  }, [deliveryISODate])

  useEffect(() => {
    const handlePaste = (event) => {
      const parsedValues = parseTextToPtuValues(event.clipboardData.getData('text'))

      if (parsedValues.length === 0) {
        return
      }

      onPtusPastedFromClipboard(parsedValues)
    }

    document.addEventListener('paste', handlePaste)

    return () => {
      document.removeEventListener('paste', handlePaste)
    }
  }, [])

  const onFillAll = async (bulkMode: BulkFillMode, bulkFillValue: number | undefined) => {
    if (bulkFillValue === undefined) return

    const newBidPtus: OfferedAndForecastAndSuggestedPtu[] = bidPtus.map((ptu) => {
      const capacityWatts = calculateBulkFillCapacity(bulkMode, ptu, bulkFillValue)
      if (capacityWatts === null) return ptu

      return { ...ptu, offeredVolume: { quantity: capacityWatts, unit: 'WATTS' } }
    })

    setBidPtus(newBidPtus)
    await saveDraft(newBidPtus)
  }

  const saveDraftCurrentValues = async () => {
    await saveDraft(bidPtus)
    pushAlert({
      message: t('bidding.create_bid.draft_saved_successfully', { activationGroup: bidMetaData.activationGroupUuid }),
      severity: 'success',
    })
  }

  const processBidPtuUpdate = async (
    newPtu: OfferedAndForecastAndSuggestedPtu,
  ): Promise<OfferedAndForecastAndSuggestedPtu> => {
    const value = Number(newPtu.offeredVolume.quantity)
    const isValid = Number.isInteger(value) && value >= 0
    if (!isValid)
      return Promise.reject(new Error(`We expect the value ${value} to be an integer greater or equal to 0`))

    const newBidPtus = bidPtus.map(
      (prevPtu): OfferedAndForecastAndSuggestedPtu =>
        prevPtu.ptu.start.equals(newPtu.ptu.start) ? { ...prevPtu, offeredVolume: newPtu.offeredVolume } : prevPtu,
    )
    setBidPtus(newBidPtus)
    await saveDraft(newBidPtus)
    return Promise.resolve(newPtu)
  }

  // TODO AF-490: Make sure we have fresh data on the price bidding page
  const saveDraft = async (newBidPtus: OfferedAndForecastAndSuggestedPtu[]) => {
    await saveBids([bidFromOfferedAndForecastPtus(bidMetaData, newBidPtus)])
    await invalidateBidHistories()
    await invalidateGroupedBids()
    await invalidateBidOverview()
  }

  const copyPastPtus = async () => {
    if (pastPtusToCopy?.length === 0) {
      return
    }

    const newBidPtus = bidPtus.map((ptu): OfferedAndForecastAndSuggestedPtu => {
      const pastPtu = pastPtusToCopy.find((pastPtu) => pastPtu.ptu.start.hour === ptu.ptu.start.hour)
      return !pastPtu ? ptu : { ...ptu, offeredVolume: pastPtu.offeredVolume }
    })

    setBidPtus(newBidPtus)

    await saveDraft(newBidPtus)

    pushAlert({
      message: t('bidding.create_bid.bid_copied_successfully'),
      severity: 'success',
    })
  }

  const onPtusPastedFromClipboard = async (newPtus: number[]) => {
    if (newPtus.length !== bidPtus.length) {
      pushAlert({
        message: t('bidding.create_bid.ptus_pasted_error', {
          expected: bidPtus.length,
          given: newPtus.length,
        }),
        severity: 'error',
      })
      return
    }

    const newBidPtus = bidPtus.map((ptu, index): OfferedAndForecastAndSuggestedPtu => {
      return { ...ptu, offeredVolume: { quantity: newPtus[index], unit: ptu.offeredVolume.unit } }
    })
    setBidPtus(newBidPtus)
    await saveDraft(newBidPtus)

    pushAlert({
      message: t('bidding.create_bid.ptus_pasted_successfully'),
      severity: 'success',
    })
  }

  return (
    <OfferedBidPtusDataGrid
      bidMetaData={bidMetaData}
      processRowUpdate={processBidPtuUpdate}
      ptus={bidPtus}
      saveDraftCurrentValues={saveDraftCurrentValues}
      onFillAll={onFillAll}
    />
  )
}

export default OfferedBidForm
