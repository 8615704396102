import type { DateTime } from 'luxon'

import type { ExtendedBid } from '@/features/bidding/types/bid'
import { convertToRoundedMw, round } from '@/features/bidding/utils/calculations/convertToRoundedMw'

export type PriceQuantity = { price: number; quantity: number }

export const getPtuPriceQuantitiesFromExtendedBids = (ptuStart: DateTime, bids: ExtendedBid[]): PriceQuantity[] => {
  const priceQuantities = bids.flatMap((bid) => getPtuPriceQuantitiesFromExtendedBid(ptuStart, bid))
  const prices = priceQuantities.map((priceQuantity) => priceQuantity.price)
  const uniquePrices = Array.from(new Set(prices)).sort((a, b) => a - b)

  return uniquePrices.map((price) => ({
    price: price,
    quantity: round(priceQuantities.reduce((total, pq) => total + (pq.price === price ? pq.quantity : 0), 0)),
  }))
}

const getPtuPriceQuantitiesFromExtendedBid = (ptuStart: DateTime, bid: ExtendedBid) => {
  return (
    bid.ptus
      .find((bidPtu) => bidPtu.ptu.start.equals(ptuStart))!
      .ptuChunks?.map((priceChunk) => ({
        price: priceChunk.offeredPrice,
        quantity: convertToRoundedMw(priceChunk.offeredVolume.quantity),
      })) || []
  )
}
