import { Box } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import DataGridSkeleton from '@/components/dataDisplay/DataGridSkeleton'
import RevenueMonthlyDataGrid from '@/features/site/components/revenue/RevenueMonthlyDataGrid'
import { RevenueMonthlySummary } from '@/features/site/components/revenue/RevenueMonthlySummary'
import type { RevenueSummaryAccordionProps } from '@/features/site/components/revenue/RevenueSummaryAccordion'
import RevenueSummaryAccordion from '@/features/site/components/revenue/RevenueSummaryAccordion'
import type { Month } from '@/features/site/constants'
import { MONTHS } from '@/features/site/constants'
import type { RevenueDataRow } from '@/features/site/types/revenue'
import { getRevenueDataByMonth } from '@/features/site/utils/revenueUtils/formatRevenueData'

type RevenueMonthlyAccordionsProps = {
  revenueData: RevenueDataRow[]
  isLoadingRevenueTimeSeries: boolean
}

const SKELETON_ROW_SIZE = 12
const SKELETON_COLUMN_SIZE = 4

export const RevenueMonthlyAccordions = ({
  revenueData,
  isLoadingRevenueTimeSeries,
}: RevenueMonthlyAccordionsProps) => {
  const { t } = useTranslation()

  const revenueDataByMonth: Record<number, RevenueDataRow[]> = getRevenueDataByMonth(revenueData)

  if (isLoadingRevenueTimeSeries) {
    return (
      <DataGridSkeleton
        includeWrapper={true}
        numberOfColumns={SKELETON_COLUMN_SIZE}
        numberOfRows={SKELETON_ROW_SIZE}
        withToolbar={false}
      />
    )
  }

  const monthlyAccordionProps: RevenueSummaryAccordionProps[] = Object.entries(revenueDataByMonth).map(
    ([month, data]) => {
      const capacity = data
        .map((revenue) => revenue.capacity)
        .reduce((totalCapacity, revenueCapacity) => totalCapacity + revenueCapacity, 0)
      const sold = data
        .map((revenue) => revenue.soldCapacity)
        .reduce((totalSold, soldCapacity) => totalSold + soldCapacity, 0)
      const revenue = data
        .map((revenue) => revenue.revenue)
        .reduce((totalRevenue, revenue) => totalRevenue + revenue, 0)

      return {
        title: t(`common.months.${MONTHS[month] as Month}`),
        monthlyDataGrid: <RevenueMonthlyDataGrid data={data} />,
        monthlySummary: <RevenueMonthlySummary capacity={capacity} revenue={revenue} sold={sold} />,
      } as RevenueSummaryAccordionProps
    },
  )

  return (
    <Box>
      {monthlyAccordionProps.map((props) => (
        <RevenueSummaryAccordion key={props.title} {...props} />
      ))}
    </Box>
  )
}
