import type { GridRowSelectionModel } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { useAvailableAnalogResourcesQuery } from '@/features/site/hooks/useAvailableAnalogResourcesQuery'
import { useAvailableRelayResourcesQuery } from '@/features/site/hooks/useAvailableRelayResourcesQuery'
import type { AvailableResource } from '@/features/site/types/availableResource'
import type { MarketProgram } from '@/features/site/types/marketProgram'
import type { ServiceRuleForm } from '@/features/site/types/serviceRuleForm'

export const WATTS_IN_KW = 1_000

interface AvailabilityWireSelectionSectionProps {
  customerUuid: string
  marketProgram: MarketProgram
}

export const AvailabilityWireSelectionSection = (props: AvailabilityWireSelectionSectionProps) => {
  const { t } = useTranslation()
  const form = useFormContext<ServiceRuleForm>()
  const { selectedResources } = form.getValues()
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>(selectedResources ?? [])

  useEffect(() => {
    form.setValue(
      'selectedResources',
      rowSelectionModel.map((resourceId) => parseInt(resourceId.toString())),
    )
  }, [rowSelectionModel])

  const isAFRR = props.marketProgram?.type === 'afrr-up' || props.marketProgram?.type === 'afrr-down'

  const columns = isAFRR
    ? [
        {
          field: 'name',
          headerName: t('customer_details.tabs.availability.common.form.resources_section.resources'),
          flex: 2,
          valueGetter: (_, availableResource: AvailableResource) => availableResource.wireName,
        },
        {
          field: 'power',
          headerName: t('customer_details.tabs.availability.common.form.resources_section.power'),
          flex: 1.5,
          valueGetter: (_, availableResource: AvailableResource) => availableResource.wireTotalPowerConsumption ?? 0,
          valueFormatter: (_, availableResource: AvailableResource) =>
            `${availableResource.wireTotalPowerConsumption / WATTS_IN_KW} kW`,
        },
      ]
    : [
        {
          field: 'name',
          headerName: t('customer_details.tabs.availability.common.form.resources_section.resources'),
          flex: 2,
          valueGetter: (_, availableResource: AvailableResource) => availableResource.wireName,
        },
        {
          field: 'type',
          headerName: t('customer_details.tabs.availability.common.form.resources_section.type'),
          flex: 1,
          valueGetter: (_, availableResource: AvailableResource) => availableResource.type,
        },
        {
          field: 'power',
          headerName: t('customer_details.tabs.availability.common.form.resources_section.power'),
          flex: 1.5,
          valueGetter: (_, availableResource: AvailableResource) => availableResource.wireTotalPowerConsumption ?? 0,
          valueFormatter: (_, availableResource: AvailableResource) =>
            `${availableResource.wireTotalPowerConsumption / WATTS_IN_KW} kW`,
        },
      ]

  const { availableRelayResources, isLoading: isLoadingRelaysResources } = useAvailableRelayResourcesQuery(
    {
      customerUuid: props.customerUuid,
      location: form.getValues().location,
      marketProgramId: props.marketProgram?.id,
    },
    {
      enabled: Boolean(props.marketProgram) && !isAFRR,
    },
  )

  const { availableAnalogResources, isLoading: isLoadingAnalogResources } = useAvailableAnalogResourcesQuery(
    {
      customerUuid: props.customerUuid,
      location: form.getValues().location,
      marketProgramId: props.marketProgram?.id,
      isUsed: isAFRR ? true : undefined,
    },
    {
      enabled: Boolean(props.marketProgram) && isAFRR,
    },
  )

  return (
    <CustomDataGrid
      keepNonExistentRowsSelected
      checkboxSelection={true}
      clickableRows={{
        isRowSelectable: () => true,
        isRowClickable: () => true,
        onRowSelectionModelChange: (newSelection) => {
          setRowSelectionModel(newSelection)
        },
      }}
      columns={columns}
      getRowId={(row: AvailableResource) => row.wireId}
      includeWrapper={false}
      initialState={{
        pagination: {
          paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
        },
      }}
      isLoading={isLoadingRelaysResources || isLoadingAnalogResources}
      rowSelection={{
        multiple: true,
        model: rowSelectionModel,
      }}
      rows={(availableRelayResources || availableAnalogResources) ?? []}
      title={t('customer_details.tabs.availability.common.form.resources_section.title')}
    />
  )
}
