import { Chip } from '@mui/material'
import type { GridCellParams, GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid'
import type { TFunction } from 'i18next'
import type { DateTime } from 'luxon'

import DataGridEditableTextField from '@/components/dataDisplay/DataGridEditableTextField'
import { DEFAULT_CURRENCY } from '@/features/bidding/constants'
import type { AggregatedBid, AggregatedBidPtu } from '@/features/bidding/utils/model/buildAggregatedBid'
import { isCapacityValid } from '@/features/bidding/utils/validation/isCapacityValid'

export const getPriceBidDataGridColumns = (
  aggregatedBid: AggregatedBid,
  editable: boolean,
  t: TFunction,
  locale: string,
  displayChip?: boolean,
): GridColDef<AggregatedBidPtu>[] => [
  {
    field: 'ptuStart',
    headerName: t('bidding.create_bid.header.ptu'),
    valueFormatter: (ptuStart: DateTime) => ptuStart.toFormat('HH:mm'),
    flex: 1,
    headerClassName: 'default-header',
  },
  {
    field: 'available',
    headerName: t('bidding.create_bid.header.available'),
    valueFormatter: (available: number) => `${available} ${aggregatedBid.unit}`,
    flex: 1,
    headerClassName: 'default-header',
    renderCell: (params: GridRenderEditCellParams<AggregatedBidPtu>) =>
      displayChip ? <Chip label={params.formattedValue} /> : params.formattedValue,
  },
  {
    field: 'remaining',
    headerName: t('bidding.create_bid.header.remaining'),
    valueFormatter: (remaining: number) => `${remaining} ${aggregatedBid.unit}`,
    flex: 1,
    headerClassName: 'default-header',
    cellClassName: (params: GridCellParams<AggregatedBidPtu>) => getNegativeValueClassName(params.row.remaining),
    renderCell: (params: GridRenderEditCellParams<AggregatedBidPtu>) =>
      displayChip ? (
        <Chip classes={{ label: getNegativeValueClassName(params.row.remaining) }} label={params.formattedValue} />
      ) : (
        params.formattedValue
      ),
  },
  ...getPriceChunkColumns(aggregatedBid, editable, locale),
]

const getPriceChunkColumns = (
  aggregatedBid: AggregatedBid,
  editable: boolean,
  locale: string,
): GridColDef<AggregatedBidPtu>[] => {
  const prices = aggregatedBid.ptus.flatMap((rowItem) =>
    rowItem.priceQuantities.map((priceCapacity) => priceCapacity.price),
  )
  const uniquePrices = Array.from(new Set(prices)).sort((a, b) => a - b)

  return uniquePrices.map((price, priceIndex) => {
    const formattedPrice = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: DEFAULT_CURRENCY,
    }).format(price)

    return {
      field: `price_${price}`,
      headerName: formattedPrice,
      editable: editable,
      flex: 1,
      valueFormatter: (value: number) => `${value ?? '-'} ${aggregatedBid.unit}`,
      valueGetter: (_, row) => row.priceQuantities.find((pc) => pc.price === price)?.quantity,
      valueSetter: (value: number, row): AggregatedBidPtu => ({
        ...row,
        priceQuantities: row.priceQuantities.map((priceCapacity) =>
          priceCapacity.price === price ? { ...priceCapacity, quantity: Number(value) } : priceCapacity,
        ),
      }),
      renderCell: (params) =>
        editable ? <DataGridEditableTextField readonlyField value={params.formattedValue} /> : undefined,
      renderEditCell: (params: GridRenderEditCellParams<AggregatedBidPtu>) => (
        <DataGridEditableTextField value={params.value} onChange={onChange(params)} />
      ),
      headerClassName: () => getClassName(priceIndex),
      cellClassName: () => getClassName(priceIndex),
    }
  })
}

const onChange = (params: GridRenderEditCellParams<AggregatedBidPtu>) => (value: string | undefined) => {
  if (value && !isCapacityValid(value)) return

  params.api.setEditCellValue({ ...params, value })
}

const getClassName = (priceIndex: number) => {
  if (priceIndex % 2 === 0) {
    return 'editable-column-light'
  } else {
    return 'editable-column-dark'
  }
}

const getNegativeValueClassName = (value: number) => {
  return value < 0 ? 'negative-value' : ''
}
