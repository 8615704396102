import { Card } from '@mui/material'
import React from 'react'

import CustomCardContent from '@/components/layouts/CustomCardContent'
import GenericPrequalificationPatternChart from '@/features/activationGroup/components/GenericPrequalificationPatternChart'

type GenericPrequalificationPatternChartCardProps = {
  patternUuid: string
  patternCsv: string
}

function GenericPrequalificationPatternChartCard({
  patternUuid,
  patternCsv,
}: Readonly<GenericPrequalificationPatternChartCardProps>) {
  return (
    <Card>
      <CustomCardContent title={patternUuid}>
        <GenericPrequalificationPatternChart patternCsv={patternCsv} />
      </CustomCardContent>
    </Card>
  )
}

export default GenericPrequalificationPatternChartCard
