import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { GET_RESOURCE_API_ID, getResource } from '@/features/resource/endpoints/resources'
import type {
  Resource,
  ResourceCustomAttributeData,
  ResourceDetails,
  ResourceSteeringConfig,
} from '@/features/resource/types'
import type { UseQueryOptions } from '@/types/queries'

const REFETCH_RESOURCE_INTERVAL = 5_000 // 5 seconds

type ResourceQueryParams = {
  id: string
}
export type UseResourceQueryResult = Omit<UseQueryResult, 'data'> & {
  resource?: Resource
  steeringConfig?: ResourceSteeringConfig
  customAttributeData?: ResourceCustomAttributeData
  stopPolling: () => void
  restartPolling: () => void
}

export function useResourceWithPollingQuery(
  params: ResourceQueryParams,
  options?: UseQueryOptions<ResourceDetails>,
): UseResourceQueryResult {
  const [refetchingResourceEnabled, setRefetchingResourceEnabled] = useState<boolean>(true)

  const { data, ...queryResult } = useQuery({
    ...options,
    refetchInterval: REFETCH_RESOURCE_INTERVAL,
    enabled: refetchingResourceEnabled,
    queryKey: [GET_RESOURCE_API_ID, params],
    queryFn: () => getResource(params.id),
  })

  function restartPolling() {
    setRefetchingResourceEnabled(true)
  }

  function stopPolling() {
    setRefetchingResourceEnabled(false)
  }

  return {
    resource: data?.resourceResponse,
    steeringConfig: data?.steeringConfigResponse,
    customAttributeData: {
      schemas: data?.customAttributes.schemas ?? [],
      values: new Map(Object.entries(data?.customAttributes.values ?? {})),
    },
    ...queryResult,
    stopPolling,
    restartPolling,
  }
}
