import {
  ConstructionOutlined,
  ExpandLess,
  ExpandMore,
  LightbulbOutlined,
  MultilineChartOutlined,
  ScienceOutlined,
  UploadFileOutlined,
} from '@mui/icons-material'
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { usePermissions } from '@/features/authorization/contexts/PermissionsContext'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'

import ListItemLink from './ListItemLink'

function ExperimentsSection() {
  const { t } = useTranslation()
  const { isEnabled } = useFeatureToggle()
  const { permissions } = usePermissions()
  const { loggedInUser } = useAuth()
  const [open, setOpen] = useState(false)

  const hasCustomerManagerUserRole = loggedInUser?.role === USER_ROLES.CUSTOMER_MANAGERS.value

  function handleClick() {
    setOpen(!open)
  }

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <ScienceOutlined />
        </ListItemIcon>
        <ListItemText primary={t('common.experiments')} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse unmountOnExit in={open} timeout="auto">
        <List disablePadding component="div">
          {isEnabled('FLEXPORTAL_MEASUREMENTS_PAGE_ENABLED') && !hasCustomerManagerUserRole && (
            <ListItemButton sx={{ padding: 0, pl: 2 }}>
              <ListItemLink
                activePath={'/measurements'}
                icon={<MultilineChartOutlined />}
                primary={t('common.measurements')}
                to="/measurements"
              />
            </ListItemButton>
          )}
          {isEnabled('FLEXPORTAL_INTEGRATIONS_PAGE_ENABLED') && permissions.has('integrations') && (
            <ListItemButton sx={{ padding: 0, pl: 2 }}>
              <ListItemLink
                activePath={'/integrations'}
                icon={<ConstructionOutlined />}
                primary={t('common.integrations')}
                to="/integrations/customers"
              />
            </ListItemButton>
          )}
          {permissions.has('bidding') && (
            <ListItemButton sx={{ padding: 0, pl: 2 }}>
              <ListItemLink
                activePath={'/bidding/import-accepted-bid'}
                icon={<UploadFileOutlined />}
                primary={t('common.import_accepted_bid')}
                to="/bidding/import-accepted-bid"
              />
            </ListItemButton>
          )}
          {isEnabled('FLEXPORTAL_EXAMPLE_FEATURE_ENABLED') && permissions.has('exampleFeatures') && (
            <ListItemButton sx={{ padding: 0, pl: 2 }}>
              <ListItemLink
                activePath={'/example-feature'}
                icon={<LightbulbOutlined />}
                primary={t('common.example_feature')}
                to="/example-feature"
              />
            </ListItemButton>
          )}
        </List>
      </Collapse>
    </>
  )
}

export default ExperimentsSection
