import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { BidType, MarketProgram } from '@/features/bidding/constants'
import type { GroupedBidsPageQueryParams } from '@/features/bidding/endpoints/groupedBids'
import { getGroupedBids } from '@/features/bidding/endpoints/groupedBids'
import { useErrorHandler } from '@/features/bidding/hooks/useErrorHandler'
import type { GroupedBid, GroupedBidsPage } from '@/features/bidding/types/groupedBid'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import type { UseQueryOptions } from '@/types/queries'

const GET_BIDS_LATEST_PRICES_API_ID = 'GET_BID_GROUPS'

export type UseBidsLatestPricesQueryResult = Omit<UseQueryResult, 'data'> & {
  prices: number[] | null
}

export type BidsLatestPricesQueryParams = {
  country: CountryIdentifier
  portfolio: string
  marketProgram: MarketProgram
  bidType: BidType
  untilDeliveryDay: MarketDate // retrieve latest prices taking into account the bids until this delivery day (included)
}

export const useBidsLatestPricesQuery = (
  params: BidsLatestPricesQueryParams,
  options?: UseQueryOptions<GroupedBidsPage>,
): UseBidsLatestPricesQueryResult => {
  const groupedBidsPageQueryParams: GroupedBidsPageQueryParams = {
    country: params.country,
    pagination: { pageSize: 10, page: 0 },
    filter: {
      items: [
        { field: 'portfolio', operator: 'equals', value: params.portfolio },
        { field: 'marketProgram', operator: 'equals', value: params.marketProgram },
        { field: 'bidType', operator: 'equals', value: params.bidType },
      ],
    },
  }
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_BIDS_LATEST_PRICES_API_ID, groupedBidsPageQueryParams],
    queryFn: () => getGroupedBids(groupedBidsPageQueryParams),
  })

  useErrorHandler(queryResult.isError)

  if (!data) {
    return { prices: null, ...queryResult }
  }

  const latestBidWithPrices = data.groupedBids.find(
    (groupedBid) => groupedBid.deliveryDay <= params.untilDeliveryDay && getGroupedBidPrices(groupedBid).length > 0,
  )
  return { prices: latestBidWithPrices ? getGroupedBidPrices(latestBidWithPrices) : [], ...queryResult }
}

const getGroupedBidPrices = (groupedBid: GroupedBid) => {
  const bids = Object.values(groupedBid.bids)
  if (bids.length === 0) return []
  const bidPtus = bids[0].ptus
  if (bidPtus.length === 0) return []
  const ptuChunks = bidPtus[0].ptuChunks
  if (ptuChunks == undefined) return []
  return ptuChunks.map((ptuChunk) => ptuChunk.offeredPrice)
}
