import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MainContentContainer from '@/components/layouts/MainContentContainer'
import { MarketProgramFilterTabs } from '@/features/site/components/availability/MarketProgramFilterTabs'
import { RestrictionsDataGrid } from '@/features/site/components/availability/restriction/RestrictionsDataGrid'
import { ServiceRulesDataGrid } from '@/features/site/components/availability/serviceRule/ServiceRulesDataGrid'
import { NoMarketProgram } from '@/features/site/components/NoMarketProgram'
import { useCustomerMarketProgramsQuery } from '@/features/site/hooks/useCustomerMarketProgramsQuery'
import type { MarketProgram } from '@/features/site/types/marketProgram'
import type { MarketProgramContract } from '@/features/site/types/marketProgramContract'
import type { Site } from '@/features/site/types/site'
import { sortMarketProgramContracts } from '@/features/site/utils/sortMarketPrograms'
import type { MarketProgramType } from '@/types/marketProgramType'

type SiteUnavailabilityViewProps = {
  site: Site
}

export const SiteUnavailabilityView = ({ site }: SiteUnavailabilityViewProps) => {
  const { t } = useTranslation()
  const [selectedMarketProgram, setSelectedMarketProgram] = useState<MarketProgramContract>()

  const { marketProgramContracts, isFetching: isFetchingCustomerMarketPrograms } = useCustomerMarketProgramsQuery({
    uuid: site.uuid!,
  })

  if (isFetchingCustomerMarketPrograms) return null

  const activeMarketPrograms = sortMarketProgramContracts(
    t,
    marketProgramContracts?.filter((contract) => !contract.isDisabled) ?? [],
  )

  if (activeMarketPrograms.length === 0) {
    return <NoMarketProgram />
  }

  const siteMarketProgramTypes = activeMarketPrograms.map((program) => program.service.type as MarketProgramType)

  const marketProgram = selectedMarketProgram ?? activeMarketPrograms[0]
  const marketProgramType = selectedMarketProgram?.service.type ?? activeMarketPrograms[0].service.type

  const handleChange = (newValue: MarketProgramType) => {
    setSelectedMarketProgram(activeMarketPrograms.find((program) => program.service.type === newValue))
  }

  return (
    <MainContentContainer>
      <MarketProgramFilterTabs
        activeMarketPrograms={siteMarketProgramTypes}
        value={marketProgramType}
        onChange={handleChange}
      />
      {isMarketProgramAvailability(marketProgram.service) ? (
        <ServiceRulesDataGrid
          selectedMarketProgram={marketProgram}
          siteLocation={site.location ?? ''}
          siteTimezone={site.timezone ?? ''}
          siteUuid={site.uuid ?? ''}
        />
      ) : (
        <RestrictionsDataGrid
          selectedMarketProgram={marketProgram.service}
          siteLocation={site.location ?? ''}
          siteTimezone={site.timezone ?? ''}
          siteUuid={site.uuid ?? ''}
        />
      )}
    </MainContentContainer>
  )
}

function isMarketProgramAvailability(program: MarketProgram) {
  return !program.isReserve || program.reserve === 'afrr'
}
