import type { ForwardedRef } from 'react'
import type { FieldPath, FieldValues } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'

import LabeledTextField from '@/components/dataDisplay/LabeledTextField'
import type { TextFieldControllerProps } from '@/components/inputs/TextFieldController'
import IbanFieldController from '@/features/site/components/IbanFieldController'

type EditableIbanFieldControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = TextFieldControllerProps<TFieldValues, TName> & {
  isEditable?: boolean
  label: string
}

export function EditableIbanFieldController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  { label, isEditable = false, ...props }: EditableIbanFieldControllerProps<TFieldValues, TName>,
  ref: ForwardedRef<HTMLInputElement | HTMLDivElement>,
) {
  const { watch } = useFormContext<TFieldValues>()

  if (isEditable) {
    return <IbanFieldController {...props} ref={ref} label={label} sx={{ backgroundColor: 'white' }} />
  }
  const value = watch(props.name)

  return <LabeledTextField label={label} text={value} />
}
