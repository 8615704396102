import { Checkbox, FormControl, FormControlLabel, Stack, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import type { DateTime } from 'luxon'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ZONED_TODAY } from '@/constants/dateTime'
import { renderDuration } from '@/features/site/components/availability/restriction/form/renderDuration'
import type { RestrictionForm } from '@/features/site/types/restrictionForm'

export const RestrictionRepeatingDatesSection = ({ timezone }: { timezone: string }) => {
  const { t, i18n } = useTranslation()
  const { getValues, control, setValue, trigger } = useFormContext<RestrictionForm>()
  const { id, type, allDay, startDate, endDate, endTime, startTime } = getValues()

  useEffect(() => {
    trigger(['startDate', 'startTime', 'endDate', 'endTime'])
  }, [startDate, startTime, endDate, endTime, allDay])

  function isDateSelectorDisabled(date: DateTime) {
    const now = ZONED_TODAY(timezone)
    if (id) {
      return now > date
    }
  }

  return (
    <>
      <Stack direction="row" spacing={1}>
        <FormControl>
          <Controller
            control={control}
            name="startDate"
            render={({ field, fieldState }) => (
              <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
                <DatePicker
                  disabled={isDateSelectorDisabled(startDate)}
                  label={t('customer_details.tabs.availability.common.form.dates_section.start_date')}
                  minDate={ZONED_TODAY(timezone)}
                  slotProps={{
                    textField: {
                      error: !!fieldState.error,
                      helperText: fieldState.error?.message,
                    },
                  }}
                  {...field}
                  views={['day', 'month', 'year']}
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
        <FormControl>
          <Controller
            control={control}
            name="endDate"
            render={({ field, fieldState }) => (
              <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
                <DatePicker
                  disabled={isDateSelectorDisabled(endDate)}
                  label={t('customer_details.tabs.availability.common.form.dates_section.end_date')}
                  minDate={ZONED_TODAY(timezone)}
                  slotProps={{
                    textField: {
                      error: !!fieldState.error,
                      helperText: fieldState.error?.message,
                    },
                  }}
                  {...field}
                  views={['day', 'month', 'year']}
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
        <FormControl>
          <Controller
            control={control}
            name="startTime"
            render={({ field, fieldState }) => (
              <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
                <TimePicker
                  disabled={allDay}
                  label={t('customer_details.tabs.availability.common.form.dates_section.start_time')}
                  {...field}
                  format="hh:mm a"
                  slotProps={{
                    textField: {
                      error: !!fieldState.error,
                      helperText: fieldState.error?.message,
                    },
                    field: {
                      readOnly: true,
                    },
                  }}
                  views={['hours']}
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
        <FormControl>
          <Controller
            control={control}
            name="endTime"
            render={({ field, fieldState }) => (
              <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
                <TimePicker
                  disabled={allDay}
                  label={t('customer_details.tabs.availability.common.form.dates_section.end_time')}
                  slotProps={{
                    textField: {
                      error: !!fieldState.error,
                      helperText: fieldState.error?.message,
                    },
                    field: {
                      readOnly: true,
                    },
                  }}
                  {...field}
                  timeSteps={{
                    minutes: 59,
                  }}
                  views={['hours', 'minutes']}
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={allDay}
                onChange={(event) => {
                  setValue('allDay', event.target.checked)
                  if (event.target.checked) {
                    setValue('startTime', ZONED_TODAY(timezone).startOf('day'))
                    setValue('endTime', ZONED_TODAY(timezone).endOf('day').startOf('minute'))
                  }
                }}
              />
            }
            label={t('customer_details.tabs.availability.common.form.dates_section.all_day')}
          />
        </FormControl>
      </Stack>
      <Typography variant="caption">
        {t('customer_details.tabs.availability.common.form.dates_section.duration')}:
        {renderDuration(type, endDate, endTime, startDate, startTime)}
      </Typography>
    </>
  )
}
