export const ActivationGroupRouteInformation = {
  INDEX: {
    label: 'component.page_header.activation_group',
    navigationPath: () => '/activation-groups',
    routePath: '/',
  },
  ACTIVATION_GROUP_DETAILS: {
    label: 'component.page_header.activation_group',
    navigationPath: (activationGroupUuid: string) => `/activation-groups/${activationGroupUuid}`,
    routePath: '/:activationGroupUuid',
  },
  ACTIVATION_GROUP_GENERAL: {
    label: 'component.page_header.activation_group',
    navigationPath: (activationGroupUuid: string) => `/activation-groups/${activationGroupUuid}/general`,
    routePath: '/:activationGroupUuid/general',
  },
  ACTIVATION_GROUP_RESOURCES: {
    label: 'component.page_header.activation_group',
    navigationPath: (activationGroupUuid: string) => `/activation-groups/${activationGroupUuid}/resources`,
    routePath: '/:activationGroupUuid/resources',
  },
  ACTIVATION_GROUP_SCHEDULES: {
    label: 'component.page_header.activation_group',
    navigationPath: (activationGroupUuid: string) => `/activation-groups/${activationGroupUuid}/schedules`,
    routePath: '/:activationGroupUuid/schedules',
  },
  ACTIVATION_GROUP_PREQUALIFICATION_PATTERNS: {
    label: 'component.page_header.activation_group',
    navigationPath: (activationGroupUuid: string) =>
      `/activation-groups/${activationGroupUuid}/prequalification-patterns`,
    routePath: '/:activationGroupUuid/prequalification-patterns',
  },
  ACTIVATION_GROUP_PREQUALIFICATION_PATTERN_DETAILS: {
    label: 'component.page_header.activation_group',
    navigationPath: (activationGroupUuid: string, patternUuid: string) =>
      `/activation-groups/${activationGroupUuid}/prequalification-patterns/${patternUuid}`,
    routePath: '/:activationGroupUuid/prequalification-patterns/:patternUuid',
  },
  ACTIVATION_GROUP_SCHEDULE_DETAILS: {
    label: 'component.page_header.activation_group',
    navigationPath: (activationGroupUuid: string, activationGroupScheduleUuid: string) =>
      `/activation-groups/${activationGroupUuid}/schedules/${activationGroupScheduleUuid}`,
    routePath: '/:activationGroupUuid/schedules/:activationGroupScheduleUuid',
  },
  ACTIVATION_GROUP_SCHEDULE_GENERAL: {
    label: 'component.page_header.activation_group',
    navigationPath: (activationGroupUuid: string, activationGroupScheduleUuid: string) =>
      `/activation-groups/${activationGroupUuid}/schedules/${activationGroupScheduleUuid}/general`,
    routePath: '/:activationGroupUuid/schedules/:activationGroupScheduleUuid/general',
  },
  ACTIVATION_GROUP_SCHEDULE_RESOURCES: {
    label: 'component.page_header.activation_group',
    navigationPath: (activationGroupUuid: string, activationGroupScheduleUuid: string) =>
      `/activation-groups/${activationGroupUuid}/schedules/${activationGroupScheduleUuid}/resources`,
    routePath: '/:activationGroupUuid/schedules/:activationGroupScheduleUuid/resources',
  },
} as const
