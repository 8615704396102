import { useMutation } from '@tanstack/react-query'

import { createResourceLevelRestriction } from '@/features/site/endpoints/restrictions'
import type { CreateResourceLevelRestrictionRequest } from '@/features/site/types/restriction'

export type UseCreateResourceLevelRestrictionMutationResult = ReturnType<
  typeof useCreateResourceLevelRestrictionMutation
>

export function useCreateResourceLevelRestrictionMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (variables: { customerUuid: string; restriction: CreateResourceLevelRestrictionRequest }) =>
      createResourceLevelRestriction(variables.customerUuid, variables.restriction),
  })

  return {
    createResourceLevelRestriction: mutateAsync,
    ...mutationResult,
  }
}
