import { FormControl, Stack, Switch } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AvailabilityStatusChip } from '@/features/site/components/availability/datagrid/AvailabilityStatusChip'
import type { ResourceLevelRestrictionForm } from '@/features/site/types/restrictionForm'

export const ResourceLevelRestrictionStatusSection = () => {
  const { t } = useTranslation()
  const { getValues, setValue, control, trigger } = useFormContext<ResourceLevelRestrictionForm>()
  const { enabled, status } = getValues()

  useEffect(() => {
    trigger(['enabled', 'status'])
  }, [enabled, status])

  if (!status) return null

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h6">{t('common.status')}</Typography>
      <Stack alignItems="center" direction="row" spacing={1}>
        <AvailabilityStatusChip status={status} />
        <FormControl>
          <Controller
            control={control}
            name="enabled"
            render={() => (
              <Switch
                checked={enabled}
                onChange={(event) => {
                  setValue('enabled', event.target.checked)
                }}
              />
            )}
          />
        </FormControl>
      </Stack>
    </Stack>
  )
}
