import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useReducer } from 'react'

import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import {
  hasRevenueNWPermissions,
  hasRevenuePermissions,
} from '@/features/authorization/permissionHandlers/revenuePermissions'
import { useSiteQuery } from '@/features/site/hooks/useSiteQuery'
import type { Site } from '@/features/site/types/site'
import type { User } from '@/features/user/types/user'

// All different permissions that can be set in the application.
export type Permission =
  | 'activationGroups'
  | 'bidding'
  | 'disturbances'
  | 'exampleFeatures'
  | 'bessDashboard'
  | 'integrations'
  | 'partners'
  | 'resources'
  | 'site'
  | 'siteDetails'
  | 'siteContactsMutation'
  | 'siteMarketPrograms'
  | 'siteCreation'
  | 'reports'
  | 'revenues'
  | 'revenuesNW'
  | 'powerMeasurements'
  | 'prequalifications'
  | 'spotOnForEboilers'
  | 'users'
  | 'wholesale'

export type PermissionsProviderProps = {
  children: ReactNode
}

export type PermissionHandlerParams = {
  loggedInUser: User
  site: Site | null
}

type PermissionHandler = (params: PermissionHandlerParams) => boolean

type SetPermissionsAction = {
  type: 'SET_PERMISSIONS'
  payload: Set<Permission>
}

type ResetPermissionsAction = {
  type: 'RESET_PERMISSIONS'
}

type SetErrorAction = {
  type: 'SET_ERROR'
  payload: Error
}

type PermissionActions = SetPermissionsAction | ResetPermissionsAction | SetErrorAction

export type State = {
  isLoading: boolean
  error: Error | null
  permissions: Set<Permission>
}

function reducer(state: State, action: PermissionActions): State {
  switch (action.type) {
    case 'SET_PERMISSIONS':
      return { ...state, isLoading: false, permissions: action.payload }
    case 'RESET_PERMISSIONS':
      return { ...state, isLoading: false, permissions: new Set() }
    case 'SET_ERROR':
      return { ...state, isLoading: false, permissions: new Set(), error: action.payload }
    default:
      return state
  }
}

// High order function that checks if the user has one of the allowed roles.
function hasOneOfRoles(allowedRoles: string[]) {
  return ({ loggedInUser }: PermissionHandlerParams): boolean => {
    const userRole = loggedInUser?.role

    if (!userRole) {
      return false
    }

    return allowedRoles.includes(userRole)
  }
}

// Includes all the permissions and their respective handlers. The handlers are used to determine if a user has a specific permission.
// permissionHandlers is run once the application starts in order to define the permissions for the logged in user.
// Once the app is loaded, the permissions are set in the state and can be accessed by the components using the usePermissions hook.
const permissionHandlers: Record<Permission, PermissionHandler> = {
  ['activationGroups']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['bidding']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['disturbances']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['exampleFeatures']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['bessDashboard']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['integrations']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['partners']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['resources']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['site']: hasOneOfRoles([USER_ROLES.RESOURCE_OWNERS.value]),
  ['siteDetails']: hasOneOfRoles([
    USER_ROLES.ADMINISTRATORS.value,
    USER_ROLES.PARTNER_ADMINISTRATORS.value,
    USER_ROLES.CUSTOMER_MANAGERS.value,
  ]),
  ['siteMarketPrograms']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['siteContactsMutation']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value, USER_ROLES.PARTNER_ADMINISTRATORS.value]),
  ['siteCreation']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['reports']: (params) => {
    const hasPartnerCodePermission = params.loggedInUser?.partnerCode === 'GR-OPTIMUS'

    return (
      hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value])(params) ||
      (hasOneOfRoles([USER_ROLES.PARTNER_ADMINISTRATORS.value, USER_ROLES.CUSTOMER_MANAGERS.value])(params) &&
        hasPartnerCodePermission)
    )
  },
  ['revenues']: hasRevenuePermissions,
  ['revenuesNW']: hasRevenueNWPermissions,
  ['powerMeasurements']: (params) => {
    const { site } = params
    const supportedCountries = ['FI', 'SE', 'NO']
    const hasSupportedCountry = supportedCountries.includes(site?.countryCode ?? '')
    return hasOneOfRoles([USER_ROLES.RESOURCE_OWNERS.value])(params) && hasSupportedCountry
  },
  ['prequalifications']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['spotOnForEboilers']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
  ['users']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value, USER_ROLES.PARTNER_ADMINISTRATORS.value]),
  ['wholesale']: hasOneOfRoles([USER_ROLES.ADMINISTRATORS.value]),
}

const initialState: State = {
  isLoading: true,
  permissions: new Set(),
  error: null,
}

const PermissionsContext = createContext<State>(initialState)

export function PermissionsProvider(props: Readonly<PermissionsProviderProps>) {
  const { loggedInUser, isStarting: isAuthLoading, error: authError } = useAuth()
  const firstAllowedRoId = loggedInUser?.allowedRoIds?.[0]
  const hasCustomer = loggedInUser?.role === USER_ROLES.RESOURCE_OWNERS.value && !!firstAllowedRoId
  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    site,
    isLoading: isSiteLoading,
    error: customerError,
  } = useSiteQuery(
    { uuid: firstAllowedRoId },
    {
      enabled: hasCustomer,
    },
  )
  const isPermissionsLoading = isAuthLoading || isSiteLoading

  useEffect(() => {
    // We wait until all async operations are done before setting the permissions
    if (!loggedInUser || isPermissionsLoading) {
      return
    }

    const permissions = new Set<Permission>()

    for (const [permission, handler] of Object.entries(permissionHandlers)) {
      // Adds the permission just when the permission handler returns true.
      if (handler({ loggedInUser, site })) {
        permissions.add(permission as Permission)
      }
    }

    // Setting the permissions also sets the isLoading flag to false. This way we can be sure that the permissions are set and the app is ready to be used.
    dispatch({ type: 'SET_PERMISSIONS', payload: permissions })

    return () => {
      dispatch({ type: 'RESET_PERMISSIONS' })
    }
  }, [isPermissionsLoading, loggedInUser, site])

  useEffect(() => {
    const error = customerError || authError

    if (error) {
      dispatch({ type: 'SET_ERROR', payload: error })
    }
  }, [customerError, authError])

  return <PermissionsContext.Provider value={state}>{props.children}</PermissionsContext.Provider>
}

export function usePermissions() {
  return useContext(PermissionsContext)
}
