import { FormHelperText, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type { WeekdayStr } from 'rrule'
import { ALL_WEEKDAYS } from 'rrule/dist/esm/weekday'

import type { ServiceRuleForm } from '@/features/site/types/serviceRuleForm'

export const ServiceRuleRecurrenceSection = () => {
  const { t } = useTranslation()
  const { getValues, setValue, formState, trigger } = useFormContext<ServiceRuleForm>()
  const { recurringDays } = getValues()

  return (
    <>
      <Typography variant="h6">{t('customer_details.tabs.availability.availability_details.recurrence')}</Typography>
      <Stack>
        <ToggleButtonGroup
          aria-label={'recurrence button group'}
          color="primary"
          size="small"
          sx={{ width: '100%' }}
          value={recurringDays}
          onChange={(event, value) => {
            setValue('recurringDays', value)
            trigger('recurringDays')
          }}
        >
          {ALL_WEEKDAYS.map((day: WeekdayStr) => (
            <ToggleButton key={day} value={day}>
              {t(`common.days.${day}.long`)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <FormHelperText error>{formState.errors.recurringDays?.message}</FormHelperText>
      </Stack>
    </>
  )
}
