import { ajvResolver } from '@hookform/resolvers/ajv'
import { AddLink, RemoveCircleOutline } from '@mui/icons-material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Alert, Box, CardHeader, Stack, Table, TableBody, TableCell, TableRow, Tooltip } from '@mui/material'
import type { GridRowId, GridRowParams } from '@mui/x-data-grid'
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro'
import { useQueryClient } from '@tanstack/react-query'
import type { JSONSchemaType } from 'ajv'
import type { JSONValue } from 'jsonc-eslint-parser/lib/utils/ast'
import { useEffect, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import DataGridDetailPanelToggle from '@/components/dataDisplay/DataGridDetailPanelToggle'
import CheckboxController from '@/components/inputs/CheckboxController'
import CustomButton from '@/components/inputs/CustomButton'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import SelectFieldController from '@/components/inputs/SelectFieldController'
import TextFieldController from '@/components/inputs/TextFieldController'
import { COLUMN_WITH_SMALL_ICON_WIDTH } from '@/constants/layout'
import { useAlertContext } from '@/contexts/AlertContext'
import { StyledCustomDataGrid } from '@/features/resource/components/steering/StyledCustomDataGrid'
import { useResourceData } from '@/features/resource/contexts/ResourceDataContext'
import { GET_RESOURCE_API_ID } from '@/features/resource/endpoints/resources'
import { useCustomAttributeSchemasQuery } from '@/features/resource/hooks/useCustomAttributeSchemasQuery'
import { useLinkCustomAttributeSchemaToResourceMutation } from '@/features/resource/hooks/useLinkCustomAttributeSchemaToResourceMutation'
import { useUnlinkCustomAttributeSchemaFromResourceMutation } from '@/features/resource/hooks/useUnlinkCustomAttributeSchemaFromResourceMutation'
import { useUpdateCustomAttributeMutation } from '@/features/resource/hooks/useUpdateCustomAttributeMutation'
import type {
  AttributeDefinition,
  AttributeValueType,
  CustomAttributeSchema,
  Resource,
} from '@/features/resource/types'
import { errorHandler } from '@/utils/errorHandler'

export interface CustomAttributesFormProps {
  resourceID: Resource['resourceID']
  customAttributeSchema: CustomAttributeSchema
  customAttributeValuesForNamespace?: Record<string, AttributeValueType>
  onSave: () => void
}

export interface JsonSchemaInputFieldProps {
  attributeDefinition: AttributeDefinition
}

export interface LinkSchemaForm {
  namespace: string
}

// The json schema should have properties with one entry with the namespace and nested properties inside, which are needed to perform the validation.
// If the JSON schema is not valid, no validation will be performed and form will be always valid.
const getSchemaForValidation = (jsonSchema: string) => {
  try {
    const parsedSchema = JSON.parse(jsonSchema)
    if (!parsedSchema?.properties) {
      return {} as JSONSchemaType<JSONValue>
    }
    const propertyKey = Object.keys(parsedSchema.properties)[0]

    return parsedSchema.properties[propertyKey] ?? ({} as JSONSchemaType<JSONValue>)
  } catch {
    console.error('Error parsing the form json schema for Custom Attributes validation')
    return {} as JSONSchemaType<JSONValue>
  }
}

const JsonSchemaInputField = ({ attributeDefinition: { name, type } }: JsonSchemaInputFieldProps) => {
  const form = useFormContext()

  if (type === 'boolean') {
    return <CheckboxController name={name} />
  }

  if (type === 'number' || type === 'integer') {
    return (
      <TextFieldController
        id={name}
        name={name}
        type="number"
        onChange={(event) => form.setValue(name, Number(event.target.value))}
      />
    )
  }

  return <TextFieldController id={name} name={name} type={type} />
}

const CustomAttributesForm = ({
  resourceID,
  customAttributeValuesForNamespace,
  customAttributeSchema,
  onSave,
}: CustomAttributesFormProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { pushAlert } = useAlertContext()

  const form = useForm({
    resolver: ajvResolver(getSchemaForValidation(customAttributeSchema.jsonSchema)),
    defaultValues: customAttributeValuesForNamespace,
  })
  const { updateCustomAttribute } = useUpdateCustomAttributeMutation(resourceID, customAttributeSchema)

  const onCancel = () => {
    form.reset()
  }

  const onSubmit = async () => {
    try {
      await updateCustomAttribute(form.getValues()).then(onSave)
      await queryClient.invalidateQueries({ queryKey: [GET_RESOURCE_API_ID, { id: resourceID }] })
    } catch (err) {
      const error = errorHandler(err, t('resources.resource_custom_attributes.form.update_error'))

      pushAlert({ message: error.message, severity: 'error' })
    }
  }

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <Box
            sx={{
              overflowX: 'auto',
            }}
          >
            <Table aria-label={`${t('resources.resource_custom_attributes.title')}_${customAttributeSchema.namespace}`}>
              <TableBody>
                {customAttributeSchema.attributeDefinitions.map((attributeDefinition: AttributeDefinition, index) => (
                  <TableRow key={attributeDefinition.name}>
                    <TableCell key={attributeDefinition.name}>
                      <Stack alignItems="center" direction="row" spacing={1}>
                        <CustomTypography variant="normal">{attributeDefinition.title}</CustomTypography>
                        <Tooltip
                          arrow
                          placement="right-start"
                          title={<span>{`${attributeDefinition.description} (${attributeDefinition.name})`}</span>}
                        >
                          <InfoOutlinedIcon fontSize="small" sx={{ color: 'black' }} />
                        </Tooltip>
                      </Stack>
                    </TableCell>
                    <TableCell key={index} sx={{ cursor: 'pointer' }} title={attributeDefinition.title}>
                      <JsonSchemaInputField attributeDefinition={attributeDefinition} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <Stack direction="row" justifyContent="flex-end" padding={2} spacing={1}>
            <CustomButton type="submit" variant="contained">
              {t('common.button.save')}
            </CustomButton>
            <CustomButton variant="outlined" onClick={onCancel}>
              {t('common.button.cancel')}
            </CustomButton>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  )
}

const ResourceCustomAttributesCard = () => {
  const { resource, customAttributeData } = useResourceData()
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()
  const queryClient = useQueryClient()
  const [expandedRows, setExpandedRows] = useState<GridRowId[]>([])
  const { customAttributeSchemas } = useCustomAttributeSchemasQuery()
  const [localResourceSchemas, setLocalResourceSchemas] = useState(customAttributeData?.schemas || [])
  const form = useForm<LinkSchemaForm>()
  const { linkSchemaToResource } = useLinkCustomAttributeSchemaToResourceMutation(resource?.resourceID ?? '')
  const { unlinkSchemaFromResource } = useUnlinkCustomAttributeSchemaFromResourceMutation(resource?.resourceID ?? '')

  useEffect(() => {
    setLocalResourceSchemas(customAttributeData?.schemas || [])
  }, [customAttributeData])

  if (!customAttributeData || !resource) {
    return null
  }

  const onLink = async () => {
    const newCustomAttrSchemaLocal = customAttributeSchemas?.find(
      (schema) => schema.namespace === form.getValues().namespace,
    ) ?? {
      namespace: form.getValues().namespace,
      title: '',
      description: '',
      owner: '',
      attributeDefinitions: [],
      jsonSchema: '',
    }
    try {
      if (form.getValues().namespace) {
        await linkSchemaToResource(form.getValues().namespace)
        await queryClient.invalidateQueries({ queryKey: [GET_RESOURCE_API_ID, { id: resource.resourceID }] })
        setLocalResourceSchemas((prev) => [...prev, newCustomAttrSchemaLocal])
        form.reset()
      }
    } catch (err) {
      const error = errorHandler(err, t('resources.resource_custom_attributes.actions.link.error'))
      pushAlert({ message: error.message, severity: 'error' })
    }
  }

  const onUnLink = async (params) => {
    try {
      await unlinkSchemaFromResource(params.id)
      await queryClient.invalidateQueries({ queryKey: [GET_RESOURCE_API_ID, { id: resource.resourceID }] })
      setLocalResourceSchemas(localResourceSchemas.filter((schema) => schema.namespace !== params.id))
    } catch (err) {
      const error = errorHandler(err, t('resources.resource_custom_attributes.actions.unlink.error'))
      pushAlert({ message: error.message, severity: 'error' })
    }
  }

  const columns = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => <DataGridDetailPanelToggle params={params} />,
      sortable: false,
      flex: 0.4,
    },
    {
      field: 'title',
      headerName: t('resources.resource_custom_attributes.table.title'),
      flex: 2,
      renderCell: (params) => params.title,
    },
    {
      field: 'namespace',
      headerName: t('resources.resource_custom_attributes.table.namespace'),
      flex: 1,
      renderCell: (params) => params.namespace,
    },
    {
      field: 'owner',
      headerName: t('resources.resource_custom_attributes.table.owner'),
      flex: 1,
      renderCell: (params) => params.owner,
    },
    {
      field: 'description',
      headerName: t('resources.resource_custom_attributes.table.description'),
      flex: 2,
      renderCell: (params) => params.description,
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      width: COLUMN_WITH_SMALL_ICON_WIDTH,
      renderCell: (params) => (
        <CustomIconButton
          Icon={RemoveCircleOutline}
          aria-label={t('resources.resource_custom_attributes.actions.unlink.label')}
          onClick={(e) => {
            e.preventDefault()
            onUnLink(params)
          }}
        />
      ),
    },
  ]

  return (
    <Stack>
      <CardHeader
        title={
          <Stack alignItems="center" direction="row" spacing={1}>
            <CustomTypography variant="h6">{t('resources.resource_custom_attributes.title')}</CustomTypography>
            <Tooltip
              arrow
              placement="right-start"
              title={<span>{t('resources.resource_custom_attributes.tooltip')}</span>}
            >
              <HelpOutlineIcon fontSize="small" sx={{ color: 'black' }} />
            </Tooltip>
          </Stack>
        }
      />
      {localResourceSchemas.length > 0 ? (
        <StyledCustomDataGrid
          hideFooter
          hideFooterPagination
          hideFooterRowCount
          columns={columns}
          detailPanelExpandedRowIds={expandedRows}
          getDetailPanelContent={({ row }: GridRowParams<CustomAttributeSchema>) => (
            <CustomAttributesForm
              customAttributeSchema={row}
              customAttributeValuesForNamespace={customAttributeData.values.get(row.namespace)}
              resourceID={resource.resourceID}
              onSave={() => setExpandedRows(expandedRows.filter((id) => id !== row.namespace))}
            />
          )}
          getDetailPanelHeight={() => 'auto'}
          getRowId={(row) => row.namespace}
          includeWrapper={false}
          rows={localResourceSchemas}
          onDetailPanelExpandedRowIdsChange={(newIds) => setExpandedRows(newIds)}
        />
      ) : (
        <Alert severity="info">{t('resources.resource_custom_attributes.info_empty')}</Alert>
      )}
      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(onLink)}>
          <Stack direction="row" justifyContent="flex-start" padding={2} spacing={2}>
            <Box flexBasis="25%" flexGrow={1}>
              <SelectFieldController
                id="namespace"
                label={t('resources.resource_custom_attributes.actions.link.select')}
                name="namespace"
                options={
                  customAttributeSchemas
                    ?.filter((schema) => !localResourceSchemas.some((local) => local.namespace === schema.namespace))
                    .map((schema: CustomAttributeSchema) => ({
                      value: schema.namespace,
                      label: schema.title,
                      id: schema.namespace,
                    })) ?? []
                }
              />
            </Box>
            <Box flexBasis="25%" flexGrow={1}>
              <CustomButton startIcon={<AddLink />} variant="contained" onClick={onLink}>
                {t('resources.resource_custom_attributes.actions.link.button')}
              </CustomButton>
            </Box>
          </Stack>
        </form>
      </FormProvider>
    </Stack>
  )
}

export default ResourceCustomAttributesCard
