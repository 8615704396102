import { useTranslation } from 'react-i18next'

import Page from '@/components/layouts/Page'
import { SiteActivationsView } from '@/features/activation/components/SiteActivationsView'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useSiteQuery } from '@/features/site/hooks/useSiteQuery'

export const SiteActivations = () => {
  const { t } = useTranslation()
  const { loggedInUser } = useAuth()

  const siteUuid = loggedInUser?.allowedRoIds?.[0] ?? ''

  const { site, isFetching: isFetchingSite } = useSiteQuery({ uuid: siteUuid }, { enabled: Boolean(siteUuid) })

  if (!site || isFetchingSite) return null

  return (
    <Page
      pageHeaderProps={{
        pageTitle: t('component.page_header.activations'),
        pageTag: 'customer-activations',
      }}
    >
      <SiteActivationsView activationDetailsPath={'activations/:activationId'} site={site} />
    </Page>
  )
}
