import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import { useAlertContext } from '@/contexts/AlertContext'
import ResourceCard from '@/features/resource/components/ResourceCard'
import { ManualSteeringForm } from '@/features/resource/components/steering/ManualSteeringForm'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useResourceData } from '@/features/resource/contexts/ResourceDataContext'
import type { ValidationErrorResponse } from '@/features/resource/endpoints/resources'
import { updateBatterySoC } from '@/features/resource/endpoints/resources'
import { useBatteryInfoWithPollingQuery } from '@/features/resource/hooks/useBatteryInfoWithPollingQuery'
import { useResourceDetails } from '@/features/resource/pages/ResourceDetailsPage'
import { convertEnergyPower } from '@/utils/powerEnergyTransformations'

const StyledResourceDetailRow = styled(TableRow)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

const StyledResourceDetailHeaderRow = styled(TableRow)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

const BatterySteeringPage = () => {
  const { resourceUuid, controlPortId } = useParams()
  const { pushAlert } = useAlertContext()
  const { setActivePage } = useResourceDetails()
  const { t } = useTranslation()

  const [desiredSoC, setDesiredSoC] = useState<string>('')
  const [isSteeringActive, setIsSteeringActive] = useState(false)
  const [steeringType, setSteeringType] = useState<'SOC' | 'POWER' | null>(null)

  const { batteryInfo } = useBatteryInfoWithPollingQuery({ controlPortId: controlPortId! })
  const { steeringConfig } = useResourceData()

  useEffect(() => {
    setActivePage(ResourceRouteInformation.RESOURCE_BATTERY_STEERING.routePath)
  }, [setActivePage])

  const handleSteeringTypeSelection = (type: 'SOC' | 'POWER') => {
    setSteeringType(type)
    setIsSteeringActive(true)
  }

  const handleSteeringComplete = () => {
    setIsSteeringActive(false)
    setSteeringType(null)
  }

  const handleSoCUpdate = async () => {
    const socValue = parseFloat(desiredSoC)
    if (socValue >= 0 && socValue <= 1) {
      try {
        await updateBatterySoC(resourceUuid!, socValue)
        pushAlert({
          message: t('resources.battery.success_update_soc'),
          severity: 'success',
        })
        handleSteeringComplete()
        setDesiredSoC('')
      } catch (error) {
        console.error('Failed to update battery SOC', error)

        if (
          axios.isAxiosError(error) &&
          error.response?.status === 400 &&
          error.response.data?.status === 'VALIDATION_FAILED'
        ) {
          const validationError = error.response.data as ValidationErrorResponse
          pushAlert({
            message: validationError.message,
            severity: 'error',
          })
        } else {
          pushAlert({
            message: t('resources.battery.errors_update_soc'),
            severity: 'error',
          })
        }
      }
    } else {
      pushAlert({
        message: t('resources.battery.invalid_soc_value'),
        severity: 'error',
      })
    }
  }

  const columns = useMemo(
    () => [
      t('resources.battery.state_of_charge'),
      t('resources.battery.state_of_health'),
      t('resources.battery.rated_energy'),
      t('resources.battery.energy_to_full_charge'),
      t('resources.battery.max_power_from_grid'),
      t('resources.battery.max_power_to_grid'),
    ],
    [t],
  )

  if (!batteryInfo) return null

  const isInvalidSoC = desiredSoC === '' || parseFloat(desiredSoC) < 0 || parseFloat(desiredSoC) > 1

  const batteryContent = (
    <>
      <CardHeader sx={{ paddingX: 0 }} title={t('resources.battery.information')} />
      <Table aria-label={t('resources.battery.information')} size="small">
        <TableHead>
          <StyledResourceDetailHeaderRow>
            {columns.map((column) => (
              <TableCell key={column}>
                <CustomTypography color="grey.500" variant="caption">
                  {column}
                </CustomTypography>
              </TableCell>
            ))}
          </StyledResourceDetailHeaderRow>
        </TableHead>
        <TableBody>
          <StyledResourceDetailRow>
            <TableCell>{`${batteryInfo.stateOfCharge}`}</TableCell>
            <TableCell>{`${batteryInfo.stateOfHealth}`}</TableCell>
            <TableCell>{`${convertEnergyPower(batteryInfo.ratedEnergy, 'kilowatts')}`}</TableCell>
            <TableCell>{`${convertEnergyPower(batteryInfo.energyToFullCharge, 'kilowatts')}`}</TableCell>
            <TableCell>{`${convertEnergyPower(batteryInfo.maxUsablePower, 'kilowatts')}`}</TableCell>
            <TableCell>{`${convertEnergyPower(batteryInfo.minUsablePower, 'kilowatts')}`}</TableCell>
          </StyledResourceDetailRow>
        </TableBody>
      </Table>
      <Box mt={4}>
        {!isSteeringActive ? (
          <Box>
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={() => handleSteeringTypeSelection('SOC')}>
                {t('resources.battery.start_soc_steering')}
              </Button>
              <Button variant="outlined" onClick={() => handleSteeringTypeSelection('POWER')}>
                {t('resources.battery.start_manual_steering')}
              </Button>
            </Stack>
          </Box>
        ) : (
          <>
            {steeringType === 'SOC' && (
              <Card sx={{ bgcolor: 'rgba(20, 132, 160, 0.04)' }}>
                <CardHeader title={t('resources.battery.set_soc')} />
                <CardContent>
                  <Stack direction="row">
                    <Box flexBasis="25%">
                      <TextField
                        fullWidth
                        error={isInvalidSoC}
                        inputProps={{
                          step: '0.01',
                          min: 0,
                          max: 1,
                        }}
                        placeholder={t('resources.battery.enter_desired_soc')}
                        type="number"
                        value={desiredSoC}
                        onChange={(e) => setDesiredSoC(e.target.value)}
                      />
                    </Box>
                  </Stack>
                  <Stack direction="row" mt={2} spacing={2}>
                    <Button disabled={isInvalidSoC} variant="contained" onClick={handleSoCUpdate}>
                      {t('resources.battery.steer_to_soc')}
                    </Button>
                    <Button variant="outlined" onClick={handleSteeringComplete}>
                      {t('common.button.cancel')}
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            )}
            {steeringType === 'POWER' && (
              <ManualSteeringForm
                isReleaseControlSupported={steeringConfig?.isReleaseControlSupported ?? false}
                resourceId={resourceUuid!}
                steeringDefaultNotDefined={steeringConfig?.steeringDefaultType === 'NONE'}
                onSteeringComplete={handleSteeringComplete}
              />
            )}
          </>
        )}
      </Box>
    </>
  )

  return <ResourceCard content={batteryContent} />
}

export default BatterySteeringPage
