import { Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import ActivationGroupCard from '@/features/activationGroup/components/ActivationGroupCard'
import ActivationGroupResourcesDataGrid from '@/features/activationGroup/components/ActivationGroupResourcesDataGrid'
import { ActivationGroupRouteInformation } from '@/features/activationGroup/constants'
import { useActivationGroupDetails } from '@/features/activationGroup/pages/ActivationGroupDetails'

function ActivationGroupResources() {
  const { t } = useTranslation()
  const { activationGroupUuid } = useParams()

  const { setPageConfig } = useActivationGroupDetails()

  useEffect(() => {
    setPageConfig({
      title: t('activation_groups.tabs.resources'),
      activeTab: ActivationGroupRouteInformation.ACTIVATION_GROUP_RESOURCES.routePath,
    })
  }, [])

  if (!activationGroupUuid) {
    return null
  }

  return (
    <Stack direction="column" marginY={3} spacing={3}>
      <ActivationGroupCard />
      <ActivationGroupResourcesDataGrid activationGroupUuid={activationGroupUuid} />
    </Stack>
  )
}

export default ActivationGroupResources
