import type { MarketProgramType } from '@/types/marketProgramType'

export const MARKET_PROGRAMS: MarketProgramType[] = [
  'afrr-down',
  'afrr-up',
  'fcrd-down',
  'fcrd-up',
  'fcrn',
  'ffr-up',
  'load-cur',
  'max-price',
  'mfrr-down',
  'mfrr-up',
  'mfrrda-down',
  'mfrrda-up',
  'mfrr-rr-down',
  'mfrr-rr-up',
  'nordics-fcrd-down-dynamic',
  'nordics-fcrd-down-static',
  'nordics-fcrd-up-dynamic',
  'nordics-fcrd-up-static',
  'nordics-fcrn',
  'fcr-d-down-dynamic',
  'fcr-d-up-dynamic',
  'fcr-d-up-static',
  'fcr-n',
]

export const MARKET_PROGRAMS_COLOR_PALETTE = ['#6D8AB4', '#A7D2E2', '#E9D4A4', '#E5ADAA', '#84a97f']
