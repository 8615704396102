import axios from 'axios'

import environment from '@/environment'
import type { AmActivatedResource } from '@/features/activation/types/activatedResource'
import type { AmActivation } from '@/features/activation/types/activation'

const ACTIVATION_MANAGER_API_URL = `${environment.services.activationManagerApiUrl}`

export const GET_AM_ACTIVATIONS_API_ID = 'GET_AM_ACTIVATIONS'
export const GET_AM_ACTIVATION_API_ID = 'GET_AM_ACTIVATION'
export const GET_AM_ACTIVATED_RESOURCES_API_ID = 'GET_AM_ACTIVATED_RESOURCES'

export async function getActivations(siteId: string | undefined): Promise<AmActivation[]> {
  const response = await axios.get(`${ACTIVATION_MANAGER_API_URL}`, {
    params: {
      siteId,
    },
  })
  const activations = response.data

  if (!activations || activations.length === 0) {
    return []
  }

  return activations
}

export async function getActivation(activationId: string): Promise<AmActivation> {
  const response = await axios.get(`${ACTIVATION_MANAGER_API_URL}/${activationId}`, {})
  return response.data
}

export async function getActivatedResources(siteId?: string, activationId?: string): Promise<AmActivatedResource[]> {
  const response = await axios.get<AmActivatedResource[]>(`${ACTIVATION_MANAGER_API_URL}/${activationId}/resources`, {
    params: {
      siteId,
    },
  })
  const activatedResources = response.data

  if (!activatedResources || activatedResources.length === 0) {
    return []
  }

  return activatedResources.map((resource, index) => ({
    id: index,
    ...resource,
  }))
}
