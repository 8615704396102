import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Stack } from '@mui/material'
import type { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { DEFAULT_DATA_GRID_FILTER_MODEL, DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import ActivationGroupResourcesDataGridToolbar from '@/features/activationGroup/components/ActivationGroupResourcesDataGridToolbar'
import AssignResourceDialog from '@/features/activationGroup/components/AssignResourceDialog'
import EditResourceDialog from '@/features/activationGroup/components/EditResourceDialog'
import UnassignResourceConfirmationButton from '@/features/activationGroup/components/UnassignResourceConfirmationButton'
import { useActivationGroupResourcesQuery } from '@/features/activationGroup/hooks/useActivationGroupResourcesQuery'
import { useActivationGroupDetails } from '@/features/activationGroup/pages/ActivationGroupDetails'
import type { ApiActivationGroupResource } from '@/features/activationGroup/types'
import LifecycleStageChip from '@/features/resource/components/LifecycleStageChip'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { convertEnergyPower } from '@/utils/powerEnergyTransformations'

type ActivationGroupResourcesDataGridProps = {
  activationGroupUuid: string
}

type ActivationGroupResourceRenderCell = GridRenderCellParams<ApiActivationGroupResource>

const ActivationGroupResourcesDataGrid = ({ activationGroupUuid }: ActivationGroupResourcesDataGridProps) => {
  const { t } = useTranslation()
  const { resources, isLoading } = useActivationGroupResourcesQuery({ activationGroupUuid })
  const [openAssignResourceDialog, setOpenAssignResourceDialog] = useState(false)
  const [openEditResourceDialog, setOpenEditResourceDialog] = useState(false)
  const [selectedActivationGroupResource, setSelectedActivationGroupResource] =
    useState<ApiActivationGroupResource | null>(null)
  const { activationGroup } = useActivationGroupDetails()

  const columns: GridColDef<ApiActivationGroupResource>[] = [
    {
      field: 'uuid',
      headerName: t('common.uuid'),
      flex: 2,
    },
    {
      field: 'name',
      headerName: t('common.name'),
      flex: 1,
    },
    {
      field: 'verifiedCapacity',
      headerName: t('activation_groups.resources.table.header.verified_capacity'),
      flex: 1,
      valueGetter: (value: ApiActivationGroupResource['verifiedCapacity']) => convertEnergyPower(value, 'kilowatts'),
    },
    {
      field: 'qualifiedCapacity',
      headerName: t('activation_groups.resources.table.header.qualified_capacity'),
      flex: 1,
      valueGetter: (value: ApiActivationGroupResource['qualifiedCapacity']) =>
        value != null ? convertEnergyPower(value, 'kilowatts') : '-',
    },
    {
      field: 'activationPriority',
      headerName: t('activation_groups.resources.table.header.activation_priority'),
      flex: 1,
    },
    {
      field: 'lifecycleStage',
      headerName: t('activation_groups.resources.table.header.lifecycle_stage'),
      flex: 1,
      renderCell: ({ row: { lifecycleStage } }: ActivationGroupResourceRenderCell) => (
        <LifecycleStageChip lifecycleStage={lifecycleStage} />
      ),
    },
    {
      field: 'isUsedForMinCapacityPrequalification',
      headerName: t('activation_groups.resources.table.header.is_used_for_min_capacity_prequalification'),
      flex: 1,
      renderCell: ({ row: { isUsedForMinCapacityPrequalification } }: ActivationGroupResourceRenderCell) => (
        <Stack justifyContent="center" sx={{ height: 1 }}>
          {isUsedForMinCapacityPrequalification ? (
            <CheckIcon
              color="success"
              titleAccess={t('activation_groups.resources.table.is_used_for_min_capacity_prequalification')}
            />
          ) : (
            <CloseIcon
              color="error"
              titleAccess={t('activation_groups.resources.table.is_not_used_for_min_capacity_prequalification')}
            />
          )}
        </Stack>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      renderCell: ({ row }: ActivationGroupResourceRenderCell) => (
        <Stack alignItems="center" flexDirection="row" gap={1} sx={{ height: '100%' }}>
          <CustomIconButton
            Icon={EditOutlinedIcon}
            aria-label="edit"
            onClick={(e) => {
              e.preventDefault()
              handleOpenEditResourceDialog(row)
            }}
          />
          <UnassignResourceConfirmationButton
            key={row.uuid}
            activationGroup={activationGroup}
            activationGroupResources={resources ?? []}
            unassignedResource={row}
          />
        </Stack>
      ),
    },
  ]

  function handleOpenAssignResourceDialog() {
    setOpenAssignResourceDialog(true)
  }

  function handleCloseAssignResourceDialog() {
    setOpenAssignResourceDialog(false)
  }

  function handleOpenEditResourceDialog(activationGroupResource: ApiActivationGroupResource) {
    setSelectedActivationGroupResource(activationGroupResource)
    setOpenEditResourceDialog(true)
  }

  function handleCloseEditResourceDialog() {
    setOpenEditResourceDialog(false)
  }

  return (
    <>
      <CustomDataGrid
        disableColumnMenu
        disableColumnSorting
        aria-label={t('activation_groups.resources.table.title')}
        clickableRows={{
          navigateTo: ({ row }: GridRowParams<ApiActivationGroupResource>) =>
            ResourceRouteInformation.RESOURCE_DETAILS.navigationPath(row.uuid),
        }}
        columns={columns}
        getRowId={(row) => row.uuid}
        initialState={{
          filter: {
            filterModel: DEFAULT_DATA_GRID_FILTER_MODEL,
          },
          pagination: {
            paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
          },
          sorting: {
            sortModel: [],
          },
        }}
        isLoading={isLoading}
        rows={resources ?? []}
        slotProps={{ toolbar: { onAssignResourceToActivationGroup: handleOpenAssignResourceDialog } }}
        slots={{ toolbar: ActivationGroupResourcesDataGridToolbar }}
      />

      <AssignResourceDialog
        activationGroup={activationGroup}
        open={openAssignResourceDialog}
        resources={resources ?? []}
        onClose={handleCloseAssignResourceDialog}
      />

      {selectedActivationGroupResource && (
        <EditResourceDialog
          activationGroup={activationGroup}
          activationGroupResource={selectedActivationGroupResource}
          open={openEditResourceDialog}
          resources={resources ?? []}
          onClose={handleCloseEditResourceDialog}
        />
      )}
    </>
  )
}

export default ActivationGroupResourcesDataGrid
