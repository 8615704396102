export type Month =
  | 'january'
  | 'february'
  | 'march'
  | 'april'
  | 'may'
  | 'june'
  | 'july'
  | 'august'
  | 'september'
  | 'october'
  | 'november'
  | 'december'

export const MONTHS: Record<number, Month> = {
  1: 'january',
  2: 'february',
  3: 'march',
  4: 'april',
  5: 'may',
  6: 'june',
  7: 'july',
  8: 'august',
  9: 'september',
  10: 'october',
  11: 'november',
  12: 'december',
}

export const SITE_STEPS_FIELD_WIDTH = '350px'
export const SITE_REVIEW_BLOCK_MAX_WIDTH = '250px'
export const SITE_REVIEW_TABLE_CELL_STYLES = {
  paddingX: 0,
  width: SITE_REVIEW_BLOCK_MAX_WIDTH,
  maxWidth: SITE_REVIEW_BLOCK_MAX_WIDTH,
}
export const SITE_MARKET_PROGRAMS_QUERY_STALE_TIME = 10 * 60 * 1000 // 10 minutes

export const RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_CUSTOMERS_TOGGLE_NAME =
  'RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_CUSTOMERS'

export const RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_COUNTRIES_TOGGLE_NAME =
  'RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_COUNTRIES'

// TOOD: Take a look if this is still necessary when removing the feature flag calld FLEX_PORTAL_NEW_REVENUE_PAGE
export const NW_MARKET_PROGRAMS = ['FCR_D_UP_DYNAMIC', 'FCR_D_DOWN_DYNAMIC', 'FCR_D_UP_STATIC', 'FCR_N']
