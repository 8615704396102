import { Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

import ProfileMenu from '@/components/dataDisplay/ProfileMenu'
import CustomAppBar from '@/components/layouts/CustomAppBar'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import type { User } from '@/features/user/types/user'

type AppHeaderProps = {
  drawerWidth: number
}

function AppHeader({ drawerWidth }: Readonly<AppHeaderProps>) {
  const { logout, loggedInUser, isLogged } = useAuth()
  const navigate = useNavigate()
  const theme = useTheme()

  const handleProfileClick = () => {
    navigate('/profile')
  }

  const handlePrivacyPolicyClick = () => {
    navigate('/privacy-policy')
  }

  function handleLogout() {
    logout()
  }

  return (
    <CustomAppBar
      sx={{
        backgroundColor: theme.palette.background.default,
        boxShadow: 'none',
        zIndex: theme.zIndex.appBar,
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }}
      toolbarProps={{
        sx: {
          justifyContent: 'flex-end',
        },
      }}
    >
      {isLogged && (
        <Stack sx={{ flexDirection: 'row', gap: 2 }}>
          <ProfileMenu
            user={loggedInUser as User}
            onLogout={handleLogout}
            onNavigateToPrivacyPolicy={handlePrivacyPolicyClick}
            onNavigateToSettings={handleProfileClick}
          />
        </Stack>
      )}
    </CustomAppBar>
  )
}

export default AppHeader
