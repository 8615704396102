import { Box, CircularProgress, Stack } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppBarContent } from '@/components/layouts/Page'
import { useAlertContext } from '@/contexts/AlertContext'
import { SiteRevenueCharts } from '@/features/site/components/revenue/SiteRevenueCharts'
import { SiteRevenueDataGrid } from '@/features/site/components/revenue/SiteRevenueDataGrid'
import { SiteRevenueWidgets } from '@/features/site/components/revenue/SiteRevenueWidgets'
import { useExportRevenue } from '@/features/site/hooks/useExportRevenue'
import { useInsightRevenuesByMarketProgramsQuery } from '@/features/site/hooks/useInsightRevenuesByMarketProgramQuery'
import { useRevenueFilters } from '@/features/site/hooks/useRevenueFilters'
import type { InsightRevenuePeriod } from '@/features/site/types/revenue'
import type { Site } from '@/features/site/types/site'
import { getLimitRevenueDateTimeRange } from '@/features/site/utils/revenueUtils/getLimitRevenueDateTimeRange'
import { errorHandler } from '@/utils/errorHandler'

import { SiteDetailsRevenueNWAppBar } from './SiteDetailsRevenueNWAppBar'
import { SiteRevenueTimezoneInfo } from './SiteRevenueTimezoneInfo'

type SiteRevenueViewForNWProps = {
  site: Site
  revenuePeriods: InsightRevenuePeriod[]
}

export function SiteRevenueViewForNW({ site, revenuePeriods }: Readonly<SiteRevenueViewForNWProps>) {
  const { t } = useTranslation()
  const { setAppBarContent } = useAppBarContent()
  const { pushAlert } = useAlertContext()
  const timezone = site.timezone ?? ''

  const limitDateTimeRange = getLimitRevenueDateTimeRange(timezone, revenuePeriods)
  const revenueFilters = useRevenueFilters({ limitDateTimeRange })
  const [state] = revenueFilters
  const { revenues, isLoading, error, isFetched } = useInsightRevenuesByMarketProgramsQuery({
    marketPrograms: state.marketPrograms,
    startTime: state.timeRange.startTime,
    endTime: state.timeRange.endTime,
    timezone,
    siteId: site.uuid,
  })
  const exportCallback = useExportRevenue()

  function handleExport() {
    exportCallback(revenues, site.name, {
      startDate: state.timeRange.startTime,
      endDate: state.timeRange.endTime,
    })
  }

  useEffect(() => {
    setAppBarContent(
      <SiteDetailsRevenueNWAppBar
        limitDateTimeRange={limitDateTimeRange}
        revenueFilters={revenueFilters}
        siteUuid={site.uuid}
        timezone={site.timezone ?? ''}
        onExport={handleExport}
      />,
    )
  }, [
    state.marketPrograms,
    state.timeRange,
    limitDateTimeRange.endTime.toString(),
    limitDateTimeRange.startTime.toString(),
    isFetched,
  ])

  useEffect(() => {
    if (error) {
      const err = errorHandler(error, t('customer_revenue.revenues_request_error'))

      pushAlert({
        message: err.message,
        severity: 'error',
      })
    }
  }, [error])

  if (isLoading) {
    return (
      <Stack sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
        <CircularProgress title={t('common.loading')} />
      </Stack>
    )
  }

  return (
    <Box paddingY={5}>
      <Stack spacing={3}>
        <SiteRevenueWidgets revenues={revenues} />

        <SiteRevenueTimezoneInfo timezone={timezone} />

        <SiteRevenueCharts revenues={revenues} />

        <SiteRevenueDataGrid revenues={revenues} />
      </Stack>
    </Box>
  )
}
