import { zodResolver } from '@hookform/resolvers/zod'
import { DialogContent } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import FormDialogActions from '@/components/feedback/FormDialogActions'
import CheckboxController from '@/components/inputs/CheckboxController'
import SelectFieldController from '@/components/inputs/SelectFieldController'
import { useActivationGroupQuery } from '@/features/activationGroup/hooks/useActivationGroupQuery'
import { getGenericStartPrequalificationSchema } from '@/features/activationGroup/schemas'
import type { GenericStartPrequalification } from '@/features/activationGroup/types'

type GenericStartPrequalificationDialogFormProps = {
  patternUuid: string
  isLoading: boolean
  onCancel: () => void
  onSubmit: (data: GenericStartPrequalification) => void
}

/**
 * Contains all the logic related to the form to start a new prequalification.
 *
 * It is used within the StartPrequalificationForm component.
 */
const GenericStartPrequalificationDialogForm = ({
  patternUuid,
  isLoading,
  onCancel,
  onSubmit,
}: GenericStartPrequalificationDialogFormProps) => {
  const { t } = useTranslation()
  const form = useForm<GenericStartPrequalification>({
    defaultValues: {
      patternUuid,
      isMinCapacity: false,
    },
    resolver: zodResolver(getGenericStartPrequalificationSchema(t)),
  })

  const { activationGroupUuid = null } = useParams()
  const { activationGroup } = useActivationGroupQuery({ activationGroupUuid })

  if (!activationGroupUuid || !activationGroup) {
    return null
  }

  function handleSubmit(data: GenericStartPrequalification) {
    onSubmit(data)
  }

  return (
    <FormProvider {...form}>
      <form
        noValidate
        aria-label={t('activation_groups.start_prequalification_form.title')}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {/* TODO: BESS-490 Fetch the available activation trigger channels from the msa-prequalification-manager API*/}
          <SelectFieldController
            required
            id="activation-trigger-channel"
            label={t('activation_groups.start_prequalification_form.portfolio_channel_label')}
            name="activationTriggerChannel"
            options={[1, 2, 3].map((channel) => ({
              value: channel,
              label: activationGroup.portfolio.code + ' @ ' + channel,
              id: 'Channel-' + channel,
            }))}
          />

          <CheckboxController
            label={t('activation_groups.start_prequalification_form.is_min_capacity_label')}
            name="isMinCapacity"
          />
        </DialogContent>

        <FormDialogActions isLoading={isLoading} onCancel={onCancel} />
      </form>
    </FormProvider>
  )
}

export default GenericStartPrequalificationDialogForm
