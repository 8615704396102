import type { FieldPath, FieldValues } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'

import LabeledTextField from '@/components/dataDisplay/LabeledTextField'

import type { TimezoneFieldControllerProps } from './TimezoneFieldController'
import { TimezoneFieldController } from './TimezoneFieldController'

type EditableTimeZoneFieldControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = TimezoneFieldControllerProps<TFieldValues, TName> & {
  isEditable?: boolean
  label: string
}

export function EditableTimezoneFieldController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  isEditable = false,
  ...timezoneFieldControllerProps
}: EditableTimeZoneFieldControllerProps<TFieldValues, TName>) {
  const { watch } = useFormContext<FieldValues>()

  if (isEditable) {
    return <TimezoneFieldController label={label} {...timezoneFieldControllerProps} />
  }

  const value = watch(timezoneFieldControllerProps.name)

  return <LabeledTextField label={label ?? ''} text={value?.toString()} />
}
