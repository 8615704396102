import { BidType, MarketProgram } from '@/features/bidding/constants'

export const MARKET_PROGRAMS_WITH_ENERGY_BIDDING_ENABLED: MarketProgram[] = [
  MarketProgram.MFRR_UP,
  MarketProgram.MFRR_DOWN,
]

export type BidConfig = {
  ptuLengthInMin: number
  volumeUnit: 'WATTS' | 'WATT_HOURS'
  priceLadderType: PriceLadderType
}

/**
 * Cumulative means the total volume equals the volume of the chunk with the highest quantity
 * Incremental means the total volume is the sum of all chunks
 * e.g. 1MW at 10€, 2MW at 20€, 3MW at 30€. Total volume would be 3MW in Cumulative but 6MW in Incremental.
 */
export enum PriceLadderType {
  'CUMULATIVE',
  'INCREMENTAL',
}

const BID_CONFIG: Partial<Record<CountryIdentifier, Partial<Record<BidType, BidConfig>>>> = {
  gr: {
    [BidType.CAPACITY]: { ptuLengthInMin: 30, volumeUnit: 'WATTS', priceLadderType: PriceLadderType.CUMULATIVE },
    [BidType.ENERGY]: { ptuLengthInMin: 15, volumeUnit: 'WATTS', priceLadderType: PriceLadderType.CUMULATIVE },
  },
  fi: {
    [BidType.CAPACITY]: { ptuLengthInMin: 60, volumeUnit: 'WATTS', priceLadderType: PriceLadderType.INCREMENTAL },
    [BidType.ENERGY]: { ptuLengthInMin: 15, volumeUnit: 'WATT_HOURS', priceLadderType: PriceLadderType.INCREMENTAL },
  },
  se: {
    [BidType.CAPACITY]: { ptuLengthInMin: 60, volumeUnit: 'WATTS', priceLadderType: PriceLadderType.INCREMENTAL },
  },
}

const DEFAULT_CONFIG: BidConfig = {
  ptuLengthInMin: 60,
  volumeUnit: 'WATTS',
  priceLadderType: PriceLadderType.INCREMENTAL,
}

export default (selectedCountry: CountryIdentifier, bidType: BidType): BidConfig => {
  return BID_CONFIG[selectedCountry]?.[bidType] ?? DEFAULT_CONFIG
}
