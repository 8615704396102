import type { AxiosResponse } from 'axios'
import axios from 'axios'

import environment from '@/environment'
import type {
  CreateSite,
  FinancialInformation,
  Site,
  SiteMarketProgramContractRequest,
  UpdateSiteInformation,
} from '@/features/site/types/site'

export const GET_SITE_API_ID = 'GET_SITE'

export const ERROR_NAMES = {
  VALIDATION_FAILED: 'validation-failed',
}

export async function saveSite(site: CreateSite): Promise<Site> {
  const response = await axios.post<Site>(`${environment.services.sitesManagerApiUrl}`, site)

  return response.data
}

export async function updateSiteInformation(
  siteUuid: string,
  params: UpdateSiteInformation,
): Promise<AxiosResponse<void, UpdateSiteInformation>> {
  const url = new URL(`${environment.services.sitesManagerApiUrl}/${siteUuid}/information`)

  url.searchParams.append('location', params.location)

  return axios.put<void, AxiosResponse<void, UpdateSiteInformation>, UpdateSiteInformation>(url.toString(), params)
}

export async function updateSiteFinancialInformation(
  siteUuid: string,
  location: string,
  params: FinancialInformation,
): Promise<AxiosResponse<void, string>> {
  const url = new URL(`${environment.services.sitesManagerApiUrl}/${siteUuid}/financial-information`)

  url.searchParams.append('location', location)

  return axios.put<void, AxiosResponse<void, string>, FinancialInformation>(url.toString(), params)
}

export async function updateSiteMarketProgramContracts(
  siteUuid: string,
  location: string,
  params: SiteMarketProgramContractRequest[],
): Promise<AxiosResponse<void, string>> {
  const url = new URL(`${environment.services.sitesManagerApiUrl}/${siteUuid}/market-program-contracts`)

  url.searchParams.append('location', location)

  return axios.put<void, AxiosResponse<void, string>, SiteMarketProgramContractRequest[]>(url.toString(), params)
}

export async function getSite(siteUuid: string, location?: string): Promise<Site> {
  const response = await axios.get(`${environment.services.sitesManagerApiUrl}/${siteUuid}`, {
    params: {
      location,
    },
  })
  return response.data
}
