import { Box, Paper, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import LanguageFieldController from '@/features/site/components/LanguageFieldController'
import type { CustomerSettingsFormValues } from '@/features/site/components/settings/SiteSettingsForm'
import { TimezoneFieldController } from '@/features/site/components/TimezoneFieldController'

export const SiteLocalizationSettingsSection = () => {
  const { t } = useTranslation()
  const { setValue } = useFormContext<CustomerSettingsFormValues>()

  function handleTimeZoneChange(evt, value) {
    setValue('localization.timeZone', value)
  }
  return (
    <Box>
      <CustomTypography gutterBottom variant="h4">
        {t('customer_settings.form.localization_settings_title')}
      </CustomTypography>
      <Paper
        sx={{
          p: 3,
        }}
      >
        <Stack noValidate component="form" maxWidth={400} spacing={2}>
          <LanguageFieldController showTooltip name="localization.uiLanguage" />

          <TimezoneFieldController
            showTooltip
            label={t('customer_settings.form.timezone_label')}
            name="localization.timeZone"
            onChange={handleTimeZoneChange}
          />
        </Stack>
      </Paper>
    </Box>
  )
}
