import type { TextFieldProps } from '@mui/material'
import { Autocomplete, FormHelperText, MenuItem, TextField } from '@mui/material'
import type FormControl from '@mui/material/FormControl'
import type { ComponentProps, ReactNode, SyntheticEvent } from 'react'
import { forwardRef, useMemo } from 'react'

import CustomFormControl from '@/components/inputs/CustomFormControl'

export type SelectFieldOption<V> = { id: string; value: V; label: string; icon?: ReactNode }

type AutocompleteProps = ComponentProps<typeof Autocomplete>
type FormControlProps = ComponentProps<typeof FormControl>

export type CustomAutocompleteProps<V> = Omit<AutocompleteProps, 'renderInput' | 'options' | 'onChange'> & {
  color?: FormControlProps['color']
  error?: boolean
  helperText?: string
  label?: string
  id?: string
  showAllOption?: boolean
  fullWidth?: boolean
  options: readonly SelectFieldOption<V>[]
  variant?: 'outlined' | 'outlinedWhite'
  textFieldProps?: TextFieldProps
  onChange: (event: SyntheticEvent<Element, Event>, value: V) => void
}

/**
 * It modifies the MUI Autocomplete component to add some Sympower customizations:
 *
 * - Adds a new 'outlinedWhite' variant.
 * - Restrict the variant prop to 'outlined' and 'outlinedWhite',
 *
 */
function CustomAutocomplete<V>(props: CustomAutocompleteProps<V>, ref) {
  const {
    id,
    label,
    fullWidth,
    helperText,
    options,
    onChange,
    size = 'medium',
    color,
    error,
    variant = 'outlined',
    disabled,
    sx,
    textFieldProps,
    ...autocompleteProps
  } = props
  const { optionsMap, optionsValues } = useMemo<{
    optionsMap: Map<V, SelectFieldOption<V>>
    optionsValues: V[]
  }>(() => {
    const map = new Map<V, SelectFieldOption<V>>()
    const values: V[] = []

    options.forEach((option) => {
      map.set(option.value, option)
      values.push(option.value)
    })

    return { optionsMap: map, optionsValues: values }
  }, [options])

  return (
    <CustomFormControl
      color={color}
      disabled={disabled}
      error={Boolean(error)}
      fullWidth={fullWidth}
      size={size}
      sx={sx}
      variant={variant}
    >
      <Autocomplete
        {...autocompleteProps}
        ref={ref}
        disabled={disabled}
        fullWidth={fullWidth}
        getOptionLabel={(value) => {
          const option = optionsMap.get(value as V)

          return option?.label ?? ''
        }}
        options={optionsValues}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            color={color}
            error={error}
            id={id}
            label={label}
            variant="outlined"
          />
        )}
        renderOption={({ key, ...props }, value) => {
          const option = optionsMap.get(value as V)

          return (
            <MenuItem
              key={key}
              component="li"
              sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', gap: 1 }}
              {...props}
            >
              {option!.icon}
              {option!.label}
            </MenuItem>
          )
        }}
        size={size}
        onChange={(event, newValue) => {
          onChange(event, newValue as V)
        }}
      />
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </CustomFormControl>
  )
}

export default forwardRef(CustomAutocomplete) as typeof CustomAutocomplete
