import AddIcon from '@mui/icons-material/Add'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { Autocomplete, Button, FormControlLabel, Stack, Switch, TextField } from '@mui/material'
import type { GridFilterItem, GridFilterModel } from '@mui/x-data-grid'
import { gridFilterModelSelector, GridToolbarFilterButton, useGridApiContext } from '@mui/x-data-grid'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import type { DateRange } from '@mui/x-date-pickers-pro'
import { DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import GridToolbar from '@/components/dataDisplay/GridToolbar'
import type { Resource } from '@/features/site/components/availability/types'
import { AVAILABILITY_STATUSES } from '@/features/unavailability/types/availabilityStatus'
import { getFilterItemValue, getFilterItemValues, updateDataGridFilters } from '@/utils/datagrid/filters'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends AvailabilityDataGridToolbarProps {}
}

interface AvailabilityDataGridToolbarProps {
  availableResources: Resource[]
  mode: 'afrr' | 'unavailability' | undefined
  onAddClick?: () => void
}

const RESOURCES_FILTER_NAME = 'resources'
const STATUS_FILTER_NAME = 'status'
const START_DATE_FILTER_NAME = 'startDate'
const END_DATE_FILTER_NAME = 'endDate'

export const DEFAULT_DATA_GRID_AVAILABLITY_FILTER_MODEL = {
  items: [],
}

export const AvailabilityDataGridToolbar = (props: AvailabilityDataGridToolbarProps) => {
  const { t } = useTranslation()
  const apiRef = useGridApiContext()
  const filterModel = gridFilterModelSelector(apiRef) as GridFilterModel

  const selectedResources = getFilterItemValues(filterModel, RESOURCES_FILTER_NAME) ?? []
  const showExpired =
    getFilterItemValue(filterModel, STATUS_FILTER_NAME, ['not']) !== AVAILABILITY_STATUSES.expired.value
  const selectedDateRange = {
    startDate: getFilterItemValue(filterModel, START_DATE_FILTER_NAME, ['includesOrAfter', 'includes']),
    endDate: getFilterItemValue(filterModel, END_DATE_FILTER_NAME, ['includesOrBefore', 'includes']),
  }

  const isCreateButtonVisible = !!props.mode

  function handleSelectedResourceFilter(wires: string[]) {
    const items = wires.map((resource) => ({
      field: 'resources',
      operator: 'contains',
      value: resource,
    }))

    updateDataGridFilters(apiRef, [RESOURCES_FILTER_NAME], items)
  }

  function handleSelectedDateFilter(dates: DateRange<DateTime>) {
    const startDateFilter = {
      id: 'start-date',
      field: START_DATE_FILTER_NAME,
      operator: 'includesOrAfter',
      value: dates[0]?.toISODate(),
    }

    const endDateFilter = {
      id: 'end-date',
      field: END_DATE_FILTER_NAME,
      operator: 'includesOrBefore',
      value: dates[1]?.toISODate(),
    }

    const rangeStart = {
      id: 'range-start',
      field: START_DATE_FILTER_NAME,
      operator: 'includesOrAfter',
      value: dates[0]?.toISODate(),
    }
    const rangeEnd = {
      id: 'range-end',
      field: END_DATE_FILTER_NAME,
      operator: 'includesOrBefore',
      value: dates[1]?.toISODate(),
    }

    const dateFilters: GridFilterItem[] = []

    if (dates[0] && dates[1]) {
      dateFilters.push(rangeStart, rangeEnd)
    } else if (dates[0]) {
      dateFilters.push(startDateFilter)
    } else if (dates[1]) {
      dateFilters.push(endDateFilter)
    }

    updateDataGridFilters(apiRef, [START_DATE_FILTER_NAME, END_DATE_FILTER_NAME], dateFilters)
  }

  function handleShowExpiredToggle(value: boolean) {
    const otherFilters = filterModel.items.filter((item) => item.id !== 'hide-expired')

    const statusFilter = {
      id: STATUS_FILTER_NAME,
      field: STATUS_FILTER_NAME,
      operator: 'not',
      value: AVAILABILITY_STATUSES.expired.value,
    }

    if (value) {
      apiRef.current.deleteFilterItem(statusFilter)
    } else {
      apiRef.current.upsertFilterItems([...otherFilters, statusFilter])
    }
  }

  return (
    <Stack direction={'row'} gap={4} mb={2} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <GridToolbar
        filters={
          <>
            <Autocomplete
              filterSelectedOptions
              multiple
              id="selected-resources"
              limitTags={2}
              options={props.availableResources
                .map((resource) => resource.name)
                .filter((value, index, self) => self.indexOf(value) === index)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('customer_details.tabs.availability.unavailability_data_grid.filters.select_resources')}
                />
              )}
              sx={{ minWidth: '300px' }}
              value={selectedResources.filter((resource) => !!resource)}
              onChange={(event, value) => {
                handleSelectedResourceFilter(value)
              }}
            />

            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DateRangePicker
                slotProps={{
                  actionBar: {
                    actions: ['clear'],
                  },
                  textField: {
                    label: t('customer_details.tabs.availability.unavailability_data_grid.filters.filter_on_date'),
                    InputProps: {
                      endAdornment: <CalendarTodayIcon color={'action'} />,
                      sx: {
                        width: '300px',
                      },
                    },
                  },
                }}
                slots={{ field: SingleInputDateRangeField }}
                value={[
                  selectedDateRange.startDate ? DateTime.fromISO(selectedDateRange.startDate) : null,
                  selectedDateRange.endDate ? DateTime.fromISO(selectedDateRange.endDate) : null,
                ]}
                onChange={(date) => {
                  handleSelectedDateFilter(date as DateRange<DateTime>)
                }}
              />
            </LocalizationProvider>
            <FormControlLabel
              control={
                <Switch
                  checked={showExpired}
                  onChange={(event) => {
                    handleShowExpiredToggle(event.target.checked)
                  }}
                />
              }
              label={t('customer_details.tabs.availability.unavailability_data_grid.filters.show_expired')}
              sx={{ m: 0 }}
            />
            <GridToolbarFilterButton />
          </>
        }
      />
      {isCreateButtonVisible && (
        <Button startIcon={<AddIcon />} variant={'contained'} onClick={props.onAddClick}>
          {props.mode === 'afrr'
            ? t('customer_details.tabs.availability.availability_data_grid.actions.add')
            : t('customer_details.tabs.availability.unavailability_data_grid.actions.add')}
        </Button>
      )}
    </Stack>
  )
}
