import { Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import { SITE_MARKET_PROGRAMS_QUERY_STALE_TIME } from '@/features/site/constants'
import { useMarketProgramsQuery } from '@/features/site/hooks/useMarketProgramsQuery'
import type { CreateSiteForm } from '@/features/site/types/site'

import BasicInformationReviewSection from './BasicInformationReviewSection'
import ContactsReviewSection from './ContactsReviewSection'
import FinancialInformationReviewSection from './FinancialInformationReviewSection'
import MarketProgramsReviewSection from './MarketProgramsReviewSection'
import NotificationsReviewSection from './NotificationsReviewSection'

function ReviewStep() {
  const { getValues } = useFormContext<CreateSiteForm>()
  const { marketPrograms, isLoading } = useMarketProgramsQuery({
    gcTime: SITE_MARKET_PROGRAMS_QUERY_STALE_TIME,
    staleTime: SITE_MARKET_PROGRAMS_QUERY_STALE_TIME,
  })

  const formData = getValues()

  return (
    <Stack sx={{ flexDirection: 'column', gap: 3 }}>
      <BasicInformationReviewSection
        address={formData.address}
        location={formData.location}
        name={formData.name}
        partnerCode={formData.partnerCode}
        siteType={formData.siteType}
        symbolicName={formData.symbolicName}
      />

      <MarketProgramsReviewSection
        isLoading={isLoading}
        marketProgramContracts={formData.marketProgramContracts}
        marketPrograms={marketPrograms ?? []}
      />

      <ContactsReviewSection contacts={formData.contacts} />

      <NotificationsReviewSection notificationPreferences={formData.notificationPreferences} />

      {formData.partnerCode === 'SYMPOWER' && (
        <FinancialInformationReviewSection financialInformation={formData.financialInformation} />
      )}
    </Stack>
  )
}

export default ReviewStep
