import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { CustomSelectFieldProps, SelectFieldOption } from '@/components/inputs/CustomSelectField'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import { FIELD_WIDTH } from '@/constants/layout'
import { MARKET_PROGRAMS } from '@/constants/marketPrograms'
import { getMarketProgramOptions } from '@/features/site/utils/getMarketProgramSortedOptions'
import type { MarketProgramType } from '@/types/marketProgramType'
import { sortOptionsAlphabetically } from '@/utils/sortOptionsAlphabetically'

type MarketProgramSelectFieldProps = {
  showAllOption?: CustomSelectFieldProps<string>['showAllOption']
  size?: CustomSelectFieldProps<string>['size']
  sx?: CustomSelectFieldProps<string>['sx']
  value: CustomSelectFieldProps<string>['value']
  variant?: CustomSelectFieldProps<string>['variant']
  onChange: CustomSelectFieldProps<string>['onChange']
  marketProgramOptions?: SelectFieldOption<MarketProgramType>[]
}

const MarketProgramFilter = ({ sx, marketProgramOptions, ...props }: MarketProgramSelectFieldProps) => {
  const { t } = useTranslation()

  const sortedMarketProgramsOptions = useMemo(() => {
    if (marketProgramOptions) {
      return marketProgramOptions.toSorted((a, b) => a.label.localeCompare(b.label))
    }

    return sortOptionsAlphabetically<MarketProgramType>(getMarketProgramOptions(MARKET_PROGRAMS, t))
  }, [marketProgramOptions, t])

  return (
    <CustomSelectField
      {...props}
      id="market-program"
      label={t('common.market_program.label')}
      options={sortedMarketProgramsOptions}
      sx={{ minWidth: FIELD_WIDTH, ...sx }}
    />
  )
}

export default MarketProgramFilter
