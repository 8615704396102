import { ALL_RESULTS } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { useBidsLatestPricesQuery } from '@/features/bidding/hooks/useBidsLatestPricesQuery'
import { useGroupedBidsPageQuery } from '@/features/bidding/hooks/useGroupedBidsPageQuery'
import type { ExtendedBid } from '@/features/bidding/types/bid'
import type { GroupedBidParams } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import { updateExtendedBidPrices } from '@/features/bidding/utils/price/updateBidPrices'

type useActivationGroupBidsQueryResult = {
  activationGroupBids: ExtendedBid[]
  isLoading: boolean
}

export const useActivationGroupBidsQuery = (groupParams: GroupedBidParams): useActivationGroupBidsQueryResult => {
  const { selectedCountry } = useBiddingContext()

  const { groupedBidsPage, isLoading: isLoadingGroupedBids } = useGroupedBidsPageQuery({
    pagination: { pageSize: ALL_RESULTS, page: 0 },
    filter: {
      items: [
        { field: 'portfolio', operator: 'equals', value: groupParams.portfolioCode },
        { field: 'marketProgram', operator: 'equals', value: groupParams.marketProgram },
        { field: 'deliveryDay', operator: 'equals', value: groupParams.deliveryDay },
        { field: 'bidType', operator: 'equals', value: groupParams.bidType },
      ],
    },
    country: selectedCountry,
  })

  const { prices, isLoading: isLoadingPrices } = useBidsLatestPricesQuery({
    country: selectedCountry,
    portfolio: groupParams.portfolioCode,
    marketProgram: groupParams.marketProgram,
    bidType: groupParams.bidType,
    untilDeliveryDay: groupParams.deliveryDay.minus({ days: 1 }),
  })

  const isLoading = isLoadingGroupedBids || isLoadingPrices

  const activationGroupBids =
    groupedBidsPage?.groupedBids
      .flatMap((groupedBid) => Object.values(groupedBid.bids))
      .map((bid) => addPricesIfMissing(bid, prices || [])) ?? []

  return {
    activationGroupBids,
    isLoading,
  }
}

export function addPricesIfMissing(bid: ExtendedBid, prices: number[]) {
  if (bidHasPrices(bid)) {
    return bid
  }
  const updatedPrices = Object.fromEntries(prices.map((price) => [price, price]))
  return updateExtendedBidPrices(bid, updatedPrices)
}

function bidHasPrices(bid: ExtendedBid) {
  return bid.ptus.some((ptu) => (ptu.ptuChunks ? ptu.ptuChunks.length > 0 : false))
}

export default useActivationGroupBidsQuery
