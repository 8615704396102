import { Stack } from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid'
import type { GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import CustomButton from '@/components/inputs/CustomButton'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { DisturbancesRouteInformation } from '@/features/disturbance/constants'
import { useDisturbancesQuery } from '@/features/disturbance/hooks/useDisturbancesQuery'
import type { Disturbance } from '@/features/disturbance/types'
import { getGrafanaFcrdStaticDisturbanceUrl } from '@/features/disturbance/utils/grafana'

type DisturbanceRenderCell = GridRenderCellParams<Disturbance>

const DATE_FORMAT = 'dd LLL, yyyy'

const DisturbanceGrid = () => {
  const { t } = useTranslation()
  const [paginationModel, setPaginationModel] = useState(DEFAULT_DATA_GRID_PAGINATION_MODEL)
  const { disturbances, isLoading, totalCount } = useDisturbancesQuery({ pagination: paginationModel })

  const columns: GridColDef<Disturbance>[] = [
    {
      field: 'uuid',
      headerName: t('common.uuid'),
      flex: 2,
    },
    {
      field: 'type',
      headerName: t('common.type'),
      flex: 1,
      valueFormatter: (value: Disturbance['type']) =>
        t(`disturbances.types.${value.toLowerCase() as Lowercase<Disturbance['type']>}`),
    },
    {
      field: 'marketProgram',
      headerName: t('common.market_program.label'),
      flex: 2,
      valueFormatter: (value: Disturbance['marketProgram']) => t(`bidding.market_program.${value}`),
    },
    {
      field: 'priceArea',
      headerName: t('disturbances.table.header.price_area'),
      flex: 1,
    },
    {
      field: 'startedAt',
      headerName: t('disturbances.table.header.started_at'),
      flex: 1,
      valueFormatter: (value: Disturbance['startedAt']) => value.toFormat(DATE_FORMAT),
    },
    {
      field: 'endedAt',
      headerName: t('disturbances.table.header.ended_at'),
      flex: 1,
      valueFormatter: (value: Disturbance['endedAt']) => value?.toFormat(DATE_FORMAT) ?? '-',
    },
    {
      field: 'finishedAt',
      headerName: t('disturbances.table.header.finished_at'),
      flex: 1,
      valueFormatter: (value: Disturbance['finishedAt']) => value?.toFormat(DATE_FORMAT) ?? '-',
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      renderCell: ({ row: { startedAt, endedAt, priceArea } }: DisturbanceRenderCell) => {
        return (
          <Stack alignItems="center" flexDirection="row" gap={1} sx={{ height: '100%' }}>
            <CustomButton
              component={Link}
              target="_blank"
              to={getGrafanaFcrdStaticDisturbanceUrl({
                activationGroupUuid: null,
                portfolioCode: priceArea,
                activationTriggerChannel: 0,
                startedAt,
                endedAt,
              })}
              variant="text"
              onClick={(event) => event.stopPropagation()}
            >
              {t('common.view_grafana')}
            </CustomButton>
          </Stack>
        )
      },
    },
  ]

  return (
    <CustomDataGrid
      disableColumnMenu
      disableColumnSorting
      aria-label={t('disturbances.table.title')}
      clickableRows={{
        navigateTo: ({ row }: GridRowParams<Disturbance>) =>
          DisturbancesRouteInformation.DISTURBANCE_ACTIVATED_RESOURCE.navigationPath(row.uuid),
      }}
      columns={columns}
      getRowId={(row) => row.uuid}
      isLoading={isLoading}
      paginationMode="server"
      paginationModel={paginationModel}
      rowCount={totalCount}
      rows={disturbances ?? []}
      onPaginationModelChange={setPaginationModel}
    />
  )
}

export default DisturbanceGrid
