import { EventOutlined } from '@mui/icons-material'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import type { DesktopDateTimeRangePickerProps } from '@mui/x-date-pickers-pro'
import {
  DesktopDateTimeRangePicker,
  LocalizationProvider,
  SingleInputDateTimeRangeField,
} from '@mui/x-date-pickers-pro'
import type { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

const CUSTOM_DATE_TIME_RANGE_PICKER_WIDTH = '338px'

/**
 * It contains some defaults for the DesktopDateTimeRangePicker component, so we achieve a consistent look and feel.
 *
 */
function CustomDateTimeRangePicker({
  sx,
  slotProps,
  slots,
  ...props
}: Readonly<DesktopDateTimeRangePickerProps<DateTime>>) {
  const { i18n } = useTranslation()

  return (
    <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
      <DesktopDateTimeRangePicker
        disableFuture
        ampm={false}
        calendars={2}
        format="dd-MM-yyyy, HH:mm"
        slotProps={{
          ...slotProps,
          textField: {
            ...slotProps?.textField,
            InputProps: {
              endAdornment: <EventOutlined />,
            },
          },
        }}
        slots={{ ...slots, field: SingleInputDateTimeRangeField }}
        sx={{ ...sx, minWidth: CUSTOM_DATE_TIME_RANGE_PICKER_WIDTH }}
        {...props}
      />
    </LocalizationProvider>
  )
}

export default CustomDateTimeRangePicker
