import { CircularProgress, FormHelperText, InputAdornment, TextField } from '@mui/material'
import { verifyTOTPSetup } from 'aws-amplify/auth'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAlertContext } from '@/contexts/AlertContext'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { updateMfa } from '@/features/user/endpoints/users'
import type { MfaSettings } from '@/features/user/types/user'
import type { UserPersonalInfoForm } from '@/features/user/types/userPersonalInfo'

type UserProfileTotpValidateCodeProps = {
  hasPreferredOptionError: boolean
  mfaSettings: MfaSettings
  userId: string
}

function UserProfileTotpValidateCode({
  hasPreferredOptionError,
  mfaSettings,
  userId,
}: Readonly<UserProfileTotpValidateCodeProps>) {
  const [validationCode, setValidationCode] = useState('')
  const { refreshSession } = useAuth()
  const { pushAlert } = useAlertContext()
  const { t } = useTranslation()
  const { setValue, trigger } = useFormContext<UserPersonalInfoForm>()

  async function handleValidateCode() {
    try {
      // Try to validate the code with the Amplify API.
      await verifyTOTPSetup({ code: validationCode })

      // The code was validated with success, now it's time to let our database knows about the change.
      await updateMfa(userId, {
        ...mfaSettings,
        isTotpEnabled: true,
      })

      // Inform the form the user has enabled TOTP.
      setValue('mfaSettings.isTotpEnabled', true)

      // Clean the error state in the form in case there was one.
      trigger('mfaSettings.preferredOption')

      pushAlert({
        message: t('user_profile.settings.security.verify_code_success'),
        severity: 'success',
      })

      refreshSession()
    } catch {
      pushAlert({
        message: t('user_profile.settings.security.verify_code_error'),
        severity: 'error',
      })
    } finally {
      setValidationCode('')
    }
  }

  useEffect(() => {
    if (validationCode.length === 6) {
      handleValidateCode()
    }
  }, [validationCode])

  const isDisabled = validationCode.length === 6

  return (
    <>
      <TextField
        fullWidth
        disabled={isDisabled}
        error={hasPreferredOptionError}
        placeholder={t('user_profile.settings.security.enter_code_placeholder')}
        slotProps={{
          input: {
            endAdornment: isDisabled && (
              <InputAdornment position="end">
                <CircularProgress color="inherit" size="20px" />
              </InputAdornment>
            ),
          },
        }}
        value={validationCode}
        onChange={(event) => {
          // users are only allowed to enter numbers
          const value = event.target.value.replace(/\D/g, '')

          setValidationCode(value)
        }}
      />
      {hasPreferredOptionError && (
        <FormHelperText error>{t('user_profile.settings.security.mfa_need_validation_for_totp')}</FormHelperText>
      )}
    </>
  )
}

export default UserProfileTotpValidateCode
