import { useMutation } from '@tanstack/react-query'

import { saveSite } from '@/features/site/endpoints/sites'
import type { CreateSite } from '@/features/site/types/site'

export type UseCreateSiteMutationMutationResult = ReturnType<typeof useCreateSiteMutation>

export function useCreateSiteMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (site: CreateSite) => saveSite(site),
  })

  return {
    createSite: mutateAsync,
    ...mutationResult,
  }
}
