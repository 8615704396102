import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'

import type { CustomSelectFieldProps } from '@/components/inputs/CustomSelectField'
import CustomSelectField from '@/components/inputs/CustomSelectField'

export type SelectFieldControllerProps<
  TValue extends string | ReadonlyArray<string> | number | undefined,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = Omit<CustomSelectFieldProps<TValue>, 'name'> & {
  name: TName
  // It enables showing field validation errors. Its default value is true
  showError?: boolean
  showErrorMessage?: boolean
  controllerProps?: Omit<ControllerProps<TFieldValues, TName>, 'render' | 'name'>
}

function SelectFieldController<
  TValue extends string | ReadonlyArray<string> | number | undefined,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  {
    name,
    showError = true,
    showErrorMessage = true,
    controllerProps,
    fullWidth,
    ...fieldProps
  }: SelectFieldControllerProps<TValue, TFieldValues, TName>,
  ref: ForwardedRef<HTMLSelectElement>,
) {
  const { control } = useFormContext<TFieldValues>()

  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field: { value, ref: fieldRef, ...field }, fieldState: { error, invalid } }) => (
        <CustomSelectField
          {...field}
          ref={ref || fieldRef}
          error={showError && invalid}
          fullWidth={fullWidth ?? true}
          helperText={showErrorMessage ? error?.message : undefined}
          name={name}
          value={value ?? ''}
          {...fieldProps}
        />
      )}
    />
  )
}

export default forwardRef(SelectFieldController) as typeof SelectFieldController
