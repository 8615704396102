import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import BidConfirmPriceToolbar from '@/features/bidding/components/price/confirm/BidConfirmPriceToolbar'
import { MAX_PTU_ROWS_PER_DATAGRID } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import type { ExtendedBid } from '@/features/bidding/types/bid'
import getBidConfig from '@/features/bidding/utils/getBidConfig'
import { buildAggregatedBid } from '@/features/bidding/utils/model/buildAggregatedBid'
import { getPriceBidDataGridColumns } from '@/features/bidding/utils/price/getPriceBiddingDataGridColumns'

type Props = {
  activationGroupBids: ExtendedBid[]
}

const BidConfirmPriceDataGrid: FC<Props> = ({ activationGroupBids }) => {
  const { t, i18n } = useTranslation()
  const { selectedCountry } = useBiddingContext()
  const { priceLadderType } = getBidConfig(selectedCountry, activationGroupBids[0].bidType)

  const aggregatedBid = buildAggregatedBid(activationGroupBids, priceLadderType)

  return (
    <CustomDataGrid
      disableDefaultHeaderBackground
      hideFooter
      columns={getPriceBidDataGridColumns(aggregatedBid, false, t, i18n.language, true)}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: MAX_PTU_ROWS_PER_DATAGRID,
            page: 0,
          },
        },
      }}
      rows={aggregatedBid.ptus}
      slotProps={{
        toolbar: {
          groupParams: { ...activationGroupBids[0], portfolioCode: activationGroupBids[0].portfolio.code },
        },
      }}
      slots={{ toolbar: BidConfirmPriceToolbar }}
    />
  )
}

export default BidConfirmPriceDataGrid
