import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import type { GridColDef, GridRenderCellParams, GridSortItem } from '@mui/x-data-grid'
import { getGridDateOperators } from '@mui/x-data-grid/colDef/gridDateOperators'
import { getGridSingleSelectOperators } from '@mui/x-data-grid/colDef/gridSingleSelectOperators'
import type { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ChipList from '@/components/dataDisplay/ChipList'
import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import TextList from '@/components/dataDisplay/TextList'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { COLUMN_WITH_SMALL_ICON_WIDTH } from '@/constants/layout'
import {
  AvailabilityDataGridToolbar,
  DEFAULT_DATA_GRID_AVAILABLITY_FILTER_MODEL,
} from '@/features/site/components/availability/datagrid/AvailabilityDataGridToolbar'
import { AvailabilityStatusChip } from '@/features/site/components/availability/datagrid/AvailabilityStatusChip'
import { getGridDateIncludesOperators } from '@/features/site/components/availability/datagrid/includesFilterOperators'
import { ServiceRuleDetailsCreateDialog } from '@/features/site/components/availability/serviceRule/ServiceRuleDetailsCreateDialog'
import { ServiceRuleDetailsDialog } from '@/features/site/components/availability/serviceRule/ServiceRuleDetailsDialog'
import { ServiceRuleDetailsEditDialog } from '@/features/site/components/availability/serviceRule/ServiceRuleDetailsEditDialog'
import type { Resource } from '@/features/site/components/availability/types'
import {
  formatLimitValue,
  formatResourceName,
  getLimitValueColumnHeader,
  getSelectedDays,
} from '@/features/site/components/availability/utils'
import { useInvalidateServiceRulesQuery, useServiceRulesQuery } from '@/features/site/hooks/useServiceRulesQuery'
import type { MarketProgram } from '@/features/site/types/marketProgram'
import type { MarketProgramContract } from '@/features/site/types/marketProgramContract'
import type { AnalogWire } from '@/features/unavailability/types/analogWire'
import { AVAILABILITY_STATUSES, AVAILABILITY_STATUSES_LIST } from '@/features/unavailability/types/availabilityStatus'
import type { RelayWire } from '@/features/unavailability/types/relayWire'
import type { ServiceRule } from '@/features/unavailability/types/serviceRule'
import { convertToTimeZoneDateTime, formatDateTimeWithLocale } from '@/utils/time'

interface ServiceRulesDataGridProps {
  selectedMarketProgram: MarketProgramContract
  siteUuid: string
  siteTimezone: string
  siteLocation: string
}

export const ServiceRulesDataGrid = (props: ServiceRulesDataGridProps) => {
  const { t, i18n } = useTranslation()
  const [selectedServiceRule, setSelectedServiceRule] = useState({} as ServiceRule)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [createFormDialogOpen, setCreateFormDialogOpen] = useState(false)
  const [editFormDialogOpen, setEditFormDialogOpen] = useState(false)
  const invalidateServiceRulesQuery = useInvalidateServiceRulesQuery()

  const { serviceRules, isFetching } = useServiceRulesQuery({
    customerUuid: props.siteUuid,
    serviceId: props.selectedMarketProgram.service.id,
    location: props.siteLocation,
  })

  const handleClose = () => {
    setDialogOpen(false)
    setSelectedServiceRule({} as ServiceRule)
  }

  const handleOpen = (serviceRule: ServiceRule) => {
    setSelectedServiceRule(serviceRule)
    setDialogOpen(true)
  }

  const handleCancelServiceRuleSubmit = async () => {
    setSelectedServiceRule({} as ServiceRule)
    setCreateFormDialogOpen(false)
    setEditFormDialogOpen(false)
    await invalidateServiceRulesQuery()
  }

  function getServiceRuleColumns(
    t: TFunction,
    selectedMarketProgram: MarketProgram,
    customerTimeZone: string,
  ): GridColDef<ServiceRule>[] {
    return [
      {
        field: 'startDate',
        headerName: t('customer_details.tabs.availability.available_from'),
        flex: 1,
        type: 'date',
        filterOperators: [...getGridDateOperators(), ...getGridDateIncludesOperators(customerTimeZone)],
        valueGetter: getDateValueGetter(customerTimeZone),
        valueFormatter: getDateFormatter(i18n.language),
      },
      {
        field: 'endDate',
        headerName: t('customer_details.tabs.availability.available_to'),
        flex: 1,
        type: 'date',
        filterOperators: [...getGridDateOperators(), ...getGridDateIncludesOperators(customerTimeZone)],
        valueGetter: getDateValueGetter(customerTimeZone),
        valueFormatter: getDateFormatter(i18n.language),
      },
      {
        field: 'timeOfDay',
        headerName: t('customer_details.tabs.availability.start_end_time'),
        flex: 1,
        sortable: false,
        valueGetter: (_, serviceRule: ServiceRule) => `${serviceRule.startTime} - ${serviceRule.endTime}`,
      },
      {
        field: 'recurrence',
        headerName: t('customer_details.tabs.availability.repeat'),
        flex: 1.4,
        renderCell: (params: GridRenderCellParams<ServiceRule>) => {
          const selectedDays = getSelectedDays(params.row.recurrence ?? '')
          if (selectedDays.length == 0) {
            return '—'
          } else if (selectedDays.length === 7) {
            return t('customer_details.tabs.availability.availability_data_grid.cells.recurrence.daily')
          } else {
            return (
              <ChipList
                chips={
                  selectedDays.map((day) => ({
                    id: day,
                    label: t(`common.days.${day}.short`),
                    sx: { cursor: 'inherit' },
                  })) ?? []
                }
                limit={3}
                size="small"
              />
            )
          }
        },
      },
      {
        field: 'limitValue',
        headerName: getLimitValueColumnHeader(t, selectedMarketProgram.type),
        flex: 1,
        valueFormatter: (value: ServiceRule['limitValue']) => {
          return formatLimitValue(t, selectedMarketProgram.type, Number(value))
        },
      },
      {
        field: 'resources',
        headerName: t('common.resources'),
        flex: 0.5,
        sortable: false,
        valueGetter: (_, serviceRule: ServiceRule) => getResources(serviceRule).map((wire) => formatResourceName(wire)),
        renderCell: (params: GridRenderCellParams<ServiceRule>) => {
          return (
            <ChipList
              chips={
                getResources(params.row).map((resource) => ({
                  id: resource.id?.toString() || '',
                  label: formatResourceName(resource),
                  sx: { cursor: 'inherit' },
                })) ?? []
              }
              color="secondary"
              limit={0}
              size="small"
            />
          )
        },
      },
      {
        field: 'updatedAt',
        headerName: t('customer_details.tabs.availability.last_updated'),
        flex: 1,
        valueGetter: getDateValueGetter(customerTimeZone),
        valueFormatter: getDateFormatter(i18n.language),
      },
      {
        field: 'exceptionDates',
        headerName: t('customer_details.tabs.availability.availability_details.exception_dates.title'),
        flex: 1.25,
        sortable: false,
        valueGetter: (_, serviceRule: ServiceRule) =>
          serviceRule.exceptionDates?.map((date) => formatDateTimeWithLocale(DateTime.fromISO(date), i18n.language)),
        renderCell: (params: GridRenderCellParams<ServiceRule>) => {
          return (
            <TextList
              limit={1}
              values={
                params.row.exceptionDates
                  ?.sort()
                  .map((date) => formatDateTimeWithLocale(DateTime.fromISO(date), i18n.language)) ?? []
              }
            />
          )
        },
      },
      {
        field: 'status',
        headerName: t('common.status'),
        flex: 1,
        type: 'singleSelect',
        valueOptions: [...AVAILABILITY_STATUSES_LIST],
        sortComparator: (v1, v2) => {
          return AVAILABILITY_STATUSES[v1].sortingOrder - AVAILABILITY_STATUSES[v2].sortingOrder
        },
        filterOperators: [...getGridSingleSelectOperators()],
        renderCell: (params: GridRenderCellParams<ServiceRule>) => {
          return <AvailabilityStatusChip status={params.value} />
        },
      },
      {
        field: 'createdAt', // hidden
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        width: COLUMN_WITH_SMALL_ICON_WIDTH,
        renderCell: (params: GridRenderCellParams<ServiceRule>) => {
          return (
            params.row.status !== 'expired' && (
              <CustomIconButton
                Icon={EditOutlinedIcon}
                aria-label="edit"
                size="small"
                onClick={(e) => {
                  e.stopPropagation()
                  setSelectedServiceRule(params.row)
                  setEditFormDialogOpen(true)
                }}
              />
            )
          )
        },
      },
    ]
  }

  return (
    <>
      <CustomDataGrid
        disableGutterTop
        clickableRows={{
          onRowClick: (params) => {
            handleOpen(params.row)
          },
        }}
        columnVisibilityModel={{
          createdAt: false,
        }}
        columns={getServiceRuleColumns(t, props.selectedMarketProgram.service, props.siteTimezone)}
        includeWrapper={false}
        initialState={{
          sorting: {
            sortModel: getSortModel(props.selectedMarketProgram.service),
          },
          pagination: {
            paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
          },
          filter: {
            filterModel: DEFAULT_DATA_GRID_AVAILABLITY_FILTER_MODEL,
          },
        }}
        isLoading={isFetching}
        rows={serviceRules ?? []}
        slotProps={{
          toolbar: {
            availableResources: getUniqueServiceRuleResources(serviceRules ?? []),
            mode:
              // TODO: remove this when other service rule types are supported
              props.selectedMarketProgram.service.type === 'afrr-up' ||
              props.selectedMarketProgram.service.type === 'afrr-down'
                ? 'afrr'
                : undefined,
            onAddClick: () => setCreateFormDialogOpen(true),
          },
        }}
        slots={{
          toolbar: AvailabilityDataGridToolbar,
        }}
        sx={{ pt: 2 }}
      />

      <ServiceRuleDetailsDialog
        customerTimeZone={props.siteTimezone}
        marketProgram={props.selectedMarketProgram.service}
        open={dialogOpen}
        serviceRule={selectedServiceRule}
        onClose={handleClose}
      />
      <ServiceRuleDetailsCreateDialog
        customerLocation={props.siteLocation}
        customerTimeZone={props.siteTimezone}
        customerUuid={props.siteUuid}
        marketProgram={props.selectedMarketProgram}
        open={createFormDialogOpen}
        onClose={() => handleCancelServiceRuleSubmit()}
      />
      <ServiceRuleDetailsEditDialog
        customerLocation={props.siteLocation}
        customerTimeZone={props.siteTimezone}
        customerUuid={props.siteUuid}
        marketProgram={props.selectedMarketProgram}
        open={editFormDialogOpen}
        serviceRule={selectedServiceRule}
        onClose={() => handleCancelServiceRuleSubmit()}
      />
    </>
  )
}

function getUniqueServiceRuleResources(serviceRules: ServiceRule[]): Resource[] {
  const wires = serviceRules.flatMap((serviceRule) => getResources(serviceRule))
  const uniqueWires: Resource[] = []
  const seen = new Set()

  for (const wire of wires) {
    const key = `${wire.id}-${formatResourceName(wire)}`
    if (!seen.has(key)) {
      seen.add(key)
      uniqueWires.push({ id: wire.id, name: formatResourceName(wire) })
    }
  }

  return uniqueWires
}

function getResources(serviceRule: ServiceRule): (RelayWire | AnalogWire)[] {
  return [...(serviceRule.relayWires ?? []), ...(serviceRule.analogWires ?? [])]
}

function getDateValueGetter(customerTimeZone: string) {
  return (value: string) => convertToTimeZoneDateTime(customerTimeZone, value)
}

function getDateFormatter(language: string) {
  return (value: DateTime) => formatDateTimeWithLocale(value, language)
}

function getSortModel(selectedMarketProgram: MarketProgram): GridSortItem[] {
  switch (selectedMarketProgram.type) {
    case 'afrr-up':
    case 'afrr-down':
      return [
        {
          field: 'endDate',
          sort: 'desc',
        },
        {
          field: 'startDate',
          sort: 'desc',
        },
      ]
    default:
      return [
        {
          field: 'createdAt',
          sort: 'desc',
        },
        {
          field: 'startDate',
          sort: 'desc',
        },
      ]
  }
}
