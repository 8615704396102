import axios from 'axios'

import environment from '@/environment'
import type { CustomAttributeSchema } from '@/features/resource/types'

export const GET_CUSTOM_ATTRIBUTE_SCHEMAS_API_ID = 'GET_CUSTOM_ATTRIBUTE_SCHEMAS'

const BASE_CUSTOM_ATTRIBUTES_API_URL = `${environment.services.resourcesManagerApiUrl}/custom-attributes`

export async function getAllCustomAttributeSchemas(): Promise<CustomAttributeSchema[]> {
  const response = await axios.get(`${BASE_CUSTOM_ATTRIBUTES_API_URL}/schemas`)
  return response.data
}

export async function linkCustomAttributeSchemaToResource(resourceId: string, namespace: string): Promise<void> {
  const payload = [resourceId]
  await axios.post(`${BASE_CUSTOM_ATTRIBUTES_API_URL}/schemas/${namespace}/resources`, payload)
}

export async function unlinkCustomAttributeSchemaFromResource(resourceId: string, namespace: string): Promise<void> {
  await axios.delete(`${BASE_CUSTOM_ATTRIBUTES_API_URL}/schemas/${namespace}/resources/${resourceId}`)
}
