import type { ExtendedBid, ExtendedBidPtu, PtuChunk } from '@/features/bidding/types/bid'

export function updateExtendedBidPrices(bid: ExtendedBid, updatedPrices: Record<number, number | null>) {
  const updatedOfferedBid: ExtendedBidPtu[] = bid.ptus.map((ptu) => ({
    ...ptu,
    ptuChunks: calculateUpdatedPtuChunks(ptu.ptuChunks || [], updatedPrices),
  }))

  return { ...bid, ptus: updatedOfferedBid }
}

const calculateUpdatedPtuChunks = (existingChunks: PtuChunk[], updatedPrices: Record<number, number | null>) => {
  const updatedPtuChunks: PtuChunk[] = existingChunks
    .map((chunk): PtuChunk | null => {
      const newPrice = updatedPrices[chunk.offeredPrice]
      if (!newPrice) return null

      return { ...chunk, offeredPrice: newPrice }
    })
    .filter((chunk): chunk is PtuChunk => chunk !== null)

  for (const [oldPrice, newPrice] of Object.entries(updatedPrices)) {
    if (newPrice !== null && !existingChunks.some((chunk) => chunk.offeredPrice === Number(oldPrice))) {
      updatedPtuChunks.push({ offeredPrice: newPrice, offeredVolume: { quantity: 0, unit: 'WATTS' } })
    }
  }

  return updatedPtuChunks
}
