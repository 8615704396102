import { FormControlLabel, Switch } from '@mui/material'
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import type { TFunction } from 'i18next'
import type { FC, ReactElement } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { AcceptedQuantityDiffCell } from '@/features/bidding/components/AcceptedQuantityDiffCell'
import BidVersionPtusToolbar from '@/features/bidding/components/BidVersionPtusToolbar'
import { OfferedQuantityDiffCell } from '@/features/bidding/components/OfferedQuantityDiffCell'
import { PtuChunkCell } from '@/features/bidding/components/PtuChunkCell'
import { BidType, MAX_PTU_ROWS_PER_DATAGRID } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { useForecastQuery } from '@/features/bidding/hooks/useForecastQuery'
import type { ExtendedBid, Ptu, PtuChunk, Volume } from '@/features/bidding/types/bid'
import { findAcceptedVolumeForTime } from '@/features/bidding/utils/findVolumeForTime'
import { formatVolume } from '@/features/bidding/utils/formatVolume'
import getBidConfig from '@/features/bidding/utils/getBidConfig'
import { getBidDisplayUnit } from '@/features/bidding/utils/getBidDisplayUnit'
import { buildBidPtuResultRowItems } from '@/features/bidding/utils/model/buildBidPtuResultRowItems'

type Props = {
  activationGroupSelectorComponent?: ReactElement
  bidSelectorComponent?: ReactElement
  bidVersion: ExtendedBid
  boughtBackVersion?: ExtendedBid
  isLoading: boolean
}

export type PtuResult = {
  hierarchy: string[]
  ptu: Ptu
  offeredVolume: Volume
  acceptedVolume?: Volume
  forecastedVolume?: Volume
  chunks?: PtuChunk[]
}

const MIN_COL_WIDTH = 120
export const ZERO_VOLUME: Volume = { quantity: 0, unit: '' }

const BidVersionPtusDataGrid: FC<Props> = ({
  bidVersion,
  boughtBackVersion,
  isLoading,
  bidSelectorComponent,
  activationGroupSelectorComponent,
}) => {
  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation()
  const { selectedCountry } = useBiddingContext()
  const { versionForecast } = useForecastQuery({
    date: bidVersion.deliveryDay,
    activationGroupUuid: bidVersion.activationGroupUuid,
  })

  const unit = getVolumeUnit(bidVersion)
  const isPtuLessThanAnHour = getBidConfig(selectedCountry, bidVersion.bidType).ptuLengthInMin < 60

  return (
    <CustomDataGrid
      hideFooter
      treeData
      columns={getColumns(bidVersion, boughtBackVersion, t, unit)}
      data-testid={`activation-group-bid-${bidVersion.activationGroupUuid}`}
      getRowId={(row: PtuResult) => `${row.ptu.start}-${row.ptu.end}`}
      getTreeDataPath={(row) => row.hierarchy}
      groupingColDef={{
        headerName: t('bidding.results.header.ptu'),
        hideDescendantCount: true,
        flex: 1,
        minWidth: MIN_COL_WIDTH,
      }}
      initialState={{ pagination: { paginationModel: { pageSize: MAX_PTU_ROWS_PER_DATAGRID, page: 0 } } }}
      isGroupExpandedByDefault={() => expanded}
      isLoading={isLoading}
      rows={buildBidPtuResultRowItems(bidVersion, isPtuLessThanAnHour, versionForecast)}
      slotProps={{
        toolbar: {
          bid: bidVersion,
          activationGroupSelectorComponent: activationGroupSelectorComponent,
          bidSelectorComponent: bidSelectorComponent,
          // Show the expand/collapse all rows component only if the PTU is less than 1 hour
          expandAllRowsComponent: isPtuLessThanAnHour ? (
            <FormControlLabel
              control={<Switch onChange={(e) => setExpanded(e.target.checked)} />}
              label={expanded ? t('bidding.results.collapse_all_rows') : t('bidding.results.expand_all_rows')}
            />
          ) : undefined,
        },
      }}
      slots={{ toolbar: BidVersionPtusToolbar }}
      sx={{
        '& .MuiDataGrid-footerContainer': { display: 'none !important;' },
        '& .MuiDataGrid-row[aria-expanded="true"]': { backgroundColor: `rgba(32, 74, 100, 0.12) !important` },
        '& .MuiDataGrid-row[aria-level="2"]': { backgroundColor: `rgba(32, 74, 100, 0.08) !important` },
        '& .MuiDataGrid-treeDataGroupingCell': { margin: 0 },
        '& .MuiDataGrid-treeDataGroupingCellToggle': { display: isPtuLessThanAnHour ? 'block' : 'none' },
      }}
    />
  )
}

const getColumns = (
  bidVersion: ExtendedBid,
  boughtBackVersion: ExtendedBid | undefined,
  t: TFunction,
  unit: string,
): GridColDef[] => {
  const columns: GridColDef[] = []
  if (boughtBackVersion) {
    columns.push(...getBuybackColumns(boughtBackVersion, t, unit))
  } else {
    columns.push(...getDifferenceColumns(hasAcceptedVolumes(bidVersion), t, unit))
  }
  const hasPriceChunks = bidVersion.ptus.some((offeredPtu) => offeredPtu.ptuChunks && offeredPtu.ptuChunks.length > 0)
  if (hasPriceChunks) {
    columns.push(...getPriceChunksColumns(bidVersion, unit))
  }
  return columns
}

const getDifferenceColumns = (hasAcceptedValues: boolean, t: TFunction, unit: string): GridColDef[] => {
  const differenceColumns = [
    {
      field: 'ptu.forecastedWatts',
      headerName: t('bidding.results.header.forecast'),
      valueFormatter: (value: number | null) => formatVolume(value, unit),
      valueGetter: (_, ptuResult: PtuResult) => ptuResult.forecastedVolume?.quantity ?? null,
      flex: 1,
      minWidth: MIN_COL_WIDTH,
    },
    {
      field: 'ptu.offeredWattsDiff',
      headerName: t('bidding.results.header.offered'),
      renderCell: (params: GridRenderCellParams<PtuResult>) => <OfferedQuantityDiffCell row={params.row} />,
      flex: 1,
      minWidth: MIN_COL_WIDTH,
    },
  ]

  if (hasAcceptedValues) {
    differenceColumns.push({
      field: 'ptu.acceptedWattsDiff',
      headerName: t('bidding.results.header.accepted'),
      renderCell: (params: GridRenderCellParams<PtuResult>) => <AcceptedQuantityDiffCell row={params.row} />,
      flex: 1,
      minWidth: MIN_COL_WIDTH,
    })
  }

  return differenceColumns
}

const getBuybackColumns = (boughtBackVersion: ExtendedBid, t: TFunction, unit: string): GridColDef[] => [
  {
    field: 'ptu.offeredWatts',
    headerName: t('bidding.results.header.offered'),
    valueFormatter: (value: number) => formatVolume(value, unit),
    valueGetter: (_, ptuResult: PtuResult) => ptuResult.offeredVolume.quantity,
    flex: 1,
    minWidth: MIN_COL_WIDTH,
  },
  {
    field: 'ptu.acceptedWatts',
    headerName: t('bidding.results.header.accepted'),
    valueFormatter: (value: number | null) => formatVolume(value, unit),
    valueGetter: (_, ptuResult: PtuResult) => getOriginalAcceptedAmount(ptuResult, boughtBackVersion),
    flex: 1,
    minWidth: MIN_COL_WIDTH,
  },
  {
    field: 'ptu.buyback',
    headerName: t('bidding.results.header.buyback'),
    valueFormatter: (value: number | null) => formatVolume(value, unit),
    valueGetter: (_, ptuResult: PtuResult) => getBuybackAmount(ptuResult, boughtBackVersion),
    flex: 1,
    minWidth: MIN_COL_WIDTH,
  },
  {
    field: 'ptu.netResult',
    headerName: t('bidding.results.header.net_result'),
    valueFormatter: (value: number | null) => formatVolume(value, unit),
    valueGetter: (_, ptuResult: PtuResult) => ptuResult.acceptedVolume?.quantity ?? null,
    flex: 1,
    minWidth: MIN_COL_WIDTH,
  },
]

function getPriceChunksColumns(bid: ExtendedBid, volumeUnit: string): GridColDef[] {
  const allPrices = bid.ptus
    .flatMap((ptu) => {
      if (ptu.ptuChunks) {
        return ptu.ptuChunks.map((ptuChunk) => ptuChunk.offeredPrice)
      }
    })
    .filter((price) => price != null)
  const distinctPrices = Array.from(new Set(allPrices)).toSorted((a, b) => a - b)
  return distinctPrices.map((price) => {
    return {
      field: 'ptu.priceChunks.' + price,
      headerName: `${price} €/${getPriceUnit(bid)}`,
      renderCell: (params: GridRenderCellParams<PtuChunk>) => <PtuChunkCell chunk={params?.value} unit={volumeUnit} />,
      valueGetter: (_, ptuResult: PtuResult) => ptuResult.chunks?.find((chunk) => chunk.offeredPrice === price),
      flex: 1,
      minWidth: MIN_COL_WIDTH,
    }
  })
}

const getOriginalAcceptedAmount = (currentPtuResult: PtuResult, boughtBackVersion: ExtendedBid) => {
  return findAcceptedVolumeForTime(currentPtuResult.ptu.start, boughtBackVersion.ptus)?.quantity
}

const getBuybackAmount = (currentPtuResult: PtuResult, boughtBackVersion: ExtendedBid) => {
  const originalAccepted = getOriginalAcceptedAmount(currentPtuResult, boughtBackVersion)
  if (!originalAccepted) return

  return originalAccepted - currentPtuResult.acceptedVolume!.quantity
}

const hasAcceptedVolumes = (bidVersion: ExtendedBid) => bidVersion.ptus.some((ptu) => ptu.acceptedVolume != null)

const getVolumeUnit = (bid: ExtendedBid): string => {
  if (!bid.ptus || bid.ptus.length === 0) {
    return ''
  }

  return getBidDisplayUnit(bid.ptus[0].offeredVolume.unit)
}

const getPriceUnit = (bid: ExtendedBid): string => {
  return bid.bidType === BidType.CAPACITY ? 'MW' : 'MWh'
}

export default BidVersionPtusDataGrid
