import type { TFunction } from 'i18next'

import { MARKET_PROGRAMS_COLOR_PALETTE } from '@/constants/marketPrograms'
import { COMMON_LEGEND_OPTIONS } from '@/features/site/constants/revenueChart'
import type { RevenueDataRow } from '@/features/site/types/revenue'
import { getMonthShortNames } from '@/utils/date'

import { convertToStandardMarketProgramType } from './convertToStandardMarketProgramType'

type SeriesData = {
  name: string
  type: string
  stack: string
  label: {
    show: boolean
  }
  data: number[]
}

// Assign a specific color to each market program
export function assignColorsToMarketPrograms(programs: string[]) {
  return programs.reduce(
    (acc, program, index) => {
      acc[program] = MARKET_PROGRAMS_COLOR_PALETTE[index]
      return acc
    },
    {} as Record<string, string>,
  )
}

/**
 * Transforms revenue data grouped by market program and month into a structure
 * suitable for charting, using market program names as keys.
 *
 * @param data - The input data, where:
 *   - The first level keys are market program names.
 *   - The second level keys are month numbers (1-12).
 *   - The values are arrays of RevenueDataRow objects.
 * @returns - The transformed data, where:
 *   - programs - Market program names.
 *   - seriesData - The SeriesData[] to be used in the chart for every market.
 */
export function getChartDataUsingMapOfMarketProgramsGroupedByMonth(
  data: Record<string, Record<number, RevenueDataRow[]>>,
  t: TFunction,
) {
  const marketPrograms = Object.keys(data) // Get all market programs
  const groupedData: Record<string, Record<string, number>> = {}

  // Initialize all months with empty revenue data
  const monthNames = getMonthShortNames()

  monthNames.forEach((month) => {
    groupedData[month] = {}
  })

  marketPrograms.forEach((marketProgram) => {
    Object.entries(data[marketProgram]).forEach(([monthKey, rows]) => {
      const monthIndex = parseInt(monthKey) - 1 // Convert month number to 0-based index
      const month = monthNames[monthIndex] // Get month name

      if (!groupedData[month][marketProgram]) {
        groupedData[month][marketProgram] = 0
      }

      rows.forEach((row) => {
        groupedData[month][marketProgram] += row.revenue
      })
    })
  })

  return marketPrograms.reduce(
    (acc, program) => {
      const marketProgramName = t(`common.market_program.${convertToStandardMarketProgramType(program)}`)

      return {
        programs: [...acc.programs, marketProgramName],
        seriesData: [
          ...acc.seriesData,
          {
            name: marketProgramName,
            type: 'bar',
            stack: 'total',
            label: { show: false },
            data: monthNames.map((month) => groupedData[month][program] ?? 0),
          },
        ],
      }
    },
    { programs: [], seriesData: [] } as { programs: string[]; seriesData: SeriesData[] },
  )
}

export function getRevenuesChartOptions(programs: string[], seriesData: SeriesData[], colors: Record<string, string>) {
  return {
    color: programs.map((program) => colors[program]), // Apply consistent colors
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        let tooltipText = `<strong>${params[0].axisValue}</strong><br/>`
        params.forEach((item: any) => {
          tooltipText += `${item.marker} ${item.seriesName}: €${item.value.toFixed(2)}<br/>`
        })
        return tooltipText
      },
    },
    legend: { ...COMMON_LEGEND_OPTIONS, data: programs },
    xAxis: { type: 'category', data: getMonthShortNames() },
    yAxis: { type: 'value', axisLabel: { formatter: '€{value}' } },
    series: seriesData.map((series) => ({
      ...series,
      itemStyle: { color: colors[series.name] },
    })),
  }
}

export function getRevenuesDistributionChartOptions(
  data: Record<string, Record<number, RevenueDataRow[]>>,
  colors: Record<string, string>,
  t: TFunction,
) {
  const programTotals: Record<string, number> = {}

  // Iterate over market programs first
  Object.entries(data).forEach(([marketProgram, months]) => {
    if (!programTotals[marketProgram]) {
      programTotals[marketProgram] = 0
    }

    // Iterate over the months and sum the revenue
    Object.values(months).forEach((rows) => {
      rows.forEach((row) => {
        programTotals[marketProgram] += row.revenue
      })
    })
  })

  const pieData = Object.entries(programTotals).map(([name, value]) => {
    const marketProgramName = t(`common.market_program.${convertToStandardMarketProgramType(name)}`)

    return {
      name: marketProgramName,
      value: parseFloat(value.toFixed(2)),
      itemStyle: { color: colors[marketProgramName] }, // Apply consistent colors
    }
  })

  return {
    tooltip: {
      trigger: 'item',
      formatter: ({ name, value, percent }: any) => `${name}: €${value.toFixed(2)} (${percent.toFixed(2)}%)`,
    },
    legend: COMMON_LEGEND_OPTIONS,
    series: [
      {
        type: 'pie',
        radius: '50%',
        data: pieData,
        label: {
          formatter: ({ name, value }: any) => `${name}: €${value.toFixed(2)}`,
        },
      },
    ],
  }
}
