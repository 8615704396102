import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react'
import { Box, Grid } from '@mui/material'

import EnvironmentRibbon from '@/components/layouts/EnvironmentRibbon'
import environment from '@/environment'
import { useWhitelabelConfigurationContext } from '@/features/whitelabel/context/WhitelabelContext'
import { useAmplifyTheme } from '@/features/whitelabel/hooks/useAmplifyTheme'

function Header() {
  const { branding } = useWhitelabelConfigurationContext()

  return (
    <Box sx={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'column', marginTop: 4 }}>
      <img alt={'Logo ' + branding.name} src={branding.coloredLogo} style={{ margin: '0 auto', width: '300px' }} />
    </Box>
  )
}

function Login() {
  const { palette } = useWhitelabelConfigurationContext()
  const theme = useAmplifyTheme()
  const authenticatorComponents = {
    SignIn: {
      Header,
    },
  }

  return (
    <div style={{ backgroundColor: palette.background }}>
      <Grid container alignItems="center" direction="column" justifyContent="center" sx={{ gap: 2, height: '100vh' }}>
        {environment.environmentName !== 'production' && <EnvironmentRibbon name={environment.environmentName} />}
        <ThemeProvider theme={theme}>
          <Authenticator hideSignUp components={authenticatorComponents} />
        </ThemeProvider>
      </Grid>
    </div>
  )
}

export default Login
