import { Box, capitalize, Card, CardHeader, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomCardContent from '@/components/layouts/CustomCardContent'
import { formatDuration } from '@/features/activation/utils/formatDateTime'
import type { GenericActivationGroupSchedule } from '@/features/activationGroup/types'
import { convertEnergyPower } from '@/utils/powerEnergyTransformations'

const StyledResourceDetailRow = styled(TableRow)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

const StyledResourceDetailHeaderColumn = styled(TableCell)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

type ActivationGroupScheduleCardProps = {
  activationGroupSchedule: GenericActivationGroupSchedule
}

const ActivationGroupScheduleDetailTable = ({
  activationGroupSchedule,
}: {
  activationGroupSchedule: GenericActivationGroupSchedule
}) => {
  const { t } = useTranslation()
  const rows = useMemo(() => {
    return [
      {
        label: t('common.uuid'),
        value: activationGroupSchedule.uuid,
        column: 1,
        position: 1,
      },
      {
        label: t('activation_groups.schedules.table.header.start'),
        value: DateTime.fromISO(activationGroupSchedule.startAt).toLocaleString(DateTime.DATETIME_SHORT),
        column: 1,
        position: 2,
      },
      {
        label: t('activation_groups.schedules.table.header.end'),
        value: DateTime.fromISO(activationGroupSchedule.endAt).toLocaleString(DateTime.DATETIME_SHORT),
        column: 1,
        position: 2,
      },
      {
        label: t('activation_groups.schedules.table.header.schedule_type'),
        value: activationGroupSchedule.scheduleType,
        column: 1,
        position: 2,
      },
      {
        label: t('activation_groups.schedules.table.header.portfolio_channel'),
        value: activationGroupSchedule.portfolioCode + ' @ ' + activationGroupSchedule.activationTriggerChannel,
        column: 2,
        position: 1,
      },
      {
        label: t('activation_groups.schedules.table.header.volume'),
        value: activationGroupSchedule.volume ? convertEnergyPower(activationGroupSchedule.volume, 'kilowatts') : '-',
        column: 2,
        position: 2,
      },
      {
        label: t('activation_groups.schedules.table.header.duration'),
        value: formatDuration(activationGroupSchedule.startAt, activationGroupSchedule.endAt),
        column: 2,
        position: 3,
      },
      {
        label: t('activation_groups.schedules.table.header.status'),
        value: capitalize(activationGroupSchedule.status.toLowerCase()),
        column: 2,
        position: 3,
      },
    ]
  }, [activationGroupSchedule, t])

  // Determine the number of columns by finding the maximum column number in the rows
  const columns = Math.max(...rows.map((row) => row.column))

  // Group rows by their column number and sort them by their position within the column
  const columnsData = Array.from({ length: columns }, (_, i) =>
    rows.filter((row) => row.column === i + 1).sort((a, b) => a.position - b.position),
  )

  return (
    <>
      <CardHeader sx={{ paddingX: 0 }} title={t('activation_groups.detail_panel.general_information')}></CardHeader>
      <Box display="flex">
        {columnsData.map((columnRows) => (
          <Table
            key={columnRows[0].column}
            aria-label={t('activation_groups.detail_panel.general_information')}
            role="table"
            size="small"
          >
            <TableBody>
              {columnRows.map((row) => (
                <StyledResourceDetailRow key={row.label} role="row">
                  <StyledResourceDetailHeaderColumn>
                    <CustomTypography color="grey.500" variant="caption">
                      {row.label}
                    </CustomTypography>
                  </StyledResourceDetailHeaderColumn>
                  <TableCell>
                    <CustomTypography sx={{ lineHeight: 'inherit' }} variant="inherit">
                      {row.value}
                    </CustomTypography>
                  </TableCell>
                </StyledResourceDetailRow>
              ))}
            </TableBody>
          </Table>
        ))}
      </Box>
    </>
  )
}

const ActivationGroupScheduleCard = ({ activationGroupSchedule }: ActivationGroupScheduleCardProps) => {
  if (!activationGroupSchedule) {
    return null
  }

  return (
    <Card sx={{ marginY: 3 }}>
      <CustomCardContent title={activationGroupSchedule.uuid}>
        <ActivationGroupScheduleDetailTable activationGroupSchedule={activationGroupSchedule} />
      </CustomCardContent>
    </Card>
  )
}

export default ActivationGroupScheduleCard
