import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import type { CreateSiteForm } from '@/features/site/types/site'
import { formatValue } from '@/features/site/utils/review'

import ReviewInformationBlock from './ReviewInformationBlock'
import ReviewSiteSection from './ReviewSiteSection'

type BasicInformationReviewSectionProps = {
  financialInformation: CreateSiteForm['financialInformation']
}

// IBAN max width needs to be greater in order to support large ibans
const IBAN_MAX_WIDTH = '400px'

function FinancialInformationReviewSection({ financialInformation }: Readonly<BasicInformationReviewSectionProps>) {
  const { t } = useTranslation()

  return (
    <ReviewSiteSection stepId="financial_information" title={t('sites.add_new.steps.financial_information')}>
      <Stack sx={{ flexDirection: 'row', gap: 2 }}>
        <ReviewInformationBlock
          name={t('sites.add_new.form.financial_information.iban_label')}
          sx={{ maxWidth: IBAN_MAX_WIDTH }}
          value={formatValue(financialInformation?.iban)}
        />

        <ReviewInformationBlock
          name={t('sites.add_new.form.financial_information.iban_holder_label')}
          value={formatValue(financialInformation?.ibanHolder)}
        />
      </Stack>
    </ReviewSiteSection>
  )
}

export default FinancialInformationReviewSection
