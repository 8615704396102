import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import {
  GET_ACTIVATION_GROUP_SCHEDULE_API,
  getGenericActivationGroupSchedule,
} from '@/features/activationGroup/endpoints/activationGroups'
import type { GenericActivationGroupSchedule } from '@/features/activationGroup/types'
import type { UseQueryOptions } from '@/types/queries'

export type UseGenericActivationGroupScheduleQueryParams = {
  activationGroupScheduleUuid: string | null
}

export type UseGenericActivationGroupScheduleQueryResult = Omit<UseQueryResult, 'data'> & {
  activationGroupSchedule: GenericActivationGroupSchedule | null
}

export function useGenericActivationGroupScheduleQuery(
  params: UseGenericActivationGroupScheduleQueryParams,
  options?: UseQueryOptions<GenericActivationGroupSchedule>,
): UseGenericActivationGroupScheduleQueryResult {
  const { data = null, ...queryResult } = useQuery<GenericActivationGroupSchedule>({
    ...options,
    queryFn: () => getGenericActivationGroupSchedule(params.activationGroupScheduleUuid!),
    queryKey: [GET_ACTIVATION_GROUP_SCHEDULE_API],
    enabled: !!params.activationGroupScheduleUuid,
  })

  return {
    ...queryResult,
    activationGroupSchedule: data,
  }
}
