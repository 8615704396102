import { zodResolver } from '@hookform/resolvers/zod'
import { DialogContent, InputAdornment, Stack } from '@mui/material'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import FormDialogActions from '@/components/feedback/FormDialogActions'
import CheckboxController from '@/components/inputs/CheckboxController'
import TextFieldController from '@/components/inputs/TextFieldController'
import { getAssignResourceToActivationGroupSchema } from '@/features/activationGroup/schemas'
import type {
  ActivationGroup,
  ApiActivationGroupResource,
  AssignResourceToActivationGroup,
} from '@/features/activationGroup/types'
import { ActivationGroupState } from '@/features/activationGroup/types'

type AssignResourceDialogFormProps = {
  activationGroup: ActivationGroup
  activationGroupResource: ApiActivationGroupResource
  isLoading: boolean
  onCancel: () => void
  onSubmit: (data: AssignResourceToActivationGroup) => void
}

/**
 * Contains all the logic related to the form to edit a resource from an existing activation group.
 *
 * It is used within the EditResourceDialog component.
 */
const EditResourceDialogForm = ({
  activationGroup,
  activationGroupResource,
  isLoading,
  onCancel,
  onSubmit,
}: AssignResourceDialogFormProps) => {
  const { t } = useTranslation()
  const form = useForm<AssignResourceToActivationGroup>({
    defaultValues: {
      resourceUuid: activationGroupResource.uuid,
      qualifiedCapacity: activationGroupResource.qualifiedCapacity,
      activationPriority: activationGroupResource.activationPriority,
      isUsedForMinCapacityPrequalification: activationGroupResource.isUsedForMinCapacityPrequalification,
    },
    resolver: zodResolver(getAssignResourceToActivationGroupSchema(t, activationGroup)),
  })

  function handleSubmit(data: AssignResourceToActivationGroup) {
    onSubmit(data)
  }

  return (
    <FormProvider {...form}>
      <form
        noValidate
        aria-label={t('activation_groups.edit_resource_form.title')}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Stack spacing={2}>
            <CustomTypography noWrap fontWeight="bold" variant="body2">
              {t('common.uuid')}
            </CustomTypography>
            <CustomTypography noWrap variant="body1">
              {activationGroupResource.uuid}
            </CustomTypography>
            <CustomTypography noWrap fontWeight="bold" variant="body2">
              {t('common.name')}
            </CustomTypography>
            <CustomTypography noWrap variant="body1">
              {activationGroupResource.name}
            </CustomTypography>
          </Stack>

          <TextFieldController
            label={t('activation_groups.assign_resource_form.qualified_capacity_label')}
            name="qualifiedCapacity"
            required={activationGroup.state === ActivationGroupState.LIVE}
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">Watts</InputAdornment>,
              },
            }}
            type="number"
          />

          <TextFieldController
            label={t('activation_groups.assign_resource_form.priority_label')}
            name="activationPriority"
            type="number"
          />

          <CheckboxController
            checked={form.watch('isUsedForMinCapacityPrequalification')}
            label={t('activation_groups.assign_resource_form.is_used_for_min_capacity_prequalification_label')}
            name="isUsedForMinCapacityPrequalification"
          />
        </DialogContent>

        <FormDialogActions isLoading={isLoading} onCancel={onCancel} />
      </form>
    </FormProvider>
  )
}

export default EditResourceDialogForm
