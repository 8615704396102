import { Stack } from '@mui/material'

import { usePermissions } from '@/features/authorization/contexts/PermissionsContext'
import { SiteRevenueExportButton } from '@/features/site/components/revenue/SiteRevenueExportButton'
import { SiteSelector } from '@/features/site/components/SiteSelector'
import type { UseRevenueFiltersResult } from '@/features/site/hooks/useRevenueFilters'
import type { InsightRevenueDateTimeRange } from '@/features/site/types/revenue'

import SiteRevenueNWMarketProgramFilter from './SiteRevenueNWMarketProgramFilter'
import { SiteRevenuePeriodFilter } from './SiteRevenuePeriodFilter'

type SiteDetailsRevenueNWAppBarProps = {
  siteUuid: string
  timezone: string
  revenueFilters: UseRevenueFiltersResult
  limitDateTimeRange: InsightRevenueDateTimeRange
  onExport: () => void
}

export const SiteDetailsRevenueNWAppBar = ({
  siteUuid,
  timezone,
  revenueFilters,
  limitDateTimeRange,
  onExport,
}: Readonly<SiteDetailsRevenueNWAppBarProps>) => {
  const [state, dispatch] = revenueFilters

  const { permissions } = usePermissions()

  function handleMarketProgramsChange(marketPrograms: string[]) {
    dispatch({ type: 'setMarketPrograms', payload: marketPrograms })
  }

  function handleTimeRangeChange(timeRange: InsightRevenueDateTimeRange) {
    dispatch({ type: 'setTimeRange', payload: timeRange })
  }

  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={1} sx={{ width: '100%' }}>
      <Stack direction="row" flex={1} spacing={1}>
        {permissions.has('siteDetails') && <SiteSelector siteUuid={siteUuid} />}

        <SiteRevenueNWMarketProgramFilter marketPrograms={state.marketPrograms} onChange={handleMarketProgramsChange} />

        <SiteRevenuePeriodFilter
          defaultPeriod="allTime"
          limitDateTimeRange={limitDateTimeRange}
          timeRange={state.timeRange}
          timezone={timezone}
          onChange={handleTimeRangeChange}
        />
      </Stack>

      <SiteRevenueExportButton onExport={onExport} />
    </Stack>
  )
}
