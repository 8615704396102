import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import NumericWidget from '@/features/dashboard/components/NumericWidget'
import type { SiteRevenuePerMarketProgram } from '@/features/site/types/revenue'
import { formatCapacity, formatRevenue } from '@/features/site/utils/revenueUtils/formatRevenueData'

type SiteRevenueWidgetsProps = {
  revenues: SiteRevenuePerMarketProgram | null
}

type RevenueMetrics = {
  totalRevenue: number
  offeredCapacity: number
  soldCapacity: number
}

export const SiteRevenueWidgets = ({ revenues }: SiteRevenueWidgetsProps) => {
  const { t } = useTranslation()

  const { totalRevenue, offeredCapacity, soldCapacity } = calculateRevenueMetrics(revenues)
  const revenueColor = getRevenueColor(totalRevenue)

  return (
    <Stack direction="row" gap={3} justifyContent="space-between">
      <NumericWidget
        disabled
        color={revenueColor}
        title={t('site_revenue.total_revenue')}
        value={formatRevenue(t, totalRevenue)}
        valueFontSize={24}
      />
      <NumericWidget
        disabled
        title={t('site_revenue.offered_capacity')}
        value={formatCapacity(t, offeredCapacity)}
        valueFontSize={24}
      />
      <NumericWidget
        disabled
        title={t('site_revenue.sold_capacity')}
        value={formatCapacity(t, soldCapacity)}
        valueFontSize={24}
      />
    </Stack>
  )
}

/**
 * Calculate the total revenue, offered capacity, and sold capacity from the revenue data
 * @param revenues
 * @returns RevenueMetrics
 */
function calculateRevenueMetrics(revenues: SiteRevenuePerMarketProgram | null): RevenueMetrics {
  if (!revenues) {
    return {
      totalRevenue: 0,
      offeredCapacity: 0,
      soldCapacity: 0,
    }
  }

  return Array.from(revenues.values()).reduce(
    (result, revenueData) => {
      if (!revenueData) {
        return result
      }

      // Process each RevenueDataRow array in a single pass
      const rowTotals = revenueData.reduce(
        (acc, row) => {
          return {
            totalRevenue: acc.totalRevenue + row.revenue,
            offeredCapacity: acc.offeredCapacity + row.capacity,
            soldCapacity: acc.soldCapacity + row.soldCapacity,
          }
        },
        {
          totalRevenue: 0,
          offeredCapacity: 0,
          soldCapacity: 0,
        },
      )

      // Add to the accumulated results
      return {
        totalRevenue: result.totalRevenue + rowTotals.totalRevenue,
        offeredCapacity: result.offeredCapacity + rowTotals.offeredCapacity,
        soldCapacity: result.soldCapacity + rowTotals.soldCapacity,
      }
    },
    {
      totalRevenue: 0,
      offeredCapacity: 0,
      soldCapacity: 0,
    },
  )
}

/**
 * Returns the color of the revenue value based on its sign
 * @param revenue
 * @returns 'positive' | 'negative' | 'neutral'
 */
function getRevenueColor(revenue: number): 'positive' | 'negative' | 'neutral' {
  if (revenue > 0) return 'positive'
  if (revenue < 0) return 'negative'
  return 'neutral'
}
