import { useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomSelectField from '@/components/inputs/CustomSelectField'
import PageHeader from '@/components/layouts/PageHeader'
import { FIELD_WIDTH } from '@/constants/layout'
import SpotOnResourceGrid from '@/features/resource/components/SpotOnResourceGrid'
import { useSpotOnEboilerScheduleProgram } from '@/features/resource/contexts/SpotOnEboilerScheduleProgramContext'
import { GET_SPOT_ON_CUSTOMERS_API_ID, getCustomers } from '@/features/resource/endpoints/scheduleBuilder'
import { getCustomerIdOptions } from '@/features/site/utils/getCustomerSortedOptions'
import { sortOptionsAlphabetically } from '@/utils/sortOptionsAlphabetically'

function SpotOnEboilerResourcesPage() {
  const { t } = useTranslation()
  const { spotOnScheduleProgram } = useSpotOnEboilerScheduleProgram()
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null)

  const { data: customers } = useQuery({
    queryKey: [GET_SPOT_ON_CUSTOMERS_API_ID],
    queryFn: () => getCustomers(spotOnScheduleProgram!.id),
    enabled: Boolean(spotOnScheduleProgram),
  })

  const sortedCustomersOptions = useMemo(
    () =>
      sortOptionsAlphabetically<string>(
        getCustomerIdOptions(
          customers?.map((customer) => ({
            ...customer,
            uuid: customer.id,
          })) ?? [],
        ),
      ),
    [customers],
  )

  return (
    <>
      <PageHeader
        isRoot
        appBarContent={
          <CustomSelectField
            showAllOption
            id="customers"
            label={t('resources.customers_filter_label')}
            options={sortedCustomersOptions}
            size="medium"
            sx={{ minWidth: FIELD_WIDTH }}
            value={selectedCustomer ?? ''}
            variant="outlinedWhite"
            onChange={(e) => setSelectedCustomer(e.target.value)}
          />
        }
        pageTag={'spot-on-eboilers'}
        pageTitle={t('spot_on_for_eboilers.title')}
      />
      <SpotOnResourceGrid
        customerIds={selectedCustomer ? [selectedCustomer] : (customers?.map((customer) => customer.id) ?? [])}
      />
    </>
  )
}

export default SpotOnEboilerResourcesPage
