import type { ActivationGroup, ActivationGroupUuid } from '@/features/activationGroup/types'
import { getActivationGroupByUuid } from '@/features/activationGroup/utils/activationGroups'
import type { BidOverviewRowItem } from '@/features/bidding/components/overview/BidsOverviewDataGrid'
import { Status } from '@/features/bidding/constants'
import type { ExtendedBid } from '@/features/bidding/types/bid'
import type { BidOverviewGroup } from '@/features/bidding/types/bidOverview'
import { getBidTotalAccepted, getBidTotalOffered } from '@/features/bidding/utils/calculations/getBidTotal'
import getBidConfig from '@/features/bidding/utils/getBidConfig'
import { getBidDisplayUnit } from '@/features/bidding/utils/getBidDisplayUnit'
import { getLatestExtendedBid } from '@/features/bidding/utils/getLatestBid'

export const buildBidOverviewRowItem = (
  bidOverviewGroup: BidOverviewGroup,
  activationGroups: ActivationGroup[],
  selectedCountry: CountryIdentifier,
): BidOverviewRowItem | undefined => {
  const capacityBids = Object.values(bidOverviewGroup.capacityBids)
  const energyBids = Object.values(bidOverviewGroup.energyBids)

  if ((!capacityBids || capacityBids.length === 0) && (!energyBids || energyBids.length === 0)) {
    return
  }

  const uniqueActivationGroupUuids: ActivationGroupUuid[] = [
    ...new Set([...Object.keys(bidOverviewGroup.capacityBids), ...Object.keys(bidOverviewGroup.energyBids)]),
  ]

  const latestBid = getLatestExtendedBid([...capacityBids, ...energyBids])

  const { offeredVolume: totalOfferedCapacity, acceptedVolume: totalAcceptedCapacity } =
    calculateOfferedAndAcceptedVolumes(capacityBids, selectedCountry)
  const capacityBidUnit = getUnit(capacityBids)
  const capacityBidStatuses = getUniqueStatuses(capacityBids)

  const { offeredVolume: totalOfferedEnergy, acceptedVolume: totalAcceptedEnergy } = calculateOfferedAndAcceptedVolumes(
    energyBids,
    selectedCountry,
  )
  const energyBidUnit = getUnit(energyBids)
  const energyBidStatuses = getUniqueStatuses(energyBids)

  return {
    portfolio: bidOverviewGroup.portfolio,
    marketProgram: bidOverviewGroup.marketProgram,
    deliveryDay: bidOverviewGroup.deliveryDay,
    createdAt: latestBid.createdAt,
    activationGroupCodes: uniqueActivationGroupUuids.map(
      (uuid) => getActivationGroupByUuid(activationGroups, uuid)!.code,
    ),
    capacityBid: {
      uniqueStatuses: capacityBidStatuses,
      totalOfferedVolume: totalOfferedCapacity,
      totalAcceptedVolume: totalAcceptedCapacity,
      unit: capacityBidUnit,
    },
    energyBid: {
      uniqueStatuses: energyBidStatuses,
      totalOfferedVolume: totalOfferedEnergy,
      totalAcceptedVolume: totalAcceptedEnergy,
      unit: energyBidUnit,
    },
  }
}

const getUnit = (bids: ExtendedBid[]): string => {
  if (!bids || bids.length === 0) {
    return ''
  }

  return getBidDisplayUnit(bids[0].ptus[0].offeredVolume.unit)
}

const calculateOfferedAndAcceptedVolumes = (bids: ExtendedBid[], selectedCountry: CountryIdentifier) => {
  if (!bids || bids.length === 0) {
    return { offeredVolume: null, acceptedVolume: null }
  }

  const priceLadderType = getBidConfig(selectedCountry, bids[0].bidType).priceLadderType
  return {
    offeredVolume: bids.reduce((total, bid) => total + getBidTotalOffered(bid.ptus, priceLadderType), 0),
    acceptedVolume: bids.reduce((total, bid) => total + getBidTotalAccepted(bid.ptus, priceLadderType), 0),
  }
}

const getUniqueStatuses = (bids: ExtendedBid[]) => {
  return !bids || bids.length === 0 ? [Status.NO_BID] : [...new Set(bids.map((bid) => bid.status))]
}
