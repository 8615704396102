import type { DateTime } from 'luxon'

import type { BidType, MarketProgram, Status } from '@/features/bidding/constants'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'

export type BidMetaData = {
  id?: number
  version?: number
  portfolio: Portfolio
  deliveryDay: MarketDate
  createdAt: DateTime
  marketProgram: MarketProgram
  status: Status
  activationGroupUuid: string
  bidType: BidType
}

/**
 * This type replaces the Bid type, combining offeredBid and acceptedBid into ptus
 */
export type ExtendedBid = BidMetaData & {
  ptus: ExtendedBidPtu[]
}

export type ExtendedBidPtu = {
  ptu: Ptu
  offeredVolume: Volume
  acceptedVolume?: Volume
  ptuChunks?: PtuChunk[]
}

export type PastBid = ExtendedBid & {
  actualControllable: ForecastPtu[]
}

// TODO: Remove 'activationGroupUuid' from the omitting once we have activation groups on forecasts
export type Forecast = Omit<BidMetaData, 'activationGroupUuid' | 'bidType'> & {
  forecastPtus: ForecastPtu[]
}

export type Ptu = {
  start: DateTime
  end: DateTime
}

export type ForecastPtu = {
  ptu: Ptu
  volume: Volume
}

export type BidPtu = ForecastPtu & {
  ptuChunks?: PtuChunk[]
}

export type MarketProgramBiddingName =
  | 'FCRD_DOWN'
  | 'FCRD_UP'
  | 'FCRN'
  | 'FFR_UP'
  | 'MFRR_UP'
  | 'MFRR_DOWN'
  | 'NORDICS_FCRD_DOWN_STATIC'
  | 'NORDICS_FCRD_UP_STATIC'
  | 'NORDICS_FCRD_DOWN_DYNAMIC'
  | 'NORDICS_FCRD_UP_DYNAMIC'
  | 'NORDICS_FCRN'

export type OfferedAndForecastAndSuggestedPtu = {
  ptu: Ptu
  offeredVolume: Volume
  forecastedVolume?: Volume
  suggestedVolume?: Volume
  offeredPtuChunks?: PtuChunk[]
}

export enum BulkFillMode {
  FORECAST_RATIO = 'forecasted_ratio',
  OFFER_RATIO = 'offer_ratio',
  ACCEPTED_RATIO = 'accepted_ratio',
  ABSOLUTE_VALUE = 'absolute_value',
}

export type SuggestedBid = {
  activationGroupUuid: string
  ptu: Ptu
  volume: Volume
}

export type BidVolumes = {
  version: number
  ptus: ExtendedBidPtu[]
  forecast?: ForecastPtu[]
  suggestedBid?: SuggestedBid[]
}

export type PtuVolumes = {
  ptu: Ptu
  offeredVolume: Volume
  currentAcceptedVolume?: Volume
  acceptedVolume?: Volume
  forecastedVolume?: Volume
}

export enum PortfolioState {
  NOT_LIVE = 'NOT_LIVE',
  LIVE = 'LIVE',
  LIVE_WITHOUT_BIDDING = 'LIVE_WITHOUT_BIDDING',
}

export type Portfolio = {
  code: string
  countryCode: string
  priceArea: string
  partnerCode: string
  partnerName: string
  name: string
  partition?: string
  state?: PortfolioState
}

export type PtuChunk = {
  offeredVolume: Volume
  acceptedVolume?: Volume
  offeredPrice: number
  acceptedPrice?: number
}

export type Volume = { quantity: number; unit: string }
