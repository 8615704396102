import { zodResolver } from '@hookform/resolvers/zod'
import { FileUploadOutlined } from '@mui/icons-material'
import { Alert, Stack } from '@mui/material'
import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import ButtonBar from '@/components/inputs/ButtonBar'
import FileFieldController from '@/components/inputs/FileFieldController'
import MainContentContainer from '@/components/layouts/MainContentContainer'
import Page from '@/components/layouts/Page'
import { useAlertContext } from '@/contexts/AlertContext'
import SubmitButton from '@/features/bidding/components/SubmitButton'
import { useImportTradeMutation } from '@/features/wholesale/hooks/useImportTradeMutation'
import { getTradeImportSchema } from '@/features/wholesale/schemas'
import type { TradeImportForm } from '@/features/wholesale/types'
import { errorHandler } from '@/utils/errorHandler'

const ImportTrade = () => {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()
  const { importTrade, isPending } = useImportTradeMutation()

  async function onSubmit(data: TradeImportForm) {
    try {
      await importTrade(data.fileContent)

      pushAlert({
        message: t('wholesale.trade.import.success.description'),
        severity: 'info',
        title: t('wholesale.trade.import.success.title'),
      })
    } catch (err) {
      const error = errorHandler(err, t('wholesale.trade.import.failure.unknown'))

      pushAlert({
        message: error.message,
        severity: 'error',
        title: t('wholesale.trade.import.failure.title'),
      })
    }
  }

  const form = useForm<TradeImportForm>({
    resolver: zodResolver(getTradeImportSchema(t)),
  })

  const dialogProps = {
    title: t('wholesale.trade.import.confirmation.title'),
    description: t('wholesale.trade.import.confirmation.description'),
  }

  return (
    <>
      <Page
        pageHeaderProps={{
          breadcrumbItems: [{ text: t('component.page_header.wholesale'), to: '/wholesale' }],
          pageTitle: t('component.page_header.import_trade'),
          pageTag: 'wholesale-trade-import',
        }}
      >
        <MainContentContainer>
          <Stack direction="column" mt={2} spacing={2}>
            <Alert severity="warning">{t('wholesale.trade.import.form.usage_warning')}</Alert>

            <FormProvider {...form}>
              <form
                noValidate
                aria-label={t('wholesale.trade.import.form.title')}
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <Stack direction="column" spacing={2}>
                  <CustomTypography data-testid="usage-warning" variant="h3">
                    {t('wholesale.trade.import.form.file_content_label')}
                  </CustomTypography>

                  <FileFieldController
                    required
                    id="fileContent"
                    inputProps={{
                      'data-testid': 'wholesale-trade-import-file-upload',
                    }}
                    name="fileContent"
                  />
                </Stack>

                <ButtonBar alignment="left">
                  <SubmitButton
                    buttonProps={{
                      startIcon: <FileUploadOutlined />,
                      disabled: isPending,
                      'data-testid': 'import-button',
                    }}
                    dialogProps={dialogProps}
                    shouldShowConfirmation={true}
                    onSubmit={form.handleSubmit(onSubmit)}
                  >
                    {t('wholesale.trade.import.form.import_button_label')}
                  </SubmitButton>
                </ButtonBar>
              </form>
            </FormProvider>
          </Stack>
        </MainContentContainer>
      </Page>
    </>
  )
}

export default ImportTrade
