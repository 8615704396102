import type { Dispatch, ReactNode } from 'react'
import { createContext, useContext, useReducer } from 'react'

import {
  BASIC_INFORMATION,
  CONTACT_DETAILS,
  FINANCIAL_INFORMATION,
  MARKET_PROGRAMS,
  NOTIFICATIONS,
  REVIEW,
} from '@/features/site/constants/createSiteSteps'
import type { SiteCreateStep } from '@/features/site/types/siteCreateStep'

interface SiteStepsProviderProps {
  children: ReactNode
}

type State = {
  stepsToShow: SiteCreateStep[]
  activeStep: number
  completed: Record<number, boolean>
  failed: Record<number, boolean>
}

type Actions = SetStepsToShowAction | SetCompletedAction | SetFailedAction | MoveToStepAction

type SetStepsToShowAction = {
  type: 'setStepsToShowAction'
  payload: SiteCreateStep[]
}

type MoveToStepAction = {
  type: 'moveToStep'
  payload: number
}

type SetCompletedAction = {
  type: 'setCompleted'
  payload: Record<number, boolean>
}

type SetFailedAction = {
  type: 'setFailed'
  payload: Record<number, boolean>
}

export const ALL_STEPS: Array<SiteCreateStep> = [
  BASIC_INFORMATION,
  MARKET_PROGRAMS,
  CONTACT_DETAILS,
  NOTIFICATIONS,
  FINANCIAL_INFORMATION,
  REVIEW,
]
export const INDIRECT_SITE_STEPS: Array<SiteCreateStep> = [
  BASIC_INFORMATION,
  MARKET_PROGRAMS,
  CONTACT_DETAILS,
  NOTIFICATIONS,
  REVIEW,
]

const initialState = {
  stepsToShow: ALL_STEPS,
  activeStep: 0,
  completed: {},
  failed: {},
}

const SiteStepsContext = createContext<[State, Dispatch<Actions>]>([initialState, () => {}])

function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'moveToStep': {
      return {
        ...state,
        activeStep: action.payload,
      }
    }
    case 'setStepsToShowAction': {
      return {
        ...state,
        stepsToShow: action.payload,
      }
    }
    case 'setCompleted': {
      return {
        ...state,
        completed: {
          ...state.completed,
          ...action.payload,
        },
      }
    }
    case 'setFailed': {
      return {
        ...state,
        failed: {
          ...state.failed,
          ...action.payload,
        },
      }
    }
    default: {
      return state
    }
  }
}

function SiteStepsProvider(props: Readonly<SiteStepsProviderProps>) {
  const result = useReducer(reducer, initialState)

  return <SiteStepsContext.Provider value={result}>{props.children}</SiteStepsContext.Provider>
}

const useSiteStepsContext = () => useContext(SiteStepsContext)

export { SiteStepsContext, SiteStepsProvider, useSiteStepsContext }
