import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { AmActivation } from '@/features/activation/types/activation'
import { GET_AM_ACTIVATIONS_API_ID, getActivations } from '@/features/site/endpoints/amActivations'
import type { UseQueryOptions } from '@/types/queries'

type ActivationsFromAmQueryParams = {
  siteId: string
}
export type UseActivationsFromAmQueryResult = Omit<UseQueryResult, 'data'> & {
  amActivations: AmActivation[] | null
}

export function useActivationsFromAmQuery(
  params: ActivationsFromAmQueryParams,
  options?: UseQueryOptions<AmActivation[]>,
): UseActivationsFromAmQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_AM_ACTIVATIONS_API_ID, params],
    queryFn: () => {
      return getActivations(params.siteId)
    },
  })

  return { amActivations: data, ...queryResult }
}
