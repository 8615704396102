import { InfoOutlined } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'

type InfoFormTextProps = {
  text: string
}

/**
 * InfoText component displays an info icon and a text message. It is used to provide additional information or context to the user.
 *
 * The icon is displayed on the left side of the text.
 *
 * The text is displayed in a custom typography style.
 */
function InfoText({ text }: Readonly<InfoFormTextProps>) {
  const { t } = useTranslation()

  return (
    <Stack
      sx={{
        gap: 0.75,
        flexDirection: 'row',
      }}
    >
      <InfoOutlined color="info" titleAccess={t('component.info_text.icon')} />

      <CustomTypography variant="body1">{text}</CustomTypography>
    </Stack>
  )
}

export default InfoText
