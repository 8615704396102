import axios from 'axios'

import { CUSTOMERS_API_URL } from '@/features/site/endpoints/customers'
import type {
  CreateServiceRuleRequest,
  EditServiceRuleRequest,
  ServiceRule,
} from '@/features/unavailability/types/serviceRule'

export const GET_SERVICE_RULES_API_ID = 'GET_SERVICE_RULES'

export async function getServiceRules(
  customerUuid: string,
  serviceId: number | undefined,
  location: string | undefined,
): Promise<ServiceRule[]> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  const response = await axios.get(`${CUSTOMER_API_URL}/services/${serviceId}/rules`, {
    params: {
      location,
    },
  })
  return response.data
}

export async function createServiceRule(
  customerUuid: string,
  serviceId: number | undefined,
  serviceRule: CreateServiceRuleRequest,
): Promise<void> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  await axios.post(`${CUSTOMER_API_URL}/services/${serviceId}/rules`, serviceRule)
}

export async function deleteServiceRule(
  customerUuid: string,
  serviceId: number | undefined,
  serviceRuleId: number | undefined,
): Promise<void> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  await axios.delete(`${CUSTOMER_API_URL}/services/${serviceId}/rules/${serviceRuleId}`)
}

export async function editServiceRule(customerUuid: string, serviceRule: EditServiceRuleRequest): Promise<void> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  await axios.put(`${CUSTOMER_API_URL}/services/${serviceRule.serviceId}/rules/${serviceRule.id}`, serviceRule)
}
