import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ResourceCard from '@/features/resource/components/ResourceCard'
import ResourceCustomAttributesCard from '@/features/resource/components/ResourceCustomAttributesCard'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useResourceDetails } from '@/features/resource/pages/ResourceDetailsPage'

const ResourceCustomAttributesPage = () => {
  const { resourceUuid } = useParams()
  const { setActivePage } = useResourceDetails()

  useEffect(() => {
    setActivePage(ResourceRouteInformation.RESOURCE_CUSTOM_ATTRIBUTES.routePath)
  }, [setActivePage])

  if (!resourceUuid) {
    return null
  }

  return <ResourceCard content={<ResourceCustomAttributesCard />} />
}

export default ResourceCustomAttributesPage
