import type {
  BidMetaData,
  ExtendedBid,
  OfferedAndForecastAndSuggestedPtu,
  PtuVolumes,
} from '@/features/bidding/types/bid'

export function bidFromOfferedAndForecastPtus(
  metaData: BidMetaData,
  offeredAndForecastPtus: OfferedAndForecastAndSuggestedPtu[],
): ExtendedBid {
  return {
    ...metaData,
    ptus: offeredAndForecastPtus.map((ptu) => ({
      ptu: ptu.ptu,
      offeredVolume: ptu.offeredVolume,
      ptuChunks: ptu.offeredPtuChunks,
    })),
  }
}

export function bidFromPtuVolumes(metaData: BidMetaData, ptus: PtuVolumes[]): ExtendedBid {
  return {
    ...metaData,
    ptus: ptus.map((ptu) => ({
      ptu: ptu.ptu,
      offeredVolume: ptu.offeredVolume,
      acceptedVolume: ptu.acceptedVolume,
    })),
  }
}
