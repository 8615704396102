import { DialogContent } from '@mui/material'
import { t } from 'i18next'

import CustomDialog from '@/components/feedback/CustomDialog'
import { ResourceLevelRestrictionDetailsForm } from '@/features/site/components/availability/restriction/form/resourceLevelUnavailaibility/ResourceLevelRestrictionDetailsForm'

interface ResourceLevelRestrictionDetailsCreateDialogProps {
  open: boolean
  onClose: () => void
  customerTimeZone: string
  customerLocation: string
  customerUuid: string
}

export const ResourceLevelRestrictionDetailsCreateDialog = (
  props: ResourceLevelRestrictionDetailsCreateDialogProps,
) => {
  return (
    <CustomDialog
      open={props.open}
      size="large"
      title={t('customer_details.tabs.availability.unavailability_form.create_dialog.title')}
      onClose={props.onClose}
    >
      <DialogContent>
        <ResourceLevelRestrictionDetailsForm
          customerLocation={props.customerLocation}
          customerTimeZone={props.customerTimeZone}
          customerUuid={props.customerUuid}
          onClose={props.onClose}
        />
      </DialogContent>
    </CustomDialog>
  )
}
