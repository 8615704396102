import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import belgiumFlagUrl from '@/assets/flags/be.svg'
import czechFlagUrl from '@/assets/flags/cz.svg'
import germanyFlagUrl from '@/assets/flags/de.svg'
import denmarkFlagUrl from '@/assets/flags/dk.svg'
import estoniaFlagUrl from '@/assets/flags/ee.svg'
import spainFlagUrl from '@/assets/flags/es.svg'
import finlandFlagUrl from '@/assets/flags/fi.svg'
import franceFlagUrl from '@/assets/flags/fr.svg'
import unitedKingdomFlagUrl from '@/assets/flags/gb.svg'
import greeceFlagUrl from '@/assets/flags/gr.svg'
import italyFlagUrl from '@/assets/flags/it.svg'
import netherlandsFlagUrl from '@/assets/flags/nl.svg'
import norwayFlagUrl from '@/assets/flags/no.svg'
import polandFlagUrl from '@/assets/flags/pl.svg'
import swedenFlagUrl from '@/assets/flags/se.svg'

export const COUNTRY_FLAGS = {
  be: belgiumFlagUrl,
  cz: czechFlagUrl,
  de: germanyFlagUrl,
  dk: denmarkFlagUrl,
  ee: estoniaFlagUrl,
  es: spainFlagUrl,
  fi: finlandFlagUrl,
  fr: franceFlagUrl,
  gb: unitedKingdomFlagUrl,
  gr: greeceFlagUrl,
  it: italyFlagUrl,
  nl: netherlandsFlagUrl,
  no: norwayFlagUrl,
  pl: polandFlagUrl,
  se: swedenFlagUrl,
}

export type CountryCode = keyof typeof COUNTRY_FLAGS

interface FlagIconProps {
  countryCode: CountryCode
  sx?: BoxProps['sx']
}

const FlagIcon = ({ countryCode, sx }: FlagIconProps) => {
  const flagUrl = COUNTRY_FLAGS[countryCode]

  return !flagUrl ? null : (
    <Box
      component="span"
      sx={{
        display: 'flex',
        width: '22px',
        height: '16px',
        ...sx,
      }}
    >
      <img alt={countryCode} src={flagUrl} />
    </Box>
  )
}

export default FlagIcon
