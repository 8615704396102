import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { updateActivationGroupSchedule } from '@/features/activationGroup/endpoints/activationGroups'
import type {
  GenericActivationGroupSchedule,
  GenericApiUpdateActivationGroupSchedule,
} from '@/features/activationGroup/types'
import type { UseMutationOptions } from '@/types/queries'

export type UseGenericUpdateActivationGroupScheduleMutationParams = {
  activationGroupScheduleUuid: string
}
export type UseGenericUpdateActivationGroupScheduleMutationResult = Omit<
  UseMutationResult<GenericActivationGroupSchedule, Error, GenericApiUpdateActivationGroupSchedule>,
  'mutateAsync'
> & {
  updateActivationGroupSchedule: UseMutationResult<
    GenericActivationGroupSchedule,
    Error,
    GenericApiUpdateActivationGroupSchedule
  >['mutateAsync']
}

export function useGenericUpdateActivationGroupScheduleMutation(
  params: UseGenericUpdateActivationGroupScheduleMutationParams,
  options?: UseMutationOptions<GenericActivationGroupSchedule, GenericApiUpdateActivationGroupSchedule>,
): UseGenericUpdateActivationGroupScheduleMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: GenericApiUpdateActivationGroupSchedule) =>
      updateActivationGroupSchedule(params.activationGroupScheduleUuid, variables),
  })

  return { updateActivationGroupSchedule: mutateAsync, ...mutationResult }
}
