import type { GridColDef, GridRowParams } from '@mui/x-data-grid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_FILTER_MODEL, DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import ActivationGroupScheduleResourcesDataGridToolbar from '@/features/activationGroup/components/ActivationGroupScheduleResourcesDataGridToolbar'
import AssignScheduleResourceDrawer from '@/features/activationGroup/components/AssignScheduleResourceDrawer'
import { useGenericActivationGroupScheduleResourcesQuery } from '@/features/activationGroup/hooks/useGenericActivationGroupScheduleResourcesQuery'
import type { ApiActivationGroupResource, GenericActivationGroupSchedule } from '@/features/activationGroup/types'
import { ResourceRouteInformation } from '@/features/resource/constants'

type ActivationGroupScheduleResourcesDataGridProps = {
  activationGroupSchedule: GenericActivationGroupSchedule
  activationGroupUuid: string
}

const ActivationGroupScheduleResourcesDataGrid = ({
  activationGroupSchedule,
  activationGroupUuid,
}: ActivationGroupScheduleResourcesDataGridProps) => {
  const { t } = useTranslation()
  const { resources, isLoading } = useGenericActivationGroupScheduleResourcesQuery({
    activationGroupScheduleUuid: activationGroupSchedule.uuid,
    pagination: {
      page: 0,
      pageSize: 100,
    },
  })
  const [openAssignResourceDialog, setOpenAssignResourceDialog] = useState(false)

  const columns: GridColDef<ApiActivationGroupResource>[] = [
    {
      field: 'uuid',
      headerName: t('common.uuid'),
      flex: 1,
    },
    {
      field: 'name',
      headerName: t('common.name'),
      flex: 2,
    },
  ]

  function handleOpenAssignResourceDialog() {
    setOpenAssignResourceDialog(true)
  }

  function handleCloseAssignResourceDialog() {
    setOpenAssignResourceDialog(false)
  }

  return (
    <>
      <CustomDataGrid
        disableColumnMenu
        disableColumnSorting
        aria-label={t('activation_groups.schedules.resources.table.title')}
        clickableRows={{
          navigateTo: ({ row }: GridRowParams<ApiActivationGroupResource>) =>
            ResourceRouteInformation.RESOURCE_DETAILS.navigationPath(row.uuid),
        }}
        columns={columns}
        getRowId={(row) => row.uuid}
        initialState={{
          filter: {
            filterModel: DEFAULT_DATA_GRID_FILTER_MODEL,
          },
          pagination: {
            paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
          },
          sorting: {
            sortModel: [],
          },
        }}
        isLoading={isLoading}
        rows={resources?.results ?? []}
        slotProps={{
          toolbar: {
            onAssignResourceToActivationGroup: handleOpenAssignResourceDialog,
            activationGroupSchedule: activationGroupSchedule,
          },
        }}
        slots={{ toolbar: ActivationGroupScheduleResourcesDataGridToolbar }}
        title={t('activation_groups.schedules.resources.table.title')}
      />

      {/* We do this to avoid pre-fetching data when is not actually being rendered */}
      {openAssignResourceDialog && (
        <AssignScheduleResourceDrawer
          activationGroupSchedule={activationGroupSchedule}
          activationGroupUuid={activationGroupUuid}
          open={openAssignResourceDialog}
          scheduleResources={resources?.results ?? []}
          onClose={handleCloseAssignResourceDialog}
        />
      )}
    </>
  )
}

export default ActivationGroupScheduleResourcesDataGrid
