import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useTheme } from '@mui/material/styles'
import type { ReactElement } from 'react'
import { forwardRef } from 'react'
import type { NavLinkProps as RouterNavLinkProps } from 'react-router-dom'
import { NavLink as RouterNavLink, useMatch } from 'react-router-dom'

import environment from '@/environment'

const Link = forwardRef<HTMLAnchorElement, RouterNavLinkProps>(function Link(itemProps, ref) {
  return <RouterNavLink ref={ref} {...itemProps} role={undefined} />
})

function getMargin(envTheme, theme) {
  if (envTheme === 'sympower') return theme.spacing(0)
}

type ListItemLinkProps = {
  icon: ReactElement
  activePath: string
  primary: string
  to: string
}

function ListItemLink({ icon, activePath, primary, to }: Readonly<ListItemLinkProps>) {
  const theme = useTheme()
  const envTheme = environment.theme

  const isActive = useMatch(activePath) !== null

  return (
    <li style={{ margin: getMargin(envTheme, theme) }}>
      <ListItem component={Link} sx={{ backgroundColor: isActive ? theme.palette.action.selected : '' }} to={to}>
        <ListItemIcon sx={{ color: theme.palette.action.active }}>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  )
}

export default ListItemLink
