import { useMutation } from '@tanstack/react-query'

import { updateSiteInformation } from '@/features/site/endpoints/sites'
import type { UpdateSiteInformation } from '@/features/site/types/site'

export type UpdateSiteInformationMutationResult = ReturnType<typeof useUpdateSiteInformationMutation>

type UpdateSiteInformationMutationVariables = {
  siteUuid: string
  siteInformation: UpdateSiteInformation
}

export function useUpdateSiteInformationMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: ({ siteUuid, siteInformation }: UpdateSiteInformationMutationVariables) =>
      updateSiteInformation(siteUuid, siteInformation),
  })

  return {
    updateSiteInformation: mutateAsync,
    ...mutationResult,
  }
}
