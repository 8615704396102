import AddIcon from '@mui/icons-material/Add'
import { Box, Tab, Tabs } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import ButtonBar from '@/components/inputs/ButtonBar'
import CustomButton from '@/components/inputs/CustomButton'
import PageHeader from '@/components/layouts/PageHeader'
import TabPanel from '@/components/layouts/TabPanel'
import { useActivationGroupsQuery } from '@/features/activationGroup/hooks/useActivationGroupsQuery'
import { BidHistoriesByTypeView } from '@/features/bidding/components/bidView/BidHistoriesByTypeView'
import CountrySelector from '@/features/bidding/components/CountrySelector'
import { BidType } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { useHasCapacityBidHistories, useHasEnergyBidHistories } from '@/features/bidding/hooks/useHasBidHistories'
import { buildBidViewLink } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import getGroupedBidParamsFromUrl from '@/features/bidding/utils/groupedBidParams/getGroupedBidParamsFromUrl'

const BidHistoriesView = () => {
  const { t } = useTranslation()
  const { selectedCountry } = useBiddingContext()
  const location = useLocation()
  const navigate = useNavigate()
  const groupParams = getGroupedBidParamsFromUrl(location)
  const { activationGroups } = useActivationGroupsQuery({ countryCode: selectedCountry })
  const { isLoading: isLoadingCapacityBids, hasBidHistories: hasCapacityBids } = useHasCapacityBidHistories(
    selectedCountry,
    groupParams,
  )
  const { isLoading: isLoadingEnergyBids, hasBidHistories: hasEnergyBids } = useHasEnergyBidHistories(
    selectedCountry,
    groupParams,
  )
  const [activeTab, setActiveTab] = useState(-1)

  useEffect(() => {
    if (isLoadingCapacityBids || isLoadingEnergyBids) {
      return
    }

    let newActiveTab = -1

    if (hasCapacityBids && groupParams?.bidType === BidType.CAPACITY) {
      newActiveTab = 0
    } else if (hasEnergyBids && groupParams?.bidType === BidType.ENERGY) {
      newActiveTab = 1
    } else {
      newActiveTab = 0
    }

    setActiveTab(newActiveTab)
  }, [isLoadingCapacityBids, isLoadingEnergyBids, hasCapacityBids, hasEnergyBids, groupParams?.bidType])

  if (activeTab == -1 || isLoadingCapacityBids || isLoadingEnergyBids || !groupParams || !activationGroups) {
    return <FullPageSpinner />
  }

  return (
    <>
      <PageHeader
        appBarContent={
          <Box alignItems="center" display="flex" flexDirection="row" sx={{ width: '100%' }}>
            <Box display="flex" flexDirection="row" sx={{ flex: 1, justifyContent: 'left' }}>
              <CountrySelector disabled />
            </Box>
            <ButtonBar alignment="right" sx={{ flex: 1 }}>
              <CustomButton
                color="inheritText"
                component={Link}
                startIcon={<AddIcon />}
                sx={{ color: 'black' }}
                to="/bidding/todo-list"
                variant="contained"
              >
                {t(`bidding.overview.add_new_bid`)}
              </CustomButton>
            </ButtonBar>
          </Box>
        }
        breadcrumbItems={[{ text: t('component.page_header.bidding'), to: '/bidding' }]}
        pageTitle={t('component.page_header.bid.results.label')}
      />

      <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          scrollButtons="auto"
          style={{ minHeight: 0 }}
          value={activeTab}
          variant="scrollable"
          onChange={(_, newValue) => {
            setActiveTab(newValue)
            navigate(
              buildBidViewLink({
                deliveryDay: groupParams?.deliveryDay,
                portfolioCode: groupParams?.portfolioCode,
                marketProgram: groupParams?.marketProgram,
                bidType: newValue === 0 ? BidType.CAPACITY : BidType.ENERGY,
              }),
            )
          }}
        >
          {hasCapacityBids && <Tab label={t('bidding.results.capacity_bids')} style={{ minHeight: 0 }} value={0} />}
          {hasEnergyBids && <Tab label={t('bidding.results.energy_bids')} style={{ minHeight: 0 }} value={1} />}
        </Tabs>
      </Box>
      {hasCapacityBids && (
        <TabPanel index={0} value={activeTab}>
          <BidHistoriesByTypeView activationGroups={activationGroups} bidType={BidType.CAPACITY} />
        </TabPanel>
      )}
      {hasEnergyBids && (
        <TabPanel index={1} value={activeTab}>
          <BidHistoriesByTypeView activationGroups={activationGroups} bidType={BidType.ENERGY} />
        </TabPanel>
      )}
    </>
  )
}

export default BidHistoriesView
