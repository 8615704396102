import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import InfoText from '@/components/dataDisplay/InfoText'
import MarketProgramsEditTable from '@/features/site/components/marketPrograms/MarketProgramsEditTable'

function MarketProgramsStep() {
  const { t } = useTranslation()

  return (
    <Stack gap={4}>
      <InfoText text={t('sites.add_new.form.market_programs.info')} />

      <MarketProgramsEditTable />
    </Stack>
  )
}

export default MarketProgramsStep
