import { useMutation } from '@tanstack/react-query'

import { updateCustomerContact } from '@/features/site/endpoints/customers'
import type { CustomerContact } from '@/features/site/types/customer'

export type UseUpdateCustomerContactMutationResult = ReturnType<typeof useUpdateCustomerContactMutation>

export function useUpdateCustomerContactMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (variables: { customerUuid: string; contact: CustomerContact }) =>
      updateCustomerContact(variables.customerUuid, variables.contact),
  })

  return {
    updateCustomerContact: mutateAsync,
    ...mutationResult,
  }
}
