import type { FieldPath, FieldValues } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'

import LabeledTextField from '@/components/dataDisplay/LabeledTextField'

import type { PartnerFieldControllerProps } from './PartnerFieldController'
import { PartnerFieldController } from './PartnerFieldController'

type EditablePartnerFieldControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = PartnerFieldControllerProps<TFieldValues, TName> & {
  isEditable?: boolean
  label: string
}

export function EditablePartnerFieldController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  isEditable = false,
  ...partnerFieldControllerProps
}: EditablePartnerFieldControllerProps<TFieldValues, TName>) {
  const { watch } = useFormContext<TFieldValues>()

  if (isEditable) {
    return <PartnerFieldController label={label} {...partnerFieldControllerProps} />
  }

  const partnerCode = watch(partnerFieldControllerProps.name)
  const partnerName =
    partnerFieldControllerProps.partners.find((partner) => partner.partnerCode === partnerCode)?.name ?? ''

  return <LabeledTextField label={label ?? ''} text={partnerName} />
}
