import { Box, Grid, Stack } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import InfoText from '@/components/dataDisplay/InfoText'
import CheckboxController from '@/components/inputs/CheckboxController'
import EmailFieldController from '@/features/site/components/EmailFieldController'
import LanguageFieldController from '@/features/site/components/LanguageFieldController'
import { PhoneNumberListFieldController } from '@/features/site/components/PhoneNumberListFieldController'
import { SITE_STEPS_FIELD_WIDTH } from '@/features/site/constants'
import type { CreateSiteForm } from '@/features/site/types/site'

const MAX_PHONE_COUNT = 10

function NotificationsStep() {
  const { t } = useTranslation()
  const { watch, setValue, trigger } = useFormContext<CreateSiteForm>()

  const isEmailEnabled = watch('notificationPreferences.isEmailEnabled')
  const isSmsEnabled = watch('notificationPreferences.isSmsEnabled')

  return (
    <Grid container direction="column" gap={5}>
      <Grid size={{ md: 8, sm: 6 }}>
         <InfoText text={t('sites.add_new.form.notifications.info')} />
      </Grid>
      <Grid width={SITE_STEPS_FIELD_WIDTH}>
        <LanguageFieldController name="notificationPreferences.language" />
      </Grid>

      <Grid width={SITE_STEPS_FIELD_WIDTH}>
        <Stack flexDirection="column" gap={2}>
          <Box>
            <CustomTypography
              sx={{
                marginBottom: 1,
              }}
              variant="h5"
            >
              {t('sites.add_new.form.notifications.message_types.label')}
            </CustomTypography>

            <Stack flexDirection="row" gap={3.75}>
              <CheckboxController
                label={t('common.email')}
                name="notificationPreferences.isEmailEnabled"
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  const { checked } = evt.target

                  setValue('notificationPreferences.isEmailEnabled', checked)

                  // We need to trigger validation for the email numbers when Email checkbox change
                  trigger('notificationPreferences.email')
                }}
              />

              <CheckboxController
                label={t('sites.add_new.form.notifications.message_types.sms')}
                name="notificationPreferences.isSmsEnabled"
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  const { checked } = evt.target

                  setValue('notificationPreferences.isSmsEnabled', checked)

                  // We need to trigger validation for the phone numbers when SMS checkbox change
                  trigger('notificationPreferences.phoneNumbers.0')
                }}
              />
            </Stack>
          </Box>

          <Stack flexDirection="column" gap={5}>
            <EmailFieldController
              isEmailEnabled={isEmailEnabled}
              label={t('common.email')}
              name="notificationPreferences.email"
              required={isEmailEnabled}
            />

            <PhoneNumberListFieldController
              TextFieldControllerProps={{
                label: t('common.phone'),
              }}
              isSmsEnabled={isSmsEnabled}
              maxPhoneCount={MAX_PHONE_COUNT}
              name="notificationPreferences.phoneNumbers"
            />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default NotificationsStep
