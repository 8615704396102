import { Stack } from '@mui/material'
import type { SelectChangeEvent } from '@mui/material/Select'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { SelectFieldOption } from '@/components/inputs/CustomSelectField'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import { FIELD_WIDTH } from '@/constants/layout'
import type { ResourceGlobalFilters, ResourceType } from '@/features/resource/types'
import { getResourceTypeOptions } from '@/features/resource/utils/resourceTypes'
import type { Customer } from '@/features/site/types/customer'
import { getCustomerIdOptions } from '@/features/site/utils/getCustomerSortedOptions'
import { sortOptionsAlphabetically } from '@/utils/sortOptionsAlphabetically'

export type ResourcesAppBarContentProps = {
  customers: Customer[]
  resourceTypes: ResourceType[]
  countryCodeOptions: SelectFieldOption<string>[]
  globalFilters: ResourceGlobalFilters
  onCustomerChange: (event: SelectChangeEvent) => void
  onResourceTypeChange: (event: SelectChangeEvent) => void
  onCountryCodeChange: (event: SelectChangeEvent) => void
}

const ResourcesAppBarContent = ({
  customers,
  resourceTypes,
  globalFilters,
  countryCodeOptions,
  onCustomerChange,
  onResourceTypeChange,
  onCountryCodeChange,
}: ResourcesAppBarContentProps) => {
  const { t } = useTranslation()
  const sortedResourceTypeOptions = useMemo(
    () => sortOptionsAlphabetically<ResourceType>(getResourceTypeOptions(resourceTypes, t)),
    [resourceTypes],
  )
  const sortedCustomersOptions = useMemo(
    () => sortOptionsAlphabetically<string>(getCustomerIdOptions(customers)),
    [customers],
  )

  return (
    <Stack sx={{ flexDirection: 'row', gap: 1 }}>
      <CustomSelectField
        showAllOption
        id="country-code"
        label={t('resources.country_code_filter_label')}
        options={countryCodeOptions}
        size="medium"
        sx={{ minWidth: FIELD_WIDTH }}
        value={globalFilters.countryCode ?? ''}
        variant="outlinedWhite"
        onChange={onCountryCodeChange}
      />

      <CustomSelectField
        showAllOption
        id="customers"
        label={t('resources.customers_filter_label')}
        options={sortedCustomersOptions}
        size="medium"
        sx={{ minWidth: FIELD_WIDTH }}
        value={globalFilters.customerId ?? ''}
        variant="outlinedWhite"
        onChange={onCustomerChange}
      />

      <CustomSelectField
        showAllOption
        id="resource-type"
        label={t('resources.resource_types_filter_label')}
        options={sortedResourceTypeOptions}
        size="medium"
        sx={{ minWidth: FIELD_WIDTH }}
        value={globalFilters.resourceType ?? ''}
        variant="outlinedWhite"
        onChange={onResourceTypeChange}
      />
    </Stack>
  )
}

export default ResourcesAppBarContent
