import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import Page from '@/components/layouts/Page'
import { DataGridSyncUrlManagerProvider } from '@/contexts/DataGridSyncUrlManagerContext'
import { ProtectedRoute } from '@/features/authentication/components/ProtectedRoute'
import { usePermissions } from '@/features/authorization/contexts/PermissionsContext'
import NavigateToError from '@/features/error/pages/NavigateToError'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import { SiteDetailsRouteInformation } from '@/features/site/pages/constants'
import { PowerMeasurements } from '@/features/site/pages/PowerMeasurements'
import { ResourceLevelUnavailability } from '@/features/site/pages/ResourceLevelUnavailability'
import { SiteCompanyInfo } from '@/features/site/pages/SiteCompanyInfo'
import SiteCreate from '@/features/site/pages/SiteCreate'
import SiteDetails from '@/features/site/pages/SiteDetails'
import { SiteDetailsActivationDetails } from '@/features/site/pages/SiteDetailsActivationDetails'
import { SiteDetailsActivations } from '@/features/site/pages/SiteDetailsActivations'
import { SiteDetailsCompanyInfo } from '@/features/site/pages/SiteDetailsCompanyInfo'
import { SiteDetailsMarketPrograms } from '@/features/site/pages/SiteDetailsMarketPrograms'
import { SiteDetailsRevenue } from '@/features/site/pages/SiteDetailsRevenue'
import { SiteDetailsSettings } from '@/features/site/pages/SiteDetailsSettings'
import { SiteDetailsUnavailability } from '@/features/site/pages/SiteDetailsUnavailability'
import { SitePowerMeasurements } from '@/features/site/pages/SitePowerMeasurements'
import { SiteRevenue } from '@/features/site/pages/SiteRevenue'
import Sites from '@/features/site/pages/Sites'
import { SiteSettingsForRoUser } from '@/features/site/pages/SiteSettingsForRoUser'
import { SiteUnavailability } from '@/features/unavailability/pages/SiteUnavailability'

import { SiteStepsProvider } from './contexts/SiteStepsContext'

export default function SitesRoutes() {
  const { t } = useTranslation()
  const { isEnabled } = useFeatureToggle()

  const { permissions } = usePermissions()

  return (
    <Routes>
      <Route element={<ProtectedRoute permission="site" />}>
        <Route element={<SiteUnavailability />} path={'/unavailability'} />
        <Route element={<SiteCompanyInfo />} path="/company-info" />
        <Route element={<SiteSettingsForRoUser />} path="/settings" />
        <Route element={<ProtectedRoute permission="revenues" />}>
          <Route element={<SiteRevenue />} path="/revenue" />
        </Route>
        <Route element={<ProtectedRoute permission="powerMeasurements" />}>
          <Route
            element={
              <Page
                pageHeaderProps={{
                  pageTag: 'power-measurements',
                  hiddenTitle: true,
                  hiddenAppBar: true,
                  pageTitle: t('customer_measurements.power_measurements.title'),
                }}
              >
                <PowerMeasurements />
              </Page>
            }
            path="/power-measurements"
          />
        </Route>
      </Route>
      {isEnabled('FLEXPORTAL_SITE_CREATE_ENABLED') && (
        <Route element={<ProtectedRoute permission="siteCreation" />}>
          <Route
            element={
              <Page
                pageHeaderProps={{
                  pageTag: 'site-create',
                  hiddenBackButton: true,
                  hiddenAppBar: true,
                  pageTitle: t('component.page_header.site.create'),
                }}
              >
                <SiteStepsProvider>
                  <SiteCreate />
                </SiteStepsProvider>
              </Page>
            }
            path="/create"
          />
        </Route>
      )}
      <Route element={<ProtectedRoute permission="siteDetails" />}>
        <Route
          element={
            <DataGridSyncUrlManagerProvider>
              <Sites />
            </DataGridSyncUrlManagerProvider>
          }
          path="/"
        />
        <Route element={<SiteDetails />} path="/:siteUuid">
          <Route index element={<SiteDetailsCompanyInfo />} />
          <Route element={<SiteDetailsCompanyInfo />} path={SiteDetailsRouteInformation.SITE_INFO.routePath} />
          <Route element={<SiteDetailsUnavailability />} path={SiteDetailsRouteInformation.UNAVAILABILITY.routePath} />
          <Route
            element={<ResourceLevelUnavailability />}
            path={SiteDetailsRouteInformation.RES_LEVEL_UNAVAILABILITY.routePath}
          />
          <Route element={<SiteDetailsActivations />} path={SiteDetailsRouteInformation.ACTIVATIONS.routePath} />
          <Route element={<SitePowerMeasurements />} path={SiteDetailsRouteInformation.POWER_MEASUREMENTS.routePath} />
          <Route
            element={<SiteDetailsActivationDetails />}
            path={SiteDetailsRouteInformation.ACTIVATION_DETAILS.routePath}
          />
          <Route element={<SiteDetailsRevenue />} path={SiteDetailsRouteInformation.REVENUE.routePath} />
          {isEnabled('FLEX_PORTAL_SITE_MARKET_PROGRAM_CONTRACTS') && permissions.has('siteMarketPrograms') && (
            <Route
              element={<SiteDetailsMarketPrograms />}
              path={SiteDetailsRouteInformation.MARKET_PROGRAMS.routePath}
            />
          )}
          <Route element={<SiteDetailsSettings />} path={SiteDetailsRouteInformation.SETTINGS.routePath} />
        </Route>
      </Route>

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}
