import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import PublicIcon from '@mui/icons-material/Public'
import { Stack, Tooltip } from '@mui/material'
import type { GridColDef, GridSortModel } from '@mui/x-data-grid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import FlagIcon from '@/components/dataDisplay/FlagIcon'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { USER_ROLES } from '@/constants/userRoles'
import environment from '@/environment'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { PartnerFormDialog } from '@/features/partner/components/PartnerFormDialog'
import PartnersDataGridToolbar, {
  DEFAULT_DATA_GRID_PARTNERS_FILTER_MODEL,
} from '@/features/partner/components/PartnersDataGridToolbar'
import { useInvalidatePartnersQuery, usePartnersQuery } from '@/features/partner/hooks/usePartnersQuery'
import type { Partner } from '@/openapi/v1/msa-customer-partner-manager/partner'
import { deserializeFilterModelFromSearchParams } from '@/utils/datagrid/filters'
import { deserializePaginationModelFromSearchParams } from '@/utils/datagrid/pagination'
import { deserializeSortModelFromSearchParams } from '@/utils/datagrid/sorting'

const DEFAULT_DATA_GRID_PARTNERS_SORT_MODEL: GridSortModel = [{ field: 'name', sort: 'asc' }]

function Partners() {
  const { loggedInUser } = useAuth()
  const { t } = useTranslation()
  const locationInfos: LocationInfo[] = environment.getLocationsList()
  const [selectedPartner, setSelectedPartner] = useState({} as Partner)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const handleOpenDialog = (partner: Partner, editMode: boolean) => {
    setSelectedPartner(partner)
    setDialogOpen(true)
    setEditMode(editMode)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
    setSelectedPartner({} as Partner)
    setEditMode(false)
  }

  const { partners, isFetching } = usePartnersQuery({ enabled: isCurrentUserAdmin() })
  const invalidatePartnersQuery = useInvalidatePartnersQuery()

  function getCountryOptions(): CountryIdentifier[] {
    if (!partners) return []

    return Array.from(
      new Set(partners.filter((p) => !!p.countryCode).map((p) => p.countryCode!.toLowerCase() as CountryIdentifier)),
    )
  }

  function isCurrentUserAdmin() {
    return loggedInUser?.role === USER_ROLES.ADMINISTRATORS.value
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('partners.partner_name'),
      flex: 1,
    },
    {
      field: 'partnerCode',
      headerName: t('partners.symbolic_name'),
      renderHeader: () => (
        <Stack alignItems="center" className={'MuiDataGrid-columnHeaderTitle'} direction="row" gap={1}>
          <strong style={{ fontWeight: 500 }}>{t('partners.symbolic_name')}</strong>
          <Tooltip title={t('partners.symbolic_name_hint')}>
            <HelpOutlineIcon fontSize="small" sx={{ color: '#000' }} />
          </Tooltip>
        </Stack>
      ),
      flex: 1,
    },
    {
      field: 'countryCode',
      headerName: t('common.country.label'),
      flex: 1,
      type: 'singleSelect',
      valueOptions: getCountryOptions(),
      getOptionLabel: (value) => (value ? t(`common.country.${value as CountryIdentifier}`) : '-'),
      valueGetter: (value: Partner['countryCode']) => value?.toLowerCase(),
      renderHeader: () => (
        <Tooltip title={t('common.country.label')}>
          <PublicIcon />
        </Tooltip>
      ),
      renderCell: (params) => (
        <Stack sx={{ flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
          <FlagIcon countryCode={params.row.countryCode?.toLowerCase()} />
        </Stack>
      ),
    },
  ]

  return (
    <>
      <CustomDataGrid
        clickableRows={{
          onRowClick: (params) => {
            handleOpenDialog(params.row, true)
          },
        }}
        columns={columns}
        getRowId={(row) => row.uuid}
        initialState={{
          filter: {
            filterModel: deserializeFilterModelFromSearchParams() ?? DEFAULT_DATA_GRID_PARTNERS_FILTER_MODEL,
          },
          pagination: {
            paginationModel: deserializePaginationModelFromSearchParams() ?? DEFAULT_DATA_GRID_PAGINATION_MODEL,
          },
          sorting: {
            sortModel: deserializeSortModelFromSearchParams() ?? DEFAULT_DATA_GRID_PARTNERS_SORT_MODEL,
          },
        }}
        isLoading={isFetching}
        rows={partners?.filter((partner) => partner.partnerCode !== 'SYMPOWER') ?? []}
        slotProps={{
          toolbar: {
            countryOptions: getCountryOptions(),
            onClickAddNewPartner: () => handleOpenDialog({} as Partner, false),
          },
        }}
        slots={{
          toolbar: PartnersDataGridToolbar,
        }}
      />

      {dialogOpen && (
        <PartnerFormDialog
          editMode={editMode}
          locationInfos={locationInfos}
          open={dialogOpen}
          partner={selectedPartner}
          onClose={handleCloseDialog}
          onSave={() => invalidatePartnersQuery()}
        />
      )}
    </>
  )
}

export default Partners
