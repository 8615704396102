import { styled } from '@mui/material/styles'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'

export const StyledCustomDataGrid = styled(CustomDataGrid)(({ theme }) => ({
  '& .MuiDataGrid-row--detailPanelExpanded': {
    background: 'rgba(20, 132, 160, 0.08) !important',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiDataGrid-row:hover:not(.MuiDataGrid-row--detailPanelExpanded)': {
    background: 'rgba(20, 132, 160, 0.04) !important',
  },
  borderBottom: `1px solid ${theme.palette.divider}`,
}))
