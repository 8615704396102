import type { MarketProgramType } from '@/types/marketProgramType'

/**
 * This is necessary because currently we have two different market program representations:
 * 1. 'FCR_D_UP_DYNAMIC', 'FCR_D_DOWN_DYNAMIC', 'FCR_D_UP_STATIC', 'FCR_N'
 * 2. 'fcr-d-up-dynamic', 'fcr-d-down-dynamic', 'fcr-d-up-static', 'fcr-n'
 *
 * We called the second representation MarketProgramType, and currently we linked the translations to this representation.
 *
 * The second representation is going to be the standard way to represent market programs in the future, but
 * it requires some work from the backend to be able to use it.
 *
 * This function converts the first representation to the second one, so market programs can be translated properly.
 */
export function convertToStandardMarketProgramType(standardMarketProgram: string): MarketProgramType {
  return standardMarketProgram.toLocaleLowerCase().replaceAll('_', '-') as MarketProgramType
}
