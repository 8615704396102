import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import AuthenticatedAppRoutes from '@/AuthenticatedAppRoutes'
import environment from '@/environment'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { usePermissions } from '@/features/authorization/contexts/PermissionsContext'
import ErrorShell from '@/features/error/components/ErrorShell'
import Error500 from '@/features/error/pages/Error500'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import { useAnalytics } from '@/features/googleAnalytics/hooks/useAnalytics'

export default function AuthenticatedApp() {
  const { isLoading: isFeatureToggleLoading, error: featureToggleError } = useFeatureToggle()
  const { isLoading: isPermissionsLoading, error: permissionsError } = usePermissions()
  const { error: authError } = useAuth()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { sendAnalyticsPageView } = useAnalytics()

  const environmentName = environment.environmentName

  // When loading the feature toggle keys the first time, we want to wait until it finishes.
  // The main reason is that we need to use those feature flags to build the router. Loading the router without
  // waiting the feature flag request to be completed can end up producing unexpected behaviours.
  const isAppLoading = isFeatureToggleLoading || isPermissionsLoading

  useEffect(() => {
    // When redirectTo parameter exists, it's because a user tried to access to a page without being logged. This useEffect makes sure
    // that we redirect the user to the page he was trying to access after he logs in.
    if (searchParams.has('redirectTo')) {
      navigate(searchParams.get('redirectTo') as string)
    }
  }, [])

  // TODO: Move later to an analytics context provider
  useEffect(() => {
    sendAnalyticsPageView(location.pathname)
  }, [environmentName, location.pathname])

  // If there is any error when authenticating the user, we show the 500 error page
  if (authError || featureToggleError || permissionsError) {
    return (
      <ErrorShell>
        <Error500 />
      </ErrorShell>
    )
  }

  if (isAppLoading) {
    return null
  }

  return <AuthenticatedAppRoutes />
}
