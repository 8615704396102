import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CheckboxController from '@/components/inputs/CheckboxController'
import TextFieldController from '@/components/inputs/TextFieldController'
import IbanFieldController from '@/features/site/components/IbanFieldController'
import { SITE_STEPS_FIELD_WIDTH } from '@/features/site/constants'
import type { CreateSiteForm } from '@/features/site/types/site'
import { getPlaceholderIbanByCountryCode } from '@/utils/iban'

function FinancialInformationStep() {
  const { t } = useTranslation()
  const { getValues, trigger } = useFormContext<CreateSiteForm>()

  const countryCode = getValues('countryCode')

  return (
    <Grid container direction="column" gap={5}>
      <Grid width={SITE_STEPS_FIELD_WIDTH}>
        <IbanFieldController
          label={t('sites.add_new.form.financial_information.iban_label')}
          name="financialInformation.iban"
          placeholder={getPlaceholderIbanByCountryCode(countryCode ?? '')}
          onBlur={() => {
            trigger(['financialInformation.iban', 'financialInformation.ibanHolder'])
          }}
        />
      </Grid>

      <Grid width={SITE_STEPS_FIELD_WIDTH}>
        <TextFieldController
          label={t('sites.add_new.form.financial_information.iban_holder_label')}
          name="financialInformation.ibanHolder"
          onBlur={() => {
            trigger(['financialInformation.iban', 'financialInformation.ibanHolder'])
          }}
        />
      </Grid>

      <Grid>
        <CheckboxController
          label={t('sites.add_new.form.financial_information.show_financial_information_label')}
          name="financialInformation.isFinancialVisible"
        />
      </Grid>
    </Grid>
  )
}

export default FinancialInformationStep
