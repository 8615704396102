/**
 * Will parse the text from the clipboard to an array of PTU values
 * It treats small numbers (< 1000) as MW and converts to Watts
 * Bigger numbers are treated as Watts
 * The returned values, in watts, are truncated to be multiples of 10kW (or 0.01MW)
 */
export function parseTextToPtuValues(text: string) {
  const lines = text.split('\n').filter((value) => value !== '')
  const newPtuValues = lines.map((line) => parseFloat(line)).filter((value) => !isNaN(value))

  return newPtuValues.map((value) => {
    if (value < 1000) {
      //values in MW, we need to truncate decimal places and convert to Watts
      return 1_000_000 * (Math.trunc(value * 100) / 100)
    } else {
      //value in Watts, remove the least significant digits so it shows as MW in the UI with max 2 decimals
      return Math.trunc(value / 10_000) * 10_000
    }
  })
}
