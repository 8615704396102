import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import {
  GET_CUSTOMER_POWER_CONSUMPTION_API_ID,
  getCustomerPowerConsumption,
} from '@/features/site/endpoints/customerPowerConsumption'
import type { PowerConsumption } from '@/features/site/types/powerConsumption'
import type { UseQueryOptions } from '@/types/queries'

const REFETCH_POWER_CONSUMPTION_INTERVAL = 1_000 * 60 // every minute

export type UseCustomerPowerConsumptionQueryParams = {
  customerUuid: string
  location: string
}
export type UseCustomerPowerConsumptionQueryResult = Omit<UseQueryResult, 'data'> & {
  powerConsumption: PowerConsumption | null
}

export function useCustomerPowerConsumptionWithPollingQuery(
  params: UseCustomerPowerConsumptionQueryParams,
  options?: UseQueryOptions<PowerConsumption>,
): UseCustomerPowerConsumptionQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    refetchInterval: REFETCH_POWER_CONSUMPTION_INTERVAL,
    queryKey: [GET_CUSTOMER_POWER_CONSUMPTION_API_ID, params],
    queryFn: () => getCustomerPowerConsumption(params),
  })

  return { powerConsumption: data, ...queryResult }
}
