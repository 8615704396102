import { gridFilterModelSelector, useGridApiContext } from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import type { FC } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import GridToolbar from '@/components/dataDisplay/GridToolbar'
import MultiValueSelector from '@/features/bidding/components/MultiValueSelector'
import type { MarketProgram } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { useCountryBiddableMarketProgramMap } from '@/features/bidding/hooks/useCountryBiddableMarketProgramMap'
import type { Portfolio } from '@/features/bidding/types/bid'
import { PortfolioState } from '@/features/bidding/types/bid'
import { MarketDate } from '@/features/bidding/utils/date/marketDate'
import { getFilterItemValue, updateDataGridFilter } from '@/utils/datagrid/filters'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends Props {}
}

type Props = {
  portfolios: Portfolio[] | null
}

const PORTFOLIO_FILTER_NAME = 'portfolio'
const MARKET_PROGRAM_FILTER_NAME = 'marketProgram'
const DELIVERY_DAY_FILTER_NAME = 'deliveryDay'

const BidsOverviewFilterToolbar: FC<Readonly<Props>> = ({ portfolios }) => {
  const apiRef = useGridApiContext()
  const { t, i18n } = useTranslation()
  const { selectedCountry } = useBiddingContext()

  const sortedPortfolios = useMemo(() => portfolios?.toSorted((a, b) => a.name.localeCompare(b.name)), [portfolios])
  const countryBiddableMarketProgramMap = useCountryBiddableMarketProgramMap()
  const sortedMarketPrograms = useMemo(
    () => countryBiddableMarketProgramMap[selectedCountry].toSorted((a, b) => a.localeCompare(b)),
    [],
  )

  const filterModel = gridFilterModelSelector(apiRef)
  const selectedDeliveryDay = getFilterItemValue<string>(filterModel, DELIVERY_DAY_FILTER_NAME) ?? ''
  const selectedPortfolios = getFilterItemValue<string[]>(filterModel, PORTFOLIO_FILTER_NAME) ?? []
  const selectedMarketPrograms = getFilterItemValue<MarketProgram[]>(filterModel, MARKET_PROGRAM_FILTER_NAME) ?? []

  const handleFilterChange = (field: string, value: any) => {
    updateDataGridFilter(apiRef, field, { id: field, field, operator: 'equals', value })
  }

  return (
    <GridToolbar
      filters={
        <>
          <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
            <DatePicker
              format="DD"
              label={t('bidding.overview.header.delivery_day')}
              slotProps={{ field: { clearable: true } }}
              value={selectedDeliveryDay ? new MarketDate(selectedDeliveryDay).getStartOfDay() : null}
              onChange={(value) => handleFilterChange(DELIVERY_DAY_FILTER_NAME, value?.toISODate() ?? '')}
            />
          </LocalizationProvider>
          <MultiValueSelector
            filterName={PORTFOLIO_FILTER_NAME}
            getOptionLabel={(option: string) =>
              sortedPortfolios?.find((portfolio) => portfolio.code === option)?.name ?? ''
            }
            id="selected-portfolios"
            label={t('bidding.overview.header.portfolio')}
            options={getPortfolioOptions(sortedPortfolios)}
            testId="portfolio-selector"
            value={selectedPortfolios}
          />
          <MultiValueSelector
            filterName={MARKET_PROGRAM_FILTER_NAME}
            getOptionLabel={(option: MarketProgram) => t(`bidding.market_program.${option}`)}
            id="selected-market-programs"
            label={t('bidding.overview.header.market_program')}
            options={sortedMarketPrograms}
            testId="market-program-selector"
            value={selectedMarketPrograms}
          />
        </>
      }
    />
  )
}

const getPortfolioOptions = (portfolios: Portfolio[] | undefined): string[] =>
  (portfolios ?? []).filter((portfolio) => portfolio.state === PortfolioState.LIVE).map((portfolio) => portfolio.code)

export default BidsOverviewFilterToolbar
