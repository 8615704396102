import { useTranslation } from 'react-i18next'

import Page from '@/components/layouts/Page'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import SiteCompanyInfoView from '@/features/site/components/SiteCompanyInfoView'
import { useSiteQuery } from '@/features/site/hooks/useSiteQuery'

export const SiteCompanyInfo = () => {
  const { t } = useTranslation()
  const { loggedInUser } = useAuth()
  const siteUuid = loggedInUser?.allowedRoIds?.[0] ?? ''
  const { site, isLoading } = useSiteQuery({ uuid: siteUuid }, { enabled: Boolean(siteUuid) })

  return (
    <Page
      isLoading={!site || isLoading}
      pageHeaderProps={{
        pageTitle: t('component.page_header.company_info'),
        pageTag: 'customer-company-information',
      }}
    >
      <SiteCompanyInfoView site={site!} />
    </Page>
  )
}
