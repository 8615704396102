import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import ActivationGroupGeneralCard from '@/features/activationGroup/components/ActivationGroupGeneralCard'
import { ActivationGroupRouteInformation } from '@/features/activationGroup/constants'
import { useActivationGroupDetails } from '@/features/activationGroup/pages/ActivationGroupDetails'

function ActivationGroupGeneral() {
  const { t } = useTranslation()
  const { activationGroupUuid } = useParams()

  const { setPageConfig } = useActivationGroupDetails()

  useEffect(() => {
    setPageConfig({
      title: t('activation_groups.tabs.title'),
      activeTab: ActivationGroupRouteInformation.ACTIVATION_GROUP_GENERAL.routePath,
    })
  }, [])

  if (!activationGroupUuid) {
    return null
  }

  return <ActivationGroupGeneralCard />
}

export default ActivationGroupGeneral
