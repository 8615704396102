import { Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Page from '@/components/layouts/Page'
import ActivationGroupScheduleCard from '@/features/activationGroup/components/ActivationGroupScheduleCard'
import ActivationGroupScheduleResourcesDataGrid from '@/features/activationGroup/components/ActivationGroupScheduleResourcesDataGrid'
import { ActivationGroupRouteInformation } from '@/features/activationGroup/constants'
import { useGenericActivationGroupScheduleQuery } from '@/features/activationGroup/hooks/useGenericActivationGroupScheduleQuery'

function ActivationGroupScheduleDetails() {
  const { t } = useTranslation()
  const { activationGroupUuid, activationGroupScheduleUuid } = useParams()
  const { activationGroupSchedule, isLoading } = useGenericActivationGroupScheduleQuery({
    activationGroupScheduleUuid: activationGroupScheduleUuid ?? null,
  })

  if (!activationGroupUuid || !activationGroupScheduleUuid || !activationGroupSchedule) {
    // TODO: Render not found error page
    return null
  }

  return (
    <Page
      isLoading={isLoading}
      pageHeaderProps={{
        breadcrumbItems: [
          {
            text: t('component.page_header.activation_groups'),
            to: ActivationGroupRouteInformation.INDEX.navigationPath(),
          },
          {
            text: t('component.page_header.activation_group_schedules'),
            to: ActivationGroupRouteInformation.ACTIVATION_GROUP_SCHEDULES.navigationPath(activationGroupUuid),
          },
        ],
        pageTitle: t('activation_groups.schedules.title'),
      }}
    >
      <Stack direction="column" marginY={3} spacing={3}>
        <ActivationGroupScheduleCard activationGroupSchedule={activationGroupSchedule} />
        <ActivationGroupScheduleResourcesDataGrid
          activationGroupSchedule={activationGroupSchedule}
          activationGroupUuid={activationGroupUuid}
        />
      </Stack>
    </Page>
  )
}

export default ActivationGroupScheduleDetails
