import type { GridColDef } from '@mui/x-data-grid'
import type { TFunction } from 'i18next'

import type { ActivatedResource } from '@/features/activation/types/activatedResource'
import { formatDuration, formatResourceActivationTime } from '@/features/activation/utils/formatDateTime'

export const CUSTOMER_ACTIVATION_DETAILS_COLUMNS = (
  t: TFunction,
  timeZone: string = 'UTC',
): GridColDef<ActivatedResource>[] => [
  {
    field: 'resourceName',
    headerName: t('customer_details.activations.details.resource.label'),
    flex: 1,
  },
  {
    field: 'sectionName',
    headerName: t('customer_details.activations.details.section.label'),
    flex: 1,
    valueGetter: (value: ActivatedResource['sectionName']) => {
      return value ?? '-'
    },
  },
  {
    field: 'activatedAt',
    headerName: t('customer_details.activations.details.start_time.label'),
    flex: 1,
    valueFormatter: (value: ActivatedResource['activatedAt']) => {
      return formatResourceActivationTime(timeZone, value)
    },
  },
  {
    field: 'deactivatedAt',
    headerName: t('customer_details.activations.details.end_time.label'),
    flex: 1,
    valueFormatter: (value: ActivatedResource['deactivatedAt']) => {
      return formatResourceActivationTime(timeZone, value)
    },
  },
  {
    field: 'duration',
    headerName: t('customer_details.activations.details.duration.label'),
    flex: 1,
    valueGetter: (_, activatedResource: ActivatedResource) => {
      return formatDuration(activatedResource.activatedAt, activatedResource.deactivatedAt)
    },
  },
]
