import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { formatValue } from '@/features/site/utils/review'

import ReviewInformationBlock from './ReviewInformationBlock'
import ReviewSiteSection from './ReviewSiteSection'

type BasicInformationReviewSectionProps = {
  name: string
  symbolicName: string
  location: string
  partnerCode: string | null
  address?: string
  siteType?: string
}

function BasicInformationReviewSection({
  name,
  symbolicName,
  location,
  partnerCode,
  address,
  siteType,
}: Readonly<BasicInformationReviewSectionProps>) {
  const { t } = useTranslation()

  return (
    <ReviewSiteSection stepId="basic_information" title={t('sites.add_new.steps.basic_information')}>
      <Stack sx={{ flexDirection: 'row', gap: 2 }}>
        <ReviewInformationBlock name={t('sites.add_new.form.name.label')} value={formatValue(name)} />

        <ReviewInformationBlock name={t('sites.add_new.form.symbolic_name.label')} value={formatValue(symbolicName)} />

        <ReviewInformationBlock name={t('common.location')} value={location} />

        <ReviewInformationBlock name={t('common.partner')} value={formatValue(partnerCode)} />

        <ReviewInformationBlock name={t('customer_details.tabs.company_info.address')} value={formatValue(address)} />

        <ReviewInformationBlock name={t('common.type')} value={formatValue(siteType)} />
      </Stack>
    </ReviewSiteSection>
  )
}

export default BasicInformationReviewSection
