import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'

import environment from '@/environment'
import { toGoogleMapsCoordinates } from '@/features/site/components/companyInfo/GpsLocationConverter'
import type { GpsLocation } from '@/types/gpsLocation'

const CONTAINER_STYLE = {
  width: '100%',
  height: '400px',
  marginTop: '20px',
}

interface SiteMapProps {
  location: GpsLocation | undefined
}

function SiteMap({ location }: Readonly<SiteMapProps>) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: environment.googleMapsApiKey ?? '',
  })

  if (!isLoaded) return null
  if (!location) return null

  const coordinates = toGoogleMapsCoordinates(location)

  if (!coordinates) return null

  return (
    <GoogleMap center={coordinates} mapContainerStyle={CONTAINER_STYLE} zoom={12}>
      <MarkerF position={coordinates} />
    </GoogleMap>
  )
}

export default SiteMap
