import { Stack } from '@mui/material'

import BidStatusChip from '@/features/bidding/components/BidStatusChip'
import type { Status } from '@/features/bidding/constants'

interface BidStatusesChipProps {
  statuses: Status[]
  acceptedVolume?: string
}

const BidStatusesChip = ({ statuses, acceptedVolume }: BidStatusesChipProps) => {
  const nbrOfStatuses = statuses.length

  return (
    <Stack
      sx={{ flexDirection: 'row', width: 'fit-content', alignItems: 'center', height: '100%', position: 'relative' }}
    >
      {statuses.map((status, index) => (
        <BidStatusChip
          key={status}
          acceptedVolume={acceptedVolume}
          isFirst={index === 0}
          isLast={index === nbrOfStatuses - 1}
          status={status}
          sx={{
            zIndex: nbrOfStatuses - index,
            overflow: 'hidden',
          }}
        />
      ))}
    </Stack>
  )
}

export default BidStatusesChip
