import ReplayIcon from '@mui/icons-material/Replay'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import { Box } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ButtonBar from '@/components/inputs/ButtonBar'
import { useAlertContext } from '@/contexts/AlertContext'
import { useActivationGroupsQuery } from '@/features/activationGroup/hooks/useActivationGroupsQuery'
import { getActivationGroupByUuid } from '@/features/activationGroup/utils/activationGroups'
import SubmitButton from '@/features/bidding/components/SubmitButton'
import type { ManualAcceptFlow } from '@/features/bidding/constants'
import { acceptExtendedBid, GET_BID_HISTORIES_API_ID } from '@/features/bidding/endpoints/bids'
import type { BidMetaData, PtuVolumes } from '@/features/bidding/types/bid'
import { updateBidStatus } from '@/features/bidding/utils/bidStatus'
import { convertToRoundedMw } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import { bidFromPtuVolumes } from '@/features/bidding/utils/model/createBid'
import volumeOutOfBiddableVolumeRange from '@/features/bidding/utils/validation/volumeOutOfBiddableVolumeRange'

enum BidFormStatus {
  SENDING,
  SENT_FAILED,
}

type BidManualAcceptButtonBarProps = {
  ptus: PtuVolumes[]
  bidMetaData: BidMetaData
  manualAcceptFlow: ManualAcceptFlow
}

const BidManualAcceptButtonBar = ({ ptus, bidMetaData, manualAcceptFlow }: Readonly<BidManualAcceptButtonBarProps>) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { pushAlert } = useAlertContext()

  const { activationGroups } = useActivationGroupsQuery()
  const activationGroup = getActivationGroupByUuid(activationGroups, bidMetaData.activationGroupUuid)
  const minBiddableVolume = activationGroup?.biddableVolumeRange?.min
  const maxBiddableVolume = activationGroup?.biddableVolumeRange?.max
  const [bidFormStatus, setBidFormStatus] = useState<BidFormStatus>(BidFormStatus.SENDING)

  const { mutate: submitEditBid } = useMutation({
    mutationFn: () => acceptExtendedBid(updateBidStatus(bidFromPtuVolumes(bidMetaData, ptus), manualAcceptFlow)),
    onError: () => {
      setBidFormStatus(BidFormStatus.SENT_FAILED)

      pushAlert({
        message: t('bidding.manual_accept.saving_failed.text'),
        severity: 'error',
        title: t('bidding.manual_accept.saving_failed.title'),
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [GET_BID_HISTORIES_API_ID] })

      navigate(`/bidding/manual-accept/${manualAcceptFlow}/success`, { replace: true })
    },
  })

  function sendButton() {
    const dialogProps = {
      description: t(`bidding.dialog.accepted_volume_out_of_biddable_volume_range.description`, {
        minVolume: minBiddableVolume ? convertToRoundedMw(minBiddableVolume) : '-',
        maxVolume: maxBiddableVolume ? convertToRoundedMw(maxBiddableVolume) : '-',
      }),
      title: t('bidding.dialog.accepted_volume_out_of_biddable_volume_range.title'),
    }

    if (bidFormStatus == BidFormStatus.SENT_FAILED) {
      return (
        <SubmitButton
          buttonProps={{ startIcon: <ReplayIcon /> }}
          dialogProps={dialogProps}
          shouldShowConfirmation={anyPtusWithAcceptedVolumeOutOfBiddableVolumeRange()}
          onSubmit={submitEditBid}
        >
          {t('bidding.manual_accept.saving_failed.retry')}
        </SubmitButton>
      )
    } else {
      return (
        <SubmitButton
          buttonProps={{ startIcon: <SendOutlinedIcon /> }}
          dialogProps={dialogProps}
          shouldShowConfirmation={anyPtusWithAcceptedVolumeOutOfBiddableVolumeRange()}
          onSubmit={submitEditBid}
        >
          {t(`bidding.manual_accept.submit_button.${manualAcceptFlow}`)}
        </SubmitButton>
      )
    }
  }

  const anyPtusWithAcceptedVolumeOutOfBiddableVolumeRange = () =>
    ptus.some(
      (ptu) =>
        ptu.acceptedVolume &&
        volumeOutOfBiddableVolumeRange(ptu.acceptedVolume.quantity, { minBiddableVolume, maxBiddableVolume }),
    )

  return (
    <ButtonBar>
      <Box alignItems="center" display="flex" flexDirection="column">
        {sendButton()}
      </Box>
    </ButtonBar>
  )
}
export default BidManualAcceptButtonBar
