import { DateTime } from 'luxon'

import type { MarketProgram } from '@/features/site/types/marketProgram'
import type { RevenuePeriod } from '@/features/site/types/revenue'
import { convertToTimeZoneDateTime } from '@/utils/time'

export function getYearsBetweenDates(calculatedSince: string, calculatedUntil: string, timeZone: string) {
  const startDate = convertToTimeZoneDateTime(timeZone, calculatedSince)
  const endDate = convertToTimeZoneDateTime(timeZone, calculatedUntil)

  const startYear = startDate.year
  const endYear = endDate.year
  const years: number[] = []

  for (let year = startYear; year <= endYear; year++) {
    years.push(year)
  }

  return years
}

/**
 * Get the start time of the revenue time series. It uses the calculatedSince time coming from the
 * periods endpoint, unless that time is after the user year selection.
 *
 * We set the time to be sure that we are getting all revenues from the beginning of the day.
 *
 * This date is used mainly to filter the revenues from the /revenues endpoint, which accepts a
 * startTime parameter.
 */
export function getRevenueTimeSeriesStartTime(
  revenuePeriods: RevenuePeriod[] | null,
  timeZone: string | undefined,
  marketProgramSelection: MarketProgram | undefined,
  yearSelection: string | undefined,
) {
  if (revenuePeriods && timeZone && marketProgramSelection && yearSelection) {
    const firstDayOfYearForCustomerUtc = DateTime.fromObject(
      { year: parseInt(yearSelection), month: 1, day: 1 },
      { zone: 'utc' },
    )
    const calculatedSinceUtc = DateTime.fromISO(
      revenuePeriods.find((period) => period.serviceId === marketProgramSelection.id)!.calculatedSince,
      { zone: 'utc' },
    )
    const startDateTime =
      calculatedSinceUtc > firstDayOfYearForCustomerUtc ? calculatedSinceUtc : firstDayOfYearForCustomerUtc

    return startDateTime.set({ hour: 0, minute: 0, second: 0 })
  }
}

/**
 * Get the start time of the revenue time series. It uses the calculatedUntil time coming from the
 * periods endpoint, unless that time is before the user year selection.
 *
 * This date is used mainly to filter the revenues from the /revenues endpoint, which accepts a
 * startTime parameter.
 */
export function getRevenueTimeSeriesEndTime(
  revenuePeriods: RevenuePeriod[] | null,
  timeZone: string | undefined,
  marketProgramSelection: MarketProgram | undefined,
  yearSelection: string | undefined,
) {
  if (revenuePeriods && timeZone && marketProgramSelection && yearSelection) {
    const firstDayOfNextYearForCustomerUtc = DateTime.fromObject(
      { year: parseInt(yearSelection) + 1, month: 1, day: 1 },
      { zone: 'utc' },
    )
    const calculatedUntilUtc = DateTime.fromISO(
      revenuePeriods.find((period) => period.serviceId === marketProgramSelection.id)!.calculatedUntil,
      { zone: 'utc' },
    )

    const endDateTime =
      calculatedUntilUtc < firstDayOfNextYearForCustomerUtc ? calculatedUntilUtc : firstDayOfNextYearForCustomerUtc

    return endDateTime
  }
}

export function getAvailableYearsForMarketProgram(
  revenuePeriods: RevenuePeriod[],
  timeZone: string,
  marketProgram?: MarketProgram,
): string[] {
  const years: string[] = []
  revenuePeriods
    .filter((period) => period.serviceId === marketProgram?.id)
    .forEach((period) => {
      years.push(...getYearsBetweenDates(period.calculatedSince, period.calculatedUntil, timeZone).map(String))
    })
  return years.sort((a, b) => parseInt(b) - parseInt(a))
}
