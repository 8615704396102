import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Page from '@/components/layouts/Page'
import { SiteActivationDetailsView } from '@/features/activation/components/SiteActivationDetailsView'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useSiteQuery } from '@/features/site/hooks/useSiteQuery'

export const SiteActivationDetails = () => {
  const { t } = useTranslation()
  const { activationId } = useParams()
  const { loggedInUser } = useAuth()
  const customerUuid = loggedInUser?.allowedRoIds?.[0] ?? ''

  const { site, isFetching: isFetchingSite } = useSiteQuery({ uuid: customerUuid }, { enabled: Boolean(customerUuid) })

  if (!activationId || !site || isFetchingSite) return null

  return (
    <Page
      pageHeaderProps={{
        pageTag: 'customer-activation-details',
        pageTitle: t('component.page_header.activation.details'),
      }}
    >
      <SiteActivationDetailsView activationId={activationId} site={site} />
    </Page>
  )
}
