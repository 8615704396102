import { Autocomplete, Paper, Stack, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import ReactECharts from 'echarts-for-react'
import type { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FIELD_WIDTH } from '@/constants/layout'
import { MARKET_PROGRAMS_COLOR_PALETTE } from '@/constants/marketPrograms'
import ActivationsChartSkeleton from '@/features/activation/components/ActivationsChartSkeleton'
import type { Activation } from '@/features/activation/types/activation'
import type { ChartData } from '@/features/activation/types/activationsChartData'
import { ChartTimeUnit } from '@/features/activation/types/chartTimeUnit'
import {
  countActivationsPerTimeLabelAndMarketProgram,
  getActivationsInTimeRange,
  getDistinctMarketPrograms,
} from '@/features/activation/utils/activationCharts'
import type { MarketProgramType } from '@/types/marketProgramType'

const LINE_COLOR = '#A3A3A3'

interface ActivationsChartProps {
  isLoading?: boolean
  activations: Activation[]
  marketPrograms: Set<MarketProgramType>
  fromDate: DateTime
  toDate: DateTime
}

export const ActivationsChart = ({
  isLoading,
  activations,
  marketPrograms,
  fromDate,
  toDate,
}: ActivationsChartProps) => {
  const { t } = useTranslation()

  const activationsInSelectedTimeRange = getActivationsInTimeRange(activations, toDate)
  const relevantMarketPrograms = getDistinctMarketPrograms(activationsInSelectedTimeRange)

  const [selectedMarketPrograms, setSelectedMarketPrograms] = useState<MarketProgramType[]>([])

  const [timeUnit, setTimeUnit] = useState<ChartTimeUnit>(ChartTimeUnit.MONTH)

  if (isLoading) {
    return (
      <Paper data-testid="activations-chart-skeleton" sx={{ p: 2, mb: 2 }}>
        <ActivationsChartSkeleton />
      </Paper>
    )
  }

  if (!activations.length) {
    return null
  }

  // The Echarts library exposes the values in the tooltip, but we're the ones responsible to create the style.
  function getTooltipContent(params) {
    const xAxisText = params[0].name
    const marketProgramFormattedLines = params
      .map(({ data, marker, seriesName }) => {
        const marketProgramName = t(`common.market_program.${seriesName as MarketProgramType}`)

        return `
          <div style="display: flex; justify-content: space-between; gap: 8px">
            <span>${marker} ${marketProgramName}</span>
            <span><strong>${data[seriesName]}</strong></span>
          </div>
        `
      })
      .join('')
      .toString()

    return `<h4 style="margin-bottom: 8px">${xAxisText}</h4>${marketProgramFormattedLines}`
  }

  const chartData: ChartData[] = countActivationsPerTimeLabelAndMarketProgram(
    timeUnit,
    activationsInSelectedTimeRange,
    selectedMarketPrograms.length > 0 ? selectedMarketPrograms : Array.from(marketPrograms),
    fromDate,
    toDate,
  )

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      formatter: (params) => getTooltipContent(params),
    },
    legend: {
      bottom: 0,
      itemGap: 30,
      show: true,
      itemWidth: 15,
      icon: 'rect',
      formatter: (category) => t(`common.market_program.${category as MarketProgramType}`),
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
        },
        saveAsImage: {},
      },
    },
    animation: false,
    color: MARKET_PROGRAMS_COLOR_PALETTE,
    notMerge: true,
    dataset: {
      dimensions: ['timeLabel', ...relevantMarketPrograms],
      source: chartData,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: LINE_COLOR,
        },
      },
    },
    yAxis: {
      type: 'value',
      axisPointer: {
        snap: true,
      },
      axisLine: {
        lineStyle: {
          color: LINE_COLOR,
        },
      },
      name: t('customer_details.activations.chart.y_axis_name'),
      nameLocation: 'middle',
      nameGap: 50,
      min: 'dataMin',
      minInterval: 1,
    },
    // The series must have an entry in the array for every item we want to display a "line".
    // In our case, one for every relevant market program.
    series: [...relevantMarketPrograms].map(() => ({
      type: 'line',
    })),
  }

  return (
    <Paper sx={{ p: 4, mb: 2 }}>
      <Stack alignItems={'left'} direction={'row'} gap={4} justifyContent={'flex-start'} mb={2}>
        <Autocomplete
          filterSelectedOptions
          multiple
          getOptionLabel={(option) => t(`common.market_program.${option as MarketProgramType}`)}
          id={`market-program-chart-filter`}
          options={Array.from(relevantMarketPrograms)
            .sort((a, b) => a.localeCompare(b))
            .map((mp) => mp as string)}
          renderInput={(params) => <TextField {...params} label={t('common.market_program.label')} />}
          slotProps={{
            chip: { size: 'small' },
          }}
          sx={{ minWidth: FIELD_WIDTH }}
          value={selectedMarketPrograms}
          onChange={(event, value) => {
            setSelectedMarketPrograms(value.map((mp) => mp as MarketProgramType))
          }}
        />
        <ToggleButtonGroup aria-label={'time-unit-buttons'} color="primary" size="small" value={timeUnit}>
          <ToggleButton
            key={ChartTimeUnit.DAY}
            value={ChartTimeUnit.DAY}
            onClick={() => setTimeUnit(ChartTimeUnit.DAY)}
          >
            {t('common.time.days')}
          </ToggleButton>
          <ToggleButton
            key={ChartTimeUnit.MONTH}
            value={ChartTimeUnit.MONTH}
            onClick={() => setTimeUnit(ChartTimeUnit.MONTH)}
          >
            {t('common.time.months')}
          </ToggleButton>
          <ToggleButton
            key={ChartTimeUnit.YEAR}
            value={ChartTimeUnit.YEAR}
            onClick={() => setTimeUnit(ChartTimeUnit.YEAR)}
          >
            {t('common.time.years')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      <ReactECharts data-testid="activations-chart" notMerge={true} option={option} style={{ height: '500px' }} />
    </Paper>
  )
}
