import { NoSimOutlined } from '@mui/icons-material'
import BlurOnIcon from '@mui/icons-material/BlurOn'
import PendingActionsOutlined from '@mui/icons-material/PendingActionsOutlined'
import { Tooltip } from '@mui/material'
import type { ChipProps } from '@mui/material/Chip'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import type { Theme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { Status } from '@/features/bidding/constants'

interface BidStatusChipProps {
  status: Status
  acceptedVolume?: string
  isFirst?: boolean
  isLast?: boolean
  sx?: ChipProps['sx']
}

type StyledChipProps = {
  status: Status
  label: string
  isFirst: boolean
}

function getStatusColor(status: Status, theme: Theme): string {
  switch (status) {
    case Status.DRAFT:
    case Status.TODO:
      return theme.palette.action.disabled
    case Status.OFFERED:
      return theme.palette.secondary.light
    case Status.ACCEPTED:
      return theme.palette.success.main
    case Status.BUYBACK:
      return theme.palette.success.dark
    case Status.MANUALLY_ACCEPTED:
    case Status.MANUALLY_EDITED:
      return '#B6A75F' // Khaki
    case Status.PARTIALLY_ACCEPTED:
      return '#96B245'
    case Status.REJECTED:
      return theme.palette.error.main
    case Status.OVERBID_ACCEPTED:
      return '#839264'
    default:
      return ''
  }
}

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'isFirst',
})<StyledChipProps>(({ theme, status, label, isFirst }) => {
  const variant: ChipProps['variant'] =
    status !== Status.DRAFT && status !== Status.TODO && status !== Status.NO_BID ? 'filled' : 'outlined'
  const color = getStatusColor(status, theme)
  return {
    '&.MuiChip-root': {
      backgroundColor: getBackgroundColor(status, variant, color),
      color: variant === 'filled' ? theme.palette.secondary.contrastText : color,
      border: `1px solid ${color}`,
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      paddingLeft: isFirst ? '' : theme.spacing(4),
      opacity: status === Status.NO_BID ? 1 : undefined, // Ensures text is always visible
    },
    '& .MuiChip-icon': {
      color: getStatusIconColor(status, theme),
      marginLeft: 0,
      marginRight: 0,
    },
    '& .MuiChip-label': {
      padding: !label ? 0 : `${theme.spacing(0.375)} ${theme.spacing(0.75)}`,
    },
  }
})

const getStatusIcon = (status: Status) => {
  switch (status) {
    case Status.DRAFT:
      return <BlurOnIcon fontSize="small" />
    case Status.TODO:
      return <PendingActionsOutlined fontSize="small" />
    case Status.NO_BID:
      return <NoSimOutlined fontSize="small" />
    default:
      return undefined
  }
}

const getStatusIconColor = (status: Status, theme: Theme): string | undefined => {
  if (status === Status.TODO || status === Status.DRAFT) {
    return theme.palette.action.disabled
  }

  return undefined
}

const getBackgroundColor = (status: Status, variant: string, color: string) => {
  if (status === Status.TODO || status === Status.DRAFT) {
    return 'white'
  }
  return variant === 'filled' ? color : 'transparent'
}

const BidStatusChip = ({ status, acceptedVolume, sx = {}, isFirst = true, isLast = true }: BidStatusChipProps) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={t(`bidding.status.${status}`)}>
      <Stack
        sx={{
          paddingRight: '1px',
          borderRadius: 6,
          boxSizing: 'border-box',
          width: 'fit-content',
          marginLeft: -4,
          ...sx,
          ...(isFirst && { marginLeft: 0 }),
          ...(isLast && { paddingRight: 0 }),
        }}
      >
        <StyledChip
          icon={getStatusIcon(status)}
          isFirst={isFirst}
          label={getLabel(status, isFirst, t, acceptedVolume)}
          status={status}
        />
      </Stack>
    </Tooltip>
  )
}

const getLabel = (status: Status, isFirst: boolean, t: TFunction<'translation'>, acceptedVolume?: string) => {
  if (!isFirst) return ''

  if ([Status.TODO, Status.DRAFT, Status.OFFERED, Status.NO_BID].includes(status) || !acceptedVolume) {
    return t(`bidding.status.${status}`)
  }

  return acceptedVolume
}

export default BidStatusChip
