import { Box, Card, CardHeader, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomCardContent from '@/components/layouts/CustomCardContent'

type ActivationGroupPrequalificationPatternInfo = {
  uuid: string
  name?: string
}

type ActivationGroupPrequalificationPatternCardProps = {
  info: ActivationGroupPrequalificationPatternInfo
}

type ActivationGroupPQPatternTableProps = {
  info: ActivationGroupPrequalificationPatternInfo
}

const StyledResourceDetailRow = styled(TableRow)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

const StyledResourceDetailHeaderColumn = styled(TableCell)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

const ActivationGroupPQPatternTable = ({ info }: ActivationGroupPQPatternTableProps) => {
  const { t } = useTranslation()
  const rows = useMemo(() => {
    return [
      {
        label: t('activation_groups.detail_panel.uuid'),
        value: info.uuid,
        column: 1,
        position: 1,
      },
      {
        label: t('activation_groups.detail_panel.name'),
        value: info.name,
        column: 2,
        position: 1,
      },
    ]
  }, [info, t])

  // Determine the number of columns by finding the maximum column number in the rows
  const columns = Math.max(...rows.map((row) => row.column))

  // Group rows by their column number and sort them by their position within the column
  const columnsData = Array.from({ length: columns }, (_, i) =>
    rows.filter((row) => row.column === i + 1).sort((a, b) => a.position - b.position),
  )

  return (
    <>
      <CardHeader sx={{ paddingX: 0 }} title={t('activation_groups.detail_panel.general_information')}></CardHeader>
      <Box display="flex">
        {columnsData.map((columnRows) => (
          <Table
            key={columnRows[0].column}
            aria-label={t('activation_groups.detail_panel.general_information')}
            role="table"
            size="small"
          >
            <TableBody>
              {columnRows.map((row) => (
                <StyledResourceDetailRow key={row.label} role="row">
                  <StyledResourceDetailHeaderColumn>
                    <CustomTypography color="grey.500" variant="caption">
                      {row.label}
                    </CustomTypography>
                  </StyledResourceDetailHeaderColumn>
                  <TableCell>
                    <CustomTypography sx={{ lineHeight: 'inherit' }} variant="inherit">
                      {row.value}
                    </CustomTypography>
                  </TableCell>
                </StyledResourceDetailRow>
              ))}
            </TableBody>
          </Table>
        ))}
      </Box>
    </>
  )
}

const ActivationGroupPrequalificationPatternInfoCard = ({ info }: ActivationGroupPrequalificationPatternCardProps) => {
  return (
    <Card data-testid="activation-group-prequalification-pattern-info-card">
      <CustomCardContent title={info.uuid}>
        <ActivationGroupPQPatternTable info={info} />
      </CustomCardContent>
    </Card>
  )
}

export default ActivationGroupPrequalificationPatternInfoCard
