import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { WeekdayStr } from 'rrule'
import { ALL_WEEKDAYS } from 'rrule/dist/esm/weekday'

import { getSelectedDays } from '@/features/site/components/availability/utils'

interface RecurrenceProps {
  recurrence: string
}

export const Recurrence = (props: RecurrenceProps) => {
  const { t } = useTranslation()
  const [selectedDays] = useState(getSelectedDays(props.recurrence))

  return (
    <>
      <Typography sx={{ my: 1 }} variant="h6">
        {t('customer_details.tabs.availability.availability_data_grid.dialog.recurring_on')}
      </Typography>
      <ToggleButtonGroup disabled aria-label="recurrence button group" size="small" value={selectedDays}>
        {ALL_WEEKDAYS.map((day: WeekdayStr) => (
          <ToggleButton key={day} value={day}>
            {t(`common.days.${day}.long`)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  )
}
