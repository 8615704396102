import { useTheme } from '@mui/material/styles'
import type { FC } from 'react'

import type { Volume } from '@/features/bidding/types/bid'
import { convertToRoundedMw, round } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import { getBidDisplayUnit } from '@/features/bidding/utils/getBidDisplayUnit'

type Props = {
  row: { offeredVolume: Volume; forecastedVolume?: Volume }
}

export const OfferedQuantityDiffCell: FC<Props> = ({ row }) => {
  const theme = useTheme()

  const offeredVolume = row.offeredVolume?.quantity
  if (!offeredVolume && offeredVolume !== 0) return <span>{'-'}</span>

  const offeredMw = convertToRoundedMw(offeredVolume)
  const forecastedWatts = row.forecastedVolume?.quantity
  const diff = forecastedWatts ? offeredMw - convertToRoundedMw(forecastedWatts) : 0
  const unit = getBidDisplayUnit(row.offeredVolume.unit)

  return (
    <span>
      {`${offeredMw} ${unit}`}
      {diff !== 0 && (
        <span style={{ color: theme.palette.warning.main }}>{` (${diff > 0 ? '+' : ''}${round(diff)})`}</span>
      )}
    </span>
  )
}
