import type { DialogProps } from '@mui/material'
import { Dialog, DialogTitle } from '@mui/material'
import type { SxProps, Theme } from '@mui/system'

type CustomDialogProps = DialogProps & {
  size?: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge'
  title: string
  position?: 'top' | 'center' | 'bottom'
}

function getWidth(size: CustomDialogProps['size']) {
  switch (size) {
    case 'extraSmall':
      return '444px'
    case 'small':
      return '600px'
    case 'medium':
      return '900px'
    case 'large':
      return '1200px'
    case 'extraLarge':
      return '1536px'
    default:
      return '600px' // Default size if none is provided
  }
}

function getPositionStyles(position: CustomDialogProps['position']): SxProps<Theme> {
  switch (position) {
    case 'top':
      return { top: 10, margin: 0, position: 'absolute' }
    case 'bottom':
      return { bottom: 10, margin: 0, position: 'absolute' }
    case 'center':
    default:
      return {} // Default MUI behavior (centered dialog)
  }
}

/**
 * It modifies the MUI Dialog component to add some Sympower customizations:
 *
 * - Adds a new size prop, which provides an easy way to define a dialog width some of the pre-defined options.
 * - Adds a new position prop, which allows to position the dialog at the top or bottom of the screen.
 *
 */
const CustomDialog = ({ size, position = 'center', children, title, ...props }: CustomDialogProps) => {
  return (
    <Dialog
      {...props}
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: getWidth(size ?? 'extraSmall'),
          ...getPositionStyles(position),
        },
      }}
      role="alertdialog"
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>{title}</DialogTitle>
      {children}
    </Dialog>
  )
}

export default CustomDialog
