import { Box } from '@mui/material'
import type { GridRowParams } from '@mui/x-data-grid'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppBarContent } from '@/components/layouts/Page'
import { NOW } from '@/constants/dateTime'
import { Period } from '@/constants/period'
import { ActivationsChart } from '@/features/activation/components/ActivationsChart'
import { ActivationsDataGrid } from '@/features/activation/components/ActivationsDataGrid'
import SiteActivationsAppBar from '@/features/activation/components/SiteActivationsAppBar'
import { CUSTOMER_ACTIVATION_COLUMNS } from '@/features/activation/constants/customerActivationsColumns'
import type { Activation } from '@/features/activation/types/activation'
import type { TimeRange } from '@/features/activation/types/timeRange'
import { getDistinctMarketPrograms } from '@/features/activation/utils/activationCharts'
import { getMarketProgramTypes } from '@/features/activation/utils/getMarketProgramTypes'
import { mergeActivations } from '@/features/activation/utils/merge'
import { calculatePeriodStartAndEndTime } from '@/features/activation/utils/periodUtils'
import { usePermissions } from '@/features/authorization/contexts/PermissionsContext'
import { NoMarketProgram } from '@/features/site/components/NoMarketProgram'
import { useActivationsFromAmQuery } from '@/features/site/hooks/useActivationsFromAmQuery'
import { useActivationsFromMagQuery } from '@/features/site/hooks/useActivationsFromMagQuery'
import { useMarketProgramsQuery } from '@/features/site/hooks/useMarketProgramsQuery'
import type { Site } from '@/features/site/types/site'
import type { MarketProgramType } from '@/types/marketProgramType'
import { convertToTimeZoneDateTime } from '@/utils/time'

type SiteActivationsViewProps = {
  site: Site
  activationDetailsPath: string
}

export const SiteActivationsView = ({ site, activationDetailsPath }: SiteActivationsViewProps) => {
  const { t, i18n } = useTranslation()
  const { setAppBarContent } = useAppBarContent()
  const { permissions } = usePermissions()

  const [dateOfFirstActivation, setDateOfFirstActivation] = useState<DateTime | undefined>(undefined)
  const [timeRange, setTimeRange] = useState<TimeRange>({ from: NOW.minus({ years: 1 }), to: NOW })
  const [periodSelection, setPeriodSelection] = useState<Period>(Period.PAST_12_MONTHS)
  const [isLoading, setIsLoading] = useState(true)

  const { magActivations, isFetching: isFetchingMagActivations } = useActivationsFromMagQuery(
    {
      customerUuid: site.uuid!,
      location: site.location,
    },
    { enabled: Boolean(site.uuid) },
  )

  const { amActivations, isFetching: isFetchingAmActivations } = useActivationsFromAmQuery(
    {
      siteId: site.uuid!,
    },
    { enabled: Boolean(site.uuid) },
  )

  const { marketPrograms, isFetching: isFetchingMarketPrograms } = useMarketProgramsQuery()

  const isLoadingActivationData = isFetchingMagActivations || isFetchingAmActivations || isFetchingMarketPrograms

  const canSeePrequalifications = permissions.has('prequalifications')
  const mergedActivations: Activation[] = useMemo(() => {
    return mergeActivations(magActivations, amActivations, marketPrograms, canSeePrequalifications)
  }, [magActivations, amActivations, marketPrograms])

  const firstActivation = mergedActivations?.[mergedActivations.length - 1] ?? null

  useEffect(() => {
    if (firstActivation?.startedAt) {
      setDateOfFirstActivation(convertToTimeZoneDateTime(site.timezone, firstActivation.startedAt))
    }
  }, [firstActivation])

  const activationsToShow: Activation[] | undefined = useMemo(
    () =>
      mergedActivations?.filter(
        (a) =>
          a.endedAt &&
          DateTime.fromISO(a.endedAt, { zone: DateTime.utc().zone }) > timeRange.from &&
          DateTime.fromISO(a.endedAt, { zone: DateTime.utc().zone }) < timeRange.to,
      ),
    [mergedActivations, timeRange],
  )

  useEffect(() => {
    if (!isLoadingActivationData && site.timezone) {
      setTimeRange(calculatePeriodStartAndEndTime(periodSelection, timeRange, dateOfFirstActivation, site.timezone))
      setIsLoading(false)
    }
  }, [isLoadingActivationData])

  useEffect(() => {
    setAppBarContent(
      <SiteActivationsAppBar
        activationsData={activationsToShow ?? []}
        dateOfFirstActivation={dateOfFirstActivation}
        isLoading={isLoading}
        periodSelection={periodSelection}
        setPeriodSelection={setPeriodSelection}
        setTimeRange={setTimeRange}
        timeRange={timeRange}
        timezone={site.timezone}
      />,
    )
  }, [dateOfFirstActivation, timeRange, periodSelection, isLoadingActivationData])

  const marketProgramTypes = getMarketProgramTypes(marketPrograms)
  const marketProgramsWithoutDetails: MarketProgramType[] = ['mfrrda-down', 'mfrrda-up']

  if (!isFetchingMarketPrograms && marketProgramTypes.size === 0) {
    return <NoMarketProgram />
  }

  return (
    <Box mt={3} sx={{ width: '100%' }}>
      <ActivationsChart
        activations={activationsToShow ?? []}
        fromDate={timeRange.from}
        isLoading={isLoading}
        marketPrograms={getDistinctMarketPrograms(activationsToShow ?? [])}
        toDate={timeRange.to}
      />
      <ActivationsDataGrid
        canSeePrequalifications={canSeePrequalifications}
        columns={CUSTOMER_ACTIVATION_COLUMNS(t, site.timezone, marketPrograms, i18n.language)}
        isLoading={isLoading}
        marketProgramOptions={getDistinctMarketPrograms(activationsToShow ?? [])}
        rowNavigateTo={(params: GridRowParams<Activation>) => {
          if (!params.row.marketProgram || marketProgramsWithoutDetails.includes(params.row.marketProgram!)) return ''
          const path = activationDetailsPath.replace(':activationId', params.row.id!.toString())

          return `/${path}`
        }}
        rows={activationsToShow ?? []}
      />
    </Box>
  )
}
