import type { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import axios from 'axios'

import { getCountryAsAlpha2Code } from '@/constants/country'
import environment from '@/environment'
import type { BidType, MarketProgram } from '@/features/bidding/constants'
import type { ApiAcceptedCapacity, ApiBidWithContext, ApiCapacityOffer } from '@/features/bidding/types/api'
import type { ExtendedBid, Forecast, SuggestedBid } from '@/features/bidding/types/bid'
import type { BidHistoriesPage } from '@/features/bidding/types/bidHistory'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import {
  bidHistoryWithLuxonDates,
  extendedBidToApiAcceptedCapacityOffer,
  extendedBidToApiCapacityOffer,
  extendedBidWithContextWithLuxonDates,
  forecastWithLuxonDates,
  toInternalBidHistory,
  toInternalExtendedBid,
  toInternalForecast,
  toSuggestedBidWithLuxonDates,
} from '@/features/bidding/utils/model/modelConversion'

export const PORTFOLIO_MANAGER_API_URL = environment.services.portfolioManagerApiUrl

export const GET_BID_API_ID = 'GET_BID'
export const GET_BID_HISTORIES_API_ID = 'GET_BID_HISTORIES'

export const GET_FORECAST_API_ID = 'GET_FORECAST'
export const GET_BIDS_API_STALE_TIME = 1000 * 60 * 60 * 24 // 24 hours
export const GET_SUGGESTED_BIDS_API_ID = 'GET_SUGGESTED_BIDS'

export const saveExtendedBids = async (bids: ExtendedBid[]) => {
  const body = bids.map((bid) => extendedBidToApiCapacityOffer(bid))
  return axios.post<ApiCapacityOffer, string>(`${PORTFOLIO_MANAGER_API_URL}/bid/capacity/bulk`, body)
}

export const acceptExtendedBid = async (bid: ExtendedBid) => acceptExtendedBids([bid])

export const acceptExtendedBids = async (bids: ExtendedBid[]) => {
  const body = bids.map((bid) => extendedBidToApiAcceptedCapacityOffer(bid))
  return axios.post<ApiAcceptedCapacity, string>(`${PORTFOLIO_MANAGER_API_URL}/bid/accepted-capacity/bulk`, body)
}

export const findBid = async (
  date?: MarketDate,
  portfolioCode?: string,
  marketProgram?: MarketProgram,
  activationGroupUuid?: string,
  bidType?: BidType,
): Promise<ExtendedBid | null> => {
  const params = {
    date: date?.toISODate() ?? undefined,
    portfolioCode: portfolioCode,
    marketProgram: marketProgram,
    activationGroupUuid: activationGroupUuid ?? null,
    bidType: bidType,
  }
  const response = await axios
    .get(`${PORTFOLIO_MANAGER_API_URL}/bid`, { params: params })
    .then((response) =>
      response.data
        .map((bidWithContext: any) => extendedBidWithContextWithLuxonDates(bidWithContext))
        .sort(
          (bid1: ApiBidWithContext, bid2: ApiBidWithContext) =>
            bid1.bid.createdAt.valueOf() - bid2.bid.createdAt.valueOf(),
        )
        .map((bidWithContext: ApiBidWithContext, index: number) => toInternalExtendedBid(bidWithContext, index + 1)),
    )
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return null // Not found
      }
      throw error
    })
  if (!response || response.length == 0) {
    return null
  }

  return response.sort((bid1: ExtendedBid, bid2: ExtendedBid) => bid2.createdAt.valueOf() - bid1.createdAt.valueOf())[0]
}

export const findForecast = async (date: MarketDate, activationGroupUuid: string): Promise<Forecast | null> => {
  const response = await axios
    .get(`${PORTFOLIO_MANAGER_API_URL}/forecast`, {
      params: {
        date: date.toISODate(),
        activationGroupUuid,
      },
    })
    .then((response) =>
      response.data
        .map((forecast: any) => forecastWithLuxonDates(forecast))
        .map((forecast: any) => toInternalForecast(forecast)),
    )
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return null // Not found
      }
      throw error
    })

  if (!response || response.length === 0) {
    return null
  }

  return response[0]
}

export const getBidHistories = async (
  pagination: GridPaginationModel,
  sorting: GridSortModel,
  filter: BidHistoriesFilter,
  country: CountryIdentifier,
): Promise<BidHistoriesPage> => {
  const response = await axios.get(`${PORTFOLIO_MANAGER_API_URL}/bid-history`, {
    params: buildBidHistoriesParams(pagination, sorting, filter, country),
  })
  return {
    bidHistories: response.data.bidHistories
      .map((bidHistory: any) => bidHistoryWithLuxonDates(bidHistory))
      .map((bidHistory: any) => toInternalBidHistory(bidHistory)),
    currentPage: response.data.currentPage,
    totalBidHistories: response.data.totalBidHistories,
  }
}

export type BidHistoriesFilter = {
  portfolioCode?: string
  marketProgram?: MarketProgram
  activationGroupUuid?: string
  deliveryDay?: MarketDate
  fromDeliveryDay?: MarketDate
  bidType?: BidType
}

const buildBidHistoriesParams = (
  pagination: GridPaginationModel,
  sorting: GridSortModel,
  filter: BidHistoriesFilter,
  country: CountryIdentifier,
) => {
  const params = new URLSearchParams()

  params.append('page', String(pagination.page))
  params.append('pageSize', String(pagination.pageSize))
  params.append('countryCode', getCountryAsAlpha2Code(country))

  sorting.forEach((sortItem) => params.append('sortItems', JSON.stringify(sortItem)))

  if (filter.portfolioCode) params.append('portfolioCode', filter.portfolioCode)
  if (filter.marketProgram) params.append('marketProgram', filter.marketProgram)
  if (filter.activationGroupUuid) params.append('activationGroupUuid', filter.activationGroupUuid)
  if (filter.deliveryDay) params.append('deliveryDay', filter.deliveryDay.toISODate())
  if (filter.fromDeliveryDay) params.append('fromDeliveryDay', filter.fromDeliveryDay.toISODate())
  if (filter.bidType) params.append('bidType', filter.bidType)

  return params
}

export const findSuggestedBid = async (
  startDate: MarketDate,
  endDate: MarketDate,
  activationGroupUuid: string,
): Promise<SuggestedBid[]> => {
  try {
    const response = await axios.get(`${PORTFOLIO_MANAGER_API_URL}/suggested-bids/${activationGroupUuid}`, {
      params: {
        startDate: startDate.getStartOfDay(),
        endDate: endDate.getEndOfDay(),
      },
    })

    return response.data
      .map(toSuggestedBidWithLuxonDates)
      .sort((bid1: SuggestedBid, bid2: SuggestedBid) => bid1.ptu.start.valueOf() - bid2.ptu.start.valueOf())
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return []
    }
    throw error
  }
}

export const importAcceptedBid = async (tsv: string) => {
  return axios.post(`${PORTFOLIO_MANAGER_API_URL}/bid/import/aggregated-accepted-bid`, tsv, {
    headers: {
      'Content-Type': 'text/plain',
    },
  })
}
