import axios from 'axios'

import environment from '@/environment'
import type { UseCustomerPowerConsumptionQueryParams } from '@/features/site/hooks/useCustomerPowerConsumptionWithPollingQuery'
import type { PowerConsumption } from '@/features/site/types/powerConsumption'

export const CUSTOMERS_API_URL = `${environment.services.monolithGatewayManagerApiUrl}/customers`

export const GET_CUSTOMER_POWER_CONSUMPTION_API_ID = 'GET_CUSTOMER_POWER_CONSUMPTION'

export async function getCustomerPowerConsumption({ customerUuid, location }: UseCustomerPowerConsumptionQueryParams) {
  const response = await axios.get<PowerConsumption>(`${CUSTOMERS_API_URL}/${customerUuid}/powerConsumption`, {
    params: {
      location,
    },
  })

  return response.data
}
