import type { IANAZone } from 'luxon'
import { DateTime } from 'luxon'

import { WATTS_IN_MW } from '@/constants/units'
import { BidType, MARKET_TIMEZONE, Status } from '@/features/bidding/constants'
import type {
  ApiAcceptedCapacity,
  ApiBid,
  ApiBidExport,
  ApiBidHistory,
  ApiBidWithContext,
  ApiCapacityOffer,
  ApiForecast,
} from '@/features/bidding/types/api'
import type {
  BidPtu,
  ExtendedBid,
  ExtendedBidPtu,
  Forecast,
  ForecastPtu,
  SuggestedBid,
} from '@/features/bidding/types/bid'
import type { BidExport } from '@/features/bidding/types/bidExports'
import type { BidHistory, BidSummary } from '@/features/bidding/types/bidHistory'
import type { BidOverviewGroup } from '@/features/bidding/types/bidOverview'
import type { GroupedBid } from '@/features/bidding/types/groupedBid'
import { convertToRoundedMw } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import { MarketDate } from '@/features/bidding/utils/date/marketDate'

const sortByPtus = (ptu1: BidPtu, ptu2: BidPtu): number => ptu1.ptu.start.valueOf() - ptu2.ptu.start.valueOf()

export const toLuxonDateTime = (text: string, zone?: IANAZone) =>
  zone ? DateTime.fromISO(text, { zone: zone }) : DateTime.fromISO(text)

const toPtuWithLuxonDateTime = (ptu: { start: string; end: string }): { start: DateTime; end: DateTime } => ({
  start: toLuxonDateTime(ptu.start, MARKET_TIMEZONE),
  end: toLuxonDateTime(ptu.end, MARKET_TIMEZONE),
})

const toBidPtuWithLuxonDateTime = (
  // BidPtu with ptu start and end as string instead of DateTime
  bidPtu: Omit<BidPtu, 'ptu'> & { ptu: { start: string; end: string } },
): BidPtu => ({ ...bidPtu, ptu: toPtuWithLuxonDateTime(bidPtu.ptu) })

const toExtendedBidPtuWithLuxonDateTime = (
  // BidPtu with ptu start and end as string instead of DateTime
  bidPtu: Omit<ExtendedBidPtu, 'ptu'> & { ptu: { start: string; end: string } },
): ExtendedBidPtu => ({ ...bidPtu, ptu: toPtuWithLuxonDateTime(bidPtu.ptu) })

const extendedBidWithLuxonDates = (bid: any): ApiBid => ({
  ...bid,
  createdAt: toLuxonDateTime(bid.createdAt),
  ptus: bid.ptus.map((bidPtu: any) => toExtendedBidPtuWithLuxonDateTime(bidPtu)).sort(sortByPtus),
})

export const extendedBidWithContextWithLuxonDates = (bidWithContext: any): ApiBidWithContext => ({
  context: {
    ...bidWithContext.context,
    deliveryDay: new MarketDate(bidWithContext.context.deliveryDay),
  },
  bid: extendedBidWithLuxonDates(bidWithContext.bid),
})

export const bidHistoryWithLuxonDates = (bidHistory: any): ApiBidHistory => ({
  id: bidHistory.id,
  createdAt: toLuxonDateTime(bidHistory.createdAt),
  context: {
    ...bidHistory.context,
    deliveryDay: new MarketDate(bidHistory.context.deliveryDay),
  },
  bids: bidHistory.bids.map((bid: any) => extendedBidWithLuxonDates(bid)),
})

export const toInternalGroupedBid = (groupedBid: any): GroupedBid => {
  const deliveryDay = new MarketDate(groupedBid.deliveryDay)

  const bids = Object.keys(groupedBid.bids).reduce((acc, activationGroupUuid) => {
    acc[activationGroupUuid] = toInternalExtendedBid({
      context: { ...groupedBid, deliveryDay, activationGroupUuid },
      bid: extendedBidWithLuxonDates(groupedBid.bids[activationGroupUuid]),
    })
    return acc
  }, {})

  return { ...groupedBid, deliveryDay, bids }
}

export const toInternalBidOverviewGroup = (bidOverviewGroup: any): BidOverviewGroup => {
  const deliveryDay = new MarketDate(bidOverviewGroup.deliveryDay)

  const capacityBids = Object.keys(bidOverviewGroup.capacityBids).reduce((acc, activationGroupUuid) => {
    acc[activationGroupUuid] = toInternalExtendedBid({
      context: { ...bidOverviewGroup, deliveryDay, activationGroupUuid, bidType: BidType.CAPACITY },
      bid: extendedBidWithLuxonDates(bidOverviewGroup.capacityBids[activationGroupUuid]),
    })
    return acc
  }, {})
  const energyBids = Object.keys(bidOverviewGroup.energyBids).reduce((acc, activationGroupUuid) => {
    acc[activationGroupUuid] = toInternalExtendedBid({
      context: { ...bidOverviewGroup, deliveryDay, activationGroupUuid, bidType: BidType.ENERGY },
      bid: extendedBidWithLuxonDates(bidOverviewGroup.energyBids[activationGroupUuid]),
    })
    return acc
  }, {})

  return { ...bidOverviewGroup, deliveryDay, capacityBids, energyBids }
}

export const forecastWithLuxonDates = (forecast: any): ApiForecast => ({
  id: forecast.id,
  context: { ...forecast.context, deliveryDay: new MarketDate(forecast.context.deliveryDay) },
  createdAt: toLuxonDateTime(forecast.createdAt),
  forecastPtus: forecast.forecastPtus.map((bidPtu: any) => toBidPtuWithLuxonDateTime(bidPtu)).sort(sortByPtus),
})

export const extendedBidToApiCapacityOffer = (bid: ExtendedBid): ApiCapacityOffer => ({
  context: {
    portfolio: bid.portfolio,
    marketProgram: bid.marketProgram,
    deliveryDay: bid.deliveryDay.toISODate(),
    activationGroupUuid: bid.activationGroupUuid,
    bidType: bid.bidType,
  },
  offeredValues: bid.ptus.map((ptu) => ({ ptu: ptu.ptu, volume: ptu.offeredVolume, ptuChunks: ptu.ptuChunks })),
  status: bid.status,
})

export const extendedBidToApiAcceptedCapacityOffer = (bid: ExtendedBid): ApiAcceptedCapacity => ({
  context: {
    portfolio: bid.portfolio,
    marketProgram: bid.marketProgram,
    deliveryDay: bid.deliveryDay.toISODate(),
    activationGroupUuid: bid.activationGroupUuid,
    bidType: bid.bidType,
  },
  acceptedValues: bid.ptus.map((ptu) => ({ ptu: ptu.ptu, volume: ptu.acceptedVolume!, ptuChunks: ptu.ptuChunks })),
  status: bid.status,
})

export const toInternalExtendedBid = (bidWithContext: ApiBidWithContext, version?: number): ExtendedBid => ({
  ...bidWithContext.context,
  id: bidWithContext.bid.bidId,
  createdAt: bidWithContext.bid.createdAt,
  status: bidWithContext.bid.status,
  version,
  ptus: bidWithContext.bid.ptus.map((bidPtu) => ({
    ptu: bidPtu.ptu,
    offeredVolume: bidPtu.offeredVolume,
    acceptedVolume: bidPtu.acceptedVolume,
    ptuChunks: bidPtu.ptuChunks,
  })),
})

export const toInternalForecast = (forecast: ApiForecast): Forecast => ({
  ...forecast.context,
  id: forecast.id,
  createdAt: forecast.createdAt,
  forecastPtus: roundForecast(forecast.forecastPtus),
  // TODO: Remove this unnecessary field
  status: Status.REJECTED,
})

const calculateBidHistorySummary = (bids: ApiBid[]): BidSummary => {
  // from all the versions we just take the latest one and that's the "summary" for now
  const copiedBids = [...bids]
  copiedBids.sort((bid1, bid2) => bid2.createdAt.valueOf() - bid1.createdAt.valueOf())
  const mostRecentBid = copiedBids[0]
  return {
    status: mostRecentBid.status,
    offeredBid: mostRecentBid.ptus.map((ptu) => ({
      ptu: ptu.ptu,
      volume: ptu.offeredVolume,
      ptuChunks: ptu.ptuChunks,
    })),
    acceptedBid: mostRecentBid.ptus
      .filter((ptu) => !!ptu.acceptedVolume)
      .map((ptu) => ({
        ptu: ptu.ptu,
        volume: ptu.acceptedVolume!,
        ptuChunks: ptu.ptuChunks,
      })),
  }
}

export const toInternalBidHistory = (bidHistory: ApiBidHistory): BidHistory => ({
  ...bidHistory.context,
  id: bidHistory.id,
  createdAt: bidHistory.createdAt,
  summary: calculateBidHistorySummary(bidHistory.bids),
  bids: bidHistory.bids
    .map((bid) => toInternalExtendedBid({ context: bidHistory.context, bid: bid }))
    .sort((bid1, bid2) => bid1.createdAt.valueOf() - bid2.createdAt.valueOf()),
})

export const toInternalBidExport = (bidExport: ApiBidExport): BidExport => ({
  ...bidExport,
  createdAt: toLuxonDateTime(bidExport.createdAt),
  deliveryDay: new MarketDate(bidExport.bidStartAt),
})

const roundForecast = (forecastPtus: ForecastPtu[]): ForecastPtu[] =>
  forecastPtus.map((forecastPtu) => ({
    ...forecastPtu,
    volume: { ...forecastPtu.volume, quantity: convertToRoundedMw(forecastPtu.volume.quantity) * WATTS_IN_MW },
  }))

export const toSuggestedBidWithLuxonDates = (suggestedBid: any): SuggestedBid => ({
  ...suggestedBid,
  ptu: {
    start: toLuxonDateTime(suggestedBid.ptu.start, MARKET_TIMEZONE),
    end: toLuxonDateTime(suggestedBid.ptu.end, MARKET_TIMEZONE),
  },
})
