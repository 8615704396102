import type { TFunction } from 'i18next'

import type { RevenueDataRow } from '@/features/site/types/revenue'

const DECIMAL_PRICE_PRECISION = 100

export function convertToDecimalPrice(price: number | null | undefined) {
  if (!price) return 0
  return price / DECIMAL_PRICE_PRECISION
}

export function formatCapacity(t: TFunction, capacity: number) {
  return `${capacity.toFixed(2)} ${t('common.units.mwh')}`
}

export function formatRevenue(t: TFunction, revenue: number) {
  return `${t('customer_revenue.revenue.unit')}${revenue.toFixed(2)}`
}

export function getRevenueDataByMonth(revenueDataRows: RevenueDataRow[]): Record<number, RevenueDataRow[]> {
  const revenueDataByMonth: Record<number, RevenueDataRow[]> = {}

  revenueDataRows.forEach((rowData) => {
    const month = rowData.timestamp.month
    if (!revenueDataByMonth[month]) {
      revenueDataByMonth[month] = []
    }
    revenueDataByMonth[month].push(rowData)
  })

  return revenueDataByMonth
}

export function getRevenueDataByYear(revenueDataRows: RevenueDataRow[]): Record<number, RevenueDataRow[]> {
  const revenueDataByYear: Record<number, RevenueDataRow[]> = {}

  revenueDataRows.forEach((rowData) => {
    const year = rowData.timestamp.year

    if (!revenueDataByYear[year]) {
      revenueDataByYear[year] = []
    }

    revenueDataByYear[year].push(rowData)
  })

  return revenueDataByYear
}

/**
 * Groups revenue data by market program and month.
 *
 * @param revenueDataRowsFromMarketProgram - A map where:
 *   - The keys are market program names.
 *   - The values are arrays of RevenueDataRow objects or null.
 * @returns - The transformed data, where:
 *   - The first level keys are market program names.
 *   - The second level keys are month numbers (1-12).
 *   - The values are arrays of RevenueDataRow objects.
 *
 * @example
 * // Input:
 * const revenueDataRowsFromMarketProgram = new Map([
 *   ["Program A", [{ timestamp: { month: 1 }, revenue: 100 }]],
 *   ["Program B", [{ timestamp: { month: 1 }, revenue: 200 }]]
 * ]);
 *
 * getRevenueDataFromMarketProgramsByMonth(revenueDataRowsFromMarketProgram);
 *
 * // Output:
 * {
 *   "Program A": {
 *     1: [{ timestamp: { month: 1 }, revenue: 100 }],
 *   },
 *   "Program B": {
 *     1: [{ timestamp: { month: 1 }, revenue: 100 }],
 *   }
 * }
 */
export function getRevenueDataFromMarketProgramsByMonth(
  revenueDataRowsFromMarketProgram: Map<string, RevenueDataRow[] | null>,
): Record<number, Record<string, RevenueDataRow[]>> {
  const result: Record<string, Record<number, RevenueDataRow[]>> = {}

  for (const [marketProgram, revenueDataRows] of revenueDataRowsFromMarketProgram) {
    if (!revenueDataRows) continue

    result[marketProgram] = {}

    for (const row of revenueDataRows) {
      const month = row.timestamp.month

      if (!result[marketProgram][month]) {
        result[marketProgram][month] = []
      }

      result[marketProgram][month].push(row)
    }
  }

  return result
}
