import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Card, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomButton from '@/components/inputs/CustomButton'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import SelectFieldController from '@/components/inputs/SelectFieldController'
import TextFieldController from '@/components/inputs/TextFieldController'
import CustomCardActions from '@/components/layouts/CustomCardActions'
import CustomCardContent from '@/components/layouts/CustomCardContent'
import ErrorMessage from '@/features/resource/components/ErrorMessage'
import type { AddSteeringRangeRequest, UpdateSteeringRangeRequest } from '@/features/resource/endpoints/resources'
import type { ResourceSteeringRangeSchemaType } from '@/features/resource/schemas'
import { ResourceSteeringRangeSchema } from '@/features/resource/schemas'
import type { Resource, ResourceSteeringRange } from '@/features/resource/types'
import { convertToKilo } from '@/features/resource/utils/convertToKilo'
import { convertToWatts } from '@/features/resource/utils/convertToWatts'
import { UNIT_SYMBOLS } from '@/utils/powerEnergyTransformations'

type SteeringRangeFormProps = {
  initialData?: ResourceSteeringRange
  controlPorts: Resource['controlPorts']
  isMultistepResource: boolean
  steeringRanges: ResourceSteeringRange[]
  onSave: (data: AddSteeringRangeRequest | UpdateSteeringRangeRequest) => void
  onCancel: () => void
  onDelete?: () => void
}

const SteeringRangeForm = ({
  initialData,
  controlPorts,
  isMultistepResource,
  steeringRanges,
  onSave,
  onCancel,
  onDelete,
}: SteeringRangeFormProps) => {
  const { t } = useTranslation()

  // prepare a set off already used control ports in mappings
  const usedControlPorts = new Set<string>(
    steeringRanges
      .filter((range) => range.id !== initialData?.id)
      .flatMap((range) => range.multistepMappings.map((mapping) => mapping.controlPortId))
      .filter((controlPortId) => controlPortId !== null) ?? [],
  )

  const methods = useForm<ResourceSteeringRangeSchemaType>({
    defaultValues: initialData
      ? {
          max: {
            valueType: initialData.max.valueType,
            value: convertToKilo(initialData.max.value),
            minSecondsOnThisLevel: initialData.max.minSecondsOnThisLevel,
            maxSecondsOnThisLevel: initialData.max.maxSecondsOnThisLevel,
          },
          min: {
            valueType: initialData.min.valueType,
            value: convertToKilo(initialData.min.value),
            minSecondsOnThisLevel: initialData.min.minSecondsOnThisLevel,
            maxSecondsOnThisLevel: initialData.min.maxSecondsOnThisLevel,
          },
          step: {
            valueType: initialData.step.valueType,
            value: convertToKilo(initialData.step.value),
            minSecondsOnThisLevel: initialData.step.minSecondsOnThisLevel,
            maxSecondsOnThisLevel: initialData.step.maxSecondsOnThisLevel,
          },
          multistepMappings: initialData.multistepMappings
            ? initialData.multistepMappings.map((mapping) => ({
                stepValue: convertToKilo(mapping.stepValue),
                controlPortId: mapping.controlPortId,
              }))
            : [],
        }
      : {
          max: { valueType: 'ABSOLUTE', value: null, minSecondsOnThisLevel: null, maxSecondsOnThisLevel: null },
          min: { valueType: 'ABSOLUTE', value: null, minSecondsOnThisLevel: null, maxSecondsOnThisLevel: null },
          step: { valueType: 'ABSOLUTE', value: null, minSecondsOnThisLevel: null, maxSecondsOnThisLevel: null },
          multistepMappings: [],
        },
    resolver: zodResolver(ResourceSteeringRangeSchema(usedControlPorts)),
    mode: 'onChange',
  })

  const {
    handleSubmit,
    formState: { errors },
    watch,
  } = methods
  watch('max.valueType')
  watch('min.valueType')

  const onSubmit = (data: ResourceSteeringRangeSchemaType) => {
    const convertedData: AddSteeringRangeRequest | UpdateSteeringRangeRequest = {
      max: { ...data.max, value: convertToWatts(data.max.value) },
      min: { ...data.min, value: convertToWatts(data.min.value) },
      step: { ...data.step, value: convertToWatts(data.step.value) },
      multistepMappings: data.multistepMappings
        ? data.multistepMappings.map((mapping) => ({
            stepValue: convertToWatts(mapping.stepValue),
            controlPortId: mapping.controlPortId,
          }))
        : [],
    }
    onSave(convertedData)
  }

  const valueTypeOptions = [
    { id: 'ABSOLUTE', value: 'ABSOLUTE', label: t('resources.steering.steering_ranges.table.value_type_absolute') },
    {
      id: 'DYNAMIC',
      value: 'DYNAMIC',
      label: t('resources.steering.steering_ranges.table.value_type_dynamic'),
      disabled: true,
    },
  ]

  const maxValue = methods.watch('max.value')
  const minValue = methods.watch('min.value')
  const stepValue = methods.watch('step.value')
  const multistepMappings = methods.watch('multistepMappings')

  useEffect(() => {
    if (!isMultistepResource) return

    const calculateMultistepValues = (min: number | null, max: number | null, step: number | null) => {
      if (min === null || max === null) return []
      if (step === null || step === 0) return min === max ? [min] : [min, max]

      const values: number[] = []
      for (let i = min; i <= max; i += step) {
        values.push(i)
      }
      return values
    }

    const calculatedValues = calculateMultistepValues(minValue, maxValue, stepValue)
    const previousValues = multistepMappings?.map((mapping) => mapping.stepValue)

    if (
      calculatedValues.length !== previousValues?.length ||
      !calculatedValues.every((value, index) => value === previousValues[index])
    ) {
      methods.setValue(
        'multistepMappings',
        calculatedValues.map((multistepValue) => ({ stepValue: multistepValue, controlPortId: null })),
      )
    }
  }, [minValue, maxValue, stepValue])

  const renderRangeEntry = (name: 'max' | 'min' | 'step', label: string) => {
    const isError = name === 'max' || name === 'min' ? !!errors.maxmin : false

    return (
      <Box>
        <Typography sx={{ mb: 2 }} variant="subtitle2">
          {label}
        </Typography>
        <Stack direction="row" spacing={2}>
          <Box flexBasis="25%" flexGrow={1}>
            <Controller
              name={`${name}.valueType`}
              render={({ field, fieldState: { error } }) => (
                <CustomSelectField
                  fullWidth
                  error={isError || !!error}
                  label={t('resources.steering.steering_ranges.table.value_type')}
                  options={valueTypeOptions}
                  {...field}
                />
              )}
            />
          </Box>
          <Box flexBasis="25%" flexGrow={1}>
            <TextFieldController
              error={isError || !!errors[name]?.value}
              label={t('resources.steering.steering_ranges.table.value')}
              name={`${name}.value`}
              placeholder="Value (kW)"
              type="number"
              onChange={(e) => {
                const value = e.target.value === '' ? null : Number(e.target.value)
                methods.setValue(`${name}.value`, value)
              }}
            />
            <ErrorMessage errors={errors} name={`${name}.value`} />
          </Box>
          <Box flexBasis="25%" flexGrow={1}>
            <TextFieldController
              label={t('resources.steering.steering_ranges.table.min_time_on_level')}
              name={`${name}.minSecondsOnThisLevel`}
              placeholder="Min. seconds on this level"
              type="number"
              onChange={(e) => {
                const value = e.target.value === '' ? null : Number(e.target.value)
                methods.setValue(`${name}.minSecondsOnThisLevel`, value)
              }}
            />
          </Box>
          <Box flexBasis="25%" flexGrow={1}>
            <TextFieldController
              label={t('resources.steering.steering_ranges.table.max_time_on_level')}
              name={`${name}.maxSecondsOnThisLevel`}
              placeholder="Max. seconds on this level"
              type="number"
              onChange={(e) => {
                const value = e.target.value === '' ? null : Number(e.target.value)
                methods.setValue(`${name}.maxSecondsOnThisLevel`, value)
              }}
            />
          </Box>
        </Stack>
      </Box>
    )
  }

  const renderMultistepMapping = (
    multistepMapping: { stepValue: number | null; controlPortId: string | null },
    index: number,
  ) => {
    return (
      <Box
        key={`multistep_mapping_${multistepMapping.stepValue}_${multistepMapping.controlPortId}`}
        aria-label={`multistep_mapping_entry_${index}`}
      >
        <Stack direction="row" spacing={2}>
          <Box sx={{ width: '25%' }}>
            <Typography sx={{ mb: 2 }} variant="body1">
              {multistepMapping.stepValue} {UNIT_SYMBOLS.kilowatts}
            </Typography>
          </Box>
          <Box sx={{ width: '75%' }}>
            <SelectFieldController
              id={`multistep_mapping_${multistepMapping.stepValue}_${multistepMapping.controlPortId}`}
              label={t('resources.steering.multistep.select_control_port')}
              name={`multistepMappings.${index}.controlPortId`}
              options={
                controlPorts.map((controlPort) => ({
                  id: controlPort.controlPortID,
                  value: controlPort.controlPortID,
                  label: `${controlPort.ports} | ${controlPort.controlPortID} | ${controlPort.controlBoxType}`,
                })) ?? []
              }
              onChange={(event) => {
                methods.setValue(`multistepMappings.${index}.controlPortId`, event.target.value)
              }}
            />
          </Box>
        </Stack>
      </Box>
    )
  }

  return (
    <FormProvider {...methods}>
      <Card sx={{ bgcolor: 'rgba(20, 132, 160, 0.04)' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomCardContent>
            <Stack spacing={2}>
              {renderRangeEntry('max', t('resources.steering.steering_ranges.table.entryType.max'))}
              {renderRangeEntry('min', t('resources.steering.steering_ranges.table.entryType.min'))}
              {renderRangeEntry('step', t('resources.steering.steering_ranges.table.entryType.step'))}
              <ErrorMessage errors={errors} name="maxmin" />
              {multistepMappings?.length !== 0 && isMultistepResource && (
                <>
                  <Typography sx={{ mb: 2 }} variant="subtitle2">
                    {t('resources.steering.multistep.mappings')}
                  </Typography>
                  <ErrorMessage errors={errors} name="multistepMappings" />
                  {multistepMappings?.map(
                    (multistepValue: { stepValue: number | null; controlPortId: string | null }, index) => {
                      return renderMultistepMapping(multistepValue, index)
                    },
                  )}
                </>
              )}
            </Stack>
          </CustomCardContent>
          <CustomCardActions
            deleteProps={
              onDelete ? { onDelete, text: t('resources.steering.steering_ranges.delete'), withIcon: true } : undefined
            }
            sx={{ justifyContent: 'space-between' }}
          >
            <Stack direction="row" spacing={1}>
              <CustomButton type="submit" variant="contained">
                {t('common.button.save')}
              </CustomButton>
              <CustomButton variant="outlined" onClick={onCancel}>
                {t('common.button.cancel')}
              </CustomButton>
            </Stack>
          </CustomCardActions>
        </form>
      </Card>
    </FormProvider>
  )
}

export default SteeringRangeForm
