import { useEffect, useState } from 'react'
import type { FieldPath, FieldValues } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'

import type { CustomAutocompleteProps } from '@/components/inputs/CustomAutocomplete'
import CustomAutocomplete from '@/components/inputs/CustomAutocomplete'
import { DEFAULT_PARTNER } from '@/constants/partners'
import type { Partner } from '@/openapi/v1/msa-customer-partner-manager/partner'
import { getCountryCodeByLocation } from '@/utils/locations'

export type PartnerFieldControllerProps<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>> = Omit<
  CustomAutocompleteProps<string>,
  'options' | 'value' | 'ref' | 'disableClearable' | 'fullWidth' | 'error' | 'helperText' | 'label'
> & {
  name: TName
  location: string
  partners: Partner[]
  label: string
}

function comparePartners(a: Partner, b: Partner) {
  if (a.name === DEFAULT_PARTNER.name) return -1
  if (b.name === DEFAULT_PARTNER.name) return 1

  return a.name.localeCompare(b.name)
}

export function filterPartnersByCountry(partners: Partner[], countryCode?: string) {
  return partners.filter(
    (partner) => partner.partnerCode === DEFAULT_PARTNER.partnerCode || partner.countryCode === countryCode,
  )
}

export function PartnerFieldController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  location,
  partners,
  label,
  name,
  onChange,
  ...autocompleteProps
}: Readonly<PartnerFieldControllerProps<TFieldValues, TName>>) {
  const [availablePartners, setAvailablePartners] = useState<Partner[]>([])
  const { control } = useFormContext()

  useEffect(() => {
    if (partners) {
      const countryCode = getCountryCodeByLocation(location)

      if (countryCode) {
        setAvailablePartners(filterPartnersByCountry(partners, countryCode))
      } else {
        setAvailablePartners(partners)
      }
    }
  }, [partners, location])

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, ref: fieldRef, ...field }, fieldState: { error, invalid } }) => (
        <CustomAutocomplete
          {...field}
          {...autocompleteProps}
          ref={fieldRef}
          disableClearable
          fullWidth
          error={invalid}
          helperText={error?.message}
          label={label}
          options={
            availablePartners
              ?.toSorted((a, b) => comparePartners(a, b))
              .map((partner) => ({
                id: partner.partnerCode,
                value: partner.partnerCode,
                label: partner.name,
              })) ?? []
          }
          value={value ?? ''}
          onChange={onChange}
        />
      )}
    />
  )
}
