import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useOutletContext, useParams } from 'react-router-dom'

import Page from '@/components/layouts/Page'
import { useSiteQuery } from '@/features/site/hooks/useSiteQuery'
import { SiteTabs } from '@/features/site/pages/components/SiteTabs'
import type { Site } from '@/features/site/types/site'
import type { Breadcrumb } from '@/types/breadcrumb'

export type SiteDetailsPageConfig = {
  title: string
  breadcrumbs: Breadcrumb[]
  pageTag?: string
  appBarContent?: ReactNode
  activeTab: string
}

type ContextType = {
  site: Site
  siteDetailsCommonBreadcrumbs: Breadcrumb[]
  isFetchingSite: boolean
  pageConfig: SiteDetailsPageConfig | null
  setPageConfig: Dispatch<SetStateAction<SiteDetailsPageConfig>>
}

function SiteDetails() {
  const { t } = useTranslation()
  const { siteUuid } = useParams()
  const [pageConfig, setPageConfig] = useState<SiteDetailsPageConfig>({
    title: '',
    breadcrumbs: [],
    appBarContent: null,
    activeTab: '',
  })
  const { site, isLoading: isLoadingSite } = useSiteQuery({ uuid: siteUuid! }, { enabled: Boolean(siteUuid) })

  if (!siteUuid || !site || isLoadingSite) return null

  const siteDetailsCommonBreadcrumbs = [
    {
      text: t('component.page_header.customers'),
      to: '/sites',
    },
  ]

  return (
    <Page
      pageHeaderProps={{
        pageTitle: pageConfig.title,
        breadcrumbItems: pageConfig.breadcrumbs,
        appBarContent: pageConfig.appBarContent,
        pageTag: pageConfig.pageTag,
      }}
    >
      {pageConfig.activeTab !== '' && <SiteTabs pageConfig={pageConfig} site={site} />}

      <Outlet
        context={
          {
            site,
            siteDetailsCommonBreadcrumbs,
            isFetchingSite: isLoadingSite,
            pageConfig,
            setPageConfig,
          } satisfies ContextType
        }
      />
    </Page>
  )
}

export function useSiteDetails() {
  return useOutletContext<ContextType>()
}

export default SiteDetails
