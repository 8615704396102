export default function determineTimezone(countryCode?: string) {
  switch (countryCode) {
    case 'ee':
      return 'Europe/Tallinn'
    case 'es':
      return 'Europe/Madrid'
    case 'cz':
      return 'Europe/Prague'
    case 'dk':
      return 'Europe/Copenhagen'
    case 'nl':
      return 'Europe/Amsterdam'
    case 'fi':
      return 'Europe/Helsinki'
    case 'gr':
      return 'Europe/Athens'
    case 'no':
      return 'Europe/Oslo'
    case 'se':
      return 'Europe/Stockholm'
    default:
      return ''
  }
}
