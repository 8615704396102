import type { ChipProps } from '@mui/material'
import { alpha, Chip, Menu, MenuItem, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import EllipsisTypography from '@/components/dataDisplay/EllipsisTypography'

type Chip = Omit<ChipProps, 'color' | 'variant' | 'size'> & {
  id: string
}

interface ChipListProps {
  color?: ChipProps['color']
  variant?: ChipProps['variant']
  size?: ChipProps['size']
  direction?: 'row' | 'column'

  chips: Chip[]
  /**
   * Indicates the maximum number of chips to be displayed. The rest of the chips will be display as part of a menu.
   */
  limit?: number
}

/**
 * It is a component that displays a list of chips. It wraps a bunch of 'Chip" components and allows to assign
 * properties to all of them at once (for example, a color or a variant).
 *
 * One of the main features of this component is the ability to limit the amount of chips to be displayed.
 * If the limit is exceeded, the rest of the chips will be shown as part of a menu. This is specially useful when the
 * component is used in a small space or when the amount of chips could be very large.
 * With a limit of 0 it shows a chip with the amount of chips and the chips will be shown in a menu when hovered.
 */
const ChipList = ({
  variant = 'filled',
  color = 'default',
  direction = 'row',
  size = 'medium',
  limit = 0,
  chips,
}: ChipListProps) => {
  const theme = useTheme()
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const [openMenu, setOpenMenu] = useState(false)
  const { t } = useTranslation()
  const chipsSize = chips.length
  const limitOverflow = limit >= 0 && chipsSize > 0 && chipsSize > limit
  const visibleChips = limitOverflow ? chips.slice(0, limit) : chips
  const menuChips = limitOverflow ? chips.slice(limit) : []
  const menuChipsSize = menuChips.length

  function handleOpenMenu() {
    setOpenMenu(true)
  }
  function handleCloseMenu() {
    setOpenMenu(false)
  }

  return (
    <Stack
      ref={anchorEl}
      sx={{
        flexDirection: direction,
        gap: 0.5,
        alignItems: 'center',
        width: 'fit-content',
        height: '100%',
      }}
    >
      {visibleChips.map((chip) => (
        <Chip
          {...chip}
          key={chip.id}
          color={color}
          label={
            <EllipsisTypography sx={{ fontSize: '13px' }} tooltipTitle={chip.label} variant="body1">
              {chip.label}
            </EllipsisTypography>
          }
          size={size}
          sx={{
            // This maxWidth is used to avoid having a very large chip when the label is too long.
            maxWidth: '150px',
            ...chip.sx,
          }}
          variant={variant}
        />
      ))}

      {limitOverflow && (
        <>
          {limit === 0 ? (
            <Chip
              color={color}
              label={menuChipsSize}
              size={size}
              variant={variant}
              onMouseEnter={handleOpenMenu}
              onMouseLeave={handleCloseMenu}
            />
          ) : (
            <CustomTypography
              aria-controls={openMenu ? 'more-items-menu' : undefined}
              aria-expanded={openMenu ? 'true' : undefined}
              aria-haspopup="true"
              aria-owns={openMenu ? 'mouse-items-menu' : undefined}
              id="more-items"
              sx={{
                cursor: 'pointer',
                border: 'none',
                background: 'transparent',
                color: openMenu ? theme.palette.primary.main : theme.palette.secondary.main,
                '&:hover': { color: theme.palette.primary.main },
              }}
              variant="body2"
              onMouseEnter={handleOpenMenu}
              onMouseLeave={handleCloseMenu}
            >
              {t('component.list.more', { count: menuChipsSize })}
            </CustomTypography>
          )}

          <Menu
            disableAutoFocusItem
            MenuListProps={{
              'aria-labelledby': 'more-items',
            }}
            anchorEl={anchorEl.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            id="more-items-menu"
            open={openMenu}
            sx={{
              pointerEvents: 'none',
              '& .MuiMenu-paper': {
                width: '200px',
                background: theme.palette.secondary.dark,
              },
              '& .MuiMenu-list': {
                padding: theme.spacing(0.5, 0),
              },
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            variant="menu"
            onClose={handleCloseMenu}
          >
            {menuChips.map((chip, index) => (
              <MenuItem
                key={chip.id}
                disableGutters
                divider={index < menuChipsSize - 1}
                selected={false}
                sx={{
                  padding: theme.spacing(0.5, 1),
                  '&.MuiMenuItem-divider': {
                    borderColor: alpha(theme.palette.getContrastText(theme.palette.secondary.dark), 0.12),
                    marginTop: '1px',
                  },
                }}
              >
                <EllipsisTypography
                  sx={{
                    color: theme.palette.getContrastText(theme.palette.secondary.dark),
                    fontWeight: theme.typography.fontWeightMedium,
                    lineHeight: '14px',
                  }}
                  variant="caption"
                >
                  {chip.label}
                </EllipsisTypography>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Stack>
  )
}

export default ChipList
