import type { DateTime } from 'luxon'

import type { PtuResult } from '@/features/bidding/components/BidVersionPtusDataGrid'
import { ZERO_VOLUME } from '@/features/bidding/components/BidVersionPtusDataGrid'
import type { ExtendedBid, Forecast, PtuChunk } from '@/features/bidding/types/bid'
import { calculateAverageVolume } from '@/features/bidding/utils/calculations/calculateAverageVolume'
import { findAcceptedVolumeForTime, findVolumeForTime } from '@/features/bidding/utils/findVolumeForTime'

export const buildBidPtuResultRowItems = (
  bidVersion: ExtendedBid,
  isPtuLessThanAnHour: boolean,
  versionForecast?: Forecast | null,
): PtuResult[] => {
  const groupedByHour = groupPtuResultsByHour(bidVersion, isPtuLessThanAnHour, versionForecast)

  const results: PtuResult[] = []

  groupedByHour.forEach((ptuResults, parentPtu) => {
    const offeredVolumes = ptuResults.map((ptuResult) => ptuResult.offeredVolume)
    const acceptedVolumes = ptuResults
      .map((ptuResult) => ptuResult.acceptedVolume)
      .filter((volume) => volume != undefined)
    const forecastedVolumes =
      ptuResults
        .map((ptuResult) => findVolumeForTime(ptuResult.ptu.start, versionForecast?.forecastPtus ?? []))
        .filter((volume) => volume != undefined) ?? []
    const ptuStart = ptuResults[0].ptu.start.startOf('hour')

    // Create the parent row that displays average volumes only if the PTU duration is less than 60 minutes
    if (isPtuLessThanAnHour) {
      results.push({
        hierarchy: [parentPtu],
        ptu: { start: ptuStart, end: ptuStart.plus({ hours: 1 }) },
        offeredVolume: calculateAverageVolume(offeredVolumes) ?? ZERO_VOLUME,
        forecastedVolume: calculateAverageVolume(forecastedVolumes),
        acceptedVolume: calculateAverageVolume(acceptedVolumes),
        chunks: buildParentRowPtuChunks(ptuResults),
      })
    }

    // Add child rows that display the actual volumes per PTU
    results.push(...ptuResults)
  })

  return results
}

const groupPtuResultsByHour = (
  bidVersion: ExtendedBid,
  isPtuLessThanAnHour: boolean,
  versionForecast?: Forecast | null,
) => {
  const groupedByHour = new Map<string, PtuResult[]>()

  bidVersion.ptus.forEach((ptu) => {
    const parentPtu = ptu.ptu.start.toFormat('HH:00')

    const ptuResult: PtuResult = {
      hierarchy: [parentPtu, ...childPtu(ptu.ptu.start, isPtuLessThanAnHour)],
      ptu: ptu.ptu,
      offeredVolume: ptu.offeredVolume,
      forecastedVolume: versionForecast ? findVolumeForTime(ptu.ptu.start, versionForecast.forecastPtus) : undefined,
      acceptedVolume: findAcceptedVolumeForTime(ptu.ptu.start, bidVersion.ptus),
      chunks: ptu.ptuChunks,
    }

    if (!groupedByHour.has(parentPtu)) {
      groupedByHour.set(parentPtu, [])
    }

    groupedByHour.get(parentPtu)?.push(ptuResult)
  })

  return groupedByHour
}

const childPtu = (ptuStartTime: DateTime, isPtuLessThanAnHour: boolean) => {
  const parentPtu = ptuStartTime.toFormat('HH:00')
  const childPtu = ptuStartTime.toFormat('HH:mm')
  return parentPtu === childPtu && !isPtuLessThanAnHour ? [] : [childPtu]
}

const buildParentRowPtuChunks = (ptuResults: PtuResult[]): PtuChunk[] => {
  const groupedByPrice = groupByOfferedPrice(ptuResults)

  const results: PtuChunk[] = []

  groupedByPrice.forEach((ptuChunks, offeredPrice) => {
    const offeredVolumes = ptuChunks.map((chunk) => chunk.offeredVolume)
    const acceptedVolumes = ptuChunks
      .map((chunk) => chunk.acceptedVolume)
      .filter((volume) => volume !== null && volume !== undefined)

    results.push({
      offeredPrice: offeredPrice,
      offeredVolume: calculateAverageVolume(offeredVolumes) ?? ZERO_VOLUME,
      acceptedVolume: calculateAverageVolume(acceptedVolumes),
    })
  })

  return results
}

const groupByOfferedPrice = (ptuResults: PtuResult[]): Map<number, PtuChunk[]> => {
  const groupedByPrice = new Map<number, PtuChunk[]>()

  ptuResults.forEach((ptu) =>
    ptu.chunks?.forEach((chunk) => {
      if (!groupedByPrice.has(chunk.offeredPrice)) {
        groupedByPrice.set(chunk.offeredPrice, [])
      }
      groupedByPrice.get(chunk.offeredPrice)?.push(chunk)
    }),
  )

  return groupedByPrice
}
