import { Close } from '@mui/icons-material'
import { DialogActions, DialogContent, Divider, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import LabeledTextField from '@/components/dataDisplay/LabeledTextField'
import CustomDialog from '@/components/feedback/CustomDialog'
import CustomButton from '@/components/inputs/CustomButton'
import { AvailabilityStatusChip } from '@/features/site/components/availability/datagrid/AvailabilityStatusChip'
import { ResourcesDataGrid } from '@/features/site/components/availability/ResourcesDataGrid'
import { ExceptionDatesDataGrid } from '@/features/site/components/availability/serviceRule/ExceptionDatesDataGrid'
import { Recurrence } from '@/features/site/components/availability/serviceRule/Recurrence'
import {
  formatLimitValue,
  formatLocalizedDateMed,
  getLimitValueColumnHeader,
} from '@/features/site/components/availability/utils'
import type { MarketProgram } from '@/features/site/types/marketProgram'
import type { ServiceRule } from '@/features/unavailability/types/serviceRule'

interface AvailabilityDetailsDialogProps {
  open: boolean
  onClose: (value: string) => void
  serviceRule: ServiceRule
  marketProgram: MarketProgram
  customerTimeZone: string
}

const LabeledStatusChipField: FC<{ label; status }> = ({ label, status }) => (
  <Stack
    alignItems={'flex-start'}
    justifyContent={'space-between'}
    sx={{
      bgcolor: grey[50],
      borderRadius: 1,
      p: 2,
    }}
    width={'100%'}
  >
    <CustomTypography color={'#9e9e9e'} variant="caption" width={100}>
      {`${label}`}
    </CustomTypography>
    <AvailabilityStatusChip size="small" status={status} />
  </Stack>
)

function getResources(serviceRule: ServiceRule) {
  if (serviceRule?.relayWires && serviceRule?.relayWires?.length > 0) return serviceRule?.relayWires
  else if (serviceRule?.analogWires && serviceRule?.analogWires?.length > 0) return serviceRule?.analogWires
  else return []
}

function AvailabilityDivider() {
  return <Divider style={{ marginBottom: '16px', marginTop: '16px' }} />
}

export const ServiceRuleDetailsDialog = (props: AvailabilityDetailsDialogProps) => {
  const { t, i18n } = useTranslation()
  const { open, onClose, serviceRule, marketProgram, customerTimeZone } = props

  return (
    <CustomDialog
      open={open}
      size="medium"
      title={t('customer_details.tabs.availability.availability_data_grid.dialog.title')}
      onClose={onClose}
    >
      <DialogContent>
        <Stack direction="row" spacing={1}>
          <LabeledTextField
            label={t('customer_details.tabs.availability.available_from')}
            text={formatLocalizedDateMed(customerTimeZone, i18n.language, serviceRule.startDate)}
            variant={'filled'}
          />
          <LabeledTextField
            label={t('customer_details.tabs.availability.available_to')}
            text={formatLocalizedDateMed(customerTimeZone, i18n.language, serviceRule.endDate)}
            variant={'filled'}
          />
          <LabeledTextField
            label={t('customer_details.tabs.availability.start_end_time')}
            text={`${serviceRule.startTime} - ${serviceRule.endTime}`}
            variant={'filled'}
          />
          <LabeledTextField
            label={getLimitValueColumnHeader(t, marketProgram.type)}
            text={formatLimitValue(t, marketProgram.type, Number(serviceRule.limitValue))}
            variant={'filled'}
          />
          <LabeledStatusChipField label={t('common.status')} status={serviceRule.status} />
        </Stack>

        <Recurrence recurrence={serviceRule.recurrence ?? ''} />
        <AvailabilityDivider />

        <ExceptionDatesDataGrid exceptionDates={serviceRule.exceptionDates ?? []} />
        <AvailabilityDivider />

        <ResourcesDataGrid marketProgram={marketProgram} resources={getResources(serviceRule)} />
      </DialogContent>

      <DialogActions>
        <CustomButton
          color="primary"
          fullWidth={false}
          startIcon={<Close />}
          type="reset"
          variant="outlined"
          onClick={onClose}
        >
          {t('common.button.close')}
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  )
}
