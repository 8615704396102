import { StopCircleOutlined, TrendingUp } from '@mui/icons-material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Stack } from '@mui/material'
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { DURATION_FORMAT } from '@/constants/dateTimeFormats'
import GenericStartPrequalificationDialog from '@/features/activationGroup/components/GenericStartPrequalificationDialog'
import PrequalificationDataGridToolbar from '@/features/activationGroup/components/PrequalificationDataGridToolbar'
import { abortGenericPrequalification } from '@/features/activationGroup/endpoints/activationGroups'
import { useGenericPrequalificationsQuery } from '@/features/activationGroup/hooks/useGenericPrequalificationsQuery'
import type { ActivationGroup, GenericPrequalification } from '@/features/activationGroup/types'
import { getGrafanaDisturbanceUrl } from '@/features/disturbance/utils/grafana'

type GenericPrequalificationsDataGridProps = {
  patternUuid: string
  activationGroup: ActivationGroup
}
type GenericPrequalificationRenderCell = GridRenderCellParams<GenericPrequalification>

const DATE_FORMAT = 'dd LLL, yyyy - HH:mm:ss'
const TIME_FORMAT = 'HH:mm:ss'

const GenericPrequalificationsDataGrid = ({ patternUuid, activationGroup }: GenericPrequalificationsDataGridProps) => {
  const { t } = useTranslation()
  const [paginationModel, setPaginationModel] = useState(DEFAULT_DATA_GRID_PAGINATION_MODEL)
  const [openStartPrequalificationDialog, setOpenStartPrequalificationDialog] = useState(false)
  const { prequalifications, totalCount, isLoading } = useGenericPrequalificationsQuery({
    patternUuid,
    pagination: paginationModel,
  })

  const columns: GridColDef<GenericPrequalification>[] = [
    {
      field: 'uuid',
      headerName: t('common.uuid'),
      flex: 1,
    },
    {
      field: 'portfolioChannel',
      headerName: t('activation_groups.prequalifications.table.header.portfolio_channel'),
      flex: 2,
      valueGetter: (_, value: GenericPrequalification) => value.portfolioCode + ' @ ' + value.activationTriggerChannel,
    },
    {
      field: 'startedAt',
      headerName: t('activation_groups.prequalifications.table.header.triggeredAt'),
      flex: 2,
      valueFormatter: (value: GenericPrequalification['startedAt']) => value.toFormat(DATE_FORMAT),
    },
    {
      field: 'simulationStartedAt',
      headerName: t('activation_groups.prequalifications.table.header.simulationStartedAt'),
      flex: 1,
      valueFormatter: (value: GenericPrequalification['simulationStartedAt']) =>
        value ? value.toFormat(TIME_FORMAT) : '-',
    },
    {
      field: 'simulationFinishedAt',
      headerName: t('activation_groups.prequalifications.table.header.simulationFinishedAt'),
      flex: 1,
      valueFormatter: (value: GenericPrequalification['simulationFinishedAt']) =>
        value ? value.toFormat(TIME_FORMAT) : '-',
    },
    {
      field: 'abortedAt',
      headerName: t('activation_groups.prequalifications.table.header.abortedAt'),
      flex: 1,
      valueFormatter: (value: GenericPrequalification['abortedAt']) => (value ? value.toFormat(TIME_FORMAT) : '-'),
    },
    {
      field: 'endedAt',
      headerName: t('activation_groups.prequalifications.table.header.endedAt'),
      flex: 1,
      valueFormatter: (value: GenericPrequalification['endedAt']) => (value ? value.toFormat(TIME_FORMAT) : '-'),
    },
    {
      field: 'duration',
      headerName: t('activation_groups.prequalifications.table.header.duration'),
      flex: 1,
      valueGetter: (_, value: GenericPrequalification) =>
        value.endedAt?.diff(value.startedAt).toFormat(DURATION_FORMAT).replace('00h ', ''),
    },
    {
      field: 'is_min_capacity',
      headerName: t('activation_groups.prequalifications.table.header.is_min_capacity'),
      flex: 1,
      renderCell: ({ row: { isMinCapacity } }: GenericPrequalificationRenderCell) =>
        isMinCapacity ? (
          <CheckIcon color="success" titleAccess={t('activation_groups.prequalifications.table.is_min_capacity')} />
        ) : (
          <CloseIcon color="error" titleAccess={t('activation_groups.prequalifications.table.is_not_min_capacity')} />
        ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      renderCell: ({
        row: { uuid, startedAt, endedAt, abortedAt, activationTriggerChannel },
      }: GenericPrequalificationRenderCell) => {
        return (
          <Stack alignItems="start" flexDirection="row" sx={{ height: '100%' }}>
            <IconButton
              data-testid="view-grafana-button"
              href={getGrafanaDisturbanceUrl({ activationGroup, activationTriggerChannel, startedAt, endedAt })}
              target="_blank"
              title={t('common.view_grafana')}
            >
              <TrendingUp />
            </IconButton>

            {(abortedAt === null || endedAt === null) && (
              <IconButton
                data-testid="abort-prequalification-button"
                title={t('activation_groups.prequalifications.table.abort')}
                onClick={() => {
                  abortGenericPrequalification(uuid)
                }}
              >
                <StopCircleOutlined />
              </IconButton>
            )}
          </Stack>
        )
      },
    },
  ]

  function handleOpenStartPrequalificationDialog() {
    setOpenStartPrequalificationDialog(true)
  }

  function handleCloseStartPrequalificationDialog() {
    setOpenStartPrequalificationDialog(false)
  }

  return (
    <>
      <CustomDataGrid
        disableColumnMenu
        disableColumnSorting
        aria-label={t('activation_groups.prequalifications.table.title')}
        columns={columns}
        getRowId={(row) => row.uuid}
        isLoading={isLoading}
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={totalCount}
        rows={prequalifications ?? []}
        slotProps={{
          toolbar: {
            onStartNewPrequalification: handleOpenStartPrequalificationDialog,
          },
        }}
        slots={{
          toolbar: PrequalificationDataGridToolbar,
        }}
        onPaginationModelChange={setPaginationModel}
      />

      <GenericStartPrequalificationDialog
        open={openStartPrequalificationDialog}
        patternUuid={patternUuid}
        onClose={handleCloseStartPrequalificationDialog}
      />
    </>
  )
}

export default GenericPrequalificationsDataGrid
