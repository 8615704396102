import EditIcon from '@mui/icons-material/EditOutlined'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import GridToolbar from '@/components/dataDisplay/GridToolbar'
import CustomButton from '@/components/inputs/CustomButton'
import type { GenericActivationGroupSchedule } from '@/features/activationGroup/types'
import { ActivationScheduleStatus } from '@/features/activationGroup/types'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends ActivationGroupResourcesDataGridToolbarProps {}
}

export type ActivationGroupResourcesDataGridToolbarProps = {
  onAssignResourceToActivationGroup: () => void
  activationGroupSchedule: GenericActivationGroupSchedule
}

const ActivationGroupScheduleResourcesDataGridToolbar = ({
  onAssignResourceToActivationGroup,
  activationGroupSchedule,
}: ActivationGroupResourcesDataGridToolbarProps) => {
  const { t } = useTranslation()

  const getDisabledText = () => {
    if (new Date(activationGroupSchedule.startAt) < new Date()) {
      return t('activation_groups.schedules.resources.table.assigned_resource_disabled')
    }

    if (activationGroupSchedule.status === ActivationScheduleStatus.PENDING) {
      return t('activation_groups.schedules.resources.table.assigned_resource_disabled_pending')
    }
    return ''
  }

  const isAssignResourcesDisabled =
    new Date(activationGroupSchedule.startAt) < new Date() ||
    activationGroupSchedule.status === ActivationScheduleStatus.PENDING

  return (
    <GridToolbar
      actions={
        <Stack spacing={2}>
          <CustomButton
            aria-label={t('activation_groups.schedules.resources.table.assigned_resource_label')}
            color="primary"
            disabled={isAssignResourcesDisabled}
            startIcon={<EditIcon />}
            to="/bidding/todo-list"
            variant={'contained'}
            onClick={onAssignResourceToActivationGroup}
          >
            {t(`activation_groups.schedules.resources.table.edit_resources`)}
          </CustomButton>
          {isAssignResourcesDisabled && (
            <CustomTypography sx={{ fontSize: '12px' }} variant="body2">
              {getDisabledText()}
            </CustomTypography>
          )}
        </Stack>
      }
    />
  )
}

export default ActivationGroupScheduleResourcesDataGridToolbar
