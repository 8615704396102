import type { SelectChangeEvent } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import TextList from '@/components/dataDisplay/TextList'
import CustomMultipleSelectField from '@/components/inputs/CustomMultipleSelectField'
import type { SelectFieldOption } from '@/components/inputs/CustomSelectField'
import { FIELD_WIDTH } from '@/constants/layout'
import { NW_MARKET_PROGRAMS } from '@/features/site/constants'
import { convertToStandardMarketProgramType } from '@/features/site/utils/revenueUtils/convertToStandardMarketProgramType'
import { sortOptionsAlphabetically } from '@/utils/sortOptionsAlphabetically'

type SiteRevenueNWMarketProgramFilterProps = {
  marketPrograms: string[]
  onChange: (marketPrograms: string[]) => void
}

export const AUTOCOMPLETE_FIELD_MAX_WIDTH = 350

const SiteRevenueNWMarketProgramFilter = ({
  marketPrograms,
  onChange,
}: Readonly<SiteRevenueNWMarketProgramFilterProps>) => {
  const { t } = useTranslation()
  const sortedMarketProgramsOptions: SelectFieldOption<string>[] = useMemo(() => {
    return sortOptionsAlphabetically<string>(
      NW_MARKET_PROGRAMS.map((marketProgram) => ({
        id: marketProgram,
        label: t(`common.market_program.${convertToStandardMarketProgramType(marketProgram)}`),
        value: marketProgram,
      })),
    )
  }, [t])

  const hasAllMarketPrograms = marketPrograms.length === NW_MARKET_PROGRAMS.length

  function handleChange(evt: SelectChangeEvent<string[]>) {
    const values = evt.target.value

    if (values.includes('all')) {
      onChange(NW_MARKET_PROGRAMS)

      return
    }

    onChange(values as string[])
  }

  const options: SelectFieldOption<string>[] = hasAllMarketPrograms
    ? sortedMarketProgramsOptions
    : [
        { id: 'all', label: t('customer_revenue.select_all_market_programs'), value: 'all' },
        ...sortedMarketProgramsOptions,
      ]

  return (
    <CustomMultipleSelectField
      id="revenue-market-programs"
      label={t('common.market_program.label_plural')}
      options={options}
      renderValue={(values) => (
        <TextList
          limit={1}
          values={values.map((value) => t(`common.market_program.${convertToStandardMarketProgramType(value)}`))}
        />
      )}
      sx={{ minWidth: FIELD_WIDTH, maxWidth: AUTOCOMPLETE_FIELD_MAX_WIDTH }}
      value={marketPrograms}
      variant="outlinedWhite"
      onChange={handleChange}
    />
  )
}

export default SiteRevenueNWMarketProgramFilter
