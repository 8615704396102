import { IconChevronLeft } from '@aws-amplify/ui-react/internal'
import { Box } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ButtonBar from '@/components/inputs/ButtonBar'
import CustomButton from '@/components/inputs/CustomButton'
import PageHeader from '@/components/layouts/PageHeader'
import CountrySelector from '@/features/bidding/components/CountrySelector'
import CreateBidButton from '@/features/bidding/components/CreateBidButton'
import OfferedPriceVolumeOutOfRangeTooltip from '@/features/bidding/components/OfferedPriceVolumeOutOfRangeTooltip'
import PriceDataGrid from '@/features/bidding/components/price/offer/PriceDataGrid'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import useActivationGroupBidsQuery from '@/features/bidding/hooks/useActivationGroupBidsQuery'
import { useInvalidateBidOverviewPageQuery } from '@/features/bidding/hooks/useBidOverviewPageQuery'
import { useInvalidateGroupedBidsPageQuery } from '@/features/bidding/hooks/useGroupedBidsPageQuery'
import { useSaveBidsMutation } from '@/features/bidding/hooks/useSaveBidsMutation'
import getBidConfig from '@/features/bidding/utils/getBidConfig'
import type { GroupedBidParams } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import {
  buildBidCapacityLink,
  buildBidPriceConfirmationLink,
} from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import type { AggregatedBid, AggregatedBidPtu } from '@/features/bidding/utils/model/buildAggregatedBid'
import { buildAggregatedBid } from '@/features/bidding/utils/model/buildAggregatedBid'
import { splitAggregatedBid } from '@/features/bidding/utils/price/splitAggregatedBid'

type Props = {
  groupParams: GroupedBidParams
}

const BidOfferPrice = ({ groupParams }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { selectedCountry } = useBiddingContext()
  const { priceLadderType } = getBidConfig(selectedCountry, groupParams.bidType)
  const { activationGroupBids, isLoading } = useActivationGroupBidsQuery(groupParams)
  const [aggregatedBid, setAggregatedBid] = useState<AggregatedBid>({ ptus: [], unit: '', ladderType: priceLadderType })
  const { saveBids, isPending } = useSaveBidsMutation()
  const invalidateCachedQueryStateOfGroupedBids = useInvalidateGroupedBidsPageQuery()
  const invalidateCachedQueryStateOfBidOverview = useInvalidateBidOverviewPageQuery()

  useEffect(() => {
    if (!isLoading && aggregatedBid.ptus.length === 0) {
      setAggregatedBid(buildAggregatedBid(activationGroupBids, priceLadderType))
    }
  }, [activationGroupBids])

  // This is called when the user clicks the "Create Bid" button to submit bids in OFFERED status for final validation.
  // The saving of DRAFT status bids is done in previous pages.
  const handleOnSubmit = async () => {
    const bidByActivationGroups = splitAggregatedBid(aggregatedBid, activationGroupBids)
    await saveBids(bidByActivationGroups)
    await invalidateCachedQueryStateOfGroupedBids()
    await invalidateCachedQueryStateOfBidOverview()
    navigate(buildBidPriceConfirmationLink(groupParams))
  }

  const ptuVolumesAreValid = !anyPtuRemainingVolumeIsNegative(aggregatedBid.ptus)

  return (
    <>
      <PageHeader
        appBarContent={
          <Box alignItems="center" display="flex" flexDirection="row" sx={{ justifyContent: 'left' }}>
            <CountrySelector disabled />
          </Box>
        }
        breadcrumbItems={[
          { text: t('component.page_header.bidding'), to: '/bidding' },
          { text: t('component.page_header.bid.todo_list'), to: '/bidding/todo-list' },
          {
            text: t('component.page_header.bid.create.label', {
              portfolio: groupParams.portfolioCode,
              marketProgram: t(`bidding.market_program.${groupParams.marketProgram}`),
              bidType: groupParams.bidType.toLowerCase(),
            }),
            to: buildBidCapacityLink(groupParams),
          },
        ]}
        pageTitle={t('bidding.create_bid.price_bid')}
      />

      <PriceDataGrid
        aggregatedBid={aggregatedBid}
        groupParams={groupParams}
        isLoading={isLoading}
        onAggregatedBidChange={setAggregatedBid}
      />

      <ButtonBar>
        <CustomButton
          key="backButton"
          startIcon={<IconChevronLeft />}
          variant={'outlined'}
          onClick={() => navigate(-1)}
        >
          {t('common.navigation.back')}
        </CustomButton>
        <OfferedPriceVolumeOutOfRangeTooltip disabled={ptuVolumesAreValid}>
          <CreateBidButton
            dialogProps={{
              description: t('bidding.dialog.offered_volume_out_of_biddable_volume_range.description'),
              title: t('bidding.dialog.offered_volume_out_of_biddable_volume_range.title'),
            }}
            disabled={isLoading || isPending || !ptuVolumesAreValid}
            onSubmit={handleOnSubmit}
          />
        </OfferedPriceVolumeOutOfRangeTooltip>
      </ButtonBar>
    </>
  )
}

const anyPtuRemainingVolumeIsNegative = (aggregatedBid: AggregatedBidPtu[]) =>
  aggregatedBid.some((ptu) => ptu.remaining < 0)

export default BidOfferPrice
