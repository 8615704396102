import { FormGroup, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import TextFieldController from '@/components/inputs/TextFieldController'

export default function InformationSection() {
  const { t } = useTranslation()

  return (
    <FormGroup>
      <Grid container gap={3}>
        <Grid size={{ md: 5, sm: 6, xs: 12 }}>
          <TextFieldController label={t('user_form.form.given_name_label')} name="givenName" />
        </Grid>
        <Grid size={{ md: 5, sm: 6, xs: 12 }}>
          <TextFieldController label={t('user_form.form.family_name_label')} name="familyName" />
        </Grid>

        <Grid size={{ md: 5, sm: 6, xs: 12 }}>
          <TextFieldController required label={t('user_form.form.email_label')} name="email" />
        </Grid>
      </Grid>
    </FormGroup>
  )
}
