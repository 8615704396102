import { FormGroup, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import SelectFieldController from '@/components/inputs/SelectFieldController'
import { INTERFACE_LANGUAGES } from '@/constants/interfaceLanguages'

export default function InterfaceSection() {
  const { t } = useTranslation()

  return (
    <FormGroup>
      <Grid container gap={3}>
        <Grid size={{ md: 5, sm: 12, xs: 12 }}>
          <SelectFieldController
            id="interface-language"
            label={t('user_form.form.interface_language_label')}
            name="uiLanguage"
            options={Object.values(INTERFACE_LANGUAGES).map((lang) => ({
              id: lang.value,
              value: lang.value,
              label: t(`common.interface_language.${lang.value}`),
            }))}
            sx={{ mt: 1 }}
          />
        </Grid>
      </Grid>
    </FormGroup>
  )
}
