import { useMutation } from '@tanstack/react-query'

import { updateResourceCustomAttributes } from '@/features/resource/endpoints/resources'
import type { AttributeValueType, CustomAttributeSchema } from '@/features/resource/types'

export function useUpdateCustomAttributeMutation(resourceID: string, customAttributeSchema: CustomAttributeSchema) {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (data: Record<string, AttributeValueType>) => {
      return updateResourceCustomAttributes(resourceID, customAttributeSchema.namespace, data)
    },
  })
  return { updateCustomAttribute: mutateAsync, ...mutationResult }
}
