import type { SelectChangeEvent } from '@mui/material/Select'
import { useTranslation } from 'react-i18next'

import FlagIcon from '@/components/dataDisplay/FlagIcon'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import { FIELD_WIDTH } from '@/constants/layout'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'

type CountrySelectorProps = {
  disabled?: boolean
  onChange?: (country: CountryIdentifier) => void
}

// TODO: Should use Portfolios API instead to get LIVE portfolios
// NOTE: Portfolios API right now has some unsupported portfolios (e.g. NL and DK) as LIVE, so when selected they will cause an error
const ALLOWED_COUNTRIES: CountryIdentifier[] = ['se', 'fi', 'gr']

const CountrySelector = ({ disabled, onChange }: CountrySelectorProps) => {
  const { t } = useTranslation()
  const { selectedCountry, updateSelectedCountry } = useBiddingContext()

  return (
    <CustomSelectField
      disabled={disabled ?? false}
      id="country-selector"
      label={t('bidding.country')}
      options={ALLOWED_COUNTRIES.map((country) => ({
        value: country,
        id: `country-selector-item-${country}`,
        label: country ? t(`common.country.${country}`) : '-',
        icon: <FlagIcon countryCode={country} />,
      }))}
      sx={{ minWidth: FIELD_WIDTH }}
      value={selectedCountry}
      variant="outlinedWhite"
      onChange={(e: SelectChangeEvent) => {
        const countryIdentifier = e.target.value as CountryIdentifier

        updateSelectedCountry(countryIdentifier)
        onChange?.(countryIdentifier)
      }}
    />
  )
}

export default CountrySelector
