import type { DateTime } from 'luxon'

import type { ExtendedBid } from '@/features/bidding/types/bid'
import { calculateCumulativeVolumes } from '@/features/bidding/utils/calculations/calculateCumulativeVolumes'
import { calculateIncrementalVolumes } from '@/features/bidding/utils/calculations/calculateIncrementalVolumes'
import { round } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import { PriceLadderType } from '@/features/bidding/utils/getBidConfig'
import { getBidDisplayUnit } from '@/features/bidding/utils/getBidDisplayUnit'
import type { PriceQuantity } from '@/features/bidding/utils/price/getPtuPriceQuantitiesFromBids'
import { getPtuPriceQuantitiesFromExtendedBids } from '@/features/bidding/utils/price/getPtuPriceQuantitiesFromBids'

export type AggregatedBid = {
  ptus: AggregatedBidPtu[]
  ladderType: PriceLadderType
  unit: string
}

export type AggregatedBidPtu = {
  ptuStart: DateTime
  available: number
  remaining: number
  priceQuantities: PriceQuantity[]
}

export const buildAggregatedBid = (bids: ExtendedBid[], priceLadderType: PriceLadderType): AggregatedBid => {
  if (bids.length === 0) return { ptus: [], unit: '', ladderType: priceLadderType }

  const ptuStartTimes = bids[0].ptus.map((ptu) => ptu.ptu.start)

  const ptus = ptuStartTimes.map((ptuStart) => {
    const priceQuantities = getPtuPriceQuantitiesFromExtendedBids(ptuStart, bids)
    const volumes =
      priceLadderType == PriceLadderType.INCREMENTAL
        ? calculateIncrementalVolumes(bids, ptuStart)
        : calculateCumulativeVolumes(bids, ptuStart)

    return {
      id: ptuStart.toMillis(),
      remaining: round(volumes.available - volumes.used),
      ptuStart,
      available: volumes.available,
      priceQuantities,
    }
  })
  return {
    ptus,
    unit: getVolumeUnit(bids[0]),
    ladderType: priceLadderType,
  }
}

const getVolumeUnit = (bid: ExtendedBid): string => {
  if (!bid.ptus || bid.ptus.length === 0) {
    return ''
  }

  return getBidDisplayUnit(bid.ptus[0].offeredVolume.unit)
}
