import axios from 'axios'

import { CUSTOMERS_API_URL } from '@/features/site/endpoints/customers'
import type { AvailableResource } from '@/features/site/types/availableResource'

export async function getAvailableRelayResources(
  customerUuid: string,
  marketProgramId: number | undefined,
  location: string | undefined,
): Promise<AvailableResource[]> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  const response = await axios.get(`${CUSTOMER_API_URL}/availability/resources`, {
    params: {
      marketProgramId,
      location,
    },
  })
  return response.data
}

export async function getAvailableAnalogResources(
  customerUuid: string,
  marketProgramId: number | undefined,
  isUsed: boolean | undefined,
  location: string | undefined,
): Promise<AvailableResource[]> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  const response = await axios.get(`${CUSTOMER_API_URL}/availability/resources/analog`, {
    params: {
      marketProgramId,
      isUsed,
      location,
    },
  })
  return response.data
}
