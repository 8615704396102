import { Route, Routes } from 'react-router-dom'

import NavigateToError from '@/features/error/pages/NavigateToError'
import ImportTrade from '@/features/wholesale/pages/ImportTrade'

export default function WholesaleRoutes() {
  return (
    <Routes>
      <Route index element={<ImportTrade />} path="/" />

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}
