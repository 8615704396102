import { Box, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useResourceData } from '@/features/resource/contexts/ResourceDataContext'

const commonProps = {
  component: Link,
  disableRipple: true,
  iconPosition: 'start',
  style: { minHeight: 0 },
} as const

type ResourceTabsProps = {
  activeTab: string
}

export const ResourceTabs = ({ activeTab }: ResourceTabsProps) => {
  const { resourceUuid } = useParams()
  const { t } = useTranslation()
  const { loggedInUser } = useAuth()
  const { isEnabled } = useFeatureToggle()

  const { resource } = useResourceData()

  const batteryControlPort = resource?.controlPorts.find((port) => port.controlBoxType === 'BATTERY')
  return (
    <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
      <Tabs aria-label="resource-tabs" value={activeTab}>
        <Tab
          {...commonProps}
          label={t('resources.tabs.general')}
          to={ResourceRouteInformation.RESOURCE_GENERAL.navigationPath(resourceUuid!)}
          value={ResourceRouteInformation.RESOURCE_GENERAL.routePath}
        />
        <Tab
          {...commonProps}
          label={t('resources.tabs.steering')}
          to={ResourceRouteInformation.RESOURCE_STEERING.navigationPath(resourceUuid!)}
          value={ResourceRouteInformation.RESOURCE_STEERING.routePath}
        />
        {batteryControlPort ? (
          <Tab
            {...commonProps}
            label={t('resources.tabs.battery_steering')}
            to={ResourceRouteInformation.RESOURCE_BATTERY_STEERING.navigationPath(
              resourceUuid!,
              batteryControlPort.controlPortID,
            )}
            value={ResourceRouteInformation.RESOURCE_BATTERY_STEERING.routePath}
          />
        ) : (
          <Tab
            {...commonProps}
            label={t('resources.tabs.manual_steering')}
            to={ResourceRouteInformation.RESOURCE_MANUAL_STEERING.navigationPath(resourceUuid!)}
            value={ResourceRouteInformation.RESOURCE_MANUAL_STEERING.routePath}
          />
        )}
        {isEnabled('FLEXPORTAL_RESOURCE_CUSTOM_ATTRIBUTES_PAGE_ENABLED') && (
          <Tab
            {...commonProps}
            label={t('resources.tabs.custom_attributes')}
            to={ResourceRouteInformation.RESOURCE_CUSTOM_ATTRIBUTES.navigationPath(resourceUuid!)}
            value={ResourceRouteInformation.RESOURCE_CUSTOM_ATTRIBUTES.routePath}
          />
        )}
        {loggedInUser?.role == USER_ROLES.ADMINISTRATORS.value && (
          <Tab
            {...commonProps}
            label={t('resources.tabs.support_toolbox')}
            to={ResourceRouteInformation.RESOURCE_SUPPORT_TOOLBOX.navigationPath(resourceUuid!)}
            value={ResourceRouteInformation.RESOURCE_SUPPORT_TOOLBOX.routePath}
          />
        )}
      </Tabs>
    </Box>
  )
}
