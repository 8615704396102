import { Box, Stack } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import ButtonBar from '@/components/inputs/ButtonBar'
import PageHeader from '@/components/layouts/PageHeader'
import ActivationGroupPrequalificationPatternInfoCard from '@/features/activationGroup/components/ActivationGroupPrequalificationPatternInfoCard'
import GenericPrequalificationPatternChartCard from '@/features/activationGroup/components/GenericPrequalificationPatternChartCard'
import GenericPrequalificationsDataGrid from '@/features/activationGroup/components/GenericPrequalificationsDataGrid'
import { ActivationGroupRouteInformation } from '@/features/activationGroup/constants'
import { useActivationGroupQuery } from '@/features/activationGroup/hooks/useActivationGroupQuery'
import { useGenericActivationGroupPrequalificationPatternsQuery } from '@/features/activationGroup/hooks/useGenericActivationGroupPrequalificationPatternsQuery'
import ViewPrequalificationPatternButton from '@/features/activationGroup/pages/components/ViewPrequalificationPatternButton'

type GenericPrequalificationsDetailsProps = {
  activationGroupUuid: string
  patternUuid: string
}

const GenericPrequalificationPatternDetails = ({
  activationGroupUuid,
  patternUuid,
}: GenericPrequalificationsDetailsProps) => {
  const { t } = useTranslation()
  const { activationGroup } = useActivationGroupQuery({ activationGroupUuid })

  // Reuse the existing endpoint as there is no dedicated endpoint for fetching a single pattern in neither API
  const { activationGroupPrequalificationPatterns } = useGenericActivationGroupPrequalificationPatternsQuery({
    activationGroupUuid: activationGroupUuid ?? '',
    pagination: { pageSize: 100, page: 0 },
  })

  const pattern = activationGroupPrequalificationPatterns?.find((pattern) => pattern.uuid === patternUuid)
  if (!pattern || !activationGroup) {
    return null
  }

  const patternCsv = pattern.pattern ?? ''

  return (
    <>
      <PageHeader
        appBarContent={
          <Box alignItems="center" display="flex" flexDirection="row" sx={{ width: '100%' }}>
            <ButtonBar alignment="right" sx={{ flex: 1 }}>
              <ViewPrequalificationPatternButton pattern={patternCsv} />
            </ButtonBar>
          </Box>
        }
        breadcrumbItems={[
          {
            text: t('component.page_header.activation_groups'),
            to: ActivationGroupRouteInformation.INDEX.navigationPath(),
          },
          {
            text: t('component.page_header.prequalification_patterns'),
            to: ActivationGroupRouteInformation.ACTIVATION_GROUP_PREQUALIFICATION_PATTERNS.navigationPath(
              activationGroupUuid,
            ),
          },
        ]}
        pageTitle={t('component.page_header.prequalifications')}
      />

      <Stack direction="column" marginY={3} spacing={3}>
        <ActivationGroupPrequalificationPatternInfoCard info={{ uuid: pattern.uuid, name: pattern.name }} />
        <GenericPrequalificationPatternChartCard patternCsv={patternCsv} patternUuid={patternUuid} />
        <GenericPrequalificationsDataGrid activationGroup={activationGroup} patternUuid={patternUuid} />
      </Stack>
    </>
  )
}

const ActivationGroupPrequalificationPatternDetails = () => {
  const { activationGroupUuid = null, patternUuid } = useParams()
  const { activationGroup } = useActivationGroupQuery({ activationGroupUuid })

  if (!activationGroupUuid || !patternUuid || !activationGroup) {
    return null
  }

  return <GenericPrequalificationPatternDetails activationGroupUuid={activationGroupUuid} patternUuid={patternUuid} />
}

export default ActivationGroupPrequalificationPatternDetails
