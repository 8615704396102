import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { useResourceWithPollingQuery } from '@/features/resource/hooks/useResourceWithPollingQuery'
import type { Resource, ResourceCustomAttributeData, ResourceSteeringConfig } from '@/features/resource/types'

interface ResourceContextType {
  resource?: Resource
  steeringConfig?: ResourceSteeringConfig
  customAttributeData?: ResourceCustomAttributeData
  stopPolling: () => void
  restartPolling: () => void
}

const ResourceDataContext = createContext<ResourceContextType | null>(null)

export function ResourceDataProvider({ children }: Readonly<{ children: ReactNode }>) {
  const { resourceUuid } = useParams()
  const { resource, steeringConfig, customAttributeData, stopPolling, restartPolling } = useResourceWithPollingQuery({
    id: resourceUuid!,
  })

  const value = {
    resource,
    steeringConfig,
    customAttributeData,
    stopPolling,
    restartPolling,
  }

  return <ResourceDataContext.Provider value={value}>{children}</ResourceDataContext.Provider>
}

export function useResourceData() {
  const context = useContext(ResourceDataContext)
  if (!context) {
    throw new Error('useResourceData must be used within ResourceDataProvider')
  }
  return context
}
