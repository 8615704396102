import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import type { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import type { DateTime as DateTimeType } from 'luxon'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TOMORROW } from '@/constants/dateTime'
import { PricingTableBiddingLadderGridCell } from '@/features/bessDashboard/components/PricingTablebiddingLadderGridCell'
import { BiddingTable } from '@/features/bessDashboard/components/PricingTableGrid'
import { useImpactQuery } from '@/features/bessDashboard/hooks/usePriceForecastQuery'
import { isCapacityValid } from '@/features/bessDashboard/utils/pricingTable'
import { formatTimestampToUtcIso } from '@/features/bessDashboard/utils/utils'

type ImpactForecastTableProps = {
  marketProgram: string
}

const columns: GridColDef[] = [
  {
    field: 'ptu',
    headerName: 'PTU',
    width: 10,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'period',
    headerName: 'Period',
    width: 90,
    type: 'string',
    align: 'left',
    headerAlign: 'left',
    valueFormatter: (value: string) => {
      const date = new Date(value)
      return new Intl.DateTimeFormat('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'UTC',
      }).format(date)
    },
  },

  {
    field: 'allocated_mw',
    headerName: 'Allocated MW',
    editable: true,
    width: 180,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    valueFormatter: (value: number) => `${value ?? '-'} MW`,

    valueParser: (value) => (isCapacityValid(value) ? value : undefined),
  },
  {
    field: 'lower_bound',
    headerName: 'Lower',
    width: 80,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    valueFormatter: (value: number) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
      }).format(value)
    },
  },
  {
    field: 'average_price',
    headerName: 'Average',
    width: 80,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    valueFormatter: (value: number) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
      }).format(value)
    },
  },
  {
    field: 'upper_bound',
    headerName: 'Upper',
    width: 80,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
    valueFormatter: (value: number) => {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
      }).format(value)
    },
  },
  {
    field: 'biddingLadder',
    headerName: 'Bidding Ladder',
    flex: 1,
    width: 400,
    renderCell: (params) => <PricingTableBiddingLadderGridCell biddingLadder={params.value} />,
  },
]

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'Procured Price Estimate EUR/MW',
    children: [{ field: 'upper_bound' }, { field: 'average_price' }, { field: 'lower_bound' }],
  },
]

export default function PricingTable({ marketProgram }: Readonly<ImpactForecastTableProps>) {
  const [bidDate, setBidDate] = useState<DateTimeType>(TOMORROW)
  const formattedDates = useMemo(() => {
    const startDate = formatTimestampToUtcIso(bidDate)
    const endDate = formatTimestampToUtcIso(bidDate.plus({ hours: 23 }))
    return { startDate, endDate }
  }, [bidDate])

  const { data: priceImpactData, isLoading } = useImpactQuery(formattedDates.startDate, formattedDates.endDate)

  const { i18n } = useTranslation()

  function Wrapper({ children }) {
    return (
      <Paper sx={{ p: 2, mb: 3 }} variant="outlined">
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
          <DatePicker label="Bid Date" value={bidDate} onChange={(newValue) => setBidDate(newValue ?? TOMORROW)} />
        </LocalizationProvider>
        {children}
      </Paper>
    )
  }

  if (isLoading)
    return (
      <Wrapper>
        <Grid container alignItems="center" direction="row" justifyContent="center" sx={{ height: '400px' }}>
          <CircularProgress />
        </Grid>
      </Wrapper>
    )
  if (priceImpactData.length === 0)
    return (
      <Wrapper>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          height="240px"
          justifyContent="center"
          width="100%"
        >
          <Typography variant="h2">No data for Date: </Typography>
          <Box>{bidDate.toISODate()}</Box>
        </Box>
      </Wrapper>
    )

  return (
    <Wrapper>
      <BiddingTable
        GridColDef={columns}
        columnGroupingModel={columnGroupingModel}
        forecasts={priceImpactData}
        marketProgram={marketProgram}
      />
    </Wrapper>
  )
}
