import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { GET_INSIGHT_REVENUES_API_ID, getInsightRevenuesByMarketPrograms } from '@/features/site/endpoints/revenues'
import type {
  InsightRevenuesByMarketProgramsParams,
  RevenueDataRow,
  SiteRevenuePerMarketProgram,
} from '@/features/site/types/revenue'
import type { UseQueryOptions } from '@/types/queries'

export type UseInsightRevenuesQueryParamsResult = Omit<UseQueryResult, 'data'> & {
  revenues: SiteRevenuePerMarketProgram | null
}

export function useInsightRevenuesByMarketProgramsQuery(
  params: InsightRevenuesByMarketProgramsParams,
  options?: UseQueryOptions<Map<string, RevenueDataRow[] | null>>,
): UseInsightRevenuesQueryParamsResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_INSIGHT_REVENUES_API_ID, params],
    queryFn: () => getInsightRevenuesByMarketPrograms(params),
  })

  return { revenues: data, ...queryResult }
}
