import axios from 'axios'

import environment from '@/environment'
import type { MfaSettings, User } from '@/features/user/types/user'
import type { UserPersonalInfoForm } from '@/features/user/types/userPersonalInfo'

export const ERROR_NAMES = {
  EMAIL_EXISTS: 'email-exists',
  USERNAME_EXISTS: 'username-exists',
  MFA_UPDATE_FAILED: 'mfa-update-failed',
  VALIDATION_FAILED: 'validation-failed',
}

export const GET_USERS_API_ID = 'GET_USERS'
export const GET_USER_API_ID = 'GET_USER'

export async function getUsers(partnerCode: string | undefined | null = null): Promise<User[]> {
  const response = await axios.get(environment.services.usersManagerApiUrl, {
    params: { partnerCode },
  })
  return response.data
}

export async function getUser(userId: string): Promise<User> {
  const response = await axios.get(`${environment.services.usersManagerApiUrl}/${userId}`)
  return response.data
}

export async function saveUser(user: User) {
  return await axios.post<User, string>(environment.services.usersManagerApiUrl, user)
}

export async function deleteUser(userId: string) {
  await axios.delete(`${environment.services.usersManagerApiUrl}/${userId}`)
}

export async function updateMfa(userId: string, mfaSettings: MfaSettings) {
  await axios.put(`${environment.services.usersManagerApiUrl}/${userId}/mfa`, mfaSettings)
}

export async function resetPassword(userId: string) {
  await axios.post<User>(`${environment.services.usersManagerApiUrl}/${userId}/reset-password`)
}

// We don't need the mfaSettings information to update the personal info. The mfaSettings update is a different API.
export async function updatePersonalInfo(userId: string, personalInfo: Omit<UserPersonalInfoForm, 'mfaSettings'>) {
  await axios.put<UserPersonalInfoForm>(
    `${environment.services.usersManagerApiUrl}/${userId}/personal-info`,
    personalInfo,
  )
}
