import { useMutation } from '@tanstack/react-query'

import { updateSiteFinancialInformation } from '@/features/site/endpoints/sites'
import type { FinancialInformation } from '@/features/site/types/site'

export type UpdateSiteFinancialInfoMutationResult = ReturnType<typeof useUpdateSiteFinancialInfoMutation>

type UpdateSiteFinancialInfoMutationVariables = {
  siteUuid: string
  location: string
  financialInformation: FinancialInformation
}

export function useUpdateSiteFinancialInfoMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: ({ siteUuid, location, financialInformation }: UpdateSiteFinancialInfoMutationVariables) =>
      updateSiteFinancialInformation(siteUuid, location, financialInformation),
  })

  return {
    updateSiteFinancialInfo: mutateAsync,
    ...mutationResult,
  }
}
