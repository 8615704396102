import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import Stack from '@mui/material/Stack'
import { useTranslation } from 'react-i18next'

import CustomIconButton from '@/components/inputs/CustomIconButton'

type CancelConfirmIconButtonsProps = {
  disabled?: boolean
}

function CancelConfirmIconButtons({ disabled }: Readonly<CancelConfirmIconButtonsProps>) {
  const { t } = useTranslation()

  return (
    <Stack direction="row" gap={0.5}>
      <CustomIconButton
        Icon={ClearIcon}
        color="default"
        disabled={disabled}
        iconProps={{
          titleAccess: t('common.button.cancel'),
        }}
        size="small"
        type="reset"
        variant="solid"
      />

      <CustomIconButton
        Icon={CheckIcon}
        color="success"
        disabled={disabled}
        iconProps={{
          titleAccess: t('common.button.save'),
        }}
        size="small"
        type="submit"
        variant="solid"
      />
    </Stack>
  )
}

export default CancelConfirmIconButtons
