import { useMutation } from '@tanstack/react-query'

import { editServiceRule } from '@/features/site/endpoints/serviceRules'
import type { EditServiceRuleRequest } from '@/features/unavailability/types/serviceRule'

export type UseEditServiceRuleMutationResult = ReturnType<typeof useEditServiceRuleMutation>

export function useEditServiceRuleMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (variables: { customerUuid: string; serviceRule: EditServiceRuleRequest }) =>
      editServiceRule(variables.customerUuid, variables.serviceRule),
  })

  return {
    editServiceRule: mutateAsync,
    ...mutationResult,
  }
}
