import { MonetizationOnOutlined, StorefrontOutlined } from '@mui/icons-material'
import BlockIcon from '@mui/icons-material/Block'
import BoltOutlinedIcon from '@mui/icons-material/Bolt'
import CampaignOutlinedIcon from '@mui/icons-material/Campaign'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import DateRangeIcon from '@mui/icons-material/DateRange'
import SettingsIcon from '@mui/icons-material/Settings'
import { Box, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { usePermissions } from '@/features/authorization/contexts/PermissionsContext'
import { hasRevenuePermissions } from '@/features/authorization/permissionHandlers/revenuePermissions'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import {
  RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_COUNTRIES_TOGGLE_NAME,
  RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_CUSTOMERS_TOGGLE_NAME,
} from '@/features/site/constants'
import { SiteDetailsRouteInformation } from '@/features/site/pages/constants'
import type { SiteDetailsPageConfig } from '@/features/site/pages/SiteDetails'
import type { Site } from '@/features/site/types/site'

const commonProps = {
  component: Link,
  disableRipple: true,
  iconPosition: 'start',
  style: { minHeight: 0 },
} as const

type SiteTabsProps = {
  site: Site
  pageConfig: SiteDetailsPageConfig
}

export const SiteTabs = ({ site, pageConfig }: SiteTabsProps) => {
  const { t } = useTranslation()
  const { loggedInUser } = useAuth()

  const { permissions } = usePermissions()
  const isMarketProgramsViewPermitted = permissions.has('siteMarketPrograms')

  const { isEnabled, useFeatureWithContext } = useFeatureToggle()

  const { isFeatureEnabled: isNewRestrictionsEnabledForCountry } = useFeatureWithContext(
    RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_COUNTRIES_TOGGLE_NAME,
    { countryCode: site.countryCode?.toLowerCase() ?? '' },
  )

  const { isFeatureEnabled: isNewRestrictionsEnabledForCustomer } = useFeatureWithContext(
    RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_CUSTOMERS_TOGGLE_NAME,
    { userId: site.uuid ?? '' },
  )

  const isCustomerOnNewPlatform = isNewRestrictionsEnabledForCountry || isNewRestrictionsEnabledForCustomer

  return (
    <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
      <Tabs aria-label="customer-tabs" value={pageConfig.activeTab}>
        <Tab
          {...commonProps}
          icon={<ContactMailIcon />}
          label={t('customer_details.tabs.company_info.title')}
          to={`/sites/${site.uuid}/contact-info`}
          value={SiteDetailsRouteInformation.SITE_INFO.navigationPath}
        />
        {isCustomerOnNewPlatform ? (
          <Tab
            {...commonProps}
            icon={<BlockIcon />}
            label={t('customer_details.tabs.res_level_unavailability.title')}
            to={`/sites/${site.uuid}/res-level-unavailability`}
            value={SiteDetailsRouteInformation.RES_LEVEL_UNAVAILABILITY.navigationPath}
          />
        ) : (
          <Tab
            {...commonProps}
            icon={<DateRangeIcon />}
            label={t('customer_details.tabs.unavailability.title')}
            to={`/sites/${site.uuid}/unavailability`}
            value={SiteDetailsRouteInformation.UNAVAILABILITY.navigationPath}
          />
        )}
        <Tab
          {...commonProps}
          icon={<CampaignOutlinedIcon />}
          label={t('customer_details.tabs.activations')}
          to={`/sites/${site.uuid}/activations`}
          value={SiteDetailsRouteInformation.ACTIVATIONS.navigationPath}
        />
        {['FI', 'SE', 'NO'].includes(site.countryCode ?? '') && (
          <Tab
            {...commonProps}
            icon={<BoltOutlinedIcon />}
            label={t('customer_details.tabs.power_measurements.title')}
            to={`/sites/${site.uuid}/power-measurements`}
            value={SiteDetailsRouteInformation.POWER_MEASUREMENTS.navigationPath}
          />
        )}
        {isEnabled('FLEX_PORTAL_SITE_MARKET_PROGRAM_CONTRACTS') && isMarketProgramsViewPermitted && (
          <Tab
            {...commonProps}
            icon={<StorefrontOutlined />}
            label={t('customer_details.tabs.market_programs.title')}
            to={`/sites/${site.uuid}/market-programs`}
            value={SiteDetailsRouteInformation.MARKET_PROGRAMS.navigationPath}
          />
        )}
        {hasRevenuePermissions({ loggedInUser: loggedInUser!, site }) && (
          <Tab
            {...commonProps}
            icon={<MonetizationOnOutlined />}
            label={t('customer_details.tabs.revenue.title')}
            to={`/sites/${site.uuid}/revenue`}
            value={SiteDetailsRouteInformation.REVENUE.navigationPath}
          />
        )}
        <Tab
          {...commonProps}
          icon={<SettingsIcon />}
          label={t('customer_details.tabs.settings.title')}
          to={`/sites/${site.uuid}/settings`}
          value={SiteDetailsRouteInformation.SETTINGS.navigationPath}
        />
      </Tabs>
    </Box>
  )
}
