import { Autocomplete, TextField } from '@mui/material'
import type { GridFilterItem } from '@mui/x-data-grid'
import { useGridApiContext } from '@mui/x-data-grid'

import { AUTOCOMPLETE_FIELD_WIDTH } from '@/constants/layout'
import { OPERATOR_VALUE_HAS_ANY_OF, updateDataGridFilter } from '@/utils/datagrid/filters'

type Props<V> = {
  id: string
  testId: string
  filterName: string
  label: string
  value: V[]
  options: readonly V[]
  getOptionLabel: (option: V) => string
}

const MultiValueSelector = <V,>({ id, testId, filterName, label, options, value, getOptionLabel }: Props<V>) => {
  const apiRef = useGridApiContext()

  const handleMultiSelectFilterChange = (filterName: string, value: V[]) => {
    const item: GridFilterItem = {
      id: filterName,
      field: filterName,
      operator: OPERATOR_VALUE_HAS_ANY_OF,
      value,
    }
    updateDataGridFilter(apiRef, filterName, item)
  }

  return (
    <Autocomplete
      filterSelectedOptions
      multiple
      data-testid={testId}
      getOptionLabel={getOptionLabel}
      id={id}
      limitTags={2}
      options={options}
      renderInput={(params) => <TextField {...params} label={label} />}
      sx={{ maxWidth: AUTOCOMPLETE_FIELD_WIDTH, minWidth: AUTOCOMPLETE_FIELD_WIDTH }}
      value={value}
      onChange={(_, value) => handleMultiSelectFilterChange(filterName, value)}
    />
  )
}

export default MultiValueSelector
