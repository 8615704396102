import './biddingTodoListDataGrid.css'

import { ChevronRight } from '@mui/icons-material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Stack } from '@mui/material'
import type { GridRowParams } from '@mui/x-data-grid'
import type { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef'
import type { GridRenderCellParams } from '@mui/x-data-grid-pro'
import type { TFunction } from 'i18next'
import type { FC, MouseEvent } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CustomChip from '@/components/dataDisplay/CustomChip'
import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { COLUMN_WITH_MEDIUM_ICON_WIDTH } from '@/constants/layout'
import type { ActivationGroup } from '@/features/activationGroup/types'
import BidStatusChip from '@/features/bidding/components/BidStatusChip'
import BiddingTodoListFilterToolbar from '@/features/bidding/components/todoList/BiddingTodoListFilterToolbar'
import { BidType, Status } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { usePortfoliosQuery } from '@/features/bidding/hooks/usePortfoliosQuery'
import type { MarketProgramBiddingName, Portfolio } from '@/features/bidding/types/bid'
import { MarketDate } from '@/features/bidding/utils/date/marketDate'
import { MARKET_PROGRAMS_WITH_ENERGY_BIDDING_ENABLED } from '@/features/bidding/utils/getBidConfig'
import { buildBidCapacityLink } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import type { BiddingTodoItem } from '@/features/bidding/utils/model/composeBiddingTodoList'

type Props = {
  todoItems: BiddingTodoItem[]
  activationGroups: ActivationGroup[]
}

export const BiddingTodoListDataGrid: FC<Props> = ({ todoItems, activationGroups }) => {
  const { t } = useTranslation()
  const { selectedCountry } = useBiddingContext()
  const { portfolios } = usePortfoliosQuery({ countryCode: selectedCountry })

  const displayEnergyBidColumns = useMemo(
    () => todoItems.some((todoItem) => MARKET_PROGRAMS_WITH_ENERGY_BIDDING_ENABLED.includes(todoItem.marketProgram)),
    [todoItems.length],
  )

  const allowRowsToBeClickable = !displayEnergyBidColumns
    ? {
        clickableRows: {
          navigateTo: (params: GridRowParams<BiddingTodoItem>) => getBidCreateLink(params.row),
        },
      }
    : {}

  return (
    <CustomDataGrid
      disableDefaultHeaderBackground
      {...allowRowsToBeClickable}
      columns={getColumns(t, portfolios ?? [], displayEnergyBidColumns)}
      getRowId={getRowId}
      initialState={{
        sorting: {
          sortModel: [
            { field: 'deliveryDay', sort: 'asc' },
            { field: 'portfolio', sort: 'asc' },
          ],
        },
        pagination: {
          paginationModel: { pageSize: 20, page: 0 },
        },
        filter: {
          filterModel: {
            items: [
              {
                id: 'deliveryDay',
                field: 'deliveryDay',
                operator: 'equals',
                value: new MarketDate().plus({ days: 2 }).toISODate(),
              },
            ],
          },
        },
      }}
      isLoading={!portfolios}
      rows={todoItems}
      slotProps={{ toolbar: { portfolios, activationGroups } }}
      slots={{ toolbar: BiddingTodoListFilterToolbar }}
    />
  )
}

const getColumns = (
  t: TFunction,
  portfolios: Portfolio[],
  energyBiddingEnabled: boolean,
): GridColDef<BiddingTodoItem>[] => [
  {
    field: 'portfolio',
    headerName: t('bidding.overview.header.portfolio'),
    valueGetter: (_, biddingTodoItem: BiddingTodoItem) => biddingTodoItem.portfolio.code,
    valueFormatter: (value: string) => portfolios.find((p) => p.code === value)?.name ?? '-',
    headerClassName: 'default-header',
    flex: 1,
  },
  {
    field: 'marketProgram',
    headerName: t('bidding.overview.header.market_program'),
    valueGetter: (_, biddingTodoItem: BiddingTodoItem) => biddingTodoItem.marketProgram,
    valueFormatter: (value: MarketProgramBiddingName) => t(`bidding.market_program.${value}`),
    headerClassName: 'default-header',
    flex: 1,
  },
  {
    field: 'activationGroupCodes',
    type: 'singleSelect',
    headerName: t('bidding.overview.header.activation_groups'),
    renderCell: (params: GridRenderCellParams<BiddingTodoItem>) => (
      <CustomChip
        color="primary"
        label={params.row.activationGroups.length}
        size="small"
        title={params.row.activationGroups
          .map((activationGroup) => activationGroup.code)
          .toSorted((a, b) => a.localeCompare(b))
          .join(', ')}
        variant="filled"
      />
    ),
    headerClassName: 'default-header',
    flex: 1,
  },
  {
    field: 'deliveryDay',
    headerName: t('bidding.overview.header.delivery_day'),
    valueGetter: (value: BiddingTodoItem['deliveryDay']) => value.toISODate(),
    valueFormatter: (value: string) => new MarketDate(value).getStartOfDay().toFormat('dd LLL, yyyy'),
    headerClassName: 'default-header',
    flex: 1,
  },
  {
    field: 'capacityBid',
    headerName: t('bidding.overview.header.capacity'),
    renderCell: (params) => (
      <Stack sx={{ flexDirection: 'row', width: 'fit-content', alignItems: 'center', height: '100%' }}>
        <BidStatusChip status={params.row.capacityBidStatus} />
      </Stack>
    ),
    headerClassName: 'capacity-bid-column',
    cellClassName: 'capacity-bid-column',
    flex: 1,
  },
  {
    field: 'capacityBidActions',
    headerName: '',
    width: COLUMN_WITH_MEDIUM_ICON_WIDTH,
    sortable: false,
    renderCell: (params: GridRenderCellParams<BiddingTodoItem>) => (
      <GridActionsColumn bidType={BidType.CAPACITY} params={params} />
    ),
    headerClassName: 'capacity-bid-actions-column',
    cellClassName: 'capacity-bid-actions-column',
  },
  ...(energyBiddingEnabled ? getEnergyBidColumns(t) : []),
]

const getEnergyBidColumns = (t: TFunction) => [
  {
    field: 'energyBid',
    headerName: t('bidding.overview.header.energy'),
    renderCell: (params) => (
      <Stack sx={{ flexDirection: 'row', width: 'fit-content', alignItems: 'center', height: '100%' }}>
        <BidStatusChip status={params.row.energyBidStatus} />
      </Stack>
    ),
    headerClassName: 'energy-bid-column',
    cellClassName: 'energy-bid-column',
    flex: 1,
  },
  {
    field: 'energyBidActions',
    headerName: '',
    width: COLUMN_WITH_MEDIUM_ICON_WIDTH,
    sortable: false,
    renderCell: (params: GridRenderCellParams<BiddingTodoItem>) => (
      <GridActionsColumn bidType={BidType.ENERGY} params={params} />
    ),
    headerClassName: 'energy-bid-column',
    cellClassName: 'energy-bid-column',
  },
]

const getRowId = (todoItem: BiddingTodoItem) =>
  `${todoItem.portfolio.code}-${todoItem.marketProgram}-${todoItem.deliveryDay.toISODate()}`

const getBidCreateLink = (todoItem: BiddingTodoItem) =>
  buildBidCapacityLink({
    portfolioCode: todoItem.portfolio.code,
    deliveryDay: todoItem.deliveryDay,
    marketProgram: todoItem.marketProgram,
    bidType: BidType.CAPACITY,
  })

const GridActionsColumn = ({
  params,
  bidType,
}: {
  params: GridRenderCellParams<BiddingTodoItem>
  bidType: BidType
}) => {
  const navigate = useNavigate()
  const bidStatus = bidType === BidType.CAPACITY ? params.row.capacityBidStatus : params.row.energyBidStatus
  const icon = bidStatus === Status.DRAFT ? EditOutlinedIcon : ChevronRight

  return (
    <Stack flexDirection="row" justifyContent="space-between" width="100%">
      {[Status.TODO, Status.DRAFT].includes(bidStatus) && (
        <Box>
          <CustomIconButton
            Icon={icon}
            aria-label="create-bid"
            data-testid="todo-create-button"
            onClick={(evt: MouseEvent<HTMLButtonElement>) => {
              evt.preventDefault()
              navigate(
                buildBidCapacityLink({
                  deliveryDay: params.row.deliveryDay,
                  portfolioCode: params.row.portfolio.code,
                  marketProgram: params.row.marketProgram,
                  bidType: bidType,
                }),
              )
            }}
          />
        </Box>
      )}
    </Stack>
  )
}
