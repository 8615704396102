import axios from 'axios'

import environment from '@/environment'
import type { MarketProgramContract } from '@/features/site/types/marketProgramContract'

export const CUSTOMERS_API_URL = `${environment.services.monolithGatewayManagerApiUrl}/customers`

export const GET_CUSTOMER_MARKET_PROGRAMS_API_ID = 'GET_CUSTOMER_MARKET_PROGRAMS'

export async function getCustomerMarketPrograms(customerUuid: string, location?: string) {
  const response = await axios.get<MarketProgramContract[]>(`${CUSTOMERS_API_URL}/${customerUuid}/services`, {
    params: {
      location,
    },
  })

  return response.data
}
