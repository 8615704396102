import BasicInformationStep from '@/features/site/components/create/BasicInformationStep'
import ContactsStep from '@/features/site/components/create/ContactsStep'
import FinancialInformationStep from '@/features/site/components/create/FinancialInformationStep'
import MarketProgramsStep from '@/features/site/components/create/MarketProgramsStep'
import NotificationsStep from '@/features/site/components/create/NotificationsStep'
import ReviewStep from '@/features/site/components/create/ReviewStep'
import type { SiteCreateStep } from '@/features/site/types/siteCreateStep'

export const BASIC_INFORMATION: SiteCreateStep = {
  id: 'basic_information',
  content: <BasicInformationStep />,
  optional: false,
  determineStatus: async (form) => {
    return (await form.trigger(['name', 'symbolicName', 'location'])) ? 'completed' : 'failed'
  },
}

export const MARKET_PROGRAMS: SiteCreateStep = {
  id: 'market_programs',
  content: <MarketProgramsStep />,
  optional: false,
  determineStatus: async (form) => {
    return (await form.trigger(['marketProgramContracts'])) ? 'completed' : 'failed'
  },
}

export const CONTACT_DETAILS: SiteCreateStep = {
  id: 'contact_details',
  content: <ContactsStep />,
  optional: true,
  determineStatus: async (form) => {
    const isValid = await form.trigger(['contacts'])
    if (!isValid) {
      return 'failed'
    }
    if ((form.getValues('contacts')?.length ?? 0) > 0) return 'completed'
    return 'initial'
  },
}

export const NOTIFICATIONS: SiteCreateStep = {
  id: 'notifications',
  content: <NotificationsStep />,
  optional: true,
  determineStatus: async (form) => {
    const isValid = await form.trigger(['notificationPreferences'])
    if (!isValid) {
      return 'failed'
    }
    if (
      (form.getValues('notificationPreferences')?.email?.trim().length ?? 0) > 0 ||
      (form.getValues('notificationPreferences')?.phoneNumbers?.[0].trim().length ?? 0) > 0
    ) {
      return 'completed'
    }
    return 'initial'
  },
}

export const FINANCIAL_INFORMATION: SiteCreateStep = {
  id: 'financial_information',
  content: <FinancialInformationStep />,
  optional: true,
  determineStatus: async (form) => {
    const isValid = await form.trigger(['financialInformation'])
    if (!isValid) {
      return 'failed'
    }
    if (form.getValues('financialInformation')?.iban) return 'completed'
    return 'initial'
  },
}

export const REVIEW: SiteCreateStep = {
  id: 'review',
  content: <ReviewStep />,
  optional: false,
  determineStatus: async () => {
    return 'initial'
  },
}
