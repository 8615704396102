import { useIsMutating, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useAlertContext } from '@/contexts/AlertContext'
import { saveExtendedBids } from '@/features/bidding/endpoints/bids'
import type { ExtendedBid } from '@/features/bidding/types/bid'

const SAVE_BIDS_API_ID = 'SAVE_BIDS'

export function useSaveBidsMutation() {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()

  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (updatedBids: ExtendedBid[]) => saveExtendedBids(updatedBids),
    mutationKey: [SAVE_BIDS_API_ID],
    onError: () => {
      pushAlert({
        message: t('bidding.save_bid.saving_failed.text'),
        severity: 'error',
        title: t('bidding.save_bid.saving_failed.title'),
      })
    },
  })

  return {
    saveBids: mutateAsync,
    ...mutationResult,
  }
}

export const useIsSavingBids = (): boolean => useIsMutating({ mutationKey: [SAVE_BIDS_API_ID] }) > 0
