import type { SelectChangeEvent } from '@mui/material/Select'
import { useMemo, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PageHeader from '@/components/layouts/PageHeader'
import { DEFAULT_DATA_GRID_FILTER_MODEL, DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { useDataGridSyncUrlManager } from '@/contexts/DataGridSyncUrlManagerContext'
import ResourceGrid from '@/features/resource/components/ResourceGrid'
import ResourcesAppBarContent from '@/features/resource/components/ResourcesAppBarContent'
import { RESOURCE_TYPES } from '@/features/resource/constants'
import type {
  ResourceGlobalFilters,
  ResourceGridActions,
  ResourceGridState,
  ResourceType,
} from '@/features/resource/types'
import {
  deserializeGlobalFiltersFromSearchParams,
  serializeGlobalFiltersToSearchParamsObject,
} from '@/features/resource/utils/globalFilters'
import { useCustomersQuery } from '@/features/site/hooks/useCustomersQuery'
import { getCountryOptions } from '@/utils/countryOptionsFromCustomers'
import { deserializeFilterModelFromSearchParams } from '@/utils/datagrid/filters'
import { deserializePaginationModelFromSearchParams } from '@/utils/datagrid/pagination'
import { deserializeSortModelFromSearchParams } from '@/utils/datagrid/sorting'
import { sortOptionsAlphabetically } from '@/utils/sortOptionsAlphabetically'

const gridReducer = (state: ResourceGridState, action: ResourceGridActions): ResourceGridState => {
  switch (action.type) {
    // Reset pagination page to 0 when filters change
    case 'setFilter':
      return {
        ...state,
        filters: action.payload,
      }
    case 'setSort':
      return {
        ...state,
        sort: action.payload,
      }
    case 'setPagination':
      return {
        ...state,
        pagination: action.payload,
      }
    case 'resetPagination':
      return {
        ...state,
        pagination: DEFAULT_DATA_GRID_PAGINATION_MODEL,
      }
    default:
      return state
  }
}

const ResourcesIndex = () => {
  const { t } = useTranslation()
  const [globalFilters, setGlobalFilters] = useState<ResourceGlobalFilters>(deserializeGlobalFiltersFromSearchParams())
  const [gridState, gridDispatch] = useReducer(gridReducer, {
    filters: deserializeFilterModelFromSearchParams() ?? DEFAULT_DATA_GRID_FILTER_MODEL,
    sort: deserializeSortModelFromSearchParams() ?? [],
    pagination: deserializePaginationModelFromSearchParams() ?? DEFAULT_DATA_GRID_PAGINATION_MODEL,
  })

  const syncUrlManager = useDataGridSyncUrlManager()
  const { customers, isLoading } = useCustomersQuery()

  const activeCustomers = useMemo(() => customers?.filter((customer) => customer.isActive) ?? [], [customers])

  const filteredCustomers = useMemo(() => {
    if (!globalFilters.countryCode) return activeCustomers
    return activeCustomers.filter((customer) => customer.countryCode?.toLowerCase() === globalFilters.countryCode)
  }, [activeCustomers, globalFilters.countryCode])

  const countryOptions = useMemo(
    () => sortOptionsAlphabetically<string>(getCountryOptions(activeCustomers, t)),
    [activeCustomers, t],
  )

  function handleCustomerChange(evt: SelectChangeEvent) {
    const selectedCustomer = evt.target.value

    setGlobalFilters((prevState) => ({ ...prevState, customerId: selectedCustomer }))
    gridDispatch({ type: 'resetPagination' })

    syncUrlManager.syncGlobalFilterSearchParams(
      serializeGlobalFiltersToSearchParamsObject({ ...globalFilters, customerId: selectedCustomer }),
    )
  }

  function handleResourceTypeChange(evt: SelectChangeEvent) {
    const selectedResourceType = evt.target.value as ResourceType

    setGlobalFilters((prevState) => ({ ...prevState, resourceType: selectedResourceType }))
    gridDispatch({ type: 'resetPagination' })

    syncUrlManager.syncGlobalFilterSearchParams(
      serializeGlobalFiltersToSearchParamsObject({ ...globalFilters, resourceType: selectedResourceType }),
    )
  }

  function handleCountryCodeChange(evt: SelectChangeEvent) {
    const selectedCountryCode = evt.target.value
    setGlobalFilters((prevState) => ({ ...prevState, countryCode: selectedCountryCode, customerId: undefined }))

    gridDispatch({ type: 'resetPagination' })

    syncUrlManager.syncGlobalFilterSearchParams(
      serializeGlobalFiltersToSearchParamsObject({
        ...globalFilters,
        countryCode: selectedCountryCode,
        customerId: '',
      }),
    )
  }

  const selectedCustomerName = useMemo(() => {
    if (!globalFilters.customerId || !customers) {
      return t('resources.all_customers')
    }
    const customer = customers.find((customer) => customer.uuid === globalFilters.customerId)

    return customer?.name ?? ''
  }, [globalFilters.customerId])

  return (
    <>
      <PageHeader
        isRoot
        appBarContent={
          !isLoading && (
            <ResourcesAppBarContent
              countryCodeOptions={countryOptions}
              customers={filteredCustomers}
              globalFilters={globalFilters}
              resourceTypes={RESOURCE_TYPES}
              onCountryCodeChange={handleCountryCodeChange}
              onCustomerChange={handleCustomerChange}
              onResourceTypeChange={handleResourceTypeChange}
            />
          )
        }
        pageTitle={t('component.page_header.resources')}
      />

      <ResourceGrid
        globalFilters={globalFilters}
        gridDispatch={gridDispatch}
        gridState={gridState}
        title={selectedCustomerName}
      />
    </>
  )
}

export default ResourcesIndex
