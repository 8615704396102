import { Divider } from '@mui/material'
import { useEffect, useState } from 'react'

import ResourceCard from '@/features/resource/components/ResourceCard'
import BatteryUsableEnergyPanel from '@/features/resource/components/steering/BatteryUsableEnergyPanel'
import SteeringCapabilitiesPanel from '@/features/resource/components/steering/SteeringCapabilitiesPanel'
import SteeringDefaultsPanel from '@/features/resource/components/steering/SteeringDefaultsPanel'
import SteeringMultistepPanel from '@/features/resource/components/steering/SteeringMultistepPanel'
import SteeringRangesPanel from '@/features/resource/components/steering/SteeringRangesPanel'
import { useResourceData } from '@/features/resource/contexts/ResourceDataContext'
import type {
  ControlPort,
  ResourceSteeringConfig,
  ResourceSteeringDefaultsSchemaType,
  ResourceSteeringMultistepSchemaType,
  SteeringCapabilitiesSchemaType,
} from '@/features/resource/types'

const ResourceSteeringCard = () => {
  const { steeringConfig, resource } = useResourceData()
  const [localSteeringConfig, setLocalSteeringConfig] = useState<ResourceSteeringConfig | undefined>(steeringConfig)
  const [localBatteryControlPort, setLocalBatteryControlPort] = useState<ControlPort | undefined>(
    resource?.controlPorts.find((port) => port.controlBoxType === 'BATTERY'),
  )

  useEffect(() => {
    setLocalSteeringConfig(steeringConfig)
  }, [steeringConfig])

  useEffect(() => {
    setLocalBatteryControlPort(resource?.controlPorts.find((port) => port.controlBoxType === 'BATTERY'))
  }, [resource])

  if (!resource || !steeringConfig || !localSteeringConfig) {
    return null
  }

  const updateSteeringDefaults = (data: ResourceSteeringDefaultsSchemaType) => {
    setLocalSteeringConfig((prevConfig) => {
      if (!prevConfig) return prevConfig
      return {
        ...prevConfig,
        steeringDefaultType: data.steeringDefaultType,
        defaultConsumptionLevel: data.defaultConsumptionLevel,
      }
    })
  }

  const updateSteeringCapabilities = (data: SteeringCapabilitiesSchemaType) => {
    setLocalSteeringConfig((prevConfig) => {
      if (!prevConfig) return prevConfig
      return {
        ...prevConfig,
        isReleaseControlSupported: data.isReleaseControlSupported,
        minReleaseControlTimeInSeconds: data.isReleaseControlSupported ? data.minReleaseControlTimeInSeconds : null,
      }
    })
  }

  const updateMultistepSettings = (data: ResourceSteeringMultistepSchemaType) => {
    setLocalSteeringConfig((prev) => {
      if (!prev) return prev
      return {
        ...prev,
        isMultistepResource: data.isMultistepResource,
        multistepStrategy: data.multistepStrategy,
      }
    })
  }

  const updateBatteryUsableEnergy = (userDefinedUsableEnergy: number | null) => {
    setLocalBatteryControlPort((prevPort) => (prevPort ? { ...prevPort, userDefinedUsableEnergy } : prevPort))
  }

  return (
    <ResourceCard
      content={
        <>
          <SteeringCapabilitiesPanel
            resourceID={resource.resourceID}
            steeringConfig={localSteeringConfig}
            onUpdateSteeringCapabilities={updateSteeringCapabilities}
          />
          <Divider />
          <SteeringDefaultsPanel
            resourceID={resource.resourceID}
            steeringConfig={localSteeringConfig}
            onUpdateSteeringDefaults={updateSteeringDefaults}
          />
          <Divider />
          {localBatteryControlPort && (
            <>
              <BatteryUsableEnergyPanel
                controlPort={localBatteryControlPort}
                resourceID={resource.resourceID}
                onUpdateUsableEnergy={updateBatteryUsableEnergy}
              />
              <Divider />
            </>
          )}
          <SteeringMultistepPanel
            resourceID={resource.resourceID}
            steeringConfig={localSteeringConfig}
            onUpdateMultistepSettings={updateMultistepSettings}
          />
          <Divider />
          <SteeringRangesPanel resource={resource} steeringConfig={localSteeringConfig} />
        </>
      }
    />
  )
}

export default ResourceSteeringCard
