import { Box } from '@mui/material'
import type { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import MultiSelectDrawer from '@/components/layouts/MultiSelectDrawerProps'
import { useAlertContext } from '@/contexts/AlertContext'
import { useActivationGroupResourcesQuery } from '@/features/activationGroup/hooks/useActivationGroupResourcesQuery'
import { useInvalidateGenericActivationGroupScheduleResourcesQuery } from '@/features/activationGroup/hooks/useGenericActivationGroupScheduleResourcesQuery'
import { useGenericUpdateActivationGroupScheduleMutation } from '@/features/activationGroup/hooks/useGenericUpdateActivationGroupScheduleMutation'
import type {
  ApiActivationGroupResource,
  AssignResourceToActivationGroupSchedule,
  GenericActivationGroupSchedule,
  GenericApiActivationGroupScheduleResource,
} from '@/features/activationGroup/types'
import { errorHandler } from '@/utils/errorHandler'

type ResourceTableProps = {
  rows: ApiActivationGroupResource[]
  selectionModel: GridRowSelectionModel
  onSelectionChange: (newSelection: GridRowSelectionModel) => void
  isLoading: boolean
}

type AssignScheduleResourceDrawerProps = {
  activationGroupUuid: string
  activationGroupSchedule: GenericActivationGroupSchedule
  scheduleResources: GenericApiActivationGroupScheduleResource[]
  open: boolean
  onClose: () => void
}

const ResourceTable = React.memo(({ rows, selectionModel, onSelectionChange, isLoading }: ResourceTableProps) => {
  const { t } = useTranslation()

  const columns: GridColDef<ApiActivationGroupResource>[] = [
    { field: 'uuid', headerName: t('common.uuid'), flex: 1 },
    { field: 'name', headerName: t('common.name'), flex: 1 },
  ]

  return (
    <Box sx={{ flex: 1, overflow: 'auto' }}>
      <CustomDataGrid
        checkboxSelection
        disableBottomShadow
        disableColumnFilter
        disableColumnMenu
        disableGutterBottom
        disableGutterTop
        aria-label={t('activation_groups.schedules.resources.table.title')}
        clickableRows={{
          isRowClickable: () => true,
          isRowSelectable: () => true,
          onRowSelectionModelChange: onSelectionChange,
        }}
        columns={columns}
        getRowId={(row) => row.uuid}
        isLoading={isLoading}
        pageSize={10}
        rowSelection={{
          multiple: true,
          model: selectionModel,
        }}
        rows={rows}
      />
    </Box>
  )
})
ResourceTable.displayName = 'ResourceTable' // Needed for React.memo to not complain about missing display name

const AssignScheduleResourceDrawer = ({
  activationGroupUuid,
  open,
  activationGroupSchedule,
  scheduleResources,
  onClose,
}: AssignScheduleResourceDrawerProps) => {
  const { t } = useTranslation()
  const { resources: activationGroupResources, isLoading } = useActivationGroupResourcesQuery({ activationGroupUuid })
  const { updateActivationGroupSchedule, isPending } = useGenericUpdateActivationGroupScheduleMutation({
    activationGroupScheduleUuid: activationGroupSchedule.uuid,
  })
  const invalidateActivationGroupResourcesQuery = useInvalidateGenericActivationGroupScheduleResourcesQuery({
    activationGroupScheduleUuid: activationGroupSchedule.uuid,
    pagination: {
      page: 0,
      pageSize: 100,
    },
  })
  const { pushAlert } = useAlertContext()

  // Single state for selected resources
  const [selectedResources, setSelectedResources] = useState<ApiActivationGroupResource[]>([])
  // Track selection model separately to avoid infinite loops
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])

  // Initialize selected resources when drawer opens or when activation group resources load
  useEffect(() => {
    if (!open) {
      setSelectedResources([])
      setSelectionModel([])
      return
    }

    if (activationGroupResources && activationGroupResources.length > 0) {
      // Find resources from activationGroupResources that match the UUIDs in scheduleResources
      const preselectedResources = activationGroupResources.filter((resource) =>
        scheduleResources.some((scheduleResource) => scheduleResource.uuid === resource.uuid),
      )

      // Update both states in one pass to keep them in sync
      setSelectedResources(preselectedResources)
      setSelectionModel(preselectedResources.map((resource) => resource.uuid))
    }
  }, [open, activationGroupResources, scheduleResources])

  const handleSave = async () => {
    try {
      const data: AssignResourceToActivationGroupSchedule = {
        resourceUuids: selectedResources.map((resource) => resource.uuid),
      }

      await updateActivationGroupSchedule({
        resources: data.resourceUuids,
        volume: activationGroupSchedule.volume,
        endAt: activationGroupSchedule.endAt,
      })
      await invalidateActivationGroupResourcesQuery()

      pushAlert({
        message: t('activation_groups.assign_resource_success_message'),
        severity: 'success',
      })
      onClose()
    } catch (err) {
      const error = errorHandler(err, t('activation_groups.assign_resource_generic_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  // Handle selection change using useCallback to prevent recreation on every render
  const handleSelectionChange = useCallback(
    (newSelection: GridRowSelectionModel) => {
      setSelectionModel(newSelection)

      if (activationGroupResources) {
        // Find the resources that match the selected UUIDs
        const selectedResourcesIds = new Set(newSelection)
        const newSelectedResources = activationGroupResources.filter((resource) =>
          selectedResourcesIds.has(resource.uuid),
        )

        setSelectedResources(newSelectedResources)
      }
    },
    [activationGroupResources],
  )

  return (
    <MultiSelectDrawer
      cancelButtonText={t('common.button.cancel')}
      description={t('activation_groups.schedules.resources.assign_dialog.description')}
      isSaving={isPending}
      open={open}
      saveButtonText={t('common.button.save')}
      selectedItemsCount={selectedResources.length}
      title={t('activation_groups.assign_resource_dialog.title')}
      onClose={onClose}
      onSave={handleSave}
    >
      <ResourceTable
        isLoading={isLoading}
        rows={activationGroupResources ?? []}
        selectionModel={selectionModel}
        onSelectionChange={handleSelectionChange}
      />
    </MultiSelectDrawer>
  )
}

export default AssignScheduleResourceDrawer
