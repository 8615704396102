import { Paper, Skeleton, Stack } from '@mui/material'
import * as React from 'react'

import { PhasePowerMeasurement } from '@/features/site/components/power_measurements/PhasePowerMeasurement'
import { TotalPowerMeasurementWithGauge } from '@/features/site/components/power_measurements/TotalPowerMeasurementWithGauge'
import { useCustomerPowerConsumptionWithPollingQuery } from '@/features/site/hooks/useCustomerPowerConsumptionWithPollingQuery'

interface AggregatedPowerConsumptionProps {
  customerUuid: string
  location: string
}

export const AggregatedPowerMeasurement = (props: AggregatedPowerConsumptionProps) => {
  const { customerUuid, location } = props
  const { powerConsumption } = useCustomerPowerConsumptionWithPollingQuery(
    { customerUuid: customerUuid, location: location },
    { enabled: Boolean(customerUuid) },
  )

  if (!powerConsumption)
    return (
      <Paper data-testid="power-measurement-skeleton">
        <Skeleton height={483} variant="rectangular" width={552} />
      </Paper>
    )

  return (
    <>
      <TotalPowerMeasurementWithGauge totalMeasurementInW={powerConsumption?.consumption} />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={1}
        sx={{ width: '552px', mt: 4, mb: 4 }}
      >
        <PhasePowerMeasurement measurementInW={powerConsumption?.consumption1} phaseNumber={1} />
        <PhasePowerMeasurement measurementInW={powerConsumption?.consumption2} phaseNumber={2} />
        <PhasePowerMeasurement measurementInW={powerConsumption?.consumption3} phaseNumber={3} />
      </Stack>
    </>
  )
}
