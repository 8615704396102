import { useMutation } from '@tanstack/react-query'

import { deleteCustomerContact } from '@/features/site/endpoints/customers'

export type UseDeleteCustomerContactMutationResult = ReturnType<typeof useDeleteCustomerContactMutation>

export function useDeleteCustomerContactMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (variables: { customerUuid: string; contactId: number; location?: string }) =>
      deleteCustomerContact(variables.customerUuid, variables.contactId, variables.location),
  })

  return {
    deleteCustomerContact: mutateAsync,
    ...mutationResult,
  }
}
