import { zodResolver } from '@hookform/resolvers/zod'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Stack } from '@mui/material'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CheckboxController from '@/components/inputs/CheckboxController'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { useAlertContext } from '@/contexts/AlertContext'
import CancelConfirmIconButtons from '@/features/site/components/CancelConfirmIconButtons'
import EditableBox from '@/features/site/components/EditableBox'
import { EditableIbanFieldController } from '@/features/site/components/EditableIbanFieldController'
import { EditableTextFieldController } from '@/features/site/components/EditableTextFieldController'
import { useInvalidateSiteQuery } from '@/features/site/hooks/useSiteQuery'
import { useUpdateSiteFinancialInfoMutation } from '@/features/site/hooks/useUpdateSiteFinancialInfoMutation'
import { getUpdateFinancialInfoFormSchema } from '@/features/site/schemas'
import type { Site, UpdateSiteFinancialInfoForm } from '@/features/site/types/site'
import { errorHandler } from '@/utils/errorHandler'

interface SiteFinancialInfoProps {
  site: Site
}

export const SiteFinancialInfoForm = ({ site }: SiteFinancialInfoProps) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const { updateSiteFinancialInfo, isPending } = useUpdateSiteFinancialInfoMutation()
  const invalidateSiteQuery = useInvalidateSiteQuery()

  const isSiteInFinland = site.countryCode?.toLocaleLowerCase() === 'fi'

  const defaultFormValues = {
    financialInformation: {
      iban: site.financialInformation?.iban,
      ibanHolder: site.financialInformation?.ibanHolder,
      isFinancialVisible: site.financialInformation?.isFinancialVisible,
    },
  }

  const { pushAlert } = useAlertContext()

  const form = useForm<UpdateSiteFinancialInfoForm>({
    mode: 'onBlur',
    defaultValues: defaultFormValues,
    resolver: zodResolver(getUpdateFinancialInfoFormSchema(t)),
  })

  async function handleSubmit(formData: UpdateSiteFinancialInfoForm) {
    try {
      await updateSiteFinancialInfo({
        financialInformation: {
          iban: formData.financialInformation?.iban ?? null,
          ibanHolder: formData.financialInformation?.ibanHolder ?? null,
          isFinancialVisible: formData.financialInformation?.isFinancialVisible,
        },
        location: site.location!,
        siteUuid: site.uuid!,
      })

      await invalidateSiteQuery({ uuid: site.uuid })

      exitEditMode(form.getValues())
    } catch (err) {
      const error = errorHandler(err, t('customer_details.tabs.company_info.errors.update_site_information_error'))

      pushAlert({ message: error.message, severity: 'error' })
    }
  }

  function enterEditMode() {
    setEditMode(true)
  }

  function exitEditMode(formValues?: UpdateSiteFinancialInfoForm) {
    form.reset(formValues)
    setEditMode(false)
  }

  function handleReset() {
    exitEditMode()
  }

  return (
    <FormProvider {...form}>
      <Stack
        noValidate
        component="form"
        sx={{ gap: 2 }}
        onReset={handleReset}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <CustomTypography fontSize={20} variant="h4">
            {t('customer_details.tabs.company_info.financial_info.card_title')}
          </CustomTypography>

          <Box>
            {editMode ? (
              <CancelConfirmIconButtons disabled={isPending} />
            ) : (
              <CustomIconButton
                Icon={EditOutlinedIcon}
                color="primary"
                iconProps={{
                  titleAccess: t('customer_details.tabs.company_info.financial_info.edit_icon_tooltip'),
                }}
                size="small"
                variant="solid"
                onClick={enterEditMode}
              />
            )}
          </Box>
        </Stack>

        <EditableBox isEditable={editMode}>
          <Stack sx={{ flexDirection: 'column', gap: 2 }}>
            <EditableIbanFieldController
              isEditable={editMode}
              label={t('customer_settings.form.iban_label')}
              name="financialInformation.iban"
            />
            <EditableTextFieldController
              isEditable={editMode}
              label={t('customer_settings.form.iban_holder_label')}
              name="financialInformation.ibanHolder"
            />
            {isSiteInFinland && (
              <CheckboxController
                label={t('customer_settings.form.financial_visibility_title')}
                name="financialInformation.isFinancialVisible"
                onClick={enterEditMode}
              />
            )}
          </Stack>
        </EditableBox>
      </Stack>
    </FormProvider>
  )
}
