// https://mui.com/material-ui/guides/routing/#tabs
// You need to provide the routes in descendant order (leaf to root).
// This means that if you have nested routes, then the parent route should be provided last within the hierarchy.

const SITE_INFO = {
  label: 'customer_details.tabs.company_info.title',
  navigationPath: 'sites/:siteUuid/contact-info',
  routePath: '/:siteUuid/contact-info',
}

const ACTIVATIONS = {
  label: 'customer_details.tabs.activations',
  navigationPath: 'sites/:siteUuid/activations',
  routePath: '/:siteUuid/activations',
}

const ACTIVATION_DETAILS = {
  label: 'customer_details.tabs.activation_details',
  navigationPath: 'sites/:siteUuid/activation/:activationId',
  routePath: '/:siteUuid/activation/:activationId',
}

const MARKET_PROGRAMS = {
  label: 'customer_details.tabs.market_programs',
  navigationPath: 'sites/:siteUuid/market-programs',
  routePath: '/:siteUuid/market-programs',
}

const SETTINGS = {
  label: 'customer_details.tabs.settings',
  navigationPath: 'sites/:siteUuid/settings',
  routePath: '/:siteUuid/settings',
}

const UNAVAILABILITY = {
  label: 'customer_details.tabs.unavailability',
  navigationPath: 'sites/:siteUuid/unavailability',
  routePath: '/:siteUuid/unavailability',
}

const POWER_MEASUREMENTS = {
  label: 'customer_details.tabs.power_measurements',
  navigationPath: 'sites/:siteUuid/power-measurements',
  routePath: '/:siteUuid/power-measurements',
}

const RES_LEVEL_UNAVAILABILITY = {
  label: 'customer_details.tabs.unavailability',
  navigationPath: 'sites/:siteUuid/res-level-unavailability',
  routePath: '/:siteUuid/res-level-unavailability',
}

const REVENUE = {
  label: 'customer_details.tabs.revenue',
  navigationPath: 'sites/:siteUuid/revenue',
  routePath: '/:siteUuid/revenue',
}

const INDEX = {
  label: SITE_INFO.label,
  navigationPath: 'sites/:siteUuid',
}

export const SiteDetailsRouteInformation = {
  SITE_INFO,
  ACTIVATION_DETAILS,
  ACTIVATIONS,
  MARKET_PROGRAMS,
  SETTINGS,
  UNAVAILABILITY,
  POWER_MEASUREMENTS,
  RES_LEVEL_UNAVAILABILITY,
  REVENUE,
  INDEX,
} as const
