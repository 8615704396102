import type { DateTime } from 'luxon'

import type { ExtendedBid, PtuChunk } from '@/features/bidding/types/bid'
import { convertToRoundedMw } from '@/features/bidding/utils/calculations/convertToRoundedMw'

export const calculateCumulativeVolumes = (bids: ExtendedBid[], ptuStart: DateTime) => {
  let available = 0
  let used = 0

  bids.forEach((bid) => {
    const ptu = bid.ptus.find((bidPtu) => bidPtu.ptu.start.equals(ptuStart))!

    available += ptu.offeredVolume.quantity

    if (!ptu.ptuChunks || ptu.ptuChunks.length === 0) {
      return
    }

    let highestQuantityChunk: PtuChunk | null = null
    for (const chunk of ptu.ptuChunks!) {
      if (
        highestQuantityChunk === null ||
        chunk.offeredVolume.quantity.valueOf() > highestQuantityChunk.offeredVolume.quantity.valueOf()
      ) {
        highestQuantityChunk = chunk
      }
    }

    used += highestQuantityChunk?.offeredVolume.quantity ?? 0
  })

  return {
    available: convertToRoundedMw(available),
    used: convertToRoundedMw(used),
  }
}
