import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

import { GET_RESTRICTIONS_API_ID, getResourceLevelRestrictions } from '@/features/site/endpoints/restrictions'
import type { ResourceLevelRestriction } from '@/features/unavailability/types/restriction'
import type { UseQueryOptions } from '@/types/queries'

const REFETCH_RESOURCE_LEVEL_RESTRICTIONS_INTERVAL = 3_000 // 3 seconds

type ResourceLevelRestrictionsWithPollingQueryParams = {
  customerUuid: string
  location?: string
}
export type UseResourceLevelRestrictionsQueryResult = Omit<UseQueryResult, 'data'> & {
  restrictions: ResourceLevelRestriction[] | null
  stopPolling: () => void
  restartPolling: () => void
}
export function useResourceLevelRestrictionsWithPollingQuery(
  params: ResourceLevelRestrictionsWithPollingQueryParams,
  options?: UseQueryOptions<ResourceLevelRestriction[]>,
): UseResourceLevelRestrictionsQueryResult {
  const [refetchingResourceLevelRestrictionsEnabled, setRefetchingResourceLevelRestrictionsEnabled] =
    useState<boolean>(true)
  const { data = null, ...queryResults } = useQuery({
    ...options,
    refetchInterval: REFETCH_RESOURCE_LEVEL_RESTRICTIONS_INTERVAL,
    enabled: refetchingResourceLevelRestrictionsEnabled,
    queryKey: [GET_RESTRICTIONS_API_ID, params],
    queryFn: () => getResourceLevelRestrictions(params.customerUuid),
  })

  function restartPolling() {
    setRefetchingResourceLevelRestrictionsEnabled(true)
  }

  function stopPolling() {
    setRefetchingResourceLevelRestrictionsEnabled(false)
  }

  return {
    restrictions: data,
    ...queryResults,
    stopPolling,
    restartPolling,
  }
}

export function useInvalidateResourceLevelRestrictionsWithPollingQuery() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: [GET_RESTRICTIONS_API_ID] })
}
