import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import InfoText from '@/components/dataDisplay/InfoText'

type SiteRevenueTimezoneInfoProps = {
  timezone: string
}

export function SiteRevenueTimezoneInfo({ timezone }: Readonly<SiteRevenueTimezoneInfoProps>) {
  const { t } = useTranslation()

  return (
    <Stack flexDirection="column" gap={2.25} sx={{ maxWidth: '50%' }}>
      <Typography color="textSecondary" variant="body2">
        {t('common.times_displayed_in_timezone', { timezone })}
      </Typography>

      <InfoText text={t('common.timezone_information')} />
    </Stack>
  )
}
