import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Slider, Typography } from '@mui/material'
import type { SelectChangeEvent } from '@mui/material/Select'
import type { GridColDef, GridColumnGroup } from '@mui/x-data-grid'
import { gridFilteredSortedRowIdsSelector, GridFooterContainer, useGridApiContext } from '@mui/x-data-grid'
import { DataGridPro } from '@mui/x-data-grid-pro'
import React from 'react'

import { useMarketData } from '@/features/bessDashboard/hooks/useMarketData'
import type { FormattedRow } from '@/features/bessDashboard/types'
import { createPricingVolumeLadder } from '@/features/bessDashboard/utils/biddingLadderLogic'
import type { Row } from '@/features/bessDashboard/utils/pricingTableGrid'
import { transformForecastData } from '@/features/bessDashboard/utils/pricingTableGrid'

type TableProps = {
  forecasts: FormattedRow[]
  marketProgram: string
  GridColDef: GridColDef[]
  columnGroupingModel: GridColumnGroup[]
}

const SummatedTotals = () => {
  const apiRef = useGridApiContext()
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef)

  const filteredSortedRows = filteredSortedRowIds.map((id) => apiRef.current.getRow(id))

  const totalEstimatedRevenue = filteredSortedRows.reduce(
    (sum, row) => sum + (row.allocated_mw || 0) * (row.average_price || 0),
    0,
  )

  const formattedRevenue = Math.round(totalEstimatedRevenue).toLocaleString()

  return <div>{`Total Estimated Revenue: $${formattedRevenue}`}</div>
}

const CustomFooter = () => {
  return (
    <GridFooterContainer>
      <SummatedTotals />
    </GridFooterContainer>
  )
}

export function BiddingTable({ forecasts, marketProgram, GridColDef, columnGroupingModel }: Readonly<TableProps>) {
  const initialState = React.useMemo(() => {
    return transformForecastData(forecasts)
      .filter((row) => row.market_bid === 0)
      .sort((a, b) => new Date(a.period).getTime() - new Date(b.period).getTime())
  }, [forecasts])

  const filteredData = React.useMemo(
    () => transformForecastData(forecasts).filter((item) => item.market_program_name === marketProgram),
    [forecasts, marketProgram],
  )

  const { marketData, setMarketData, updateMarketData } = useMarketData(initialState)

  const [settings, setSettings] = React.useState({
    sliderValue: 0,
    selectValue: '5',
    floorPrice: 9.1,
    marketProgram: marketProgram,
  })

  const updateSettings = (name, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }))
  }

  const handleSliderChange = React.useCallback(
    (event: Event, newValue: number | number[]) => {
      updateSettings('sliderValue', newValue as number)
      updateMarketData(newValue as number, Number(settings.selectValue), marketProgram)
    },
    [updateSettings, updateMarketData, settings.sliderValue, settings.marketProgram],
  )

  const handleSelectChange = React.useCallback(
    (event: SelectChangeEvent) => {
      updateSettings('selectValue', event.target.value as string)
      updateMarketData(settings.sliderValue, Number(event.target.value as string), marketProgram)
    },
    [updateSettings, updateMarketData, settings.selectValue, settings.marketProgram],
  )

  // Filter the data for the current market program only once
  const filteredActualsState = React.useMemo(() => {
    const marketProgramData = marketData[marketProgram]
    return marketProgramData
      ? Object.values(marketProgramData).sort((a, b) => new Date(a.period).getTime() - new Date(b.period).getTime())
      : []
  }, [marketData, marketProgram])

  const handleProcessRowUpdate = React.useCallback(
    (newRow: Row) => {
      const bounds = filteredData.find(
        (d) =>
          d.market_program_name === marketProgram &&
          d.market_bid === Number(newRow.allocated_mw) &&
          d.period === newRow.period,
      )

      const averagePrice = bounds?.average_price ?? 0
      const upperBound = bounds?.upper_bound ?? 0
      const lowerBound = bounds?.lower_bound ?? 0

      const biddingLadder = createPricingVolumeLadder(
        Number(newRow.allocated_mw),
        averagePrice,
        lowerBound,
        upperBound,
        settings.sliderValue,
        Number(settings.selectValue),
      )

      // Update the state with the new row data in a batched way to reduce re-renders
      setMarketData((prevData) => {
        const updatedProgramData = {
          ...prevData[marketProgram],
          [newRow.id]: {
            ...prevData[marketProgram]?.[newRow.id],
            ...newRow,
            lower_bound: lowerBound,
            average_price: averagePrice,
            upper_bound: upperBound,
            biddingLadder: biddingLadder,
          },
        }
        return {
          ...prevData,
          [marketProgram]: updatedProgramData,
        }
      })

      return {
        ...newRow,
        lower_bound: lowerBound,
        average_price: averagePrice,
        upper_bound: upperBound,
        biddingLadder: biddingLadder,
      }
    },
    [marketProgram, filteredData, settings.sliderValue, settings.selectValue],
  )

  return (
    <div>
      <Grid container columns={2} justifyContent="space-between" spacing={2} sx={{ alignItems: 'center' }}>
        <Grid sx={{ display: 'flex', justifyContent: 'left', padding: '8px 0' }}>
          <Typography variant="h2">{`Pricing Table: ${marketProgram}`}</Typography>
        </Grid>
        <Grid container>
          <Box sx={{ minWidth: 240, mx: 8 }}>
            <Typography gutterBottom id="input-slider">
              Risk Preference
            </Typography>
            <Slider
              aria-labelledby="input-slider"
              marks={[
                {
                  value: -2,
                  label: 'Risk-averse',
                },
                {
                  value: 2,
                  label: 'Risk-seeking',
                },
                {
                  value: 0,
                  label: 'Risk-neutral',
                },
              ]}
              max={2}
              min={-2}
              step={0.1}
              value={typeof settings.sliderValue === 'number' ? settings.sliderValue : 0}
              valueLabelDisplay="auto"
              onChange={handleSliderChange}
            />
          </Box>
          <Box sx={{ minWidth: 180 }}>
            <Typography gutterBottom id="input-slider">
              Bidding Ladder Levels
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="level-select-label">Levels</InputLabel>
              <Select
                id="level-select"
                label="Levels"
                labelId="level-select-label"
                value={settings.selectValue}
                onChange={handleSelectChange}
              >
                <MenuItem value={1}>One</MenuItem>
                <MenuItem value={2}>Two</MenuItem>
                <MenuItem value={3}>Three</MenuItem>
                <MenuItem value={4}>Four</MenuItem>
                <MenuItem value={5}>Five</MenuItem>
                <MenuItem value={6}>Six</MenuItem>
                <MenuItem value={7}>Seven</MenuItem>
                <MenuItem value={8}>Eight</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>

      <DataGridPro
        disableRowSelectionOnClick
        columnGroupingModel={columnGroupingModel}
        columns={GridColDef}
        getRowHeight={() => 52}
        hideFooter={false}
        processRowUpdate={handleProcessRowUpdate}
        rows={filteredActualsState ?? []}
        slots={{
          footer: CustomFooter,
        }}
      />
    </div>
  )
}
