import { useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CsvIcon from '@/assets/icons/csv-download.svg'
import CustomButton from '@/components/inputs/CustomButton'
import CustomIconButton from '@/components/inputs/CustomIconButton'

type SiteRevenueExportButtonProps = {
  onExport?: () => void
}

function CsvDownloadIcon() {
  const { t } = useTranslation()

  return <img alt={t('common.export')} src={CsvIcon} />
}

export function SiteRevenueExportButton({ onExport }: Readonly<SiteRevenueExportButtonProps>) {
  const { t } = useTranslation()
  const theme = useTheme()
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('xl'))
  const exportTranslation = t('common.export')

  if (!isExtraLargeScreen) {
    return (
      <CustomIconButton
        Icon={CsvDownloadIcon}
        iconProps={{
          titleAccess: exportTranslation,
        }}
        tooltipProps={{
          placement: 'top',
          title: exportTranslation,
        }}
        onClick={onExport}
      />
    )
  }

  return (
    <CustomButton startIcon={<CsvDownloadIcon />} variant={'outlinedWhite'} onClick={onExport}>
      {t('common.export')}
    </CustomButton>
  )
}
