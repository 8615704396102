import { zodResolver } from '@hookform/resolvers/zod'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, Stack } from '@mui/material'
import Paper from '@mui/material/Paper'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import LabeledTextField from '@/components/dataDisplay/LabeledTextField'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { useAlertContext } from '@/contexts/AlertContext'
import CancelConfirmIconButtons from '@/features/site/components/CancelConfirmIconButtons'
import EditableBox from '@/features/site/components/EditableBox'
import MarketProgramsEditTable from '@/features/site/components/marketPrograms/MarketProgramsEditTable'
import { MarketProgramsReadTable } from '@/features/site/components/marketPrograms/MarketProgramsReadTable'
import { updateSiteInformation } from '@/features/site/endpoints/sites'
import { useInvalidateSiteQuery } from '@/features/site/hooks/useSiteQuery'
import { useUpdateSiteMarketProgramContractsMutation } from '@/features/site/hooks/useUpdateSiteMarketProgramContractsMutation'
import { getUpdateSiteMarketProgramContractsFormSchema } from '@/features/site/schemas'
import type { Site, UpdateSiteMarketProgramContractsForm } from '@/features/site/types/site'
import { convertMarketProgramContractsFormToModel } from '@/features/site/utils/createSiteForm'
import { compareMarketPrograms } from '@/features/site/utils/sortMarketPrograms'
import { errorHandler } from '@/utils/errorHandler'
import { formatDateTimeWithLocale } from '@/utils/time'

interface SiteMarketProgramsViewProps {
  site: Site
}

export default function SiteMarketProgramsView({ site }: Readonly<SiteMarketProgramsViewProps>) {
  const { t, i18n } = useTranslation()

  const [editMode, setEditMode] = useState(false)
  const { updateSiteMarketProgramContracts, isPending } = useUpdateSiteMarketProgramContractsMutation()
  const invalidateSiteQuery = useInvalidateSiteQuery()

  const { pushAlert } = useAlertContext()

  const form = useForm<UpdateSiteMarketProgramContractsForm>({
    mode: 'onBlur',
    defaultValues: {
      contractDate: site.contractDate ? DateTime.fromISO(site.contractDate, { zone: DateTime.utc().zone }) : undefined,
      marketProgramContracts:
        site.marketProgramContracts?.map((contract) => ({
          marketProgramId: contract.marketProgram?.id,
          revenueSharingFraction: contract.revenueSharingFraction!,
          roundingErrorInKilowatts: contract.roundingErrorInWatts ? contract.roundingErrorInWatts / 1000 : null,
          soldCapacityAlgorithmType: contract.soldCapacityAlgorithmType!,
          isSelected: true,
        })) ?? [],
    },
    resolver: zodResolver(getUpdateSiteMarketProgramContractsFormSchema(t)),
  })

  async function handleSubmit(formData: UpdateSiteMarketProgramContractsForm) {
    try {
      await updateSiteInformation(site.uuid!, {
        ...site,
        contractDate: (formData.contractDate as DateTime)?.toISODate() ?? undefined, // only this field is actually being updated
        partnerCode: site.partner!.partnerCode,
        timezone: site.timezone!,
        location: site.location!,
      })
      await updateSiteMarketProgramContracts({
        siteUuid: site.uuid!,
        location: site.location!,
        marketProgramContracts: convertMarketProgramContractsFormToModel(formData.marketProgramContracts),
      })
      await invalidateSiteQuery({ uuid: site.uuid })

      setEditMode(false)
    } catch (err) {
      const error = errorHandler(err, t('customer_details.tabs.market_programs.errors.update_market_programs_error'))
      pushAlert({ message: error.message, severity: 'error' })
    }
  }

  function enterEditMode() {
    setEditMode(true)
  }

  function exitEditMode() {
    form.reset()
    setEditMode(false)
  }

  return (
    <Paper sx={{ p: 3, my: 3, height: 'fit-content', width: '100%' }}>
      <Stack gap={2}>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <CustomTypography variant={'h5'}>{t('customer_details.tabs.market_programs.title')}</CustomTypography>
          <Box>
            {!editMode && (
              <CustomIconButton
                Icon={EditOutlinedIcon}
                color="primary"
                iconProps={{
                  titleAccess: t('customer_details.tabs.market_programs.edit_label'),
                }}
                onClick={enterEditMode}
              />
            )}
          </Box>
        </Stack>

        {editMode ? (
          <FormProvider {...form}>
            <EditableBox isEditable>
              <Stack
                noValidate
                component="form"
                sx={{ gap: 3 }}
                onReset={exitEditMode}
                onSubmit={form.handleSubmit(handleSubmit)}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Controller
                    control={form.control}
                    name={'contractDate'}
                    render={({ field }) => (
                      <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
                        <DatePicker {...field} label={t('customer_details.tabs.market_programs.contract_date')} />
                      </LocalizationProvider>
                    )}
                  />
                  <CancelConfirmIconButtons disabled={isPending} />
                </Stack>
                <MarketProgramsEditTable countryCode={site.countryCode!} />
              </Stack>
            </EditableBox>
          </FormProvider>
        ) : (
          <Stack gap={2}>
            <LabeledTextField
              label={t('customer_details.tabs.market_programs.contract_date')}
              text={
                site.contractDate
                  ? formatDateTimeWithLocale(
                      DateTime.fromISO(site.contractDate, { zone: DateTime.utc().zone }),
                      i18n.language,
                    )
                  : '-'
              }
            />
            <MarketProgramsReadTable
              marketProgramContracts={
                site.marketProgramContracts?.toSorted((a, b) =>
                  compareMarketPrograms(t, a.marketProgram!, b.marketProgram!),
                ) ?? []
              }
            />
          </Stack>
        )}
      </Stack>
    </Paper>
  )
}
