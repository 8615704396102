import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

type PageMetadataContextType = {
  setPageTitle: (pageTitle: string) => void
  setBrandName: (brandName: string) => void
  setFavicon: (favicon: string) => void
}

export const PageMetadataContext = createContext<PageMetadataContextType>({
  setPageTitle: () => {},
  setBrandName: () => {},
  setFavicon: () => {},
})

function PageMetadataContextProvider({ children }: Readonly<{ children: ReactNode }>) {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [brandName, setBrandName] = useState<string>('')
  const [favicon, setFavicon] = useState<string>('')

  useEffect(() => {
    if (favicon) {
      document.head.insertAdjacentHTML('beforeend', `<link rel="icon" href="${favicon}" />`)
    }
  }, [favicon])

  useEffect(() => {
    if (pageTitle && brandName) {
      document.title = `${brandName} - ${pageTitle}`
    } else if (pageTitle) {
      document.title = pageTitle
    } else if (brandName) {
      document.title = brandName
    }
  }, [pageTitle, brandName])

  const contextValue = useMemo(
    () => ({
      setPageTitle,
      setBrandName,
      setFavicon,
    }),
    [setPageTitle, setBrandName, setFavicon],
  )

  return <PageMetadataContext.Provider value={contextValue}>{children}</PageMetadataContext.Provider>
}

const usePageMetadata = () => useContext(PageMetadataContext)
export { PageMetadataContextProvider, usePageMetadata }
