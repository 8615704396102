import { useMutation } from '@tanstack/react-query'

import { editResourceLevelRestriction } from '@/features/site/endpoints/restrictions'
import type { EditResourceLevelRestrictionRequest } from '@/features/site/types/restriction'

export type UseEditResourceLevelRestrictionMutationResult = ReturnType<typeof useEditResourceLevelRestrictionMutation>

export function useEditResourceLevelRestrictionMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (variables: { customerUuid: string; restriction: EditResourceLevelRestrictionRequest }) =>
      editResourceLevelRestriction(variables.customerUuid, variables.restriction),
  })

  return {
    editResourceLevelRestriction: mutateAsync,
    ...mutationResult,
  }
}
