import { useMutation } from '@tanstack/react-query'

import { updateMeteringPortRelation } from '@/features/resource/endpoints/resources'

export function useUpdateMeteringPortRelationMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (variables: { resourceId: string; meteringPortId: string; arithmeticOperation: string }) =>
      updateMeteringPortRelation(variables.resourceId, variables.meteringPortId, variables.arithmeticOperation),
  })

  return {
    updateMeteringPortRelation: mutateAsync,
    ...mutationResult,
  }
}
