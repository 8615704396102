import { useMutation } from '@tanstack/react-query'

import { updateSiteMarketProgramContracts } from '@/features/site/endpoints/sites'
import type { SiteMarketProgramContractRequest } from '@/features/site/types/site'

type UpdateSiteMarketProgramContractsMutationVariables = {
  siteUuid: string
  location: string
  marketProgramContracts: SiteMarketProgramContractRequest[]
}

export function useUpdateSiteMarketProgramContractsMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: ({ siteUuid, location, marketProgramContracts }: UpdateSiteMarketProgramContractsMutationVariables) =>
      updateSiteMarketProgramContracts(siteUuid, location, marketProgramContracts),
  })

  return {
    updateSiteMarketProgramContracts: mutateAsync,
    ...mutationResult,
  }
}
