import type { DateTime } from 'luxon'

import environment from '@/environment'
import type { ActivationGroup } from '@/features/activationGroup/types'
import { MarketProgram } from '@/features/bidding/constants'

export function getGrafanaDisturbanceUrl({
  activationGroup,
  activationTriggerChannel,
  startedAt,
  endedAt,
}: {
  activationGroup: ActivationGroup
  activationTriggerChannel: number
  startedAt: DateTime
  endedAt: DateTime | null
}) {
  if (
    activationGroup.marketProgram === MarketProgram.NORDICS_FCRD_DOWN_STATIC ||
    activationGroup.marketProgram === MarketProgram.NORDICS_FCRD_UP_STATIC
  ) {
    return getGrafanaFcrdStaticDisturbanceUrl({
      activationGroupUuid: activationGroup.uuid,
      portfolioCode: activationGroup.portfolio.code,
      activationTriggerChannel,
      startedAt,
      endedAt,
    })
  } else return getGrafanaFcrnDisturbanceUrl({ startedAt, endedAt })
}

export function getGrafanaFcrdStaticDisturbanceUrl({
  activationGroupUuid,
  portfolioCode,
  activationTriggerChannel,
  startedAt,
  endedAt,
}: {
  activationGroupUuid: string | null
  portfolioCode: string
  activationTriggerChannel: number
  startedAt: DateTime
  endedAt: DateTime | null
}) {
  const params = new URLSearchParams({
    orgId: '1',
    from: startedAt.toMillis().toString(),
    'var-environment': environment.environmentName,
    'var-activationTriggerChannel': activationTriggerChannel.toString(),
  })

  if (portfolioCode) {
    params.set('var-PortfolioCode', portfolioCode)
  }

  if (endedAt) {
    params.set('to', endedAt.toMillis().toString())
  }

  if (activationGroupUuid) {
    params.set('var-ActivationGroup', activationGroupUuid)
  }

  return `https://grafana.sympower.net/d/pmNVQz7Iz/disturbance-monitoring-new-fcr-requirements?${params.toString()}`
}

export function getGrafanaFcrnDisturbanceUrl({
  startedAt,
  endedAt,
}: {
  startedAt: DateTime
  endedAt: DateTime | null
}) {
  const params = new URLSearchParams({
    orgId: '1',
    from: startedAt.toMillis().toString(),
  })

  if (endedAt) {
    params.set('to', endedAt.toMillis().toString())
  }

  return `https://grafana.sympower.net/d/ae05dq05gqxhcd/realtime-bess-metrics?${params.toString()}`
}
