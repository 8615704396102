import type { TextFieldProps } from '@mui/material'
import { TextField } from '@mui/material'
import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'

import LabeledTextField from '@/components/dataDisplay/LabeledTextField'

export type EditableTextFieldProps = TextFieldProps & {
  isEditable?: boolean
  label: string
}

function EditableTextField(
  { label, isEditable = false, ...props }: EditableTextFieldProps,
  ref: ForwardedRef<HTMLInputElement | HTMLDivElement>,
) {
  if (isEditable) {
    return <TextField ref={ref} {...props} label={label} sx={{ backgroundColor: 'white' }} value={props.value ?? ''} />
  }

  return <LabeledTextField label={label ?? ''} text={props.value?.toString()} />
}

export default forwardRef(EditableTextField)
