import { useMutation } from '@tanstack/react-query'

import { createServiceRule } from '@/features/site/endpoints/serviceRules'
import type { CreateServiceRuleRequest } from '@/features/unavailability/types/serviceRule'

export type UseCreateServiceRuleMutationResult = ReturnType<typeof useCreateServiceRuleMutation>

export function useCreateServiceRuleMutation() {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (variables: {
      customerUuid: string
      serviceId: number | undefined
      serviceRule: CreateServiceRuleRequest
    }) => createServiceRule(variables.customerUuid, variables.serviceId, variables.serviceRule),
  })

  return {
    createServiceRule: mutateAsync,
    ...mutationResult,
  }
}
