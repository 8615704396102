import { Box, Grid, InputAdornment, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import TextFieldController from '@/components/inputs/TextFieldController'
import { useAlertContext } from '@/contexts/AlertContext'
import { ContactCardActionButtons } from '@/features/site/components/companyInfo/ContactCardActionButtons'
import EditableBox from '@/features/site/components/EditableBox'
import { EditableTextFieldController } from '@/features/site/components/EditableTextFieldController'
import { useCreateCustomerContactMutation } from '@/features/site/hooks/useCreateCustomerContactMutation'
import { useDeleteCustomerContactMutation } from '@/features/site/hooks/useDeleteCustomerContactMutation'
import { useUpdateCustomerContactMutation } from '@/features/site/hooks/useUpdateCustomerContactMutation'
import type { CustomerContact } from '@/features/site/types/customer'
import type { SiteContact } from '@/features/site/types/site'
import { EMAIL_REGEX } from '@/utils/email'
import { errorHandler } from '@/utils/errorHandler'
import validatePhone from '@/utils/phone'

interface ContactCardProps {
  contact?: SiteContact
  siteUuid?: string
  viewOnly?: boolean
  location?: string
  isFormMode?: boolean
  onCancel?: () => void
  onChange?: () => void
}

interface CustomerContactForm {
  name: string
  phone: string
  email: string
  comment: string
}

export const ContactCard = (props: ContactCardProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [isCommentShown, setIsCommentShown] = useState(false)
  const [isFormMode, setIsFormMode] = useState(props.isFormMode || false)
  const { pushAlert } = useAlertContext()
  const { createCustomerContact } = useCreateCustomerContactMutation()
  const { updateCustomerContact } = useUpdateCustomerContactMutation()
  const { deleteCustomerContact } = useDeleteCustomerContactMutation()

  const form = useForm<CustomerContactForm>({
    mode: 'onBlur',
    defaultValues: {
      name: props.contact?.name ?? '',
      phone: props.contact?.phone ?? '',
      email: props.contact?.email ?? '',
      comment: props.contact?.comment ?? '',
    },
  })

  useWatch({
    control: form.control,
  })

  /**
   * It cretes a new customer when the form data does not contain an id. Otherwise, it updates the existing customer.
   */
  function onSubmit() {
    const siteUuid = props.siteUuid

    if (!siteUuid || !props.location) {
      return
    }

    form.handleSubmit(async (data) => {
      let contact: CustomerContact

      if (!props.contact?.legacyId) {
        contact = await createCustomerContact({
          customerUuid: siteUuid,
          contact: {
            ...data,
            location: props.location,
          },
        })
      } else {
        contact = await updateCustomerContact({
          customerUuid: siteUuid,
          contact: {
            ...data,
            id: props.contact.legacyId,
            location: props.location,
          },
        })
      }

      form.reset({
        name: contact.name,
        phone: contact.phone,
        email: contact.email,
        comment: contact.comment,
      })

      setIsFormMode(false)

      props.onChange?.()
    })()
  }

  function onFormCancel() {
    setIsFormMode(false)
    form.reset()
    props.onCancel?.()
  }

  async function handleDelete() {
    const siteUuid = props.siteUuid
    const contactId = props.contact?.legacyId

    if (!siteUuid || !contactId) {
      return
    }

    try {
      await deleteCustomerContact({ customerUuid: siteUuid, contactId, location: props.location })
    } catch (err) {
      const error = errorHandler(err, t('customer_details.errors.contact_delete_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }

    props.onChange?.()
  }

  return (
    <FormProvider {...form}>
      <EditableBox isEditable={isFormMode}>
        <Stack
          direction={{
            md: 'column',
            lg: 'row',
          }}
          justifyContent="space-between"
        >
          <Grid container spacing={2} width={'100%'}>
            <Grid size={{ xs: 12 }}>
              <Grid container spacing={2}>
                <Grid size={{ lg: 12, xl: 6 }}>
                  <EditableTextFieldController
                    required
                    controllerProps={{
                      rules: {
                        required: t('customer_details.tabs.company_info.contacts.name_required.error'),
                      },
                    }}
                    isEditable={isFormMode}
                    label={t('common.name')}
                    name="name"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid size={{ lg: 6, md: 12 }}>
              <EditableTextFieldController
                controllerProps={{
                  rules: {
                    validate: (value) => {
                      if (!validatePhone(value)) {
                        return t('customer_details.tabs.company_info.phone_invalid.error').toString()
                      }
                    },
                  },
                }}
                isEditable={isFormMode}
                label={t('customer_details.tabs.company_info.phone')}
                name="phone"
                slotProps={{
                  input: {
                    startAdornment: <InputAdornment position="start">+</InputAdornment>,
                  },
                }}
                onChange={(event) => {
                  // users are only allowed to enter numbers
                  const value = event.target.value.replace(/\D/g, '')

                  form.setValue('phone', value)
                }}
              />
            </Grid>
            <Grid size={{ lg: 6, md: 12 }}>
              <EditableTextFieldController
                controllerProps={{
                  rules: {
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t('customer_details.tabs.company_info.email_invalid.error').toString(),
                    },
                  },
                }}
                isEditable={isFormMode}
                label={t('customer_details.tabs.company_info.email')}
                name="email"
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              {(isCommentShown || isFormMode) && (
                <TextFieldController
                  multiline
                  disabled={!isFormMode}
                  id="outlined-multiline-static"
                  label={t('common.comment')}
                  name="comment"
                  rows={2}
                  sx={{
                    backgroundColor: isFormMode ? theme.palette.background.paper : 'rgba(250, 250, 250, 0.6)',
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: theme.palette.text.primary,
                    },
                  }}
                />
              )}
            </Grid>
          </Grid>

          <Box minWidth={'20%'}>
            <ContactCardActionButtons
              clearFormButton={isFormMode ? { onClick: onFormCancel } : undefined}
              deleteButton={
                props.contact && isFormMode
                  ? {
                      onClick: () => {
                        handleDelete()
                      },
                    }
                  : undefined
              }
              editButton={props.contact && !isFormMode ? { onClick: () => setIsFormMode(true) } : undefined}
              showCommentButton={
                !isFormMode && props.contact?.comment
                  ? { onClick: () => setIsCommentShown(!isCommentShown) }
                  : undefined
              }
              submitFormButton={isFormMode ? { onClick: onSubmit } : undefined}
              viewOnly={props.viewOnly}
            />
          </Box>
        </Stack>
      </EditableBox>
    </FormProvider>
  )
}
