import * as echarts from 'echarts'
import { DateTime } from 'luxon'

import type { FormattedRow } from '@/features/bessDashboard/types'

export function filterByMarketProgram(formattedData: FormattedRow[], marketProgramName: string): FormattedRow[] {
  return formattedData.filter((row) => row['market_program_name'] === marketProgramName)
}

export function filterByType(formattedData: FormattedRow[], type: string): FormattedRow[] {
  return formattedData.filter((row) => row['forecast_target'] === type)
}

export function formatUnderscoreString(input: any): any {
  if (typeof input !== 'string') {
    return input
  }

  return input.split('_').join(' ').toUpperCase()
}

export function findOverallMinMaxForColumns(data, col1, col2) {
  const values = data.flatMap((row) => [Number(row[col1]), Number(row[col2])]).filter((value) => !isNaN(value))

  return {
    overallMin: Math.min(values),
    overallMax: Math.max(values),
  }
}

export function constructDateString(date) {
  const dateInLuxon = DateTime.fromJSDate(date)

  const dayPart = dateInLuxon.toFormat('yyyy-MM-dd')
  const timePart = dateInLuxon.toFormat('HH:mm')

  return { dayPart, timePart }
}

export function getTimeZoneString(mode = 'UTC') {
  return mode === 'UTC' ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function customTooltipFormatter(params) {
  const DEFAULT_COLOR = '#000'

  const date = new Date(params[0].axisValue)
  const { dayPart, timePart } = constructDateString(date)

  const tooltipContent = `<strong>${dayPart} ${timePart} ${getTimeZoneString()}</strong><br>`

  return params.reduce((content, item, index) => {
    const seriesName = item.seriesName ?? `Series ${index + 1}`
    const value = item.value
    const data = item.data
    const color = item.color ?? DEFAULT_COLOR

    if (item.seriesType !== 'custom') {
      return `${content} <span style="color:${color};">●</span> ${seriesName}: \t ${parseFloat(value).toFixed(2)}</br>`
    }

    if (Array.isArray(data) && data.length === 3) {
      const hi_value = parseFloat(data[2]).toFixed(2)
      const lo_value = parseFloat(data[1]).toFixed(2)

      return `${content} <span style="color:${color};">●</span> ${seriesName}: \t [${lo_value}, ${hi_value}]<br>`
    }

    return `${content} <span style="color:${color};">●</span> ${seriesName}: \t ${Math.round(value)}<br>`
  }, tooltipContent)
}

export function createRenderItem(
  options: {
    fill?: string
  } = {},
) {
  const {
    fill = 'rgba(69, 170, 242, 0.3)', // default
  } = options

  return function renderItem(params, api) {
    // Only generate the polygon once
    if (params.dataIndexInside !== 0) {
      return
    }

    const count = params.dataInsideLength
    // Explicitly type the coords array
    const coords: number[][] = []

    // 1. Collect upper boundary (forwards)
    for (let i = 0; i < count; i++) {
      const xVal = api.value(0, i)
      const upper = api.value(2, i)
      coords.push(api.coord([xVal, upper]) as number[])
      coords.push(api.coord([xVal + 1, upper]) as number[])
    }

    // 2. Collect lower boundary (backwards)
    for (let i = count - 1; i >= 0; i--) {
      const xVal = api.value(0, i)
      const lower = api.value(1, i)
      coords.push(api.coord([xVal + 1, lower]) as number[])
      coords.push(api.coord([xVal, lower]) as number[])
    }

    // Clip the polygon to chart area
    const clipRect = {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height,
    }
    const clippedCoords = echarts.graphic.clipPointsByRect(coords, clipRect)

    return {
      type: 'polygon',
      shape: {
        points: clippedCoords,
      },
      style: {
        fill: fill,
      },
    }
  }
}

export function getWeekendMarkAreas(formattedData: FormattedRow[]): { xAxis: string }[][] {
  const markAreas: { xAxis: string }[][] = []

  formattedData.forEach((item, index) => {
    const date = new Date(item.ds as string) // Assuming `ds` is always a string
    const day = date.getDay() // 0 = Sunday, 6 = Saturday

    // If it's Saturday, create a markArea for Saturday and Sunday
    if (day === 6) {
      const nextIndex = index + 1 // Sunday follows Saturday
      if (nextIndex < formattedData.length) {
        markAreas.push([{ xAxis: item.ds as string }, { xAxis: formattedData[nextIndex].ds as string }])
      }
    }
  })

  return markAreas
}
