import { LocationOnOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import NumericWidget from '@/features/dashboard/components/NumericWidget'
import { NumericWidgetSkeleton } from '@/features/dashboard/components/NumericWidgetSkeleton'
import { useCustomersQuery } from '@/features/site/hooks/useCustomersQuery'

function SiteCountWidget() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { customers, isLoading } = useCustomersQuery()

  if (isLoading) {
    return <NumericWidgetSkeleton />
  }

  return (
    <NumericWidget
      icon={<LocationOnOutlined color="secondary" fontSize="large" />}
      title={t('component.navbar.customers')}
      value={customers?.filter((customer) => customer.isActive).length ?? 0}
      onClick={() => navigate(`/sites`)}
    />
  )
}

export default SiteCountWidget
