import type { BidType, MarketProgram } from '@/features/bidding/constants'
import { Status } from '@/features/bidding/constants'
import { findBid, findForecast, findSuggestedBid } from '@/features/bidding/endpoints/bids'
import type {
  BidVolumes,
  ExtendedBidPtu,
  ForecastPtu,
  OfferedAndForecastAndSuggestedPtu,
  SuggestedBid,
} from '@/features/bidding/types/bid'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import getBidConfig from '@/features/bidding/utils/getBidConfig'

export const CALCULATE_BASIS_FOR_NEW_BID_API_ID = 'CALCULATE_BASIS_FOR_NEW_BID'

const DEFAULT_VOLUME = 1_000_000

export const calculateBasisForNewBid = async (
  date: MarketDate,
  portfolioCode: string,
  marketProgram: MarketProgram,
  activationGroupUuid: string,
  bidType: BidType,
  selectedCountry: CountryIdentifier,
  isValueStackingEnabled: boolean = false,
): Promise<BidVolumes> => {
  const foundBid = await findBid(date, portfolioCode, marketProgram, activationGroupUuid, bidType)
  const foundForecast = await findForecast(date, activationGroupUuid)
  // don't make additional request unless country and market program support value stacking
  const foundSuggestedBid = isValueStackingEnabled ? await findSuggestedBid(date, date, activationGroupUuid) : []

  if (foundBid) {
    return {
      version: calculateOfferedBidVersion(foundBid.version, foundBid.status),
      ptus: foundBid.ptus,
      forecast: foundForecast ? foundForecast.forecastPtus : undefined,
      suggestedBid: foundSuggestedBid ?? undefined,
    }
  }

  if (foundForecast || foundSuggestedBid?.length > 0) {
    const forecastPtus = foundForecast?.forecastPtus ?? []
    const suggestedBidPtus = foundSuggestedBid ?? []
    const offeredBidPtus = buildDefaultOfferedBid(selectedCountry, date, bidType).map((offerPtu: ExtendedBidPtu) => {
      const forecastPtu = forecastPtus.find((forecastPtu) => forecastPtu.ptu.start.equals(offerPtu.ptu.start))
      const suggestedBidPtu = suggestedBidPtus.find((suggestedBid) => suggestedBid.ptu.start.equals(offerPtu.ptu.start))

      return {
        ptu: offerPtu.ptu,
        offeredVolume: {
          quantity: suggestedBidPtu?.volume.quantity ?? forecastPtu?.volume.quantity ?? offerPtu.offeredVolume.quantity,
          unit: offerPtu.offeredVolume.unit,
        },
        ptuChunks: [],
      }
    })

    return {
      version: 1,
      ptus: offeredBidPtus,
      forecast: forecastPtus.length > 0 ? forecastPtus : undefined,
      suggestedBid: suggestedBidPtus.length > 0 ? suggestedBidPtus : undefined,
    }
  }

  return {
    version: 1,
    ptus: buildDefaultOfferedBid(selectedCountry, date, bidType),
  }
}

const calculateOfferedBidVersion = (latestVersion?: number, latestStatus?: Status) => {
  if (!latestVersion) return 1
  if (latestStatus === Status.DRAFT) return latestVersion
  return latestVersion + 1
}

const buildDefaultOfferedBid = (
  selectedCountry: CountryIdentifier,
  date: MarketDate,
  bidType: BidType,
): ExtendedBidPtu[] => {
  const startOfDay = date.getStartOfDay()
  const startOfNextDay = startOfDay.plus({ day: 1 })
  const { ptuLengthInMin, volumeUnit } = getBidConfig(selectedCountry, bidType)

  const numberOfPtus = startOfNextDay.diff(startOfDay, 'minutes').minutes / ptuLengthInMin

  return Array.from({ length: numberOfPtus }, (_, index) => {
    const start = startOfDay.plus({ minutes: index * ptuLengthInMin })
    const end = startOfDay.plus({ minutes: index * ptuLengthInMin + ptuLengthInMin })

    return {
      ptu: { start, end },
      offeredVolume: { quantity: DEFAULT_VOLUME, unit: volumeUnit },
    }
  })
}

export const combineOfferedAndForecast = (
  offer: ExtendedBidPtu[],
  forecast?: ForecastPtu[],
  suggestedBids?: SuggestedBid[],
): OfferedAndForecastAndSuggestedPtu[] =>
  offer.map((offeredPtu) => ({
    ptu: offeredPtu.ptu,
    offeredVolume: offeredPtu.offeredVolume,
    offeredPtuChunks: offeredPtu.ptuChunks,
    forecastedVolume: forecast?.find((forecastPtu) => forecastPtu.ptu.start.equals(offeredPtu.ptu.start))?.volume,
    suggestedVolume: suggestedBids?.find((suggestedBid) => suggestedBid.ptu.start.equals(offeredPtu.ptu.start))?.volume,
  }))
