import { zodResolver } from '@hookform/resolvers/zod'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Box, CardHeader, Stack, Tooltip, Typography } from '@mui/material'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomButton from '@/components/inputs/CustomButton'
import TextFieldController from '@/components/inputs/TextFieldController'
import { GET_RESOURCE_API_ID, updateBatteryUsableEnergy } from '@/features/resource/endpoints/resources'
import { BatteryUsableEnergySchema } from '@/features/resource/schemas'
import type { BatteryUsableEnergySchemaType, ControlPort } from '@/features/resource/types'
import { convertToKilo } from '@/features/resource/utils/convertToKilo'
import { convertToWatts } from '@/features/resource/utils/convertToWatts'

const BatteryUsableEnergyTooltipContent = () => {
  const { t } = useTranslation()

  return (
    <div style={{ maxWidth: 300 }}>
      <Typography sx={{ color: 'white', fontWeight: 'bold', mb: 1 }} variant="body2">
        {t('resources.steering.battery.usable_power.tooltip.title')}
      </Typography>
      <Typography sx={{ color: 'white' }} variant="body2">
        {t('resources.steering.battery.usable_power.tooltip.description')}
      </Typography>
    </div>
  )
}

export type BatteryUsableEnergyPanelProps = {
  resourceID: string
  controlPort: ControlPort
  onUpdateUsableEnergy: (userDefinedUsableEnergy: number | null) => void
}

const BatteryUsableEnergyPanel = ({ resourceID, controlPort, onUpdateUsableEnergy }: BatteryUsableEnergyPanelProps) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const queryClient = useQueryClient()

  const form = useForm<BatteryUsableEnergySchemaType>({
    defaultValues: {
      userDefinedUsableEnergy: convertToKilo(controlPort.userDefinedUsableEnergy ?? null),
    },
    resolver: zodResolver(BatteryUsableEnergySchema),
  })

  const updateUsableEnergyMutation = useMutation({
    mutationFn: (data: BatteryUsableEnergySchemaType) => {
      return updateBatteryUsableEnergy(resourceID, convertToWatts(data.userDefinedUsableEnergy))
    },
    onSuccess: (_, variables) => {
      onUpdateUsableEnergy(convertToWatts(variables.userDefinedUsableEnergy))
      queryClient.invalidateQueries({ queryKey: [GET_RESOURCE_API_ID, { id: resourceID }] })
      setEditMode(false)
    },
    onError: (error) => {
      console.error('Failed to update battery usable energy:', error)
    },
  })

  function onOpenEditMode() {
    setEditMode(true)
  }

  function onCloseEditMode() {
    setEditMode(false)
    form.reset()
  }

  function onSave(data: BatteryUsableEnergySchemaType) {
    updateUsableEnergyMutation.mutate(data)
  }

  const formatEnergyValue = (value: number | null | undefined) => {
    if (!value) return t('resources.steering.battery.usable_power.using_default')
    return t('resources.steering.battery.usable_power.kwatt_hours', { value: convertToKilo(value) })
  }

  return (
    <>
      <CardHeader
        sx={{ paddingX: 0 }}
        title={
          <Stack alignItems="center" direction="row" spacing={1}>
            <CustomTypography variant="h6">{t('resources.steering.battery.usable_power.label')}</CustomTypography>
            <Tooltip arrow placement="right-start" title={<BatteryUsableEnergyTooltipContent />}>
              <HelpOutlineIcon fontSize="small" sx={{ color: 'black' }} />
            </Tooltip>
          </Stack>
        }
      />
      {editMode ? (
        <FormProvider {...form}>
          <form aria-label={t('resources.steering.battery.usable_power.label')} onSubmit={form.handleSubmit(onSave)}>
            <Stack alignItems="flex-start" direction="column" sx={{ gap: 3 }}>
              <TextFieldController
                error={form.formState.errors.userDefinedUsableEnergy !== undefined}
                helperText={form.formState.errors.userDefinedUsableEnergy?.message}
                label={t('resources.steering.battery.usable_power.energy_input')}
                name="userDefinedUsableEnergy"
                sx={{ width: 400 }}
                type="number"
                onChange={(e) =>
                  form.setValue('userDefinedUsableEnergy', e.target.value === '' ? null : Number(e.target.value))
                }
              />
              <Box>
                <Stack direction="row" gap={2}>
                  <CustomButton disabled={updateUsableEnergyMutation.isPending} type="submit" variant="contained">
                    {t('common.button.save')}
                  </CustomButton>
                  <CustomButton variant="outlined" onClick={onCloseEditMode}>
                    {t('common.button.cancel')}
                  </CustomButton>
                </Stack>
              </Box>
            </Stack>
          </form>
        </FormProvider>
      ) : (
        <Stack alignItems="flex-start" direction="column" sx={{ gap: 3, paddingBottom: 3 }}>
          <CustomTypography sx={{ paddingX: 3 }} variant="body1">
            {t('resources.steering.battery.usable_power.current_value', {
              value: formatEnergyValue(controlPort.userDefinedUsableEnergy),
            })}
          </CustomTypography>
          <CustomButton variant="outlined" onClick={onOpenEditMode}>
            {t('resources.steering.battery.usable_power.edit')}
          </CustomButton>
        </Stack>
      )}
    </>
  )
}

export default BatteryUsableEnergyPanel
