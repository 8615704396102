import { zodResolver } from '@hookform/resolvers/zod'
import { DialogContent } from '@mui/material'
import type { DateTime } from 'luxon'
import { useEffect } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import FormDialogActions from '@/components/feedback/FormDialogActions'
import CheckboxController from '@/components/inputs/CheckboxController'
import DesktopDateTimePickerController from '@/components/inputs/DesktopDateTimePickerController'
import SelectFieldController from '@/components/inputs/SelectFieldController'
import TextFieldController from '@/components/inputs/TextFieldController'
import { TODAY } from '@/constants/dateTime'
import { useActivationGroupQuery } from '@/features/activationGroup/hooks/useActivationGroupQuery'
import { getGenericCreateScheduleSchema } from '@/features/activationGroup/schemas'
import type { GenericCreateSchedule } from '@/features/activationGroup/types'
import { ActivationScheduleType } from '@/features/activationGroup/types'
import { convertToWatts } from '@/features/resource/utils/convertToWatts'

type GenericCreateScheduleDialogFormProps = {
  activationGroupUuid: string
  isLoading: boolean
  onCancel: () => void
  onSubmit: (data: GenericCreateSchedule) => void
}

type EndAtDatePickerProps = {
  form: UseFormReturn<GenericCreateSchedule>
}

const EndAtDatePicker = ({ form }: EndAtDatePickerProps) => {
  const { t } = useTranslation()
  const startAtValue = useWatch({ name: 'startAt', control: form.control })

  return (
    <DesktopDateTimePickerController
      ampm={false}
      disabled={!startAtValue}
      label={t('activation_groups.create_schedule_form.end_at_label')}
      // The cast is necessary because the value is a DateTime while editing,
      // even though the submitted value is converted to an ISO string.
      minDateTime={(startAtValue as unknown as DateTime) ?? TODAY}
      name="endAt"
      slotProps={{
        textField: {
          id: 'schedule-end-at',
          required: true,
          inputProps: {
            'aria-label': t('activation_groups.create_schedule_form.end_at_label'),
          },
        },
      }}
      views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
    />
  )
}

/**
 * Contains all the logic related to the form to create a new activation group schedule.
 *
 * It is used within the CreateScheduleDialog component
 */
const GenericCreateScheduleDialogForm = ({
  activationGroupUuid,
  isLoading,
  onCancel,
  onSubmit,
}: GenericCreateScheduleDialogFormProps) => {
  const { t } = useTranslation()
  const form = useForm<GenericCreateSchedule>({
    defaultValues: {
      activationGroupUuid,
      includeAllResources: true,
    },
    resolver: zodResolver(getGenericCreateScheduleSchema(t)),
  })
  const { activationGroup } = useActivationGroupQuery({ activationGroupUuid })

  const scheduleType = form.watch('scheduleType')

  useEffect(() => {
    if (scheduleType === ActivationScheduleType.DEMO) {
      form.setValue('activationTriggerChannel', 0)
    } else if (scheduleType === ActivationScheduleType.PQ) {
      form.setValue('activationTriggerChannel', -1)
    }
  }, [scheduleType, form])

  if (!activationGroup) {
    return null
  }

  function handleSubmit(data: GenericCreateSchedule) {
    const dataWithConvertedWatts = {
      ...data,
      volume: convertToWatts(data.volume)!,
    }
    onSubmit(dataWithConvertedWatts)
  }

  return (
    <FormProvider {...form}>
      <form
        noValidate
        aria-label={t('activation_groups.create_schedule_form.title')}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <SelectFieldController
            required
            id="schedule-type"
            label={t('activation_groups.create_schedule_form.schedule_type_label')}
            name="scheduleType"
            options={[ActivationScheduleType.PQ, ActivationScheduleType.DEMO].map((scheduleType) => ({
              value: scheduleType,
              label: scheduleType,
              id: scheduleType,
            }))}
          />

          {/* TODO: BESS-490 Fetch the available activation trigger channels from the msa-prequalification-manager API*/}
          <SelectFieldController
            required
            disabled={scheduleType === ActivationScheduleType.DEMO}
            id="activation-trigger-channel"
            label={t('activation_groups.create_schedule_form.portfolio_channel_label')}
            name="activationTriggerChannel"
            options={(scheduleType === ActivationScheduleType.DEMO ? [0] : [1, 2, 3]).map((channel: number) => ({
              value: channel,
              label: activationGroup.portfolio.code + ' @ ' + channel,
              id: 'Channel-' + channel,
            }))}
          />

          <DesktopDateTimePickerController
            ampm={false}
            label={t('activation_groups.create_schedule_form.start_at_label')}
            minDate={TODAY}
            name="startAt"
            slotProps={{
              textField: {
                id: 'schedule-start-at',
                required: true,
                inputProps: {
                  'aria-label': t('activation_groups.create_schedule_form.start_at_label'),
                },
              },
            }}
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
          />

          <EndAtDatePicker form={form} />

          <TextFieldController
            required
            id="schedule-volume"
            label={t('activation_groups.create_schedule_form.volume_label')}
            name="volume"
            type="number"
          />

          <CheckboxController
            label={t('activation_groups.create_schedule_form.include_all_resources_label')}
            name="includeAllResources"
          />
        </DialogContent>

        <FormDialogActions isLoading={isLoading} onCancel={onCancel} />
      </form>
    </FormProvider>
  )
}

export default GenericCreateScheduleDialogForm
