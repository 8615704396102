import { Box } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import { renderDuration } from '@/features/site/components/availability/restriction/form/renderDuration'
import { formatLocalizedDateMed } from '@/features/site/components/availability/utils'
import type { RestrictionForm } from '@/features/site/types/restrictionForm'

interface RestrictionConfigurationInfoSectionProps {
  customerTimeZone: string
}

export const RestrictionConfigurationInfoSection = (props: RestrictionConfigurationInfoSectionProps) => {
  const { t, i18n } = useTranslation()
  const { getValues } = useFormContext<RestrictionForm>()
  const { startDate, endDate, startTime, endTime, type } = getValues()

  const hasCompleteAndValidDates =
    startDate &&
    endDate &&
    startTime &&
    endTime &&
    startDate.isValid &&
    endDate.isValid &&
    startTime.isValid &&
    endTime.isValid

  return (
    <Box
      sx={{
        bgcolor: grey[200],
        borderRadius: 3,
        p: 2,
      }}
      width={'100%'}
    >
      <CustomTypography paragraph variant={'h3'}>
        {`${t('customer_details.tabs.availability.common.configuration_section.intro')}:`}
      </CustomTypography>
      <CustomTypography component="div" variant={'h4'}>
        {hasCompleteAndValidDates
          ? t('customer_details.tabs.availability.common.configuration_section.unavailability.summary', {
              duration: renderDuration(type, endDate, endTime, startDate, startTime),
              start_date: formatLocalizedDateMed(props.customerTimeZone, i18n.language, startDate),
              end_date: formatLocalizedDateMed(props.customerTimeZone, i18n.language, endDate),
            })
          : t('customer_details.tabs.res_level_unavailability.common.form.dates_section.pending_summary')}
      </CustomTypography>
    </Box>
  )
}
