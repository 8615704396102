import { FormControl, Stack } from '@mui/material'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ZONED_TODAY } from '@/constants/dateTime'
import type { ServiceRuleForm } from '@/features/site/types/serviceRuleForm'

export const ServiceRuleDatesSection = ({ timezone }: { timezone: string }) => {
  const { t, i18n } = useTranslation()
  const { getValues, control, trigger } = useFormContext<ServiceRuleForm>()
  const { startDate, startTime, endDate, endTime } = getValues()

  useEffect(() => {
    trigger(['startDate', 'startTime', 'endDate', 'endTime'])
  }, [startDate, startTime, endDate, endTime])

  return (
    <Stack direction="row" spacing={2}>
      <Stack direction="column" spacing={2}>
        <FormControl>
          <Controller
            control={control}
            name="startDate"
            render={({ field, fieldState }) => (
              <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
                <DatePicker
                  label={t('customer_details.tabs.availability.common.form.dates_section.start_date')}
                  minDate={ZONED_TODAY(timezone)}
                  {...field}
                  slotProps={{
                    textField: {
                      error: !!fieldState.error,
                      helperText: fieldState.error?.message,
                    },
                  }}
                  views={['day', 'month', 'year']}
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
        <FormControl>
          <Controller
            control={control}
            name="startTime"
            render={({ field, fieldState }) => (
              <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
                <TimePicker
                  label={t('customer_details.tabs.availability.common.form.dates_section.start_time')}
                  {...field}
                  format="hh:mm a"
                  slotProps={{
                    textField: {
                      error: !!fieldState.error,
                      helperText: fieldState.error?.message,
                    },
                    field: {
                      readOnly: true,
                    },
                  }}
                  views={['hours']}
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
      </Stack>
      <Stack direction="column" spacing={2}>
        <FormControl>
          <Controller
            control={control}
            name="endDate"
            render={({ field, fieldState }) => (
              <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
                <DatePicker
                  label={t('customer_details.tabs.availability.common.form.dates_section.end_date')}
                  {...field}
                  minDate={ZONED_TODAY(timezone)}
                  slotProps={{
                    textField: {
                      error: !!fieldState.error,
                      helperText: fieldState.error?.message,
                    },
                  }}
                  views={['day', 'month', 'year']}
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
        <FormControl>
          <Controller
            control={control}
            name="endTime"
            render={({ field, fieldState }) => (
              <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
                <TimePicker
                  label={t('customer_details.tabs.availability.common.form.dates_section.end_time')}
                  {...field}
                  slotProps={{
                    textField: {
                      error: !!fieldState.error,
                      helperText: fieldState.error?.message,
                    },
                    field: {
                      readOnly: true,
                    },
                  }}
                  timeSteps={{
                    minutes: 59,
                  }}
                  views={['hours', 'minutes']}
                />
              </LocalizationProvider>
            )}
          />
        </FormControl>
      </Stack>
    </Stack>
  )
}
