import { DialogContent } from '@mui/material'
import { t } from 'i18next'

import CustomDialog from '@/components/feedback/CustomDialog'
import { RestrictionDetailsForm } from '@/features/site/components/availability/restriction/form/RestrictionDetailsForm'
import type { MarketProgram } from '@/features/site/types/marketProgram'

interface RestrictionDetailsCreateDialogProps {
  open: boolean
  onClose: () => void
  marketProgram: MarketProgram
  customerTimeZone: string
  customerLocation: string
  customerUuid: string
}

export const RestrictionDetailsCreateDialog = (props: RestrictionDetailsCreateDialogProps) => {
  return (
    <CustomDialog
      open={props.open}
      size="large"
      title={t('customer_details.tabs.availability.unavailability_form.create_dialog.title')}
      onClose={props.onClose}
    >
      <DialogContent>
        <RestrictionDetailsForm
          customerLocation={props.customerLocation}
          customerTimeZone={props.customerTimeZone}
          customerUuid={props.customerUuid}
          marketProgram={props.marketProgram}
          onClose={props.onClose}
        />
      </DialogContent>
    </CustomDialog>
  )
}
