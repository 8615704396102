import { EditOutlined } from '@mui/icons-material'
import { Box, Stack } from '@mui/material'
import type { ReactNode } from 'react'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { useSiteStepsContext } from '@/features/site/contexts/SiteStepsContext'
import type { SiteCreateStepId } from '@/features/site/types/siteCreateStep'

type ReviewSiteSectionProps = {
  title: string
  stepId: SiteCreateStepId
  children: ReactNode
}

function ReviewSiteSection({ children, title, stepId }: Readonly<ReviewSiteSectionProps>) {
  const [{ stepsToShow }, siteStepsDispatch] = useSiteStepsContext()

  function handleEditClick() {
    const stepIndex = stepsToShow.findIndex((step) => step.id == stepId)

    if (stepIndex >= 0) {
      siteStepsDispatch({
        type: 'moveToStep',
        payload: stepIndex,
      })
    }
  }

  return (
    <Stack sx={{ flexDirection: 'column', gap: 2 }}>
      <CustomTypography variant="h6">{title}</CustomTypography>

      <Stack
        sx={{ flexDirection: 'row', gap: 2, backgroundColor: 'grey.50', justifyContent: 'flex-start', padding: 2 }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          {children}
        </Box>

        <Box>
          <CustomIconButton Icon={EditOutlined} color="primary" size="small" onClick={handleEditClick} />
        </Box>
      </Stack>
    </Stack>
  )
}

export default ReviewSiteSection
