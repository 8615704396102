import axios from 'axios'

import environment from '@/environment'
import { CUSTOMERS_API_URL } from '@/features/site/endpoints/customers'
import type {
  CreateResourceLevelRestrictionRequest,
  CreateRestrictionRequest,
  EditResourceLevelRestrictionRequest,
  EditRestrictionRequest,
} from '@/features/site/types/restriction'
import type { ResourceLevelRestriction, Restriction } from '@/features/unavailability/types/restriction'

export const GET_RESTRICTIONS_API_ID = 'GET_RESTRICTIONS'

export async function getRestrictions(customerUuid: string, location: string | undefined): Promise<Restriction[]> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  const response = await axios.get(`${CUSTOMER_API_URL}/restrictions`, {
    params: {
      location,
    },
  })
  return response.data
}

export async function createRestriction(customerUuid: string, restriction: CreateRestrictionRequest): Promise<void> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  await axios.post(`${CUSTOMER_API_URL}/restrictions`, restriction)
}

export async function editRestriction(customerUuid: string, restriction: EditRestrictionRequest): Promise<void> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  await axios.put(`${CUSTOMER_API_URL}/restrictions/${restriction.id}`, restriction, {
    params: {
      location: restriction.location,
    },
  })
}

export async function deleteRestriction(
  customerUuid: string,
  restrictionId: number,
  location: string | undefined,
): Promise<Restriction> {
  const CUSTOMER_API_URL = `${CUSTOMERS_API_URL}/${customerUuid}`
  const response = await axios.delete<Restriction>(`${CUSTOMER_API_URL}/restrictions/${restrictionId}`, {
    params: {
      location,
    },
  })
  return response.data
}

// Resource level restrictions endpoints: handled by RES domain and are applied to whole resource and not single relay wire
const BASE_RESOURCES_API_URL = `${environment.services.resourcesManagerApiUrl}/resource-owners`

export async function getResourceLevelRestrictions(customerUuid: string): Promise<ResourceLevelRestriction[]> {
  const CUSTOMER_RESOURCE_API_URL = `${BASE_RESOURCES_API_URL}/${customerUuid}`
  const response = await axios.get(`${CUSTOMER_RESOURCE_API_URL}/restrictions`)
  return response.data
}

export async function createResourceLevelRestriction(
  customerUuid: string,
  restriction: CreateResourceLevelRestrictionRequest,
): Promise<void> {
  const CUSTOMER_RESOURCE_API_URL = `${BASE_RESOURCES_API_URL}/${customerUuid}`
  await axios.post(`${CUSTOMER_RESOURCE_API_URL}/restrictions`, restriction)
}

export async function editResourceLevelRestriction(
  customerUuid: string,
  restriction: EditResourceLevelRestrictionRequest,
): Promise<void> {
  const CUSTOMER_RESOURCE_API_URL = `${BASE_RESOURCES_API_URL}/${customerUuid}`
  await axios.put(`${CUSTOMER_RESOURCE_API_URL}/restrictions/${restriction.id}`, restriction)
}

export async function deleteResourceLevelRestriction(customerUuid: string, restrictionId: number): Promise<void> {
  const CUSTOMER_RESOURCE_API_URL = `${BASE_RESOURCES_API_URL}/${customerUuid}`
  await axios.delete(`${CUSTOMER_RESOURCE_API_URL}/restrictions/${restrictionId}`)
}
