import type { SxProps } from '@mui/material'
import { Stack } from '@mui/material'
import type { ReactNode } from 'react'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import EllipsisTypography from '@/components/dataDisplay/EllipsisTypography'
import { SITE_REVIEW_BLOCK_MAX_WIDTH } from '@/features/site/constants'

type ReviewInformationBlockProps = {
  name: string
  value?: string | ReactNode
  sx?: SxProps
}

function ReviewInformationBlock({ name, value, sx }: Readonly<ReviewInformationBlockProps>) {
  return (
    <Stack
      sx={{
        flexDirection: 'column',
        flexGrow: 1,
        maxWidth: SITE_REVIEW_BLOCK_MAX_WIDTH,
        ...sx,
      }}
    >
      <CustomTypography variant="caption">{name}</CustomTypography>

      {typeof value === 'string' ? (
        <EllipsisTypography tooltipTitle={value} variant="body1">
          {value}
        </EllipsisTypography>
      ) : (
        value
      )}
    </Stack>
  )
}

export default ReviewInformationBlock
