import { Close } from '@mui/icons-material'
import { Box, DialogActions, DialogContent, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import LabeledTextField from '@/components/dataDisplay/LabeledTextField'
import CustomDialog from '@/components/feedback/CustomDialog'
import CustomButton from '@/components/inputs/CustomButton'
import { AvailabilityStatusChip } from '@/features/site/components/availability/datagrid/AvailabilityStatusChip'
import { ResourcesMinViewDataGrid } from '@/features/site/components/availability/datagrid/resourceLevelUnavailaibility/ResourcesMinViewDataGrid'
import { formatLocalizedDateMed } from '@/features/site/components/availability/utils'
import type { ResourceLevelRestriction } from '@/features/unavailability/types/restriction'

interface ResourceLevelRestrictionDetailsDialogProps {
  open: boolean
  onClose: (value: string) => void
  restriction: ResourceLevelRestriction
  customerTimeZone: string
}

const LabeledStatusChipField: FC<{ label; status }> = ({ label, status }) => (
  <Box
    sx={{
      bgcolor: grey[50],
      borderRadius: 1,
      p: 2,
    }}
    width={'100%'}
  >
    <CustomTypography color={'#9e9e9e'} variant="caption" width={100}>
      {`${label}`}
    </CustomTypography>
    <br />
    <AvailabilityStatusChip size="small" status={status} />
  </Box>
)

export const ResourceLevelRestrictionDetailsDialog = (props: ResourceLevelRestrictionDetailsDialogProps) => {
  const { t, i18n } = useTranslation()
  const { open, onClose, restriction } = props

  return (
    <CustomDialog
      open={open}
      size="medium"
      title={t('customer_details.tabs.availability.unavailability_data_grid.dialog.title')}
      onClose={onClose}
    >
      <DialogContent>
        <Stack direction="row" spacing={1}>
          <LabeledTextField
            label={t('customer_details.tabs.availability.unavailable_from')}
            text={formatLocalizedDateMed(props.customerTimeZone, i18n.language, restriction.startDate)}
            variant={'filled'}
          />
          <LabeledTextField
            label={t('customer_details.tabs.availability.unavailable_to')}
            text={formatLocalizedDateMed(props.customerTimeZone, i18n.language, restriction.endDate)}
            variant={'filled'}
          />
          <LabeledTextField
            label={t('customer_details.tabs.availability.start_end_time')}
            text={`${restriction.startTime} - ${restriction.endTime}`}
            variant={'filled'}
          />
          <LabeledTextField
            label={t('customer_details.tabs.availability.recurrence')}
            text={
              restriction.type === 'repeating-daily'
                ? t('customer_details.tabs.availability.unavailability_data_grid.cells.recurrence.daily')
                : '—'
            }
            variant={'filled'}
          />
          <LabeledStatusChipField label={t('common.status')} status={restriction.status} />
        </Stack>
        <ResourcesMinViewDataGrid resources={restriction?.resources ?? []} />
      </DialogContent>

      <DialogActions>
        <CustomButton
          color="primary"
          fullWidth={false}
          startIcon={<Close />}
          type="reset"
          variant="outlined"
          onClick={onClose}
        >
          {t('common.button.close')}
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  )
}
