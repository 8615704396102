import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { GET_INSIGHT_REVENUES_API_ID, getInsightRevenuesPeriods } from '@/features/site/endpoints/revenues'
import type { InsightRevenuePeriod, InsightRevenuePeriodsParams } from '@/features/site/types/revenue'
import type { UseQueryOptions } from '@/types/queries'

export type UseInsightRevenuePeriodQueryParamsResult = Omit<UseQueryResult, 'data'> & {
  revenuePeriods: InsightRevenuePeriod[]
}

export function useInsightRevenuePeriodsQuery(
  params: InsightRevenuePeriodsParams,
  options?: UseQueryOptions<InsightRevenuePeriod[]>,
): UseInsightRevenuePeriodQueryParamsResult {
  const { data = [], ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_INSIGHT_REVENUES_API_ID, params],
    queryFn: () => getInsightRevenuesPeriods(params),
    enabled: !!params.siteUuid && !!params.timezone && (options?.enabled ?? true),
  })

  return { revenuePeriods: data, ...queryResult }
}
