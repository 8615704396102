import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { useAppBarContent } from '@/components/layouts/Page'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { ActivationDetailsToolbar } from '@/features/activation/components/ActivationDetailsToolbar'
import { CUSTOMER_ACTIVATION_DETAILS_COLUMNS } from '@/features/activation/constants/customerActivationDetailsColumns'
import type { ActivatedResource } from '@/features/activation/types/activatedResource'
import {
  convertAmActivatedResourcesToStandard,
  convertMagActivatedResourcesToStandard,
  convertPresentActivationToStandard,
} from '@/features/activation/utils/converters'
import { useActivatedResourcesFromAmQuery } from '@/features/site/hooks/useActivatedResourcesFromAmQuery'
import { useActivatedResourcesFromMagQuery } from '@/features/site/hooks/useActivatedResourcesFromMagQuery'
import { useActivationFromAmQuery } from '@/features/site/hooks/useActivationFromAmQuery'
import { useActivationFromMagQuery } from '@/features/site/hooks/useActivationFromMagQuery'
import type { Site } from '@/features/site/types/site'

type SiteDetailsActivationDetailsProps = {
  site: Site
  activationId: string
}

function isNumeric(activationId: any) {
  return !isNaN(Number(activationId))
}

function getMarketPrograms(site?: Site) {
  return new Map(
    site?.marketProgramContracts?.map((contract) => [contract?.marketProgram?.id, contract?.marketProgram?.type]),
  )
}

export const SiteActivationDetailsView = ({ site, activationId }: SiteDetailsActivationDetailsProps) => {
  const { t } = useTranslation()
  const { setAppBarContent } = useAppBarContent()

  useEffect(() => {
    setAppBarContent(null)
  }, [])

  const { magActivation } = useActivationFromMagQuery(
    {
      customerUuid: site.uuid!,
      activationId: Number(activationId),
      location: site.location,
    },
    { enabled: isNumeric(activationId) },
  )

  const { amActivation } = useActivationFromAmQuery(
    {
      activationId: activationId!,
    },
    { enabled: !isNumeric(activationId) },
  )

  const activation = convertPresentActivationToStandard(
    magActivation,
    amActivation,
    getMarketPrograms(site ?? undefined),
  )

  const { magActivatedResources, isFetching: isFetchingMagActivatedResources } = useActivatedResourcesFromMagQuery(
    {
      location: site.location,
      activationId: magActivation?.id,
      serviceId: magActivation?.serviceId,
      customerUuid: site.uuid,
    },
    { enabled: !!magActivation },
  )
  const { amActivatedResources, isFetching: isFetchingAmActivatedResources } = useActivatedResourcesFromAmQuery(
    {
      siteId: site.uuid,
      activationId: activationId!,
    },
    { enabled: !!amActivation },
  )

  const activatedResources = magActivatedResources
    ? convertMagActivatedResourcesToStandard(magActivatedResources)
    : convertAmActivatedResourcesToStandard(amActivatedResources ?? [])

  if (!site || (!magActivation && !amActivation)) return null

  return (
    <CustomDataGrid
      columns={CUSTOMER_ACTIVATION_DETAILS_COLUMNS(t, site.timezone)}
      getRowId={(row: ActivatedResource) => `${row.resourceId}-${row.activatedAt}-${row.deactivatedAt}`}
      initialState={{
        pagination: {
          paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
        },
        sorting: {
          sortModel: [],
        },
      }}
      isLoading={isFetchingMagActivatedResources || isFetchingAmActivatedResources}
      rows={activatedResources ?? []}
      slotProps={{
        toolbar: {
          activation: activation,
          customerTimeZone: site.timezone,
        },
      }}
      slots={{
        toolbar: ActivationDetailsToolbar,
      }}
    />
  )
}
