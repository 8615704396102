import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { AmActivation } from '@/features/activation/types/activation'
import { GET_AM_ACTIVATION_API_ID, getActivation } from '@/features/site/endpoints/amActivations'
import type { UseQueryOptions } from '@/types/queries'

type ActivationFromAmQueryParams = {
  activationId: string
}
export type UseActivationFromAmQueryResult = Omit<UseQueryResult, 'data'> & {
  amActivation: AmActivation | null
}

export function useActivationFromAmQuery(
  params: ActivationFromAmQueryParams,
  options?: UseQueryOptions<AmActivation>,
): UseActivationFromAmQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_AM_ACTIVATION_API_ID, params],
    queryFn: () => getActivation(params.activationId),
  })

  return { amActivation: data, ...queryResult }
}
