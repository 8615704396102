import type { TFunction } from 'i18next'
import { z } from 'zod'

import { dateTimeSchema } from '@/utils/dateTimeSchema'
import { isBeforeOrEqual } from '@/utils/time'

type RestrictionForm = z.infer<ReturnType<typeof getRestrictionFormSchema>>

const getRestrictionFormSchema = (t: TFunction) => {
  return z
    .object({
      id: z.number().optional(),
      type: z.enum(['non-repeating', 'repeating-daily']),
      startDate: dateTimeSchema,
      endDate: dateTimeSchema,
      startTime: dateTimeSchema,
      endTime: dateTimeSchema,
      location: z.string(),
      selectedResources: z.array(z.number()),
      allDay: z.boolean(),
      status: z.enum(['waiting', 'active', 'deactivated', 'expired']).optional(),
    })
    .refine(
      (schema) => {
        return isBeforeOrEqual(schema.startDate, schema.endDate, 'days')
      },
      {
        message: t('customer_details.tabs.availability.common.form.error.end_date_before_start_date'),
        path: ['endDate'],
      },
    )
    .refine(
      (schema) => {
        if (schema.type === 'repeating-daily') {
          return schema.endTime > schema.startTime
        }

        if (schema.type === 'non-repeating' && schema.endDate.toMillis() === schema.startDate.toMillis()) {
          return schema.endTime > schema.startTime
        }

        return true
      },
      {
        message: t('customer_details.tabs.availability.common.form.error.end_time_before_start_time'),
        path: ['endTime'],
      },
    )
}

// Resource level restriction form schema: duplicate right now, but we want to be flexible in the future

type ResourceLevelRestrictionForm = z.infer<ReturnType<typeof getResourceLevelRestrictionFormSchema>>

const getResourceLevelRestrictionFormSchema = (t: TFunction) => {
  return z
    .object({
      id: z.number().optional(),
      type: z.enum(['non-repeating', 'repeating-daily']),
      startDate: dateTimeSchema,
      endDate: dateTimeSchema,
      startTime: dateTimeSchema,
      endTime: dateTimeSchema,
      location: z.string(),
      allDay: z.boolean(),
      affectedResources: z.array(z.string()).min(1, {
        message: t('customer_details.tabs.res_level_unavailability.common.form.error.resources_required'),
      }),
      status: z.enum(['waiting', 'active', 'deactivated', 'expired']).optional(),
      enabled: z.boolean(),
    })
    .refine(
      (schema) => {
        return isBeforeOrEqual(schema.startDate, schema.endDate, 'days')
      },
      {
        message: t('customer_details.tabs.availability.common.form.error.end_date_before_start_date'),
        path: ['endDate'],
      },
    )
    .refine(
      (schema) => {
        if (schema.type === 'repeating-daily') {
          return schema.endTime > schema.startTime
        }

        if (schema.type === 'non-repeating' && schema.endDate.toMillis() === schema.startDate.toMillis()) {
          return schema.endTime > schema.startTime
        }

        return true
      },
      {
        message: t('customer_details.tabs.availability.common.form.error.end_time_before_start_time'),
        path: ['endTime'],
      },
    )
}

export {
  getResourceLevelRestrictionFormSchema,
  getRestrictionFormSchema,
  ResourceLevelRestrictionForm,
  RestrictionForm,
}
