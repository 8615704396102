import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import PageHeader from '@/components/layouts/PageHeader'
import Shell from '@/components/layouts/Shell'
import ActivationRoutes from '@/features/activation/ActivationRoutes'
import ActivationGroupRoutes from '@/features/activationGroup/ActivationGroupRoutes'
import { ProtectedRoute } from '@/features/authentication/components/ProtectedRoute'
import BessDashboardRoutes from '@/features/bessDashboard/BessDashboardRoutes'
import BiddingRoutes from '@/features/bidding/BiddingRoutes'
import DisturbancesRoutes from '@/features/disturbance/DisturbancesRoutes'
import ErrorRoutes from '@/features/error/ErrorsRoutes'
import NavigateToError from '@/features/error/pages/NavigateToError'
import ExampleFeatureRoutes from '@/features/exampleFeature/ExampleFeatureRoutes'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import IntegrationsRoutes from '@/features/integration/IntegrationsRoutes'
import MeasurementsRoutes from '@/features/measurement/MeasurementsRoutes'
import PartnerRoutes from '@/features/partner/PartnerRoutes'
import ReportRoutes from '@/features/report/ReportRoutes'
import ResourcesRoutes from '@/features/resource/ResourcesRoutes'
import RedirectToSites from '@/features/site/RedirectToSites'
import SitesRoutes from '@/features/site/SitesRoutes'
import { PrivacyPolicy } from '@/features/user/pages/PrivacyPolicy'
import { UserProfile } from '@/features/user/pages/UserProfile'
import UsersRoutes from '@/features/user/UsersRoutes'
import WholesaleRoutes from '@/features/wholesale/WholesaleRoutes'
import { LandingPage } from '@/LandingPage'

export default function AuthenticatedAppRoutes() {
  const { t } = useTranslation()
  const { isEnabled } = useFeatureToggle()

  return (
    <Routes>
      <Route element={<Shell />} path="/">
        <Route index element={<LandingPage />} />
        <Route
          element={
            <>
              <PageHeader pageTag={'privacy-policy'} pageTitle={t('component.page_header.privacy_policy')} />
              <PrivacyPolicy />
            </>
          }
          path="/privacy-policy"
        />
        <Route
          element={
            <>
              <PageHeader pageTag={'user-profile'} pageTitle={t('component.page_header.user.user_profile')} />
              <UserProfile />
            </>
          }
          path="/profile"
        />

        {isEnabled('FLEXPORTAL_BIDDING_PAGE_ENABLED') && (
          <Route element={<ProtectedRoute permission="bidding" />}>
            <Route element={<BiddingRoutes />} path="bidding/*" />
          </Route>
        )}

        {isEnabled('FLEXPORTAL_WHOLESALE_PAGE_ENABLED') && (
          <Route element={<ProtectedRoute permission="wholesale" />}>
            <Route element={<WholesaleRoutes />} path="wholesale/*" />
          </Route>
        )}

        <Route element={<ProtectedRoute permission="reports" />}>
          <Route element={<ReportRoutes />} path="reports/*" />
        </Route>

        <Route element={<MeasurementsRoutes />} path="measurements/*" />

        {isEnabled('FLEXPORTAL_RESOURCES_PAGE_ENABLED') && (
          <Route element={<ProtectedRoute permission="resources" />}>
            <Route element={<ResourcesRoutes />} path="resources/*" />
          </Route>
        )}

        <Route element={<SitesRoutes />} path="sites/*" />
        <Route element={<RedirectToSites />} path="customers/*" />

        <Route element={<ProtectedRoute permission="partners" />}>
          <Route element={<PartnerRoutes />} path="partners/*" />
        </Route>

        <Route element={<ActivationRoutes />} path="activations/*" />

        <Route element={<ProtectedRoute permission="users" />}>
          <Route element={<UsersRoutes />} path="users/*" />
        </Route>

        {isEnabled('FLEXPORTAL_ACTIVATION_GROUPS_PAGE_ENABLED') && (
          <Route element={<ProtectedRoute permission="activationGroups" />}>
            <Route element={<ActivationGroupRoutes />} path="activation-groups/*" />
          </Route>
        )}

        {isEnabled('FLEXPORTAL_DISTURBANCES_PAGE_ENABLED') && (
          <Route element={<ProtectedRoute permission="disturbances" />}>
            <Route element={<DisturbancesRoutes />} path="disturbances/*" />
          </Route>
        )}

        {isEnabled('FLEXPORTAL_INTEGRATIONS_PAGE_ENABLED') && (
          <Route element={<ProtectedRoute permission="integrations" />}>
            <Route element={<IntegrationsRoutes />} path="integrations/*" />
          </Route>
        )}

        {isEnabled('FLEXPORTAL_EXAMPLE_FEATURE_ENABLED') && (
          <Route element={<ProtectedRoute permission="exampleFeatures" />}>
            <Route element={<ExampleFeatureRoutes />} path="example-feature/*" />
          </Route>
        )}

        {isEnabled('FLEXPORTAL_BESS_DASHBOARD_ENABLED') && (
          <Route element={<ProtectedRoute permission="bessDashboard" />}>
            <Route element={<BessDashboardRoutes />} path="bess-dashboard/*" />
          </Route>
        )}
      </Route>

      <Route element={<ErrorRoutes />} path="/errors/*" />

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}
