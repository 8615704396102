import { USER_ROLES } from '@/constants/userRoles'
import environment from '@/environment'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { usePartnersQuery } from '@/features/partner/hooks/usePartnersQuery'
import type { Partner } from '@/openapi/v1/msa-customer-partner-manager/partner'

function getSinglePartner(partnersArray?: Partner[]): Partner | undefined {
  return partnersArray ? partnersArray[0] : undefined
}

export function useLocationForDashboardData(): string {
  const { loggedInUser } = useAuth()
  const { partners } = usePartnersQuery({
    enabled: !!loggedInUser?.partnerCode && loggedInUser?.role !== USER_ROLES.RESOURCE_OWNERS.value,
  })

  if (loggedInUser?.role === USER_ROLES.PARTNER_ADMINISTRATORS.value) {
    return (
      environment
        .getLocationsList()
        .find((locationInfo) => locationInfo.country === getSinglePartner(partners)?.countryCode)?.location ?? ''
    )
  }
  return ''
}
