import './bidsOverviewDataGrid.css'

import { Box, Stack } from '@mui/material'
import type { GridColDef, GridFilterModel, GridPaginationModel, GridRowParams } from '@mui/x-data-grid'
import type { GridRenderCellParams } from '@mui/x-data-grid-pro'
import type { TFunction } from 'i18next'
import type { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import type { ActivationGroup } from '@/features/activationGroup/types'
import BidsMenu from '@/features/bidding/components/overview/bidsMenu/BidsMenu'
import BidsOverviewFilterToolbar from '@/features/bidding/components/overview/BidsOverviewFilterToolbar'
import BidStatusesChip from '@/features/bidding/components/overview/BidStatusesChip'
import EditBid from '@/features/bidding/components/overview/EditBid'
import type { MarketProgram } from '@/features/bidding/constants'
import { BidType, Status } from '@/features/bidding/constants'
import { GET_BIDS_API_STALE_TIME } from '@/features/bidding/endpoints/bids'
import { useBidOverviewPageQuery } from '@/features/bidding/hooks/useBidOverviewPageQuery'
import type { Portfolio } from '@/features/bidding/types/bid'
import type { BidOverviewGroup } from '@/features/bidding/types/bidOverview'
import { isManuallyAcceptable, isManuallyEditable } from '@/features/bidding/utils/bidStatus'
import { convertToRoundedMw } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import { buildBidViewLink } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'
import { buildBidOverviewRowItem } from '@/features/bidding/utils/model/buildBidOverviewRowItem'

type BidsOverviewDataGridProps = {
  portfolios: Portfolio[] | null
  activationGroups: ActivationGroup[] | null
  selectedCountry: CountryIdentifier
  filterModel: GridFilterModel
  paginationModel: GridPaginationModel
  setPaginationModel: (paginationModel: GridPaginationModel) => void
  setFilterModel: (filterModel: GridFilterModel) => void
}

export type BidOverviewRowItem = {
  portfolio: Portfolio
  marketProgram: MarketProgram
  activationGroupCodes: string[]
  deliveryDay: MarketDate
  createdAt: DateTime
  capacityBid: {
    uniqueStatuses: Status[]
    totalOfferedVolume: number | null
    totalAcceptedVolume: number | null
    unit: string
  }
  energyBid: {
    uniqueStatuses: Status[]
    totalOfferedVolume: number | null
    totalAcceptedVolume: number | null
    unit: string
  }
}

const BidsOverviewDataGrid = ({
  portfolios,
  activationGroups,
  selectedCountry,
  paginationModel,
  setPaginationModel,
  filterModel,
  setFilterModel,
}: Readonly<BidsOverviewDataGridProps>) => {
  const { t } = useTranslation()
  const { bidOverviewPage, isLoading } = useBidOverviewPageQuery(
    { country: selectedCountry, filter: filterModel, pagination: paginationModel },
    { staleTime: GET_BIDS_API_STALE_TIME },
  )

  const totalCount = bidOverviewPage?.totalCount
  const groupedBidOverviews = bidOverviewPage?.groupedBids

  const hasBids = useMemo(() => {
    if (!groupedBidOverviews || groupedBidOverviews.length === 0 || isLoading) {
      return { energy: false, capacity: false }
    }
    return {
      energy: groupedBidOverviews.some((group: BidOverviewGroup) => Object.keys(group.energyBids).length > 0),
      capacity: groupedBidOverviews.some((group: BidOverviewGroup) => Object.keys(group.capacityBids).length > 0),
    }
  }, [groupedBidOverviews, selectedCountry, isLoading])

  const hasEnergyBids = hasBids.energy
  const hasCapacityBids = hasBids.capacity

  return (
    <CustomDataGrid
      disableColumnSorting
      disableDefaultHeaderBackground
      autosizeOptions={{
        columns: [
          'deliveryDay',
          'createdAt',
          'portfolio',
          'marketProgram',
          ...(hasCapacityBids ? ['capacityBidTotalOfferedVolume', 'capacityBidStatus'] : []),
          ...(hasEnergyBids ? ['energyBidTotalOfferedVolume', 'energyBidStatus'] : []),
        ],
        includeOutliers: true,
        includeHeaders: false,
        expand: true,
      }}
      clickableRows={{
        navigateTo: (params: GridRowParams<BidOverviewRowItem>) =>
          buildBidViewLink({
            deliveryDay: params.row.deliveryDay,
            portfolioCode: params.row.portfolio.code,
            marketProgram: params.row.marketProgram,
            bidType: getBidTypeParam(params.row),
          }),
      }}
      columnGroupingModel={getColumnGrouping(t)}
      columns={getColumns(t, hasBids)}
      data-testid="bid-overview-grid"
      filterMode="server"
      filterModel={filterModel}
      getRowId={(row: BidOverviewRowItem) => row.deliveryDay.toISODate() + row.portfolio.code + row.marketProgram}
      isLoading={isLoading}
      paginationMode="server"
      paginationModel={paginationModel}
      rowCount={totalCount ?? 0}
      rows={
        groupedBidOverviews && activationGroups
          ? buildRowItems(groupedBidOverviews, activationGroups, selectedCountry)
          : []
      }
      slotProps={{
        toolbar: {
          portfolios,
        },
      }}
      slots={{ toolbar: BidsOverviewFilterToolbar }}
      onFilterModelChange={setFilterModel}
      onPaginationModelChange={setPaginationModel}
    />
  )
}

const getBidTypeParam = (row: BidOverviewRowItem): BidType => {
  const capacityBidStatuses = row.capacityBid.uniqueStatuses
  const hasCapacityBid = !(capacityBidStatuses.length === 1 && capacityBidStatuses[0] === Status.NO_BID)

  const energyBidStatuses = row.energyBid.uniqueStatuses
  const hasEnergyBid = !(energyBidStatuses.length === 1 && energyBidStatuses[0] === Status.NO_BID)

  if (hasCapacityBid) {
    return BidType.CAPACITY
  }

  if (hasEnergyBid) {
    return BidType.ENERGY
  }

  return BidType.CAPACITY
}

const buildRowItems = (
  groupedBids: BidOverviewGroup[],
  activationGroups: ActivationGroup[],
  selectedCountry: CountryIdentifier,
) =>
  groupedBids
    .map((bidOverviewGroup) => buildBidOverviewRowItem(bidOverviewGroup, activationGroups, selectedCountry))
    .filter((rowItem) => !!rowItem)

const getColumns = (
  t: TFunction,
  hasBids: { energy: boolean; capacity: boolean },
): GridColDef<BidOverviewRowItem>[] => [
  {
    field: 'deliveryDay',
    headerName: t('bidding.overview.header.delivery_day'),
    valueFormatter: (value: MarketDate) => value.getStartOfDay().toFormat('dd LLL, yyyy'),
    flex: 2,
    headerClassName: 'default-header',
  },
  {
    field: 'createdAt',
    headerName: t('bidding.overview.header.created_at'),
    valueFormatter: (value: DateTime) => value.toFormat('dd LLL, yyyy'),
    flex: 2,
    headerClassName: 'default-header',
  },
  {
    field: 'portfolio',
    headerName: t('bidding.overview.header.portfolio'),
    valueFormatter: (value: Portfolio) => value.name,
    flex: 3,
    headerClassName: 'default-header',
  },
  {
    field: 'marketProgram',
    headerName: t('bidding.overview.header.market_program'),
    flex: 3,
    valueFormatter: (value: BidOverviewRowItem['marketProgram']) => t(`bidding.market_program.${value}`),
    headerClassName: 'default-header',
  },
  {
    field: 'activationGroupCodes',
    type: 'singleSelect',
    headerName: t('bidding.overview.header.activation_groups'),
    flex: 0,
    width: 50,
    renderCell: (params: GridRenderCellParams<BidOverviewRowItem>) => params.row.activationGroupCodes.length,
    headerClassName: 'default-header',
  },
  ...(hasBids.capacity ? getCapacityBidColumns(t) : []),
  ...(hasBids.energy ? getEnergyBidColumns(t) : []),
]

const getCapacityBidColumns = (t: TFunction) => [
  {
    field: 'capacityBidTotalOfferedVolume',
    headerName: t('bidding.overview.header.offered'),
    flex: 2,
    valueGetter: (_, params: BidOverviewRowItem) => params.capacityBid,
    valueFormatter: (value: { uniqueStatuses: Status[]; totalOfferedVolume: number; unit: string }) =>
      isNoBid(value.uniqueStatuses) ? '' : getVolumeFormatted(value.totalOfferedVolume, value.unit),
    headerClassName: 'capacity-bid-column',
    cellClassName: 'capacity-bid-column',
  },
  {
    field: 'capacityBidStatus',
    headerName: t('bidding.overview.header.status'),
    minWidth: 140,
    valueGetter: (_, params: BidOverviewRowItem) => params.capacityBid.uniqueStatuses,
    renderCell: (params: GridRenderCellParams<BidOverviewRowItem>) =>
      renderStatusColumn({
        uniqueStatuses: params.row.capacityBid.uniqueStatuses,
        totalAcceptedVolume: params.row.capacityBid.totalAcceptedVolume,
        unit: params.row.capacityBid.unit,
      }),
    cellClassName: 'capacity-bid-column',
    headerClassName: 'capacity-bid-column',
  },
  {
    field: 'capacityBidActions',
    headerName: '',
    disableColumnMenu: true,
    flex: 0,
    minWidth: 100,
    renderCell: (params: GridRenderCellParams<BidOverviewRowItem>) => (
      <GridActionsColumn bidType={BidType.CAPACITY} params={params} />
    ),
    cellClassName: 'capacity-bid-actions-column',
    headerClassName: 'capacity-bid-actions-column',
  },
]

const getEnergyBidColumns = (t: TFunction) => [
  {
    field: 'energyBidTotalOfferedVolume',
    headerName: t('bidding.overview.header.offered'),
    flex: 2,
    valueGetter: (_, params: BidOverviewRowItem) => params.energyBid,
    valueFormatter: (value: { uniqueStatuses: Status[]; totalOfferedVolume: number; unit: string }) =>
      isNoBid(value.uniqueStatuses) ? '' : getVolumeFormatted(value.totalOfferedVolume, value.unit),
    headerClassName: 'energy-bid-column',
    cellClassName: 'energy-bid-column',
  },
  {
    field: 'energyBidStatus',
    headerName: t('bidding.overview.header.status'),
    minWidth: 140,
    valueGetter: (_, params: BidOverviewRowItem) => params.energyBid.uniqueStatuses,
    renderCell: (params: GridRenderCellParams<BidOverviewRowItem>) =>
      renderStatusColumn({
        uniqueStatuses: params.row.energyBid.uniqueStatuses,
        totalAcceptedVolume: params.row.energyBid.totalAcceptedVolume,
        unit: params.row.energyBid.unit,
      }),
    cellClassName: 'energy-bid-column',
    headerClassName: 'energy-bid-column',
  },
  {
    field: 'energyBidActions',
    headerName: '',
    disableColumnMenu: true,
    flex: 0,
    minWidth: 100,
    renderCell: (params: GridRenderCellParams<BidOverviewRowItem>) => (
      <GridActionsColumn bidType={BidType.ENERGY} params={params} />
    ),
    cellClassName: 'energy-bid-column',
    headerClassName: 'energy-bid-column',
  },
]

const getColumnGrouping = (t: TFunction) => [
  {
    groupId: t('bidding.overview.header.capacity'),
    children: [
      { field: 'capacityBidTotalOfferedVolume' },
      { field: 'capacityBidStatus' },
      { field: 'capacityBidActions' },
    ],
    headerClassName: 'capacity-bid-actions-column',
  },
  {
    groupId: t('bidding.overview.header.energy'),
    children: [{ field: 'energyBidTotalOfferedVolume' }, { field: 'energyBidStatus' }, { field: 'energyBidActions' }],
    headerClassName: 'energy-bid-column',
  },
]

const renderStatusColumn = ({
  uniqueStatuses,
  totalAcceptedVolume,
  unit,
}: {
  uniqueStatuses: Status[]
  totalAcceptedVolume: number | null
  unit: string
}) => <BidStatusesChip acceptedVolume={getVolumeFormatted(totalAcceptedVolume, unit)} statuses={uniqueStatuses} />

const getVolumeFormatted = (volume: number | null, unit: string) =>
  volume != null ? convertToRoundedMw(volume) + ` ${unit}` : '-'

const GridActionsColumn = ({
  params,
  bidType,
}: {
  params: GridRenderCellParams<BidOverviewRowItem>
  bidType: BidType
}) => {
  const bidUniqueStatuses =
    bidType === BidType.CAPACITY ? params.row.capacityBid.uniqueStatuses : params.row.energyBid.uniqueStatuses
  const noBid = isNoBid(bidUniqueStatuses)

  return (
    <Stack flexDirection="row" justifyContent="space-between" width="100%">
      {!noBid && (
        <>
          <Box>
            <EditBid
              groupedBidParams={{
                deliveryDay: params.row.deliveryDay,
                portfolioCode: params.row.portfolio.code,
                marketProgram: params.row.marketProgram,
                bidType: bidType,
              }}
            />
          </Box>
          <Box>
            {isMenuVisible(bidUniqueStatuses, params.row.marketProgram) && (
              <BidsMenu
                groupedBidParams={{
                  deliveryDay: params.row.deliveryDay,
                  portfolioCode: params.row.portfolio.code,
                  marketProgram: params.row.marketProgram,
                  bidType: bidType,
                }}
                statuses={bidUniqueStatuses}
              />
            )}
          </Box>
        </>
      )}
    </Stack>
  )
}

const isNoBid = (bidUniqueStatuses: Status[]) => {
  return bidUniqueStatuses.length === 1 && bidUniqueStatuses.includes(Status.NO_BID)
}

const isMenuVisible = (uniqueStatuses: Status[], marketProgram: string) =>
  uniqueStatuses.some((status) => isManuallyAcceptable(status) || isManuallyEditable(status)) &&
  editableMarketPrograms.includes(marketProgram)

const editableMarketPrograms: string[] = [
  'NORDICS_FCRD_DOWN_STATIC',
  'NORDICS_FCRD_UP_STATIC',
  'NORDICS_FCRD_DOWN_DYNAMIC',
  'NORDICS_FCRD_UP_DYNAMIC',
  'FCRN',
  'NORDICS_FCRN',
  'FFR_UP',
  'MFRR_DOWN',
  'MFRR_UP',
]

export default BidsOverviewDataGrid
