import type { SelectFieldOption } from '@/components/inputs/CustomSelectField'
import type { Customer } from '@/features/site/types/customer'

export function getCustomerIdOptions(customers: Customer[]): SelectFieldOption<string>[] {
  return customers.map(({ uuid, name }) => ({
    id: uuid!,
    label: name!,
    value: uuid!,
  }))
}
