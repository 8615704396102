import type {
  ActivationGroup,
  ApiActivationGroupResource,
  ApiUpdateActivationGroup,
  ApiVolumeRangeUnit,
  AssignResourceToActivationGroup,
} from '@/features/activationGroup/types'

export function getActivationGroupByUuid(activationGroups: ActivationGroup[] | null, uuid: string | undefined) {
  return activationGroups && uuid ? activationGroups.find((activationGroup) => activationGroup.uuid === uuid) : null
}

/**
 * Serializes the given activation group resource and its associated data into an ApiUpdateActivationGroup object.
 *
 * @param assignedResource - The resource to be assigned to the activation group.
 * @param activationGroup - The activation group to which the resource is being assigned.
 * @param activationGroupResources - The existing resources of the activation group.
 * @returns An ApiUpdateActivationGroup object containing the serialized data.
 */
export function serializeAssignResourceToActivationGroup(
  assignedResource: AssignResourceToActivationGroup,
  activationGroup: ActivationGroup,
  activationGroupResources: ApiActivationGroupResource[],
): ApiUpdateActivationGroup {
  const updatedResources = activationGroupResources.map((resource) =>
    resource.uuid === assignedResource.resourceUuid
      ? {
          ...resource,
          qualifiedCapacity: assignedResource.qualifiedCapacity,
          activationPriority: assignedResource.activationPriority,
          isUsedForMinCapacityPrequalification: assignedResource.isUsedForMinCapacityPrequalification,
        }
      : resource,
  )

  // Check if the resource was updated; if not, add it as a new one
  const resourceExists = updatedResources.some((resource) => resource.uuid === assignedResource.resourceUuid)
  if (!resourceExists) {
    updatedResources.push({
      uuid: assignedResource.resourceUuid,
      qualifiedCapacity: assignedResource.qualifiedCapacity,
      activationPriority: assignedResource.activationPriority,
      isUsedForMinCapacityPrequalification: assignedResource.isUsedForMinCapacityPrequalification,
    } as ApiActivationGroupResource) // Cast to ApiActivationGroupResource to avoid type errors
  }

  return {
    code: activationGroup.code,
    biddableVolumeRange: activationGroup.biddableVolumeRange
      ? {
          unit: activationGroup.biddableVolumeRange.unit.toUpperCase() as ApiVolumeRangeUnit,
          min: activationGroup.biddableVolumeRange.min,
          max: activationGroup.biddableVolumeRange.max,
        }
      : null,
    state: activationGroup.state ?? 'LIVE',
    resources: updatedResources,
  }
}
