import { useTranslation } from 'react-i18next'

import Page from '@/components/layouts/Page'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { SiteRevenueViewForNW } from '@/features/site/components/revenue/SiteRevenueViewForNW'
import { useHasRevenueNWPermissions } from '@/features/site/hooks/useHasRevenueNWPermissions'
import { useInsightRevenuePeriodsQuery } from '@/features/site/hooks/useInsightRevenuePeriodsQuery'
import { useSiteQuery } from '@/features/site/hooks/useSiteQuery'
import { SiteRevenueViewOld } from '@/features/site/pages/components/SiteRevenueViewOld'

export const SiteRevenue = () => {
  const { t } = useTranslation()
  const { loggedInUser } = useAuth()
  const siteUuid = loggedInUser?.allowedRoIds?.[0] ?? ''

  const { site, isFetching: isFetchingSite } = useSiteQuery(
    { uuid: siteUuid },
    {
      enabled: Boolean(siteUuid),
    },
  )

  const hasRevenueNWPermissions = useHasRevenueNWPermissions({ site })

  const { revenuePeriods, isFetching: isFetchingRevenuePeriods } = useInsightRevenuePeriodsQuery(
    {
      siteUuid,
      timezone: site?.timezone,
    },
    {
      enabled: hasRevenueNWPermissions,
    },
  )

  if (!site || isFetchingSite) {
    return null
  }

  return (
    <Page
      isLoading={isFetchingSite || isFetchingRevenuePeriods}
      pageHeaderProps={{
        pageTitle: t('customer_details.tabs.revenue.title'),
        pageTag: 'customer-revenue',
      }}
    >
      {hasRevenueNWPermissions ? (
        <SiteRevenueViewForNW revenuePeriods={revenuePeriods} site={site} />
      ) : (
        <SiteRevenueViewOld site={site} />
      )}
    </Page>
  )
}
