import ActivationGroupCard from '@/features/activationGroup/components/ActivationGroupCard'
import { useActivationGroupDetails } from '@/features/activationGroup/pages/ActivationGroupDetails'

const ActivationGroupGeneralCard = () => {
  const { activationGroup } = useActivationGroupDetails()
  if (activationGroup === undefined || activationGroup === null) {
    return null
  }

  return <ActivationGroupCard />
}

export default ActivationGroupGeneralCard
