import type { FieldPath, FieldValues } from 'react-hook-form'

import CustomIMaskInput from '@/components/inputs/CustomIMaskInput'
import type { TextFieldControllerProps } from '@/components/inputs/TextFieldController'
import TextFieldController from '@/components/inputs/TextFieldController'

const IBAN_MASK = [
  {
    mask: 'aa00 **** **** **** **** **** **** **',
    startsWith: '',
  },
]

/**
 * Wrapper around TextFieldController that provides IBAN mask, which makes it easier to input IBAN,
 * as it generates an extra space after every 4 characters.
 */
export default function IbanFieldController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: TextFieldControllerProps<TFieldValues, TName>) {
  return (
    <TextFieldController
      {...props}
      slotProps={{
        input: {
          inputProps: {
            mask: IBAN_MASK,
          },
          inputComponent: CustomIMaskInput,
        },
      }}
    />
  )
}
