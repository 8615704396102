import type { TFunction } from 'i18next'
import { z } from 'zod'

const MAX_FILE_SIZE = 5242880

export const getTradeImportSchema = (t: TFunction) =>
  z.object({
    fileContent: z
      .any()
      .refine((file) => Boolean(file), t('wholesale.trade.import.form.file_required_error'))
      .refine((file) => file?.size <= MAX_FILE_SIZE, t('wholesale.trade.import.form.file_too_big_error'))
      .refine((file) => file?.type === 'text/csv', t('wholesale.trade.import.form.file_required_error')),
  })
