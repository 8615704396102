import { ClickAwayListener, Menu } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { GridTripleDotsVerticalIcon } from '@mui/x-data-grid-pro'
import type { MouseEvent } from 'react'
import React, { useId, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ManuallyAcceptOption from '@/features/bidding/components/overview/bidsMenu/options/ManuallyAcceptOption'
import ManuallyEditOption from '@/features/bidding/components/overview/bidsMenu/options/ManuallyEditOption'
import type { Status } from '@/features/bidding/constants'
import type { GroupedBidParams } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'

interface BidsMenuProps {
  groupedBidParams: GroupedBidParams
  statuses: Status[]
}

const BidsMenu = ({ groupedBidParams, statuses }: BidsMenuProps) => {
  const id = useId()
  const { t } = useTranslation()

  const [open, setOpen] = useState<boolean>(false)

  const handleOptionsClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setOpen(true)
  }

  const handleOptionsClose = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
    event.preventDefault()
    event.stopPropagation()
    setOpen(false)
  }

  return (
    <React.Fragment>
      <ClickAwayListener onClickAway={handleOptionsClose}>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          aria-label={t('bidding.options.more_actions')}
          data-testid="menu-button"
          title={t('bidding.options.more_actions')}
          onClick={handleOptionsClick}
        >
          <GridTripleDotsVerticalIcon id={id} />
        </IconButton>
      </ClickAwayListener>
      <Menu
        anchorEl={document.getElementById(id)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={open}
        onClose={handleOptionsClose}
      >
        <ManuallyAcceptOption groupedBidParams={groupedBidParams} statuses={statuses} />
        <ManuallyEditOption groupedBidParams={groupedBidParams} statuses={statuses} />
      </Menu>
    </React.Fragment>
  )
}

export default BidsMenu
