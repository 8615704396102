import type { TFunction } from 'i18next'

import type { SelectFieldOption } from '@/components/inputs/CustomSelectField'
import type { Customer } from '@/features/site/types/customer'

export function getCountryOptions(customers: Customer[], t: TFunction): SelectFieldOption<string>[] {
  const uniqueCountryCodes: CountryIdentifier[] = Array.from(
    new Set(customers.filter((c) => !!c.countryCode).map((c) => c.countryCode!.toLowerCase() as CountryIdentifier)),
  )

  return uniqueCountryCodes.map((countryCode) => ({
    id: countryCode,
    value: countryCode,
    label: t(`common.country.${countryCode}`),
  }))
}
