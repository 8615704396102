import type { GridPaginationModel } from '@mui/x-data-grid'
import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import {
  GET_ACTIVATION_GROUP_SCHEDULE_RESOURCES_API,
  getGenericActivationGroupScheduleResources,
} from '@/features/activationGroup/endpoints/activationGroups'
import type { GenericApiActivationGroupScheduleResourcePage } from '@/features/activationGroup/types'
import { useErrorHandler } from '@/features/bidding/hooks/useErrorHandler'
import type { UseQueryOptions } from '@/types/queries'

type UseGenericActivationGroupScheduleResourcesQueryParams = {
  activationGroupScheduleUuid: string
  pagination: GridPaginationModel
}

export type UseGenericActivationGroupScheduleResourcesQueryResult = Omit<UseQueryResult, 'data'> & {
  resources: GenericApiActivationGroupScheduleResourcePage | null
}
export function useGenericActivationGroupScheduleResourcesQuery(
  params: UseGenericActivationGroupScheduleResourcesQueryParams,
  options?: UseQueryOptions<GenericApiActivationGroupScheduleResourcePage>,
): UseGenericActivationGroupScheduleResourcesQueryResult {
  const { data = null, ...queryResult } = useQuery<GenericApiActivationGroupScheduleResourcePage>({
    ...options,
    queryFn: () => getGenericActivationGroupScheduleResources(params.activationGroupScheduleUuid, params.pagination),
    queryKey: [GET_ACTIVATION_GROUP_SCHEDULE_RESOURCES_API, params],
  })

  useErrorHandler(queryResult.isError)

  return {
    ...queryResult,
    resources: data,
  }
}

export function useInvalidateGenericActivationGroupScheduleResourcesQuery(
  params: UseGenericActivationGroupScheduleResourcesQueryParams,
) {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: [GET_ACTIVATION_GROUP_SCHEDULE_RESOURCES_API, params] })
}
