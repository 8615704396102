import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import EllipsisTypography from '@/components/dataDisplay/EllipsisTypography'
import { SITE_REVIEW_TABLE_CELL_STYLES } from '@/features/site/constants'
import type { CreateSiteForm } from '@/features/site/types/site'
import { formatValue } from '@/features/site/utils/review'

import ReviewSiteSection from './ReviewSiteSection'

type ContactsReviewSectionProps = {
  contacts: CreateSiteForm['contacts']
}

function ContactsReviewSection({ contacts }: Readonly<ContactsReviewSectionProps>) {
  const { t } = useTranslation()
  const contactLength = contacts?.length ?? 0

  return (
    <ReviewSiteSection stepId="contact_details" title={t('sites.add_new.steps.contact_details')}>
      {contactLength > 0 ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>{t('common.name')}</TableCell>
              <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>{t('common.email')}</TableCell>
              <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>{t('common.phone')}</TableCell>
              <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>{t('common.comment')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(contacts ?? []).map((contact, index) => (
              <TableRow key={index}>
                <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>{formatValue(contact.name)}</TableCell>
                <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>{formatValue(contact.email)}</TableCell>
                <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>{formatValue(contact.phone)}</TableCell>
                <TableCell sx={SITE_REVIEW_TABLE_CELL_STYLES}>
                  <EllipsisTypography tooltipTitle={contact.comment}>{formatValue(contact.comment)}</EllipsisTypography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Stack sx={{ flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
          <CustomTypography variant="body2">{t('sites.add_new.form.review.empty.contact')}</CustomTypography>
        </Stack>
      )}
    </ReviewSiteSection>
  )
}

export default ContactsReviewSection
