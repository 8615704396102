import { useQuery } from '@tanstack/react-query'

import { GET_CUSTOMERS_API_ID, getCustomers } from '@/features/site/endpoints/customers'
import type { Customer } from '@/features/site/types/customer'
import type { UseUserQueryResult } from '@/features/user/hooks/useUserQuery'
import type { UseQueryOptions } from '@/types/queries'

export type UseCustomersQueryResult = Omit<UseUserQueryResult, 'data'> & {
  customers?: Customer[]
}
export function useCustomersQuery(options?: UseQueryOptions<Customer[]>): UseCustomersQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_CUSTOMERS_API_ID],
    queryFn: () => getCustomers(),
  })

  return { customers: data, ...queryResult }
}
