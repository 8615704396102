import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { getAvailableAnalogResources } from '@/features/site/endpoints/availability'
import type { AvailableResource } from '@/features/site/types/availableResource'
import type { UseQueryOptions } from '@/types/queries'

export const GET_AVAILABLE_ANALOG_RESOURCES_API_ID = 'GET_AVAILABLE_ANALOG_RESOURCES'

type AvailableAnalogResourcesQueryParams = {
  customerUuid: string
  location: string
  marketProgramId?: number
  isUsed?: boolean
}

export type UseAvailableAnalogResourcesQueryResult = Omit<UseQueryResult, 'data'> & {
  availableAnalogResources: AvailableResource[] | null
}

export function useAvailableAnalogResourcesQuery(
  params: AvailableAnalogResourcesQueryParams,
  options?: UseQueryOptions<AvailableResource[]>,
): UseAvailableAnalogResourcesQueryResult {
  const queryFn = getAvailableAnalogResources(
    params.customerUuid,
    params.marketProgramId,
    params.isUsed,
    params.location,
  )
  const { data = null, ...queryResults } = useQuery({
    ...options,
    queryKey: [GET_AVAILABLE_ANALOG_RESOURCES_API_ID, params],
    queryFn: () => queryFn,
  })

  return {
    availableAnalogResources: data,
    ...queryResults,
  }
}
