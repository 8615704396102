import type { GridColDef, GridFilterModel, GridRenderEditCellParams } from '@mui/x-data-grid'
import type { GridRenderCellParams } from '@mui/x-data-grid-pro'
import type { TFunction } from 'i18next'
import type { DateTime } from 'luxon'
import type { FC } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import DataGridEditableTextField from '@/components/dataDisplay/DataGridEditableTextField'
import { WATTS_IN_MW } from '@/constants/units'
import AcceptBidVolumePtusDataGridToolbar from '@/features/bidding/components/manualAccept/AcceptBidVolumePtusDataGridToolbar'
import MinMaxBiddableVolumesTooltip from '@/features/bidding/components/MinMaxBiddableVolumesTooltip'
import { ManualAcceptFlow, MAX_PTU_ROWS_PER_DATAGRID } from '@/features/bidding/constants'
import type { BidMetaData, BulkFillMode, PtuVolumes } from '@/features/bidding/types/bid'
import { convertToRoundedMw, round } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import { isCapacityValid } from '@/features/bidding/utils/validation/isCapacityValid'

type Props = {
  ptus: PtuVolumes[]
  processRowUpdate: (newPtu: PtuVolumes, oldPtu: PtuVolumes) => Promise<PtuVolumes>
  bidMetaData: BidMetaData
  loading?: boolean
  onFillAll: (bulkMode: BulkFillMode, bulkFillValue: number | undefined) => void
  manualAcceptFlow: ManualAcceptFlow
}

const AcceptedBidVolumePtusDataGrid: FC<Props> = ({
  ptus,
  processRowUpdate,
  loading,
  bidMetaData,
  onFillAll,
  manualAcceptFlow,
}) => {
  const { t } = useTranslation()
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [{ field: 'deliveryDay', operator: 'is', value: bidMetaData.deliveryDay.getStartOfDay() }],
  })

  return (
    <CustomDataGrid
      hideFooter
      columns={getColumns(t, manualAcceptFlow)}
      filterMode="server"
      filterModel={filterModel}
      getRowId={(row) => row.ptu.start.toString()}
      initialState={{
        pagination: {
          paginationModel: { pageSize: MAX_PTU_ROWS_PER_DATAGRID, page: 0 },
        },
      }}
      isLoading={loading}
      processRowUpdate={processRowUpdate}
      rows={ptus ?? []}
      slotProps={{
        toolbar: { onFillAll: onFillAll, manualAcceptFlow: manualAcceptFlow },
      }}
      slots={{ toolbar: AcceptBidVolumePtusDataGridToolbar }}
      title={t('bidding.manual_accept.manual_bid', { version: bidMetaData.version })}
      titleExtraInfo={
        bidMetaData.activationGroupUuid ? (
          <MinMaxBiddableVolumesTooltip activationGroupUuid={bidMetaData.activationGroupUuid} />
        ) : undefined
      }
      onFilterModelChange={setFilterModel}
    />
  )
}

const getColumns = (t: TFunction<'translation', undefined>, manualAcceptFlow: ManualAcceptFlow): GridColDef[] => {
  const currentAcceptedColumn =
    manualAcceptFlow == ManualAcceptFlow.EDIT
      ? {
          field: 'ptu.currentAcceptedWatts',
          headerName: t('bidding.manual_accept.header.current_accepted'),
          valueFormatter: (value: number | null) => `${value ?? '-'} MW`,
          valueGetter: (_, volumes: PtuVolumes) =>
            volumes.currentAcceptedVolume ? convertToRoundedMw(volumes.currentAcceptedVolume.quantity) : null,
          flex: 1,
        }
      : null

  const items = [
    {
      field: 'ptu.start',
      headerName: t('bidding.manual_accept.header.ptu'),
      flex: 1,
      valueFormatter: (value: DateTime) => value.toFormat('HH:mm'),
      valueGetter: (_, volumes: PtuVolumes) => volumes.ptu.start,
    },
    {
      field: 'ptu.forecastedWatts',
      headerName: t('bidding.manual_accept.header.forecast'),
      valueFormatter: (value: number | null) => `${value ? convertToRoundedMw(value) : '-'} MW`,
      valueGetter: (_, volumes: PtuVolumes) => volumes.forecastedVolume?.quantity,
      flex: 1,
    },
    {
      field: 'ptu.offeredWatts',
      headerName: t('bidding.manual_accept.header.offer'),
      valueFormatter: (value: number) => `${value ?? '-'} MW`,
      valueGetter: (_, volumes: PtuVolumes) => convertToRoundedMw(volumes.offeredVolume.quantity),
      flex: 1,
    },
    currentAcceptedColumn,
    {
      field: 'ptu.acceptedWatts',
      headerName: t('bidding.manual_accept.header.new_accepted'),
      editable: true,
      valueFormatter: (value: number | null) => `${value ?? '-'} MW`,
      valueGetter: (_, volumes: PtuVolumes) =>
        volumes.acceptedVolume ? convertToRoundedMw(volumes.acceptedVolume.quantity) : null,
      valueSetter: (value: number | null, volumes: PtuVolumes) => ({
        ...volumes,
        acceptedVolume: value ? { quantity: round(value * WATTS_IN_MW), unit: 'WATTS' } : volumes.acceptedVolume,
      }),
      flex: 1,
      renderCell: (params: GridRenderCellParams<PtuVolumes>) => (
        <DataGridEditableTextField readonlyField value={params.formattedValue} />
      ),
      renderEditCell: (params: GridRenderEditCellParams<PtuVolumes>) => (
        <DataGridEditableTextField
          value={params.value}
          onBlur={() => params.api.stopCellEditMode({ id: params.id, field: params.field })}
          onChange={onAcceptedChange(params)}
        />
      ),
    },
  ]

  return items.filter((x) => x != null) as GridColDef[]
}

const onAcceptedChange = (params: GridRenderEditCellParams<PtuVolumes>) => (value: string | undefined) => {
  if (value && !isCapacityValid(value)) {
    return
  }

  params.api.setEditCellValue({ ...params, value })
}

export default AcceptedBidVolumePtusDataGrid
