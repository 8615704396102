import { Box, Typography } from '@mui/material'
import type { FC } from 'react'

import type { PtuChunk } from '@/features/bidding/types/bid'
import { convertToRoundedMw } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import { formatVolume } from '@/features/bidding/utils/formatVolume'

type Props = {
  chunk: PtuChunk
  unit: string
}

export const PtuChunkCell: FC<Props> = ({ chunk, unit }) => {
  if (!chunk) return <span>{'-'}</span>

  if (chunk.acceptedVolume == null) return <span>{formatVolume(chunk.offeredVolume.quantity, unit)}</span>

  return (
    <Box alignItems="center" display="flex" sx={{ width: '100%', height: '100%' }}>
      <Box display="flex">
        <Typography color="textPrimary" variant="body2">
          {convertToRoundedMw(chunk.acceptedVolume.quantity) + ' '}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          / {convertToRoundedMw(chunk.offeredVolume.quantity)} {unit}
        </Typography>
      </Box>
    </Box>
  )
}
