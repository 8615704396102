import { CircularProgress, DialogContent, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomDialog from '@/components/feedback/CustomDialog'
import { useAlertContext } from '@/contexts/AlertContext'
import UpdateActivationGroupDialogForm from '@/features/activationGroup/components/UpdateActivationGroupDialogForm'
import { useActivationGroupResourcesQuery } from '@/features/activationGroup/hooks/useActivationGroupResourcesQuery'
import { useInvalidateActivationGroupsQuery } from '@/features/activationGroup/hooks/useActivationGroupsQuery'
import { useUpdateActivationGroupMutation } from '@/features/activationGroup/hooks/useUpdateActivationGroupMutation'
import type {
  ActivationGroup,
  ApiActivationGroupResource,
  ApiUpdateActivationGroup,
  UpdateActivationGroup,
} from '@/features/activationGroup/types'
import { errorHandler } from '@/utils/errorHandler'

interface UpdateActivationGroupDialogProps {
  onClose: () => void
  activationGroup: ActivationGroup
}

const UpdateActivationGroupDialog = ({ onClose, activationGroup }: UpdateActivationGroupDialogProps) => {
  const { t } = useTranslation()
  const { resources } = useActivationGroupResourcesQuery({
    activationGroupUuid: activationGroup.uuid,
  })
  const { updateActivationGroup, isPending: isUpdatingActivationGroupMutationPending } =
    useUpdateActivationGroupMutation({ activationGroupUuid: activationGroup.uuid })
  const invalidateActivationGroupsQuery = useInvalidateActivationGroupsQuery()
  const { pushAlert } = useAlertContext()

  async function handleSubmit(editActivationGroup: UpdateActivationGroup) {
    if (!resources) {
      return
    }

    try {
      const apiPayload = serializeUpdateActivationGroup(editActivationGroup, resources)

      await updateActivationGroup(apiPayload)
      await invalidateActivationGroupsQuery()

      pushAlert({
        message: t('activation_groups.update_activation_group_success_message'),
        severity: 'success',
      })

      onClose()
    } catch (err) {
      const error = errorHandler(err, t('activation_groups.update_activation_group_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <CustomDialog open title={t('activation_groups.update_dialog.title')} onClose={onClose}>
      {!resources ? (
        <DialogContent>
          <Stack alignContent="center" flexDirection="row" justifyContent="center" sx={{ paddingY: 3 }}>
            <CircularProgress title={t('activation_groups.update_dialog.loading_title')} />
          </Stack>
        </DialogContent>
      ) : (
        <UpdateActivationGroupDialogForm
          activationGroup={activationGroup}
          isLoading={isUpdatingActivationGroupMutationPending}
          resources={resources}
          onCancel={onClose}
          onSubmit={handleSubmit}
        />
      )}
    </CustomDialog>
  )
}

function serializeUpdateActivationGroup(
  editActivationGroup: UpdateActivationGroup,
  resources: ApiActivationGroupResource[],
): ApiUpdateActivationGroup {
  return {
    code: editActivationGroup.name,
    resources: resources,
    biddableVolumeRange: editActivationGroup.biddableVolumeRange,
    state: editActivationGroup.state,
  }
}

export default UpdateActivationGroupDialog
