import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { GET_CUSTOMER_API_ID, getCustomer } from '@/features/site/endpoints/customers'
import type { Customer } from '@/features/site/types/customer'
import type { UseQueryOptions } from '@/types/queries'

type CustomerQueryParams = {
  uuid?: string
}
export type UseCustomerQueryResult = Omit<UseQueryResult, 'data'> & {
  customer: Customer | null
}

export function useCustomerQuery(
  params: CustomerQueryParams,
  options?: UseQueryOptions<Customer>,
): UseCustomerQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_CUSTOMER_API_ID, params],
    queryFn: () => getCustomer(params.uuid as string),
    enabled: Boolean(params.uuid),
  })

  return { customer: data, ...queryResult }
}

export function useInvalidateCustomerQuery() {
  const queryClient = useQueryClient()

  return (params: CustomerQueryParams) => queryClient.invalidateQueries({ queryKey: [GET_CUSTOMER_API_ID, params] })
}
