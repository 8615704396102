import type { CountryCode } from '@/components/dataDisplay/FlagIcon'
import type { MarketProgramType } from '@/types/marketProgramType'

/**
 * This map of market programs per country is coming from:
 *
 * https://www.notion.so/sympower/e4de1e2b5e604cf29411826d7451aaa6?v=0a1c7f57abda49fea9857d88005f7b01
 */
export function getMarketProgramsByCountryCode(countryCode: CountryCode): MarketProgramType[] {
  switch (countryCode.toLocaleLowerCase()) {
    case 'gr':
      return ['mfrr-up', 'mfrr-down', 'afrr-down', 'afrr-up']
    case 'nl':
      return ['mfrrda-down']
    case 'no':
      return ['ffr-up', 'fcrn', 'fcrd-down', 'fcrd-up', 'mfrr-up', 'mfrr-down']
    case 'fi':
      return [
        'fcrd-up',
        'fcrd-down',
        'fcrn',
        'ffr-up',
        'mfrr-up',
        'mfrr-down',
        'nordics-fcrd-up-static',
        'nordics-fcrd-down-static',
        'nordics-fcrd-up-dynamic',
        'nordics-fcrd-down-dynamic',
        'nordics-fcrn',
      ]
    case 'se':
      return [
        'fcrd-up',
        'fcrd-down',
        'ffr-up',
        'mfrr-up',
        'mfrr-down',
        'nordics-fcrd-up-static',
        'nordics-fcrd-down-static',
      ]
    default:
      return []
  }
}
