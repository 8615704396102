import { useTranslation } from 'react-i18next'

import LabeledTextField from '@/components/dataDisplay/LabeledTextField'
import SiteMap from '@/features/site/components/companyInfo/SiteMap'
import type { GpsLocation } from '@/types/gpsLocation'

type SiteAddressViewProps = {
  address?: string
  gpsLocation?: GpsLocation
}

export function SiteAddressView({ address, gpsLocation }: Readonly<SiteAddressViewProps>) {
  const { t } = useTranslation()

  return (
    <>
      <LabeledTextField label={t('customer_details.tabs.company_info.address')} text={address} />
      <SiteMap location={gpsLocation} />
    </>
  )
}
