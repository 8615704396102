import { Stack } from '@mui/material'
import type { GridRenderCellParams, GridRowParams } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import LifecycleStageChip from '@/features/resource/components/LifecycleStageChip'
import ResourceSteeringTarget from '@/features/resource/components/ResourceSteeringTarget'
import ResourceTypeChip from '@/features/resource/components/ResourceTypeChip'
import SteerabilityStatusChip from '@/features/resource/components/SteerabilityStatusChip'
import SteeringStatusChip from '@/features/resource/components/SteeringStatusChip'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useSpotOnResourcesQuery } from '@/features/resource/hooks/useSpotOnResourcesQuery'
import type { Resource } from '@/features/resource/types'

type ResourceRenderCell = GridRenderCellParams<Resource>

type SpotOnResourceGridProps = {
  customerIds: string[]
}

function SpotOnResourceGrid({ customerIds }: Readonly<SpotOnResourceGridProps>) {
  const { t } = useTranslation()
  const { resources, isLoading } = useSpotOnResourcesQuery({ customerIds })

  return (
    <CustomDataGrid
      clickableRows={{
        navigateTo: ({ row }: GridRowParams<Resource>) =>
          ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_CONFIGURATION.navigationPath(row.resourceID),
      }}
      columns={[
        {
          field: 'resourceType',
          headerName: t('resources.table.header.type'),
          flex: 1,
          renderCell: ({ row }: ResourceRenderCell) => (
            <Stack
              sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <ResourceTypeChip resourceType={row.resourceType ?? 'UNKNOWN'} />
            </Stack>
          ),
          sortable: false,
        },
        {
          field: 'resourceName',
          headerName: t('resources.table.header.name'),
          flex: 2,
        },
        {
          field: 'customerName',
          headerName: t('resources.table.header.customer'),
          flex: 2,
        },
        {
          field: 'lifecycleStage',
          headerName: t('resources.table.header.lifecycle_stage'),
          flex: 2,
          renderCell: ({ row: { lifecycleStage } }: ResourceRenderCell) => {
            return lifecycleStage ? <LifecycleStageChip lifecycleStage={lifecycleStage} /> : null
          },
          sortable: false,
        },
        {
          field: 'steerabilityStatus',
          headerName: t('resources.table.header.steerability_status'),
          flex: 2,
          renderCell: ({ row: { steerabilityStatus, isRestricted } }: ResourceRenderCell) => (
            <SteerabilityStatusChip isRestricted={isRestricted} status={steerabilityStatus} />
          ),
          sortable: false,
        },
        {
          field: 'steeringStatus',
          headerName: t('resources.table.header.steering_status'),
          flex: 2,
          renderCell: ({ row: { steeringStatus } }: ResourceRenderCell) => (
            <SteeringStatusChip status={steeringStatus} />
          ),

          sortable: false,
        },
        {
          field: 'currentSteeringTarget',
          headerName: t('resources.table.header.steering_target'),
          flex: 2,
          renderCell: ({ row: { steeringData } }: ResourceRenderCell) => (
            <ResourceSteeringTarget currentSteeringTarget={steeringData?.currentSteeringTarget} />
          ),
        },
      ]}
      getRowId={(row: Resource) => row.resourceID}
      isLoading={isLoading}
      rows={resources}
    />
  )
}

export default SpotOnResourceGrid
