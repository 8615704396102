import { Box, DialogContent, DialogContentText, Tab, Tabs } from '@mui/material'
import type { FC } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ConfirmDialogActions from '@/components/feedback/ConfirmDialogActions'
import CustomDialog from '@/components/feedback/CustomDialog'
import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import TabPanel from '@/components/layouts/TabPanel'
import { useActivationGroupsQuery } from '@/features/activationGroup/hooks/useActivationGroupsQuery'
import type { ActivationGroup } from '@/features/activationGroup/types'
import BidManualAcceptForm from '@/features/bidding/components/manualAccept/BidManualAcceptForm'
import type { ManualAcceptFlow } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import type { BidHistory } from '@/features/bidding/types/bidHistory'
import type { GroupedBidParams } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'

type Props = {
  manualAcceptFlow: ManualAcceptFlow
  bidHistories: BidHistory[]
  groupParams: GroupedBidParams
}
const BidManualAcceptTabs: FC<Props> = ({ manualAcceptFlow, bidHistories, groupParams }) => {
  const { selectedCountry } = useBiddingContext()
  const { t } = useTranslation()

  const [activeTab, setActiveTab] = useState(0)
  const { activationGroups } = useActivationGroupsQuery({ countryCode: selectedCountry })
  const [anyTabUnsavedChanges, setAnyTabUnsavedChanges] = useState(false)
  const [navigateToTab, setNavigateToTab] = useState<number | null>(null)

  if (!activationGroups) {
    return <FullPageSpinner />
  }

  const sortedBidHistories = sortByActivationGroupCode(bidHistories, activationGroups)
  if (sortedBidHistories.length === 1) {
    return (
      <BidManualAcceptForm
        activationGroup={activationGroups.find((ag) => ag.uuid === sortedBidHistories[0].activationGroupUuid)!}
        bidType={groupParams.bidType}
        deliveryDay={groupParams.deliveryDay}
        manualAcceptFlow={manualAcceptFlow}
        setUnsavedChanges={setAnyTabUnsavedChanges}
      />
    )
  }

  return (
    <>
      <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          scrollButtons="auto"
          style={{ minHeight: 0 }}
          value={activeTab}
          variant="scrollable"
          onChange={(_, newActiveTab: number) => {
            if (anyTabUnsavedChanges) {
              setNavigateToTab(newActiveTab)
              return
            }
            setActiveTab(newActiveTab)
          }}
        >
          {sortedBidHistories.map((bidHistory, index) => (
            <Tab
              key={bidHistory.activationGroupUuid}
              label={activationGroups.find((a) => a.uuid === bidHistory.activationGroupUuid)?.code}
              style={{ minHeight: 0 }}
              value={index}
            />
          ))}
        </Tabs>
      </Box>

      {sortedBidHistories.map((bidHistory, index) => (
        <TabPanel key={bidHistory.activationGroupUuid} index={index} value={activeTab}>
          <BidManualAcceptForm
            activationGroup={activationGroups.find((ag) => ag.uuid === bidHistory.activationGroupUuid)!}
            bidType={groupParams.bidType}
            deliveryDay={groupParams.deliveryDay}
            manualAcceptFlow={manualAcceptFlow}
            setUnsavedChanges={setAnyTabUnsavedChanges}
          />
        </TabPanel>
      ))}

      <CustomDialog
        open={!!navigateToTab}
        title={t('bidding.manual_accept.unsaved_changes_warning.title')}
        onClose={() => setNavigateToTab(null)}
      >
        <DialogContent>
          <DialogContentText>{t('bidding.manual_accept.unsaved_changes_warning.description')}</DialogContentText>
        </DialogContent>

        <ConfirmDialogActions
          onCancel={() => setNavigateToTab(null)}
          onConfirm={() => {
            setNavigateToTab(null)
            setAnyTabUnsavedChanges(false)
            setActiveTab(navigateToTab!)
          }}
        />
      </CustomDialog>
    </>
  )
}

const sortByActivationGroupCode = (bidHistories: BidHistory[], activationGroups: ActivationGroup[]) =>
  bidHistories.slice().sort((a, b) => {
    const activationGroup1 = activationGroups.find((ag) => ag.uuid === a.activationGroupUuid)!
    const activationGroup2 = activationGroups.find((ag) => ag.uuid === b.activationGroupUuid)!

    return activationGroup1.code.localeCompare(activationGroup2.code)
  })

export default BidManualAcceptTabs
