import { Typography } from '@mui/material'
import type { GridRowSelectionModel } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { useResourcesWithPollingQuery } from '@/features/resource/hooks/useResourcesWithPollingQuery'
import type { Resource } from '@/features/resource/types'
import type { ResourceLevelRestrictionForm } from '@/features/site/types/restrictionForm'
import type { MarketProgramType } from '@/types/marketProgramType'

interface UnavailabilityResourceSelectionSectionProps {
  customerUuid: string
}

export const UnavailabilityResourceSelectionSection = (props: UnavailabilityResourceSelectionSectionProps) => {
  const { t } = useTranslation()
  const form = useFormContext<ResourceLevelRestrictionForm>()
  const { affectedResources } = form.getValues()
  const { errors } = form.formState
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>(affectedResources ?? [])

  const { resources: resourcesPollingResult, isLoading } = useResourcesWithPollingQuery({
    filters: {
      lifecycleStage: 'LIVE',
    },
    globalFilters: {
      customerId: props.customerUuid,
    },
    sort: undefined,
    pagination: {
      pageSize: 10000,
      page: 0,
    },
  })

  const [sortedResources, setSortedResources] = useState<Resource[]>([])
  const [didInitialSort, setDidInitialSort] = useState(false)

  useEffect(() => {
    if (resourcesPollingResult && (!didInitialSort || resourcesPollingResult.length !== sortedResources.length)) {
      // Sort the resources to bring selected ones to the top on initial load
      const initiallySortedResources = [...resourcesPollingResult].sort((a, b) => {
        const isASelected = rowSelectionModel.includes(a.resourceID)
        const isBSelected = rowSelectionModel.includes(b.resourceID)

        if (isASelected && !isBSelected) return -1
        if (!isASelected && isBSelected) return 1
        return 0
      })

      setSortedResources(initiallySortedResources)
      setDidInitialSort(true)
    }
  }, [resourcesPollingResult])

  useEffect(() => {
    form.setValue(
      'affectedResources',
      rowSelectionModel.map((resourceId) => resourceId.toString()),
    )
  }, [rowSelectionModel])

  const columns = [
    {
      field: 'name',
      headerName: t('customer_details.tabs.availability.common.form.resources_section.resources'),
      flex: 2,
      valueGetter: (_, availableResource: Resource) => availableResource.resourceName,
    },
    {
      field: 'type',
      headerName: t('customer_details.tabs.availability.common.form.resources_section.type'),
      flex: 1,
      valueGetter: (_, availableResource: Resource) => availableResource.resourceType,
    },
    {
      field: 'marketPrograms',
      headerName: t('common.market_program.label'),
      flex: 1,
      valueFormatter: (value: MarketProgramType[]) => {
        return value.map((marketProgram) => t(`common.market_program.${marketProgram}`)).join(', ')
      },
    },
  ]

  return (
    <>
      <CustomDataGrid
        keepNonExistentRowsSelected
        checkboxSelection={true}
        clickableRows={{
          isRowSelectable: () => true,
          isRowClickable: () => true,
          onRowSelectionModelChange: (newSelection) => {
            setRowSelectionModel(newSelection)
          },
        }}
        columns={columns}
        getRowId={(row: Resource) => row.resourceID}
        includeWrapper={false}
        initialState={{
          pagination: {
            paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
          },
        }}
        isLoading={isLoading}
        rowSelection={{
          multiple: true,
          model: rowSelectionModel,
        }}
        rows={sortedResources}
        title={t('customer_details.tabs.availability.common.form.resources_section.title')}
      />
      {errors.affectedResources && (
        <Typography color="error" sx={{ mt: 1 }}>
          {errors.affectedResources.message}
        </Typography>
      )}
    </>
  )
}
