import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import type { DateTime } from 'luxon'

import { GET_REVENUE_TIME_SERIES_API_ID, getCustomerRevenueTimeSeries } from '@/features/site/endpoints/revenues'
import type { RevenueTimeSeries } from '@/features/site/types/revenue'
import type { UseQueryOptions } from '@/types/queries'

type RevenueTimeSeriesQueryParams = {
  customerUuid: string
  serviceId: number
  startTime: DateTime
  endTime: DateTime
  location?: string
}

export type UseRevenueTimeSeriesQueryResult = Omit<UseQueryResult, 'data'> & {
  revenueTimeSeries: RevenueTimeSeries | null
}

export function useRevenueTimeSeriesQuery(
  params: RevenueTimeSeriesQueryParams,
  options?: UseQueryOptions<RevenueTimeSeries>,
): UseRevenueTimeSeriesQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_REVENUE_TIME_SERIES_API_ID, params],
    queryFn: () =>
      getCustomerRevenueTimeSeries(
        params.customerUuid,
        params.serviceId,
        params.startTime,
        params.endTime,
        params.location,
      ),
  })

  return { revenueTimeSeries: data, ...queryResult }
}
