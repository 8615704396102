import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined'
import type { MouseEvent } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomIconButton from '@/components/inputs/CustomIconButton'
import AbortActivationScheduleDialog from '@/features/activationGroup/components/AbortActivationScheduleDialog'

type AbortActivationScheduleConfirmationButtonProps = {
  activationScheduleUuid: string
}

const AbortActivationScheduleConfirmationButton = ({
  activationScheduleUuid,
}: AbortActivationScheduleConfirmationButtonProps) => {
  const { t } = useTranslation()
  const [openAbortActivationScheduleDialog, setOpenAbortActivationScheduleDialog] = useState(false)

  function handleOpenAbortActivationScheduleDialog(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault() // the whole row is clickable so this will prevent the click from going into the row too
    setOpenAbortActivationScheduleDialog(true)
  }

  function handleCloseAbortActivationScheduleDialog() {
    setOpenAbortActivationScheduleDialog(false)
  }

  return (
    <>
      <CustomIconButton
        Icon={StopCircleOutlinedIcon}
        aria-label={t('activation_groups.abort_schedule_button.label', {
          activationScheduleUuid: activationScheduleUuid,
        })}
        color="error"
        iconProps={{
          titleAccess: t('activation_groups.abort_schedule_button.title_access', {
            activationScheduleUuid: activationScheduleUuid,
          }),
        }}
        tooltipProps={{
          title: t('activation_groups.abort_schedule_button.title'),
        }}
        onClick={handleOpenAbortActivationScheduleDialog}
      />

      <AbortActivationScheduleDialog
        activationScheduleUuid={activationScheduleUuid}
        open={openAbortActivationScheduleDialog}
        onClose={handleCloseAbortActivationScheduleDialog}
      />
    </>
  )
}

export default AbortActivationScheduleConfirmationButton
