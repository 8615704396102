import { DateTime } from 'luxon'

import type { Activation, AmActivation, MagActivation } from '@/features/activation/types/activation'
import { ActivationType } from '@/features/activation/types/activation'
import { convertAmActivationToStandard, convertMagActivationToStandard } from '@/features/activation/utils/converters'
import { getMarketProgramTypes } from '@/features/activation/utils/getMarketProgramTypes'
import type { MarketProgram } from '@/features/site/types/marketProgram'

export function mergeActivations(
  magActivations: MagActivation[] | undefined | null,
  amActivations: AmActivation[] | undefined | null,
  marketPrograms: MarketProgram[] | undefined | null,
  canSeePrequalifications: boolean,
): Activation[] {
  const allActivations = [
    ...(magActivations?.map((magActivation) =>
      convertMagActivationToStandard(magActivation, getMarketProgramTypes(marketPrograms).get(magActivation.serviceId)),
    ) ?? []),
    ...(amActivations?.map((amActivation) => convertAmActivationToStandard(amActivation)) ?? []),
  ].sort(
    (a, b) =>
      (b.endedAt ? DateTime.fromISO(b.endedAt).toMillis() : 0) -
      (a.endedAt ? DateTime.fromISO(a.endedAt).toMillis() : 0),
  )

  return canSeePrequalifications
    ? allActivations
    : allActivations.filter((activation) => activation.type !== ActivationType.PREQUALIFICATION)
}
