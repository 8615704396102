import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { usePageMetadata } from '@/components/layouts/PageMetadataContext'
import UnauthenticatedAppRoutes from '@/UnauthenticatedAppRoutes'

export default function UnauthenticatedApp() {
  const { t } = useTranslation()
  const { setPageTitle } = usePageMetadata()

  useEffect(() => {
    setPageTitle(t('common.login'))
  }, [])

  return <UnauthenticatedAppRoutes />
}
