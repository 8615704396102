import { useMutation } from '@tanstack/react-query'

import { unlinkCustomAttributeSchemaFromResource } from '@/features/resource/endpoints/customAttributeSchemas'

export function useUnlinkCustomAttributeSchemaFromResourceMutation(resourceID: string) {
  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (customAttributeSchemaNamespace: string) => {
      return unlinkCustomAttributeSchemaFromResource(resourceID, customAttributeSchemaNamespace)
    },
  })
  return { unlinkSchemaFromResource: mutateAsync, ...mutationResult }
}
