import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { ReactNode } from 'react'

import EllipsisTypography from '@/components/dataDisplay/EllipsisTypography'

export interface RevenueSummaryAccordionProps {
  title: string
  monthlySummary: ReactNode
  monthlyDataGrid: ReactNode
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,

  '&.MuiAccordion-root:last-of-type': {
    borderBottom: 0,
  },
  '&.MuiAccordion-root:before': {
    display: 'none',
  },
}))

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  '&.MuiAccordionSummary-root': {
    minHeight: '52px',
  },
}))

/**
 * Revenue Summary Accordion component extends MUI Accordion component, and includes some properties used in our particular
 * use cases. The main properties are:
 *
 * - Title [mandatory]
 * - Monthly summary [mandatory]
 * - Monthly data grid [mandatory]. Will be passed to the AccordionDetails component.
 *
 * Be careful if you wrap this component around a Stack component, because the Stack component will add extra space
 * between the accordions inside when they are opened.
 */
const RevenueSummaryAccordion = ({ monthlySummary, title, monthlyDataGrid }: RevenueSummaryAccordionProps) => {
  return (
    <StyledAccordion>
      <StyledAccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{
              margin: 1,
              visibility: 'visible',
              pointerEvents: 'auto',
            }}
            titleAccess="Expand Icon"
          />
        }
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '35% 65%',
            width: '100%',
            gap: 1,
          }}
        >
          <Stack sx={{ flexDirection: 'row', alignItems: 'center', minWidth: 0 }}>
            <EllipsisTypography sx={{ flex: 1 }} tooltipTitle={title} variant="h6">
              {title}
            </EllipsisTypography>
          </Stack>

          {monthlySummary}
        </Box>
      </StyledAccordionSummary>

      <AccordionDetails>{monthlyDataGrid}</AccordionDetails>
    </StyledAccordion>
  )
}

export default RevenueSummaryAccordion
