import ReactECharts from 'echarts-for-react'
import { Duration } from 'luxon'
import { useTranslation } from 'react-i18next'

import { DURATION_FORMAT } from '@/constants/dateTimeFormats'

type GenericPrequalificationPatternChartProps = {
  patternCsv: string
}

function GenericPrequalificationPatternChart({ patternCsv }: Readonly<GenericPrequalificationPatternChartProps>) {
  const { t } = useTranslation()

  // Parse CSV data
  const lines = patternCsv.trim().split('\n')
  let data = lines
    .map((line) => {
      const [timestamp, frequency] = line.split(';')
      return [+timestamp, +frequency]
    })
    .filter(([timestamp, frequency]) => !isNaN(timestamp) && !isNaN(frequency))

  if (data.length === 0) {
    data = [[0, 50000]]
  }

  // get the max timestamp
  const maxTimestamp = data.reduce((max, [timestamp]) => Math.max(max, timestamp), 0)
  const maxFrequency = data.reduce((max, [, frequency]) => Math.max(max, frequency), 51000)
  const minFrequency = data.reduce((min, [, frequency]) => Math.min(min, frequency), 49000)
  const durationText = Duration.fromMillis(maxTimestamp).toFormat(DURATION_FORMAT).replace('00h ', '')

  const option = {
    title: {
      text: t('activation_groups.prequalification_pattern.chart_title', { duration: durationText }),
    },
    grid: {
      left: '5%',
      right: '2%',
      bottom: '22%',
      top: '30%',
      containLabel: false,
    },
    xAxis: {
      type: 'value',
      name: t('activation_groups.prequalification_pattern.timestamp'),
      nameLocation: 'middle',
      nameGap: 25,
      axisLabel: {
        formatter: '{value} ms',
      },
      splitLine: {
        show: true,
      },
      // Use a reasonable integer value (1000ms) to avoid sub-millisecond labels if there is only a single data point
      // or if the time series ends already in less than 20ms
      interval: data.length > 1 && maxTimestamp > 20 ? null : 1000,
      max: data.length > 1 && maxTimestamp > 20 ? maxTimestamp : 1000,
    },
    yAxis: {
      type: 'value',
      name: t('activation_groups.prequalification_pattern.frequency_mhz'),
      axisPointer: {
        snap: true,
      },
      scale: true,
      min: minFrequency,
      max: maxFrequency,
      splitLine: {
        show: true,
      },
    },
    series: [
      {
        data: data,
        type: data.length > 1 ? 'line' : 'scatter',
        step: 'end',
        name: t('activation_groups.prequalification_pattern.frequency_mhz'),
      },
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          show: true,
          precision: 0,
        },
      },
    },
    toolbox: {
      show: true,
      feature: {
        dataView: { show: true, readOnly: false },
        dataZoom: {
          yAxisIndex: 'none',
        },
        restore: { show: true },
        saveAsImage: {
          name: 'frequency pattern chart ' + new Date().toISOString().slice(0, 19),
        },
      },
    },
    // https://echarts.apache.org/en/option.html#dataZoom-slider
    dataZoom: [
      {
        show: true,
        startValue: '0',
        height: '10%',
        bottom: '10',
        showDataShadow: true,
        brushSelect: false,
        labelPrecision: 0,
      },
      {
        type: 'inside',
      },
    ],
    visualMap: {
      show: false,
      dimension: 1,
      pieces: [
        {
          // FCR-N range
          color: 'green',
          gte: 49900,
          lte: 50100,
        },
        {
          // FCR-D Down range
          color: 'orange',
          gt: 50100,
          lte: 50500,
        },
        {
          // FCR-D Up range
          color: 'yellow',
          gte: 49500,
          lt: 49900,
        },
        {
          // FFR Up range
          color: 'red',
          gt: 50500,
          lte: 51000,
        },
        {
          // FFR Down range
          color: 'red',
          lt: 49500,
          gte: 49000,
        },
        {
          // Above any realistic range - bad data?
          color: 'purple',
          gt: 51000,
        },
        {
          // Below any realistic range - bad data?
          color: 'purple',
          lt: 49000,
        },
      ],
    },
  }

  return (
    <ReactECharts
      data-testid="prequalification-pattern-chart"
      notMerge={true}
      option={option}
      style={{ height: '250px' }}
    />
  )
}

export default GenericPrequalificationPatternChart
