import type { ActivationGroup } from '@/features/activationGroup/types'
import type { ExtendedBid } from '@/features/bidding/types/bid'
import volumeOutOfBiddableVolumeRange from '@/features/bidding/utils/validation/volumeOutOfBiddableVolumeRange'

export const anyBidOutOfBiddableVolumeRange = (bids: ExtendedBid[], activationGroups: ActivationGroup[]) =>
  bids.some((bid) => {
    const activationGroup = activationGroups.find((activationGroup) => activationGroup.uuid === bid.activationGroupUuid)
    if (!activationGroup?.biddableVolumeRange) return false

    return bid.ptus.some((ptu) =>
      volumeOutOfBiddableVolumeRange(ptu.offeredVolume.quantity, {
        minBiddableVolume: activationGroup.biddableVolumeRange?.min,
        maxBiddableVolume: activationGroup.biddableVolumeRange?.max,
      }),
    )
  })
