import type { GridColDef, GridRowParams } from '@mui/x-data-grid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { useDisturbanceActivatedResourcesQuery } from '@/features/disturbance/hooks/useDisturbanceActivatedResourcesQuery'
import type { DisturbanceActivatedResource } from '@/features/disturbance/types'
import { ResourceRouteInformation } from '@/features/resource/constants'

type ActivatedResourcesGridProps = {
  disturbanceUuid: string
}

const DATE_FORMAT = 'dd LLL, yyyy - HH:mm:ss'

const ActivatedResourcesGrid = ({ disturbanceUuid }: ActivatedResourcesGridProps) => {
  const { t } = useTranslation()
  const [paginationModel, setPaginationModel] = useState(DEFAULT_DATA_GRID_PAGINATION_MODEL)

  const { activatedResources, isLoading, totalCount } = useDisturbanceActivatedResourcesQuery({
    disturbanceUuid,
    pagination: paginationModel,
  })

  const columns: GridColDef<DisturbanceActivatedResource>[] = [
    {
      field: 'resourceUuid',
      headerName: t('common.uuid'),
      flex: 2,
    },
    {
      field: 'resourceName',
      headerName: t('common.name'),
      flex: 2,
    },
    {
      field: 'activationGroupCode',
      headerName: t('disturbance_details.activated_resources_table.header.activation_group_code'),
      flex: 2,
    },
    {
      field: 'askedToActivateAt',
      headerName: t('disturbance_details.activated_resources_table.header.asked_to_activate_at'),
      flex: 1,
      valueFormatter: (value: DisturbanceActivatedResource['askedToActivateAt']) => value.toFormat(DATE_FORMAT),
    },
    {
      field: 'activatedAt',
      headerName: t('disturbance_details.activated_resources_table.header.activated_at'),
      flex: 1,
      valueFormatter: (value: DisturbanceActivatedResource['activatedAt']) =>
        value !== null ? value.toFormat(DATE_FORMAT) : '-',
    },
    {
      field: 'activationLatencyMillis',
      headerName: t('disturbance_details.activated_resources_table.header.activation_delay'),
      flex: 1,
      valueFormatter: (value: DisturbanceActivatedResource['activationLatencyMillis']) => `${value} ms`,
    },
    {
      field: 'askedToDeactivateAt',
      headerName: t('disturbance_details.activated_resources_table.header.asked_to_deactivate_at'),
      flex: 1,
      valueFormatter: (value: DisturbanceActivatedResource['askedToDeactivateAt']) =>
        value !== null ? value.toFormat(DATE_FORMAT) : '-',
    },
    {
      field: 'deactivatedAt',
      headerName: t('disturbance_details.activated_resources_table.header.deactivated_at'),
      flex: 1,
      valueFormatter: (value: DisturbanceActivatedResource['deactivatedAt']) =>
        value !== null ? value.toFormat(DATE_FORMAT) : '-',
    },
    {
      field: 'deactivationLatencyMillis',
      headerName: t('disturbance_details.activated_resources_table.header.deactivation_delay'),
      flex: 1,
      valueFormatter: (value: DisturbanceActivatedResource['activationLatencyMillis']) => `${value} ms`,
    },
  ]

  return (
    <CustomDataGrid
      disableColumnMenu
      disableColumnSorting
      aria-label={t('disturbance_details.activated_resources_table.title')}
      clickableRows={{
        navigateTo: ({ row }: GridRowParams<DisturbanceActivatedResource>) =>
          ResourceRouteInformation.RESOURCE_DETAILS.navigationPath(row.resourceUuid),
      }}
      columns={columns}
      getRowId={(row) => row.uuid}
      isLoading={isLoading}
      paginationMode="server"
      paginationModel={paginationModel}
      rowCount={totalCount}
      rows={activatedResources ?? []}
      onPaginationModelChange={setPaginationModel}
    />
  )
}

export default ActivatedResourcesGrid
