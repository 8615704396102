import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import EllipsisTypography from '@/components/dataDisplay/EllipsisTypography'
import FlagIcon from '@/components/dataDisplay/FlagIcon'
import TextList from '@/components/dataDisplay/TextList'
import type { Language } from '@/constants/interfaceLanguages'
import { convertLanguageToCountryCode } from '@/constants/interfaceLanguages'
import type { CreateSiteForm } from '@/features/site/types/site'
import { formatNotificationsMessageTypes, formatValue } from '@/features/site/utils/review'

import ReviewInformationBlock from './ReviewInformationBlock'
import ReviewSiteSection from './ReviewSiteSection'

type NotificationsReviewSectionProps = {
  notificationPreferences: CreateSiteForm['notificationPreferences']
}

function NotificationsReviewSection({ notificationPreferences }: Readonly<NotificationsReviewSectionProps>) {
  const { t } = useTranslation()
  const notificationLanguage = notificationPreferences?.language as Language | null
  const notificationCountryCode = notificationLanguage ? convertLanguageToCountryCode(notificationLanguage) : null
  const phoneNumbers = notificationPreferences?.phoneNumbers ?? []
  const languageTranslation = notificationLanguage ? t(`common.interface_language.${notificationLanguage}`) : null

  return (
    <ReviewSiteSection stepId="notifications" title={t('sites.add_new.steps.notifications')}>
      <Stack sx={{ flexDirection: 'row', gap: 2 }}>
        <ReviewInformationBlock
          name={t('customer_settings.form.interface_language_label')}
          value={
            notificationLanguage ? (
              <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                {notificationCountryCode && <FlagIcon countryCode={notificationCountryCode} />}

                <EllipsisTypography tooltipTitle={languageTranslation} variant="body1">
                  {languageTranslation}
                </EllipsisTypography>
              </Stack>
            ) : (
              '-'
            )
          }
        />
        <ReviewInformationBlock
          name={t('sites.add_new.form.notifications.message_types.label')}
          value={formatNotificationsMessageTypes(notificationPreferences, t)}
        />

        <ReviewInformationBlock name={t('common.email')} value={formatValue(notificationPreferences?.email)} />

        <ReviewInformationBlock
          name={t('common.phones')}
          value={
            phoneNumbers.filter((phoneNumber) => phoneNumber.length > 0).length > 0 ? (
              <TextList limit={1} values={phoneNumbers.map((phone) => `+${phone}`)} />
            ) : (
              '-'
            )
          }
        />
      </Stack>
    </ReviewSiteSection>
  )
}

export default NotificationsReviewSection
