import { DEFAULT_INTERFACE_LANGUAGE } from '@/constants/interfaceLanguages'
import { DEFAULT_PARTNER } from '@/constants/partners'
import type { MarketProgram } from '@/features/site/types/marketProgram'
import type { CreateSite, CreateSiteForm, Site, SiteMarketProgramContractRequest } from '@/features/site/types/site'
import { formatIban } from '@/utils/iban'

/**
 * This function is meant to use when sending the form data to the backend. It just adjust the form
 * data to the backend format.
 */
export function convertCreateSiteFormToSite({
  financialInformation,
  notificationPreferences,
  marketProgramContracts,
  ...formData
}: CreateSiteForm): CreateSite {
  return {
    ...formData,
    countryCode: formData.countryCode as CountryIdentifier,
    marketProgramContracts: convertMarketProgramContractsFormToModel(marketProgramContracts),
    financialInformation: financialInformation
      ? {
          ...financialInformation,
          iban: financialInformation.iban ? formatIban(financialInformation.iban) : null,
        }
      : undefined,
    notificationPreferences: notificationPreferences
      ? {
          ...notificationPreferences,
          phoneNumbers: notificationPreferences.phoneNumbers?.filter((phoneNumber) => phoneNumber.length > 0) ?? [],
        }
      : undefined,
  }
}

export function convertMarketProgramContractsFormToModel(marketProgramContracts): SiteMarketProgramContractRequest[] {
  return (
    marketProgramContracts
      ?.filter((marketProgramContract) => marketProgramContract.isSelected)
      .map(
        (marketProgramContract): SiteMarketProgramContractRequest => ({
          marketProgramId: marketProgramContract.marketProgramId,
          revenueSharingFraction: marketProgramContract.revenueSharingFraction,
          roundingErrorInWatts:
            marketProgramContract.roundingErrorInKilowatts !== null
              ? marketProgramContract.roundingErrorInKilowatts * 1000
              : null,
          soldCapacityAlgorithmType:
            marketProgramContract.soldCapacityAlgorithmType !== ''
              ? marketProgramContract.soldCapacityAlgorithmType
              : null,
        }),
      ) ?? []
  )
}

/**
 * This function is meant to use receiving the data from the backend. It just adjust the backend data to the create
 * site form format.
 *
 * When site is passed (e.g on the creating form), then we return some default values for the form.
 */
export function convertSiteToCreateSiteForm(site?: Site): CreateSiteForm {
  return {
    ...site,
    name: '',
    partnerCode: DEFAULT_PARTNER.partnerCode,
    symbolicName: '',
    location: '',
    address: '',
    timezone: '',
    contacts: [],
    notificationPreferences: {
      phoneNumbers: [''],
      email: null,
      isSmsEnabled: false,
      isEmailEnabled: false,
      language: DEFAULT_INTERFACE_LANGUAGE.value,
    },
    financialInformation: {
      iban: null,
      ibanHolder: null,
      isFinancialVisible: false,
    },
    marketProgramContracts:
      site?.marketProgramContracts?.map((marketProgramContract) => ({
        marketProgramId: marketProgramContract.marketProgramId!,
        revenueSharingFraction: marketProgramContract.revenueSharingFraction!,
        roundingErrorInKilowatts: marketProgramContract.roundingErrorInWatts
          ? marketProgramContract.roundingErrorInWatts / 1000
          : null,
        soldCapacityAlgorithmType: marketProgramContract.soldCapacityAlgorithmType!,
        isSelected: true,
      })) ?? [],
  }
}

/**
 * On the market programs step, we are showing all the market programs available on a specific country. This function
 * adds them to the form data using some default data.
 *
 * Besides that, when a site already has data related to a market program, we just inject them.
 *
 * It is also important to understand that the market programs shown on the form are being rendered in the same order as
 * the market programs come from the backend. That's another reason we need siteMarketPrograms argument on this function:
 * we need to inject the data already on the form on the right market program index.
 */
export function convertMarketProgramsToFormMarketPrograms(
  marketPrograms: MarketProgram[],
  marketProgramContracts: CreateSiteForm['marketProgramContracts'],
  countryCode?: CountryIdentifier,
): CreateSiteForm['marketProgramContracts'] {
  return marketPrograms.map((marketProgram) => {
    const initialSiteMarketProgram: CreateSiteForm['marketProgramContracts'][0] = {
      marketProgramId: marketProgram.id,
      revenueSharingFraction: null,
      roundingErrorInKilowatts: countryCode?.toLocaleLowerCase() === 'fi' ? 15 : null,
      soldCapacityAlgorithmType: countryCode?.toLocaleLowerCase() === 'fi' ? 'min' : null,
      isSelected: false,
    }

    if (!marketProgramContracts) {
      return initialSiteMarketProgram
    }

    const siteMarketProgram = marketProgramContracts?.find(
      (siteMarketProgram) => siteMarketProgram?.marketProgramId === marketProgram?.id,
    )

    return siteMarketProgram ?? initialSiteMarketProgram
  })
}
