import { Box, Switch } from '@mui/material'
import type { GridColDef, GridRenderCellParams, GridRowParams, GridSortModel } from '@mui/x-data-grid'
import { useMutation } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import GridChipCell from '@/components/dataDisplay/GridChipCell'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import type { UserRole } from '@/constants/userRoles'
import { USER_ROLES } from '@/constants/userRoles'
import { useAlertContext } from '@/contexts/AlertContext'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { usePartnersQuery } from '@/features/partner/hooks/usePartnersQuery'
import { useCustomersQuery } from '@/features/site/hooks/useCustomersQuery'
import UsersDataGridToolbar, {
  DEFAULT_DATA_GRID_USERS_FILTER_MODEL,
} from '@/features/user/components/UsersDataGridToolbar'
import { saveUser as apiSaveUser } from '@/features/user/endpoints/users'
import { useUsersQuery } from '@/features/user/hooks/useUsersQuery'
import type { User } from '@/features/user/types/user'
import { getAuthorizedRoles } from '@/features/user/utils/authorization'
import { deserializeFilterModelFromSearchParams } from '@/utils/datagrid/filters'
import { getGridMultiselectHasAnyOfOperator } from '@/utils/datagrid/operators/hasAnyOf'
import { deserializePaginationModelFromSearchParams } from '@/utils/datagrid/pagination'
import { deserializeSortModelFromSearchParams } from '@/utils/datagrid/sorting'

const DEFAULT_DATA_GRID_USERS_SORT_MODEL: GridSortModel = [{ field: 'username', sort: 'asc' }]

function Users() {
  const { loggedInUser } = useAuth()
  const { t } = useTranslation()

  const { mutate: saveUser } = useMutation({
    mutationFn: (variables: { user: User }) => apiSaveUser(variables.user),
    onSuccess: async (_, variables) => {
      const { isActive } = variables.user

      pushAlert({
        message: t(`users.user_is_now_${isActive ? 'enabled' : 'disabled'}_success_message`, {
          username: variables.user.username,
        }),
        severity: 'success',
      })
    },
  })

  const [showDisabledUsers, setShowDisabledUsers] = useState(false)
  const { pushAlert } = useAlertContext()

  const { customers, isFetching: isFetchingCustomers } = useCustomersQuery()
  const { partners, isFetching: isFetchingPartners } = usePartnersQuery({ enabled: isCurrentUserAdmin() })
  const { users, isFetching: isFetchingUsers } = useUsersQuery({ partnerCode: loggedInUser?.partnerCode })

  const partnersMap = useMemo(() => new Map(partners?.map((i) => [i.partnerCode, i.name])), [partners])

  const customerOptions = useMemo(() => getUniqueCustomerOptions(), [users, customers, showDisabledUsers])

  function getCustomer(customerUuid) {
    return customers?.find((c) => c.uuid === customerUuid)
  }

  function isCurrentUserAdmin() {
    return loggedInUser?.role === USER_ROLES.ADMINISTRATORS.value
  }

  function isCurrentUserPartnerAdmin() {
    return loggedInUser?.role === USER_ROLES.PARTNER_ADMINISTRATORS.value
  }

  function getPartnerOptions() {
    const uniqueOptions = new Set<string>()
    users?.forEach((user) => {
      if (user.partnerCode != null) {
        uniqueOptions.add(partnersMap.get(user.partnerCode) ?? user.partnerCode)
      }
    })
    return Array.from(uniqueOptions)
  }

  function getUniqueCustomerOptions() {
    const seen = new Set<string>()
    const uniqueCustomerOptions: string[] = []
    const visibleUsers = (showDisabledUsers ? users : users?.filter((user) => user.isActive)) ?? []

    visibleUsers.forEach((user) => {
      const roIds = user.allowedRoIds ?? []
      roIds.forEach((roId) => {
        if (!seen.has(roId)) {
          seen.add(roId)
          const customer = getCustomer(roId)
          if (customer !== undefined) {
            uniqueCustomerOptions.push(customer.name ?? `Unknown customer [${customer.uuid}]`)
          }
        }
      })
    })
    return uniqueCustomerOptions
  }

  const columns: GridColDef[] = [
    {
      field: 'isActive',
      headerName: t('user.is_active.label'),
      sortable: false,
      renderCell: (params: GridRenderCellParams<User>) => (
        <Switch
          checked={params.row.isActive}
          color="primary"
          onChange={(event) => {
            params.row.isActive = event.target.checked
            saveUser({ user: { ...params.row, isActive: event.target.checked } })
          }}
          onClick={(event) => event.stopPropagation()}
        />
      ),
    },
    {
      field: 'username',
      headerName: t('common.username'),
      flex: 1.5,
    },
    {
      field: 'role',
      headerName: t('users.role.label'),
      flex: 1.5,
      sortable: false,
      type: 'singleSelect',
      valueOptions: getAuthorizedRoles(loggedInUser?.role),
      getOptionLabel: (value) => (value ? t(`users.role.${value as UserRole}`) : '-'),
      renderCell: (params: GridRenderCellParams<User>) => {
        if (!params.row.role) {
          return ''
        }
        return (
          <Box>
            <GridChipCell key={params.row.role} label={t(`users.role.${params.row.role}`)} />
          </Box>
        )
      },
      valueGetter: (value: User['role']) => value ?? '',
    },
    {
      field: 'email',
      headerName: t('common.email'),
      flex: 2,
    },
    {
      field: 'allowedRoIds',
      headerName: t('users.column.customers'),
      flex: 1.5,
      sortable: false,
      filterOperators: [getGridMultiselectHasAnyOfOperator()],
      valueGetter: (value: User['allowedRoIds']) => {
        return value?.map((roId) => getCustomer(roId)?.name)
      },
      renderCell: (params: GridRenderCellParams<User>) => {
        const customers = params.row.allowedRoIds?.map(getCustomer).filter((customer) => customer !== undefined)

        return <Box>{customers?.map((customer) => <GridChipCell key={customer?.uuid} label={customer?.name} />)}</Box>
      },
    },
    {
      field: 'partner',
      headerName: t('common.partner'),
      flex: 1.5,
      type: 'singleSelect',
      valueOptions: getPartnerOptions(),
      valueGetter: (_, { partnerCode }: User) => (partnerCode ? partnersMap.get(partnerCode) : partnerCode),
    },
  ]

  return (
    <CustomDataGrid
      clickableRows={{
        navigateTo: (params: GridRowParams<User>) => `/users/${params.row.id}`,
      }}
      columnVisibilityModel={{
        partner: isCurrentUserAdmin() || false,
      }}
      columns={columns}
      getRowId={(row) => row.id}
      initialState={{
        filter: {
          filterModel: deserializeFilterModelFromSearchParams() ?? DEFAULT_DATA_GRID_USERS_FILTER_MODEL,
        },
        pagination: {
          paginationModel: deserializePaginationModelFromSearchParams() ?? DEFAULT_DATA_GRID_PAGINATION_MODEL,
        },
        sorting: {
          sortModel: deserializeSortModelFromSearchParams() ?? DEFAULT_DATA_GRID_USERS_SORT_MODEL,
        },
      }}
      isLoading={isFetchingUsers || isFetchingCustomers || isFetchingPartners}
      rows={(showDisabledUsers ? users : users?.filter((user) => user.isActive)) ?? []}
      slotProps={{
        toolbar: {
          showDisabledUsers: showDisabledUsers,
          authorizedRoles: getAuthorizedRoles(loggedInUser?.role),
          availableCustomerOptions: customerOptions,
          showCustomerFilter: isCurrentUserPartnerAdmin() || isCurrentUserAdmin(),
          onToggleDisabledUsersClick: setShowDisabledUsers,
        },
      }}
      slots={{
        toolbar: UsersDataGridToolbar,
      }}
    />
  )
}

export default Users
