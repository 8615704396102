import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { DialogContent, InputAdornment, Stack, Tooltip } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import FlagIcon from '@/components/dataDisplay/FlagIcon'
import CustomDialog from '@/components/feedback/CustomDialog'
import FormDialogActions from '@/components/feedback/FormDialogActions'
import SelectFieldController from '@/components/inputs/SelectFieldController'
import TextFieldController from '@/components/inputs/TextFieldController'
import { useAlertContext } from '@/contexts/AlertContext'
import { createPartner, ERROR_NAMES, updatePartner } from '@/features/partner/endpoints/partners'
import type { Partner } from '@/openapi/v1/msa-customer-partner-manager/partner'
import { errorHandler } from '@/utils/errorHandler'

interface PartnerFormDialogProps {
  open: boolean
  editMode: boolean
  onClose: () => void
  partner: Partner
  onSave: () => void
  locationInfos: LocationInfo[]
}

export const PartnerFormDialog = (props: PartnerFormDialogProps) => {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()

  const { open, editMode, onClose, partner, onSave, locationInfos } = props

  const form = useForm<Partner>({
    mode: 'onBlur',
    defaultValues: {
      name: partner?.name ?? '',
      countryCode: partner?.countryCode ?? locationInfos[0].country ?? '',
      partnerCode: partner?.partnerCode ?? '',
    },
  })

  const createPartnerMutation = useMutation({
    mutationFn: (variables: { partner: Partner }) => createPartner(variables.partner),
    onError: (err: any) => {
      if (
        err?.response?.data?.errorType === ERROR_NAMES.VALIDATION_FAILED &&
        err?.response?.data?.message.endsWith('partnerCode already exists')
      ) {
        form.setError('partnerCode', {
          type: 'alreadyExists',
          message: t('partners.form.symbolic_name_not_unique.error', {
            partnerCode: form.getValues().partnerCode,
          }).toString(),
        })
      } else {
        const error = errorHandler(err, t('partners.dialog.errors.create_partner_error'))

        pushAlert({
          severity: 'error',
          message: error.message,
        })
      }
    },
  })
  const updatePartnerMutation = useMutation({
    mutationFn: (variables: { partner: Partner }) => updatePartner(variables.partner),
  })

  useWatch({
    control: form.control,
  })

  async function onSubmit() {
    const partnerForm = form.getValues()
    const partnerToSave: Partner = {
      name: partnerForm.name,
      partnerCode: editMode
        ? partner.partnerCode
        : partnerForm.countryCode?.toUpperCase() + '-' + partnerForm.partnerCode,
      countryCode: partner.countryCode ?? partnerForm.countryCode,
      uuid: partner.uuid,
    }

    const mutation = editMode ? updatePartnerMutation : createPartnerMutation
    await mutation
      .mutateAsync({ partner: partnerToSave })
      .then(() => {
        onClose()
        onSave()
      })
      .catch((err) => {
        const error = errorHandler(
          err,
          editMode
            ? t('partners.dialog.errors.edit_partner_error', { partnerName: partnerToSave.name })
            : t('partners.dialog.errors.create_partner_error'),
        )

        pushAlert({
          severity: 'error',
          message: error.message,
        })
      })
  }

  function getCountryOptions() {
    return Array.from(new Set(locationInfos.map((locationInfo) => locationInfo.country))).map((countryCode) => ({
      value: countryCode,
      label: t(`common.country.${countryCode}`),
      id: countryCode,
      icon: <FlagIcon countryCode={countryCode} />,
    }))
  }

  return (
    <CustomDialog
      open={open}
      title={
        editMode
          ? t('partners.dialog.edit_title', {
              name: partner.name,
            })
          : t('partners.dialog.create_title')
      }
      onClose={onClose}
    >
      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack sx={{ flexDirection: 'column', gap: 3 }}>
              <TextFieldController
                required
                controllerProps={{
                  rules: {
                    required: t('partners.form.name_required.error'),
                  },
                }}
                label={t('common.name')}
                name="name"
              />

              <SelectFieldController
                fullWidth
                required
                controllerProps={{
                  rules: {
                    required: t('partners.form.country_required.error'),
                  },
                }}
                disabled={editMode}
                label={t('common.country.label')}
                name="countryCode"
                options={getCountryOptions()}
              />

              <Stack sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                <TextFieldController
                  required
                  InputProps={
                    editMode
                      ? {}
                      : {
                          startAdornment: (
                            <InputAdornment color={'disabled'} position="start" style={{ margin: 0 }}>
                              {form.getValues().countryCode?.toUpperCase() + '-'}
                            </InputAdornment>
                          ),
                        }
                  }
                  controllerProps={{
                    rules: {
                      required: t('partners.form.symbolic_name_required.error'),
                      pattern: !editMode
                        ? {
                            value: /^[A-Z0-9_]+$/,
                            message: t('partners.form.symbolic_name_pattern.error'),
                          }
                        : undefined,
                    },
                  }}
                  disabled={editMode}
                  label={t('partners.symbolic_name')}
                  name="partnerCode"
                  onChange={(event) => {
                    const value = event.target.value.toUpperCase()

                    form.setValue('partnerCode', value)
                  }}
                />

                <Tooltip title={t('partners.symbolic_name_hint')}>
                  <HelpOutlineIcon color="disabled" fontSize="medium" />
                </Tooltip>
              </Stack>
            </Stack>
          </DialogContent>

          <FormDialogActions onCancel={onClose} />
        </form>
      </FormProvider>
    </CustomDialog>
  )
}
