import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import AlarmOffIcon from '@mui/icons-material/AlarmOff'
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff'
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined'
import { Chip } from '@mui/material'
import type { ChipProps } from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'

import type { AvailabilityStatus } from '@/features/unavailability/types/availabilityStatus'

export const AvailabilityStatusChip = ({ status, size }: { status: AvailabilityStatus; size?: 'small' | 'medium' }) => {
  return <Chip size={size} sx={{ textTransform: 'uppercase' }} variant="outlined" {...getChipProps(status)} />
}

function getChipProps(status: AvailabilityStatus): Partial<ChipProps> {
  const { t } = useTranslation()
  switch (status) {
    case 'active': {
      return {
        color: 'success',
        icon: <AccessAlarmIcon />,
        label: t('customer_details.tabs.availability.status.active'),
      }
    }
    case 'deactivated': {
      return {
        color: 'error',
        icon: <AlarmOffIcon />,
        label: t('customer_details.tabs.availability.status.deactivated'),
      }
    }
    case 'waiting': {
      return {
        color: 'primary',
        icon: <PendingOutlinedIcon />,
        label: t('customer_details.tabs.availability.status.waiting'),
      }
    }
    case 'expired': {
      return {
        color: 'default',
        icon: <HistoryToggleOffIcon />,
        label: t('customer_details.tabs.availability.status.expired'),
      }
    }
    default: {
      return {
        color: 'default',
        label: t('customer_details.tabs.availability.status.unknown'),
      }
    }
  }
}
