import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import SiteCompanyInfoView from '@/features/site/components/SiteCompanyInfoView'
import { SiteSelector } from '@/features/site/components/SiteSelector'
import { SiteDetailsRouteInformation } from '@/features/site/pages/constants'
import { useSiteDetails } from '@/features/site/pages/SiteDetails'

export const SiteDetailsCompanyInfo = () => {
  const { site, siteDetailsCommonBreadcrumbs, setPageConfig } = useSiteDetails()
  const { t } = useTranslation()

  useEffect(() => {
    setPageConfig({
      title: t('component.page_header.company_info'),
      pageTag: 'customer-details-company-info',
      breadcrumbs: siteDetailsCommonBreadcrumbs,
      appBarContent: <SiteSelector siteUuid={site.uuid} />,
      activeTab: SiteDetailsRouteInformation.SITE_INFO.navigationPath,
    })
  }, [])

  return <SiteCompanyInfoView site={site} />
}
