import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Stack } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import MainContentContainer from '@/components/layouts/MainContentContainer'
import { AggregatedPowerMeasurement } from '@/features/site/components/power_measurements/AggregatedPowerMeasurement'
import GreyAndWhiteCloud from '@/features/site/components/power_measurements/GreyAndWhiteCloud'
import WhiteCloud from '@/features/site/components/power_measurements/WhiteCloud'
import { SiteSelector } from '@/features/site/components/SiteSelector'
import { SiteDetailsRouteInformation } from '@/features/site/pages/constants'
import { useSiteDetails } from '@/features/site/pages/SiteDetails'

export const SitePowerMeasurements = () => {
  const { t, i18n } = useTranslation()

  const { site, siteDetailsCommonBreadcrumbs, setPageConfig } = useSiteDetails()
  const { siteUuid } = useParams()

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.power_measurements.title'),
      pageTag: 'customer-details-power-measurements',
      breadcrumbs: siteDetailsCommonBreadcrumbs,
      appBarContent: (
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <SiteSelector siteUuid={siteUuid} />
          </Stack>
        </LocalizationProvider>
      ),
      activeTab: SiteDetailsRouteInformation.POWER_MEASUREMENTS.navigationPath,
    })
  }, [])

  return (
    <MainContentContainer>
      <CustomTypography fontSize={20} variant="h4">
        {t('customer_measurements.power_measurements.title')}
      </CustomTypography>

      <Stack alignItems="center" direction="column" justifyContent="center" sx={{ width: '100%', height: '100%' }}>
        <WhiteCloud style={{ position: 'absolute', top: 450, left: 900 }} />
        <GreyAndWhiteCloud style={{ position: 'absolute', top: 400, right: 600 }} />

        <AggregatedPowerMeasurement customerUuid={site.uuid!} location={site.location!} />
        <Stack direction="row" spacing={1} sx={{ my: 2 }}>
          <InfoOutlinedIcon color={'info'} width={'20px'} />
          <CustomTypography variant="body1">
            {t('customer_measurements.power_measurements.information')}
          </CustomTypography>
        </Stack>
      </Stack>
    </MainContentContainer>
  )
}
