import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'

import type { SiteMarketProgramContract } from '@/features/site/types/site'
import type { MarketProgramType } from '@/types/marketProgramType'

type MarketProgramsReadTableProps = {
  marketProgramContracts: SiteMarketProgramContract[]
}

export function MarketProgramsReadTable({ marketProgramContracts }: Readonly<MarketProgramsReadTableProps>) {
  const { t } = useTranslation()

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('sites.add_new.form.market_programs.table.columns.name')}</TableCell>
          <TableCell>{t('sites.add_new.form.market_programs.table.columns.revenue_share_fraction')}</TableCell>
          <TableCell>{t('sites.add_new.form.market_programs.table.columns.rounding_error_in_watts')}</TableCell>
          <TableCell>{t('sites.add_new.form.market_programs.table.columns.sold_capacity_algorithm')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {marketProgramContracts?.map((contract) => (
          <TableRow key={contract.marketProgram?.id}>
            <TableCell>{t(`common.market_program.${contract.marketProgram?.type as MarketProgramType}`)}</TableCell>
            <TableCell>{contract.revenueSharingFraction}</TableCell>
            <TableCell>{contract.roundingErrorInWatts ? contract.roundingErrorInWatts / 1000 : undefined}</TableCell>
            <TableCell>
              {contract.soldCapacityAlgorithmType
                ? t(
                    `sites.add_new.form.market_programs.table.sold_capacity_algorithm_options.${contract.soldCapacityAlgorithmType}`,
                  )
                : undefined}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
