import { t } from 'i18next'
import { useEffect } from 'react'

import SiteMarketProgramsView from '@/features/site/components/marketPrograms/SiteMarketProgramsView'
import { SiteSelector } from '@/features/site/components/SiteSelector'
import { SiteDetailsRouteInformation } from '@/features/site/pages/constants'
import { useSiteDetails } from '@/features/site/pages/SiteDetails'

export const SiteDetailsMarketPrograms = () => {
  const { site, isFetchingSite, setPageConfig, siteDetailsCommonBreadcrumbs } = useSiteDetails()

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.market_programs.title'),
      breadcrumbs: siteDetailsCommonBreadcrumbs,
      pageTag: 'customer-details-market-programs',
      activeTab: SiteDetailsRouteInformation.MARKET_PROGRAMS.navigationPath,
      appBarContent: <SiteSelector siteUuid={site.uuid} />,
    })
  }, [])

  if (!site || isFetchingSite) {
    return null
  }

  return <SiteMarketProgramsView site={site} />
}
