import { isIBAN } from 'validator'

// Returns true if the iban is valid
// Returns false if the iban is invalid
export function validateIban(iban: string | null): boolean {
  if (!iban) {
    return true
  }
  const ibanWithoutSpaces = formatIban(iban)
  return isIBAN(ibanWithoutSpaces)
}

// Removes inserted white spaces from iban
export function formatIban(iban: string | null): string {
  if (!iban) {
    return ''
  }
  return iban.replace(/ /g, '')
}

export function getPlaceholderIbanByCountryCode(countryCode: string): string {
  switch (countryCode) {
    case 'nl':
      return 'NL91 ABNA 0417 1643 00'
    case 'gb':
      return 'GB29 NWBK 6016 1331 9268 19'
    case 'fi':
      return 'FI21 1234 5600 0007 85'
    case 'gr':
      return 'GR16 0110 1250 0000 0001 2300 695'
    case 'se':
      return 'SE45 5000 0000 0583 9825 7466'
    case 'no':
      return 'NO93 8601 1117 947'
    default:
      return ''
  }
}
