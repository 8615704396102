import { Close, ContentCopyOutlined } from '@mui/icons-material'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined'
import { DialogActions, DialogContent, DialogContentText, Stack } from '@mui/material'
import type { FC } from 'react'
import { useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import CustomDialog from '@/components/feedback/CustomDialog'
import CustomButton from '@/components/inputs/CustomButton'
import { useAlertContext } from '@/contexts/AlertContext'
import DisplayTimestampFrequencyPattern from '@/features/activationGroup/components/DisplayTimestampFrequencyPattern'

type Props = {
  pattern: string
}

const ViewPrequalificationPatternButton: FC<Props> = ({ pattern }) => {
  const { t } = useTranslation()
  const [showDialog, setShowDialog] = useState(false)
  const { pushAlert } = useAlertContext()

  const onCopyButtonClick = async () => {
    await navigator.clipboard.writeText(pattern)

    pushAlert({
      message: t('activation_groups.prequalification_pattern.copied_to_clipboard'),
      severity: 'success',
    })
  }

  function openDialog() {
    setShowDialog(true)
  }

  function closeDialog() {
    setShowDialog(false)
  }

  return (
    <>
      <CustomButton
        data-testid="view-prequalification-pattern"
        startIcon={<GetAppOutlinedIcon />}
        variant={'contained'}
        onClick={openDialog}
      >
        {t('activation_groups.prequalification_pattern.view')}
      </CustomButton>

      <CustomDialog
        aria-labelledby={t('activation_groups.prequalification_pattern.title')}
        open={showDialog}
        title={t('activation_groups.prequalification_pattern.title')}
        onClose={closeDialog}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <DisplayTimestampFrequencyPattern pattern={pattern} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack sx={{ flexDirection: 'row', gap: 1 }}>
            <CustomButton
              color="primary"
              data-testid={`copy-button`}
              fullWidth={false}
              startIcon={<ContentCopyOutlined />}
              type="reset"
              variant="outlined"
              onClick={onCopyButtonClick}
            >
              {t('activation_groups.prequalification_pattern.copy_to_clipboard')}
            </CustomButton>
            <CustomButton
              color="primary"
              data-testid={`close-button`}
              fullWidth={false}
              startIcon={<Close />}
              type="reset"
              variant="outlined"
              onClick={closeDialog}
            >
              {t('common.button.close')}
            </CustomButton>
          </Stack>
        </DialogActions>
      </CustomDialog>
    </>
  )
}

export default ViewPrequalificationPatternButton
