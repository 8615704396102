import { Stack } from '@mui/material'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import { RevenueMonthlyAccordions } from '@/features/site/components/revenue/RevenueMonthlyAccordions'
import type { RevenueDataRow, SiteRevenuePerMarketProgram } from '@/features/site/types/revenue'
import { getRevenueDataByYear } from '@/features/site/utils/revenueUtils/formatRevenueData'

type SiteRevenueDataGridProps = {
  revenues: SiteRevenuePerMarketProgram | null
}

export function SiteRevenueDataGrid({ revenues }: Readonly<SiteRevenueDataGridProps>) {
  if (!revenues) {
    return null
  }

  const dataByTimestamp = aggregateRevenueDataByTimestamp(revenues)

  if (dataByTimestamp.length === 0) {
    return null
  }

  // It groups revenue data by year
  const dataByYear = getRevenueDataByYear(dataByTimestamp)

  return (
    <Stack spacing={4}>
      {Object.entries(dataByYear).map(([year, revenueData]) => (
        <Stack key={year} spacing={2}>
          <CustomTypography variant="h2">{year}</CustomTypography>
          <RevenueMonthlyAccordions isLoadingRevenueTimeSeries={false} revenueData={revenueData} />
        </Stack>
      ))}
    </Stack>
  )
}

/**
 * Aggregates revenue data by timestamp from a map of revenue data rows.
 * The function groups the data by timestamp and sums up the `capacity`, `soldCapacity`, and `revenue` fields.
 * A unique ID is assigned to each aggregated entry, starting from 1 and incrementing with each new entry.
 *
 * @param data - A map where each key is a market program, and each value is an array of `RevenueDataRow` objects or `null`.
 *
 * @returns An array of aggregated `RevenueDataRow` objects, with summed values for `capacity`, `soldCapacity`, and `revenue`,
 *          and a unique `id` assigned to each timestamp group.
 *
 * @example
 * // Input
 * const data = new Map<string, RevenueDataRow[] | null>([
 *   ['FCR-D UP', [
 *     { timestamp: 1725714000000, capacity: 100, soldCapacity: 80, revenue: 1000 },
 *     { timestamp: 1725717600000, capacity: 150, soldCapacity: 120, revenue: 1500 }
 *   ]],
 *   ['FCR-D DOWN', [
 *     { timestamp: 1725714000000, capacity: 200, soldCapacity: 180, revenue: 2000 },
 *     { timestamp: 1725717600000, capacity: 250, soldCapacity: 220, revenue: 2500 }
 *   ]],
 * ]);
 *
 * // Output
 * [
 *   {
 *     id: 1,
 *     timestamp: '2025-01-07T00:00:00Z',
 *     capacity: 300,
 *     soldCapacity: 260,
 *     revenue: 3000
 *   },
 *   {
 *     id: 2,
 *     timestamp: '2025-01-07T01:00:00Z',
 *     capacity: 400,
 *     soldCapacity: 340,
 *     revenue: 4000
 *   }
 * ]
 */
function aggregateRevenueDataByTimestamp(data: Map<string, RevenueDataRow[] | null>) {
  const aggregatedMap = new Map<string, RevenueDataRow>()

  data.forEach((revenueDataRows) => {
    if (!revenueDataRows) return

    revenueDataRows.forEach(({ timestamp, capacity, soldCapacity, revenue, id }) => {
      const timestampKey = timestamp.toISO() // Use ISO string as the key for the timestamp

      if (!aggregatedMap.has(timestampKey!)) {
        aggregatedMap.set(timestampKey!, {
          id,
          timestamp,
          capacity: 0,
          soldCapacity: 0,
          revenue: 0,
        })
      }

      const entry = aggregatedMap.get(timestampKey!) as RevenueDataRow
      entry.capacity += capacity
      entry.soldCapacity += soldCapacity
      entry.revenue += revenue
    })
  })

  return Array.from(aggregatedMap.values())
}
