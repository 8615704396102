import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useTranslation } from 'react-i18next'

import ConfirmDialogActions from '@/components/feedback/ConfirmDialogActions'
import CustomDialog from '@/components/feedback/CustomDialog'
import { useAlertContext } from '@/contexts/AlertContext'
import { useAbortActivationScheduleMutation } from '@/features/activationGroup/hooks/useAbortActivationScheduleMutation'
import { errorHandler } from '@/utils/errorHandler'

type AbortActivationScheduleDialogProps = {
  activationScheduleUuid: string
  open: boolean
  onClose: () => void
}

const AbortActivationScheduleDialog = ({
  activationScheduleUuid,
  open,
  onClose,
}: AbortActivationScheduleDialogProps) => {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()

  const { abortActivationSchedule } = useAbortActivationScheduleMutation()

  async function handleSubmit() {
    try {
      await abortActivationSchedule(activationScheduleUuid)
      pushAlert({ message: t('activation_groups.abort_schedule_button.success_message'), severity: 'success' })
      onClose()
    } catch (err) {
      const error = errorHandler(err, t('activation_groups.abort_schedule_button.error_message'))
      pushAlert({ message: error.message, severity: 'error' })
    }
  }

  return (
    <CustomDialog
      aria-describedby={t('activation_groups.abort_schedule_button.description')}
      aria-labelledby={t('activation_groups.abort_schedule_button.title')}
      open={open}
      title={t('activation_groups.abort_schedule_button.title')}
      onClose={onClose}
    >
      <DialogContent>
        <DialogContentText>{t('activation_groups.abort_schedule_button.description')}</DialogContentText>
      </DialogContent>

      <ConfirmDialogActions onCancel={onClose} onConfirm={handleSubmit} />
    </CustomDialog>
  )
}

export default AbortActivationScheduleDialog
