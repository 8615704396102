import { alpha, Chip, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import type { GridRowSelectionModel } from '@mui/x-data-grid'
import React from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import type { Customer } from '@/features/site/types/customer'

interface SelectedCustomersProps {
  customers: Customer[]
  selection: GridRowSelectionModel
  onRemove: any
}

export function SelectedCustomers({ customers, selection, onRemove }: Readonly<SelectedCustomersProps>) {
  const theme = useTheme()
  const { t } = useTranslation()
  const selectedVisibleCustomers = customers.filter((customer) => {
    return selection.includes(customer.uuid as string)
  })
  const backgroundColor = selectedVisibleCustomers.length > 0 ? theme.palette.primary.main : theme.palette.common.black
  return (
    <Stack
      mb={2}
      p={2}
      sx={{
        backgroundColor: alpha(backgroundColor, 0.08),
      }}
    >
      {selection.length > 0 ? (
        <div>
          <CustomTypography gutterBottom variant="body1">
            {t('user_form.form.customer_access_selected_customers')}
          </CustomTypography>
          <Stack useFlexGap direction="row" flexWrap="wrap" mt={2} spacing={1}>
            {selectedVisibleCustomers.map((customer) => (
              <Chip key={customer?.uuid} label={customer?.name} onDelete={() => onRemove(customer?.uuid)} />
            ))}
          </Stack>
        </div>
      ) : (
        <div>
          <CustomTypography gutterBottom variant="body1">
            {t('user_form.form.customer_access_nothing_selected')}
          </CustomTypography>
        </div>
      )}
    </Stack>
  )
}
