import { MarketProgram } from '@/features/bidding/constants'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'

const FINLAND_BIDDABLE_MARKET_PROGRAMS: MarketProgram[] = [
  MarketProgram.NORDICS_FCRD_DOWN_STATIC,
  MarketProgram.NORDICS_FCRD_UP_STATIC,
  MarketProgram.NORDICS_FCRD_DOWN_DYNAMIC,
  MarketProgram.NORDICS_FCRD_UP_DYNAMIC,
  MarketProgram.NORDICS_FCRN,
  MarketProgram.FCRN,
  MarketProgram.FFR_UP,
]

export function useCountryBiddableMarketProgramMap() {
  const { isEnabled } = useFeatureToggle()
  return {
    ee: [],
    es: [],
    dk: [],
    nl: [],
    fi: isEnabled('FLEXPORTAL_MFRR_FI_BIDDING_ENABLED')
      ? [...FINLAND_BIDDABLE_MARKET_PROGRAMS, MarketProgram.MFRR_DOWN, MarketProgram.MFRR_UP]
      : FINLAND_BIDDABLE_MARKET_PROGRAMS,
    gr: [MarketProgram.MFRR_UP, MarketProgram.MFRR_DOWN],
    no: [],
    se: [
      MarketProgram.FCRD_UP,
      MarketProgram.FCRD_DOWN,
      MarketProgram.MFRR_UP,
      MarketProgram.NORDICS_FCRD_DOWN_STATIC,
      MarketProgram.NORDICS_FCRD_UP_STATIC,
      MarketProgram.NORDICS_FCRD_DOWN_DYNAMIC,
      MarketProgram.NORDICS_FCRD_UP_DYNAMIC,
    ],
  }
}
