import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { SiteActivationsView } from '@/features/activation/components/SiteActivationsView'
import { SiteDetailsRouteInformation } from '@/features/site/pages/constants'
import { useSiteDetails } from '@/features/site/pages/SiteDetails'

export const SiteDetailsActivations = () => {
  const { site, siteDetailsCommonBreadcrumbs, setPageConfig } = useSiteDetails()
  const { t } = useTranslation()

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.activations'),
      pageTag: 'customer-details-activations',
      breadcrumbs: siteDetailsCommonBreadcrumbs,
      activeTab: SiteDetailsRouteInformation.ACTIVATIONS.navigationPath,
    })
  }, [])

  return (
    <SiteActivationsView
      activationDetailsPath={SiteDetailsRouteInformation.ACTIVATION_DETAILS.navigationPath.replace(
        ':siteUuid',
        site.uuid as string,
      )}
      site={site}
    />
  )
}
