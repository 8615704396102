import { useEffect } from 'react'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import MainContentContainer from '@/components/layouts/MainContentContainer'
import SpotOnEboilerScheduleFormCreate from '@/features/resource/components/SpotOnEboilerScheduleFormCreate'
import SpotOnEboilerScheduleFormEdit from '@/features/resource/components/SpotOnEboilerScheduleFormEdit'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useResourceSpotOnEboilerDetails } from '@/features/resource/pages/ResourceSpotOnEboilerDetailsPage'

type SpotOnEboilerScheduleDetailsPageProps = {
  isCreateMode: boolean
}

function SpotOnEboilerScheduleDetailsPage({ isCreateMode }: Readonly<SpotOnEboilerScheduleDetailsPageProps>) {
  const { resource, scheduledResource, setActivePage } = useResourceSpotOnEboilerDetails()

  useEffect(() => {
    setActivePage(ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_SCHEDULES.routePath)
  }, [])

  return (
    <MainContentContainer sx={{ marginBottom: 3 }}>
      <CustomTypography gutterBottom variant="h3">
        {resource?.resourceName}
      </CustomTypography>
      {isCreateMode ? (
        <SpotOnEboilerScheduleFormCreate scheduledResource={scheduledResource} />
      ) : (
        <SpotOnEboilerScheduleFormEdit scheduledResource={scheduledResource} />
      )}
    </MainContentContainer>
  )
}

export default SpotOnEboilerScheduleDetailsPage
