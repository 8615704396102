import AddIcon from '@mui/icons-material/Add'
import PublicIcon from '@mui/icons-material/Public'
import { Stack, Switch, Tooltip } from '@mui/material'
import type { GridColDef, GridRenderCellParams, GridRowParams, GridSortModel } from '@mui/x-data-grid'
import type { ChangeEvent } from 'react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import ChipList from '@/components/dataDisplay/ChipList'
import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import FlagIcon from '@/components/dataDisplay/FlagIcon'
import CustomButton from '@/components/inputs/CustomButton'
import PageHeader from '@/components/layouts/PageHeader'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import { SiteDisableConformationDialog } from '@/features/site/components/SiteDisableConformationDialog'
import SitesDataGridToolbar, {
  DEFAULT_DATA_GRID_CUSTOMERS_FILTER_MODEL,
} from '@/features/site/components/SitesDataGridToolbar'
import { useCustomersQuery } from '@/features/site/hooks/useCustomersQuery'
import { useUpdateCustomerStatusMutation } from '@/features/site/hooks/useUpdateCustomerStatusMutation'
import type { Customer } from '@/features/site/types/customer'
import type { MarketProgramType } from '@/types/marketProgramType'
import { deserializeFilterModelFromSearchParams } from '@/utils/datagrid/filters'
import { deserializePaginationModelFromSearchParams } from '@/utils/datagrid/pagination'
import { deserializeSortModelFromSearchParams } from '@/utils/datagrid/sorting'

const DEFAULT_DATA_GRID_CUSTOMERS_SORT_MODEL: GridSortModel = [{ field: 'name', sort: 'asc' }]

function Sites() {
  const { loggedInUser } = useAuth()
  const { isEnabled } = useFeatureToggle()

  // Disable customer confirmation dialog
  const [openDisableCustomerConfirmationDialog, setOpenDisableCustomerConfirmationDialog] = useState(false)
  const [customerToDisable, setCustomerToDisable] = useState<Customer | null>(null)
  const [pendingCustomerStatus, setPendingCustomerStatus] = useState<boolean | null>(null)

  const { t } = useTranslation()
  const { updateCustomerStatus } = useUpdateCustomerStatusMutation()

  const [showDisabledCustomers, setShowDisabledCustomers] = useState(false)

  const { customers, isFetching } = useCustomersQuery()

  function getCountryOptions(): CountryIdentifier[] {
    if (!customers) return []

    return Array.from(
      new Set(customers.filter((c) => !!c.countryCode).map((c) => c.countryCode!.toLowerCase() as CountryIdentifier)),
    )
  }

  function isCurrentUserAdmin() {
    return loggedInUser?.role === USER_ROLES.ADMINISTRATORS.value
  }

  function getMarketPrograms(): MarketProgramType[] {
    const marketPrograms = new Set<MarketProgramType>()

    customers?.forEach((customer) =>
      customer.services?.forEach((marketProgram) => {
        if (marketProgram.type) {
          marketPrograms.add(marketProgram.type)
        }
      }),
    )

    return Array.from(marketPrograms)
  }

  const handleSwitchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, customer: Customer) => {
      const isChecked = event.target.checked
      if (!isChecked) {
        setCustomerToDisable(customer)
        setPendingCustomerStatus(isChecked)
        setOpenDisableCustomerConfirmationDialog(true)
      } else {
        updateCustomerStatus({
          isActive: isChecked,
          uuid: customer.uuid ?? '',
          location: customer.location ?? '',
          name: customer.name ?? '',
        }).then(() => {
          customer.isActive = isChecked
        })
      }
    },
    [updateCustomerStatus],
  )

  const handleConfirmDisable = useCallback(() => {
    if (customerToDisable && pendingCustomerStatus !== null) {
      updateCustomerStatus({
        isActive: pendingCustomerStatus,
        uuid: customerToDisable.uuid ?? '',
        location: customerToDisable.location ?? '',
        name: customerToDisable.name ?? '',
      }).then(() => {
        customerToDisable.isActive = pendingCustomerStatus
        setOpenDisableCustomerConfirmationDialog(false)
        setCustomerToDisable(null)
        setPendingCustomerStatus(null)
      })
    }
  }, [customerToDisable, pendingCustomerStatus, updateCustomerStatus])

  const handleCancelDisable = useCallback(() => {
    setOpenDisableCustomerConfirmationDialog(false)
    setCustomerToDisable(null)
    setPendingCustomerStatus(null)
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'isActive',
      headerName: t('common.enabled'),
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<Customer>) => (
        <Switch
          checked={params.row.isActive}
          color="primary"
          disabled={!isCurrentUserAdmin()}
          onChange={(event) => handleSwitchChange(event, params.row)}
          onClick={(event) => {
            event.stopPropagation()
          }}
        />
      ),
    },
    {
      field: 'name',
      headerName: t('common.name'),
      flex: 1,
    },
    {
      field: 'address',
      headerName: t('common.address'),
      flex: 2,
    },
    {
      field: 'partner',
      headerName: t('common.partner'),
      flex: 1,
      valueGetter: (_, customer: Customer) => customer.partner?.name,
    },
    {
      field: 'country',
      headerName: t('common.country.label'),
      flex: 0.5,
      type: 'singleSelect',
      valueOptions: getCountryOptions(),
      getOptionLabel: (value) => (value ? t(`common.country.${value as CountryIdentifier}`) : '-'),
      valueGetter: (_, customer: Customer) => customer.countryCode?.toLowerCase(),
      renderHeader: () => (
        <Tooltip title={t('common.country.label')}>
          <PublicIcon />
        </Tooltip>
      ),
      renderCell: (params) => (
        <Stack sx={{ flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
          <FlagIcon countryCode={params.row.countryCode?.toLowerCase()} />
        </Stack>
      ),
    },
    {
      field: 'services',
      headerName: t('customer.market_programs'),
      flex: 2,
      sortable: false,
      valueGetter: (_, customer: Customer) => customer.services?.map((service) => service.type),
      renderCell: (params: GridRenderCellParams<Customer>) => (
        <ChipList
          chips={
            params.row.services?.map((service) => ({
              id: service.id?.toString() || '',
              label: service.type ? t(`common.market_program.${service.type}`) : '-',
              sx: { cursor: 'inherit' },
            })) ?? []
          }
          limit={2}
        />
      ),
    },
  ]

  return (
    <>
      <PageHeader
        isRoot
        appBarContent={
          <Stack display="flex" flexDirection="row" justifyContent="flex-end" sx={{ width: '100%' }}>
            {isCurrentUserAdmin() && isEnabled('FLEXPORTAL_SITE_CREATE_ENABLED') && (
              <CustomButton
                color="inheritText"
                component={Link}
                startIcon={<AddIcon />}
                to="/sites/create"
                variant="contained"
              >
                {t(`sites.add_new.title`)}
              </CustomButton>
            )}
          </Stack>
        }
        pageTag={'customers'}
        pageTitle={t('component.page_header.customers')}
      />
      <CustomDataGrid
        clickableRows={{
          navigateTo: (params: GridRowParams<Customer>) => `/sites/${params.row.uuid}`,
        }}
        columnVisibilityModel={{
          country: isCurrentUserAdmin() || false,
        }}
        columns={columns}
        getRowId={(row) => row.uuid}
        initialState={{
          pagination: {
            paginationModel: deserializePaginationModelFromSearchParams() ?? DEFAULT_DATA_GRID_PAGINATION_MODEL,
          },
          filter: {
            filterModel: deserializeFilterModelFromSearchParams() ?? DEFAULT_DATA_GRID_CUSTOMERS_FILTER_MODEL,
          },
          sorting: {
            sortModel: deserializeSortModelFromSearchParams() ?? DEFAULT_DATA_GRID_CUSTOMERS_SORT_MODEL,
          },
        }}
        isLoading={isFetching}
        rows={(showDisabledCustomers ? customers : customers?.filter((customer) => customer.isActive)) ?? []}
        slotProps={{
          toolbar: {
            countryOptions: getCountryOptions(),
            showCountryFilter: isCurrentUserAdmin() || false,
            showDisabledCustomers,
            marketProgramTypes: getMarketPrograms(),
            onToggleDisabledCustomersClick: setShowDisabledCustomers,
          },
        }}
        slots={{
          toolbar: SitesDataGridToolbar,
        }}
      />
      {customerToDisable && (
        <SiteDisableConformationDialog
          customerName={customerToDisable.name}
          open={openDisableCustomerConfirmationDialog}
          onCancel={handleCancelDisable}
          onConfirm={handleConfirmDisable}
        />
      )}
    </>
  )
}

export default Sites
