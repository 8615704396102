import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import PageHeaderWithoutBreadcrumbs from '@/components/layouts/PageHeaderWithoutBreadcrumbs'
import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import Dashboard from '@/features/dashboard/pages/Dashboard'

export function LandingPage() {
  const { loggedInUser } = useAuth()
  const { t } = useTranslation()

  if (loggedInUser?.role === USER_ROLES.CUSTOMER_MANAGERS.value) {
    return <Navigate replace to="/sites" />
  }

  return (
    <>
      <PageHeaderWithoutBreadcrumbs pageTag={'dashboard'} pageTitle={t('component.page_header.dashboard')} />
      <Dashboard />
    </>
  )
}
