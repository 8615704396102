import { FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Radio, RadioGroup } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import TextFieldController from '@/components/inputs/TextFieldController'
import { USER_ROLES } from '@/constants/userRoles'
import MFASwitch from '@/features/user/components/form/MFASwitch'
import { DEFAULT_MFA_SETTINGS } from '@/features/user/constants/mfa'
import type { MfaPreferredOption, User } from '@/features/user/types/user'

type SecuritySectionProps = {
  user: Partial<User>
}

function SecuritySection({ user }: Readonly<SecuritySectionProps>) {
  const { t } = useTranslation()
  const { getValues, setValue } = useFormContext<User>()

  const role = getValues('role')
  const selectedPreferredOption = getValues('mfaSettings.preferredOption')
  const hasSelectedPreferredOptionForMFA = selectedPreferredOption !== null

  return (
    <>
      <MFASwitch
        disabled={role === USER_ROLES.ADMINISTRATORS.value || role === USER_ROLES.PARTNER_ADMINISTRATORS.value}
        displayHelperText={false}
        error={false}
        isChecked={hasSelectedPreferredOptionForMFA}
        onChange={(_, checked) => {
          if (!checked) {
            setValue('mfaSettings', DEFAULT_MFA_SETTINGS)
            return
          }

          setValue('mfaSettings.preferredOption', user.mfaSettings?.preferredOption ?? 'sms')
        }}
      />
      {hasSelectedPreferredOptionForMFA && (
        <>
          <FormControl sx={{ marginBottom: 2, marginTop: 2 }}>
            <FormLabel id="mfa-method-radio-group" sx={(theme) => ({ ...theme.typography.h5 })}>
              {t('user_profile.settings.security.choose_preferred_method')}
            </FormLabel>
            <RadioGroup
              aria-labelledby="mfa-method-radio-group"
              value={selectedPreferredOption}
              onChange={(e) => setValue('mfaSettings.preferredOption', e.target.value as MfaPreferredOption)}
            >
              <FormControlLabel
                control={<Radio />}
                label={t('user_profile.settings.security.option.sms')}
                value="sms"
              />
              <FormControlLabel
                disabled
                control={<Radio />}
                label={t('user_profile.settings.security.option.totp')}
                value="totp"
              />
            </RadioGroup>
          </FormControl>
          {selectedPreferredOption === 'sms' && <SmsOption />}
        </>
      )}
    </>
  )
}

function SmsOption() {
  const { t } = useTranslation()
  const { setValue } = useFormContext<User>()

  return (
    <Grid container>
      <Grid size={{ md: 5, sm: 6, xs: 12 }}>
        <TextFieldController
          label={t('user_form.form.phone_label')}
          name="phone"
          required={true}
          slotProps={{
            input: {
              startAdornment: <InputAdornment position="start">+</InputAdornment>,
            },
          }}
          onChange={(event) => {
            // users are only allowed to enter numbers
            const value = event.target.value.replace(/\D/g, '')

            setValue('phone', value)
          }}
        />
      </Grid>
    </Grid>
  )
}

export default SecuritySection
