import { Box, Card, CardContent, Divider, Stack, useMediaQuery, useTheme } from '@mui/material'
import ReactECharts from 'echarts-for-react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import type { SiteRevenuePerMarketProgram } from '@/features/site/types/revenue'
import {
  assignColorsToMarketPrograms,
  getChartDataUsingMapOfMarketProgramsGroupedByMonth,
  getRevenuesChartOptions,
  getRevenuesDistributionChartOptions,
} from '@/features/site/utils/revenueUtils/chartUtils'
import { getRevenueDataFromMarketProgramsByMonth } from '@/features/site/utils/revenueUtils/formatRevenueData'

const COMMON_CHART_STYLES = { height: '400px', width: '100%' }

type SiteRevenueChartsProps = {
  revenues: SiteRevenuePerMarketProgram | null
}

// Based on tests done on the browser, charts start to look bad when the screen width is less than 1750px
const EXTRA_LARGE_SCREEN_WIDTH = 1750

export function SiteRevenueCharts({ revenues }: Readonly<SiteRevenueChartsProps>) {
  const { t } = useTranslation()
  const theme = useTheme()
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up(EXTRA_LARGE_SCREEN_WIDTH))

  if (!revenues) {
    return null
  }

  const revenueDataFromMarketProgramsByMonth = getRevenueDataFromMarketProgramsByMonth(revenues)

  const { programs, seriesData } = getChartDataUsingMapOfMarketProgramsGroupedByMonth(
    revenueDataFromMarketProgramsByMonth,
    t,
  )
  const colors = assignColorsToMarketPrograms(programs)

  const barChartOptions = getRevenuesChartOptions(programs, seriesData, colors)
  const pieChartOptions = getRevenuesDistributionChartOptions(revenueDataFromMarketProgramsByMonth, colors, t)

  const shouldDisplayThePieChart = Object.keys(revenueDataFromMarketProgramsByMonth).length > 1

  return (
    <Card>
      <CardContent>
        <Stack
          direction={isExtraLargeScreen ? 'row' : 'column'}
          divider={<Divider flexItem orientation={isExtraLargeScreen ? 'vertical' : 'horizontal'} />}
          spacing={2}
        >
          <Box sx={{ flex: 1 }}>
            <CustomTypography variant="h4">{t('customer_revenue.chart.revenues_title')}</CustomTypography>
            <ReactECharts data-testid="revenue-bar-chart" option={barChartOptions} style={COMMON_CHART_STYLES} />
          </Box>

          {shouldDisplayThePieChart && (
            <Box sx={{ flex: 1 }}>
              <CustomTypography variant="h4">
                {t('customer_revenue.chart.revenues_distribution_title')}
              </CustomTypography>
              <ReactECharts data-testid="revenue-pie-chart" option={pieChartOptions} style={COMMON_CHART_STYLES} />
            </Box>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
