import type { TFunction } from 'i18next'

import type { CreateSiteForm } from '@/features/site/types/site'

export function formatValue(value?: string | null) {
  return !value ? '-' : value
}

export function formatNotificationsMessageTypes(
  notificationPreferences: CreateSiteForm['notificationPreferences'],
  t: TFunction,
): string {
  const isEmailEnabled = notificationPreferences?.isEmailEnabled ?? false
  const isSmsEnabled = notificationPreferences?.isSmsEnabled ?? false
  const listOfValues: string[] = []

  if (isEmailEnabled) {
    listOfValues.push(t('common.email'))
  }

  if (isSmsEnabled) {
    listOfValues.push(t('sites.add_new.form.notifications.message_types.sms'))
  }

  return listOfValues.length === 0 ? t('sites.add_new.form.notifications.message_types.empty') : listOfValues.join('; ')
}
