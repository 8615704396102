import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import PageHeader from '@/components/layouts/PageHeader'
import { useActivationGroupsQuery } from '@/features/activationGroup/hooks/useActivationGroupsQuery'
import CountrySelector from '@/features/bidding/components/CountrySelector'
import { BiddingTodoListDataGrid } from '@/features/bidding/components/todoList/BiddingTodoListDataGrid'
import { ALL_RESULTS } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { useBidHistoriesPageQuery } from '@/features/bidding/hooks/useBidHistoriesPageQuery'
import { useCountryBiddableMarketProgramMap } from '@/features/bidding/hooks/useCountryBiddableMarketProgramMap'
import { MarketDate } from '@/features/bidding/utils/date/marketDate'
import { composeBiddingTodoList } from '@/features/bidding/utils/model/composeBiddingTodoList'

export const BiddingTodoList = () => {
  const { t } = useTranslation()
  const { selectedCountry } = useBiddingContext()
  const countryBiddableMarketProgramMap = useCountryBiddableMarketProgramMap()
  const biddableMarketPrograms = countryBiddableMarketProgramMap[selectedCountry]

  const { activationGroups } = useActivationGroupsQuery({ countryCode: selectedCountry })
  const { bidHistoriesPage } = useBidHistoriesPageQuery({
    pagination: { pageSize: ALL_RESULTS, page: 0 },
    sorting: [],
    filter: {
      items: [{ field: 'fromDeliveryDay', operator: 'equals', value: new MarketDate() }],
    },
    countryFilter: selectedCountry,
  })

  return (
    <>
      <PageHeader
        appBarContent={
          <Box display="flex" flexDirection="row" sx={{ width: '100%' }}>
            <CountrySelector />
          </Box>
        }
        breadcrumbItems={[{ text: t('component.page_header.bidding'), to: '/bidding' }]}
        pageTitle={t('component.page_header.bid.todo_list')}
      />

      {bidHistoriesPage && activationGroups && biddableMarketPrograms ? (
        <BiddingTodoListDataGrid
          activationGroups={activationGroups}
          todoItems={composeBiddingTodoList(bidHistoriesPage.bidHistories, activationGroups, biddableMarketPrograms)}
        />
      ) : (
        <FullPageSpinner />
      )}
    </>
  )
}
