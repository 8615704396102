import type { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import { z } from 'zod'

import { validateIban } from '@/utils/iban'
import validatePhone from '@/utils/phone'

const MAX_PHONE_COUNT = 10

export const getCreateSiteFormSchema = (t: TFunction) =>
  z.object({
    name: z
      .string()
      .trim()
      .nonempty({
        message: t('sites.add_new.form.name.error'),
      }),
    partnerCode: z
      .string()
      .trim()
      .nonempty({ message: t('sites.add_new.form.partner_code.error') })
      .refine((val) => val !== null, t('sites.add_new.form.partner_code.error')),
    address: z.string().optional(),
    location: z.string().trim().min(2, t('sites.add_new.form.location.error')),
    countryCode: z
      .string({
        required_error: t('sites.add_new.form.location.error'),
      })
      .optional(),
    coordinates: z.object({ latitude: z.number(), longitude: z.number() }).optional(),
    symbolicName: z
      .string()
      .trim()
      .nonempty({ message: t('sites.add_new.form.symbolic_name.error') }),
    timezone: z.string(),
    siteType: z.string().optional(),
    contacts: z
      .array(
        z.object({
          name: z
            .string()
            .trim()
            .min(1, t('customer_details.tabs.company_info.contacts.name_required.error'))
            .refine((val) => val !== null, t('customer_details.tabs.company_info.contacts.name_required.error'))
            .nullable(),
          phone: z
            .string()
            .refine((val) => validatePhone(val), t('customer_details.tabs.company_info.phone_invalid.error'))
            .optional(),
          email: z
            .string()
            .trim()
            .email(t('customer_details.tabs.company_info.email_invalid.error'))
            .or(z.literal(''))
            .optional(),
          comment: z.string().optional(),
        }),
      )
      .optional(),
    notificationPreferences: z
      .object({
        phoneNumbers: z
          .string()
          .refine((val) => validatePhone(val), t('sites.add_new.form.notifications.errors.phone_invalid'))
          .array()
          .optional()
          .refine(
            (val) => !val || (val && val.length < MAX_PHONE_COUNT),
            t('sites.add_new.form.notifications.errors.phone_required'),
          ),
        language: z.string(),
        email: z
          .string()
          .trim()
          .email(t('sites.add_new.form.notifications.errors.email_invalid'))
          .or(z.literal(''))
          .nullable(),
        isSmsEnabled: z.boolean(),
        isEmailEnabled: z.boolean(),
      })
      .refine(({ isEmailEnabled, email }) => !isEmailEnabled || (isEmailEnabled && email && email.length > 0), {
        message: t('sites.add_new.form.notifications.errors.email_required'),
        path: ['email'],
      })
      .refine(
        ({ isSmsEnabled, phoneNumbers }) =>
          !isSmsEnabled || (isSmsEnabled && phoneNumbers && phoneNumbers[0].length > 0),
        {
          message: t('sites.add_new.form.notifications.errors.phone_required'),
          path: ['phoneNumbers', 0],
        },
      )
      .optional(),
    financialInformation: getFinancialInfoFormSchema(t),
    marketProgramContracts: getMarketProgramContractsFormSchema(t),
  })

export const getUpdateSiteInformationFormSchema = (t: TFunction) =>
  z.object({
    name: z
      .string()
      .trim()
      .nonempty({
        message: t('sites.add_new.form.name.error'),
      }),
    symbolicName: z
      .string()
      .trim()
      .nonempty({ message: t('sites.add_new.form.symbolic_name.error') }),
    contractDate: z.string().optional(),
    partnerCode: z
      .string()
      .trim()
      .nonempty({ message: t('sites.add_new.form.partner_code.error') })
      .refine((val) => val !== null, t('sites.add_new.form.partner_code.error')),
    timezone: z.string({
      message: t('sites.update_information.form.errors.timezone_required'),
    }),
    address: z.string().optional(),
    coordinates: z.object({ latitude: z.number(), longitude: z.number() }).optional(),
  })

export const getUpdateFinancialInfoFormSchema = (t: TFunction) =>
  z.object({
    financialInformation: getFinancialInfoFormSchema(t),
  })

export const getUpdateSiteMarketProgramContractsFormSchema = (t: TFunction) =>
  z.object({
    contractDate: z.custom((val) => val instanceof DateTime).optional(),
    marketProgramContracts: getMarketProgramContractsFormSchema(t),
  })

const getFinancialInfoFormSchema = (t: TFunction) =>
  z
    .object({
      iban: z
        .string()
        .refine((val) => validateIban(val), {
          message: t('sites.add_new.form.financial_information.errors.iban_invalid'),
        })
        .nullable(),
      ibanHolder: z.string().nullable(),
      isFinancialVisible: z.boolean().optional(),
    })
    // When IBAN holder is filled, IBAN bank account must be filled as well
    .refine(({ iban, ibanHolder }) => !ibanHolder || (ibanHolder && iban), {
      path: ['iban'],
      message: t('sites.add_new.form.financial_information.errors.iban_required'),
    })
    // When IBAN is filled, IBAN holder must be filled as well
    .refine(({ iban, ibanHolder }) => !iban || (ibanHolder && iban), {
      path: ['ibanHolder'],
      message: t('sites.add_new.form.financial_information.errors.iban_holder_required'),
    })
    .optional()

const getMarketProgramContractsFormSchema = (t: TFunction) =>
  z
    .array(
      z.object({
        marketProgramId: z.coerce.number({
          required_error: t('sites.add_new.form.market_programs.errors.id_required'),
        }),
        revenueSharingFraction: z.coerce
          .number()
          .min(0, { message: t('sites.add_new.form.market_programs.errors.revenue_sharing_fraction_invalid') })
          .max(100, { message: t('sites.add_new.form.market_programs.errors.revenue_sharing_fraction_invalid') })
          .nullable(),
        roundingErrorInKilowatts: z.coerce
          .number()
          .min(0, {
            message: t('sites.add_new.form.market_programs.errors.rounding_error_invalid'),
          })
          .nullable(),
        soldCapacityAlgorithmType: z
          .enum(['min', 'average'], {
            message: t('sites.add_new.form.market_programs.errors.sold_capacity_algorithm_invalid'),
          })
          .or(z.literal(''))
          .optional()
          .nullable(),
        isSelected: z.boolean(),
      }),
    )
    .refine((marketProgramContracts) => marketProgramContracts.some((marketProgram) => marketProgram.isSelected), {
      message: t('sites.add_new.form.market_programs.errors.min'),
    })
