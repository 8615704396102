import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { MagActivatedResource } from '@/features/activation/types/activatedResource'
import { GET_MAG_ACTIVATED_RESOURCES_API_ID, getActivatedResources } from '@/features/site/endpoints/magActivations'
import type { UseQueryOptions } from '@/types/queries'

type ActivatedResourcesFromMagQueryParams = {
  customerUuid?: string
  activationId?: number
  serviceId?: number
  location?: string
}
export type UseActivatedResourcesFromMagQueryResult = Omit<UseQueryResult, 'data'> & {
  magActivatedResources: MagActivatedResource[] | null
}

export function useActivatedResourcesFromMagQuery(
  params: ActivatedResourcesFromMagQueryParams,
  options?: UseQueryOptions<MagActivatedResource[]>,
): UseActivatedResourcesFromMagQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_MAG_ACTIVATED_RESOURCES_API_ID, params],
    queryFn: () => getActivatedResources(params.customerUuid, params.activationId, params.serviceId, params.location),
  })

  return { magActivatedResources: data, ...queryResult }
}
