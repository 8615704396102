import type { SelectChangeEvent } from '@mui/material/Select'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import EllipsisTypography from '@/components/dataDisplay/EllipsisTypography'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import { FIELD_WIDTH } from '@/constants/layout'
import { useCustomersQuery } from '@/features/site/hooks/useCustomersQuery'
import { getCustomerIdOptions } from '@/features/site/utils/getCustomerSortedOptions'
import { sortOptionsAlphabetically } from '@/utils/sortOptionsAlphabetically'

export function SiteSelector({ siteUuid }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [selectedSite, setSelectedSite] = useState<string | undefined>('')
  const { customers } = useCustomersQuery()

  const options =
    customers && customers.length > 0 ? sortOptionsAlphabetically<string>(getCustomerIdOptions(customers)) : []

  useEffect(() => {
    if (customers && customers.length > 0) {
      setSelectedSite(siteUuid)
    }
  }, [customers])

  function changeSite(evt: SelectChangeEvent) {
    const newSiteUuid = evt.target.value
    const newPath = pathname.replace(siteUuid, newSiteUuid)

    navigate(newPath)
  }

  return (
    <CustomSelectField
      autoWidth
      id="sites"
      label={t('customer_details.customer_selector')}
      options={options}
      renderValue={(value) => {
        const customer = customers?.find((customer) => customer.uuid === value)

        if (!customer) {
          return null
        }

        return <EllipsisTypography tooltipTitle={customer.name}>{customer.name}</EllipsisTypography>
      }}
      size="medium"
      sx={{ maxWidth: FIELD_WIDTH }}
      value={selectedSite}
      variant="outlinedWhite"
      onChange={changeSite}
    />
  )
}
